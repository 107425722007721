import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const SignpostSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	const wcagLabel = t('loading.wcag.processing', {
		namespace: LocalizeNameSpaceTypes.Global,
	});
	return (
		<LayoutGap className={'flex justify-between !gap-0'}>
			{Array.from(Array(10))
				.fill(null)
				.map((_, index) => {
					return (
						<div
							// eslint-disable-next-line react/no-array-index-key
							key={index}
							className={'flex gap-4 [ md:p-4 ]'}>
							<div className={'hidden [ md:block md:pt-6 ]'}>
								<GovSkeleton
									width={40 + 'px'}
									height={40 + 'px'}
									wcagLabel={wcagLabel}
									shape={'circle'}
								/>
							</div>
							<div className={'flex flex-col gap-2 w-full py-6'}>
								<div className={'flex flex-row justify-between'}>
									<div>
										<GovSkeleton
											width={generateRandomNumber(200, 260) + 'px'}
											height={27 + 'px'}
											wcagLabel={wcagLabel}
										/>
									</div>
									<GovSkeleton
										width={20 + 'px'}
										height={20 + 'px'}
										wcagLabel={wcagLabel}
									/>
								</div>
								<div className={'flex flex-col gap-0'}>
									<div className={'flex flex-row justify-between'}>
										<div style={{ width: generateRandomNumber(80, 90) + '%' }}>
											<GovSkeleton
												width={100 + '%'}
												height={20 + 'px'}
												wcagLabel={wcagLabel}
											/>
										</div>
										<GovSkeleton
											width={20 + 'px'}
											height={20 + 'px'}
											wcagLabel={wcagLabel}
										/>
									</div>
									<GovSkeleton
										width={generateRandomNumber(80, 90) + '%'}
										height={20 + 'px'}
										wcagLabel={wcagLabel}
									/>
									<GovSkeleton
										width={generateRandomNumber(80, 90) + '%'}
										height={20 + 'px'}
										wcagLabel={wcagLabel}
									/>
									<GovSkeleton
										width={generateRandomNumber(20, 90) + '%'}
										height={20 + 'px'}
										wcagLabel={wcagLabel}
									/>
								</div>
							</div>
						</div>
					);
				})}
		</LayoutGap>
	);
};
