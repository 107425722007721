import React from 'react';
import { useLocale } from '@gov-nx/core/service';
import { PageCode, useProfilROSContextInstance } from '@gov-nx/module/page';
import {
	ContentLayout,
	ErrorStatusBanner,
	InfoBanner,
	LayoutGap,
} from '@gov-nx/ui/web';
import { PFOListView } from './PFOListView';
import { POListView } from './POListView';
import { PersonInvolmentListView } from './PersonInvolmentListView';
import { ProfilROSSkeleton } from './ProfilROSSkeleton';

export interface RegistrOsobFormProps {
	navigation: React.ReactNode;
	code: PageCode;
}

export const ProfileROSView = ({ navigation, code }: RegistrOsobFormProps) => {
	const { extendedData, controls } = useProfilROSContextInstance();
	const { t } = useLocale(code);

	if (controls.initialLoading) {
		return <ProfilROSSkeleton />;
	}

	if (controls.initialError) {
		return <ErrorStatusBanner code={code} />;
	}
	return (
		<ContentLayout customClasses={'!pt-4'}>
			{navigation}
			{Array.isArray(extendedData) && extendedData.length > 0 ? (
				<LayoutGap gap={4}>
					<p className="text-s text-secondary-700">
						{t('zaznam.udaje-ros-text')}
					</p>
					<PFOListView />
					<POListView />
					<PersonInvolmentListView />
				</LayoutGap>
			) : (
				<InfoBanner
					icon={{ name: 'empty-file', type: 'colored' }}
					type="secondary"
					className={'!px-0 !py-4'}>
					<p className={'text-2xl [ md:text-3xl ]'}>
						{t('stav.chyba.slozka-je-prazdna')}
					</p>
					<p>{t('stav.chyba.nenasli-jsme-zadne-udaje')}</p>
				</InfoBanner>
			)}
		</ContentLayout>
	);
};
