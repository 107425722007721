import axios from 'axios';
import { useConfigurationStoreBase } from '@gov-nx/store/portal-obcana';

const instance = axios.create({
	headers: {
		'Content-Type': 'application/json',
	},
});
const baseInstance = axios.create({
	headers: {
		'Content-Type': 'application/json',
	},
});

const setup = () => {
	instance.interceptors.request.use((config) => {
		config.baseURL = useConfigurationStoreBase.getState().getCmsUrlPath();
		return config;
	});
	baseInstance.interceptors.request.use((config) => {
		config.baseURL = useConfigurationStoreBase.getState().getBaseCmsUrlPath();
		return config;
	});
};

export const axiosCmsInstance = instance;
export const axiosCmsBaseInstance = baseInstance;
export const setupCmsAxiosInterceptors = setup;
