import { Form602ListQueryParams } from '@gov-nx/api/portal-obcana';
import { useFormBuilder, useLocale } from '@gov-nx/core/service';
import { FormInputProps } from '@gov-nx/ui/types';
import { useInputDefinition } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';

interface Fields {
	text: FormInputProps;
}

export const useFilterForm = () => {
	const { t } = useLocale(PageCode['moje-podani']);

	const text = useInputDefinition({
		name: 'text',
		label: '',
		placeholder: t('formular.placeholders.text'),
	});

	return useFormBuilder<Pick<Form602ListQueryParams, 'text'>, Fields>([text]);
};
