import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { LocalizeNameSpaceTypes, useLocale } from '@gov-nx/core/service';
import { LayoutGap } from '@gov-nx/ui/web';

export const SmartFormSkeleton = () => {
	const { t } = useLocale(LocalizeNameSpaceTypes.Global);

	return (
		<LayoutGap
			gap={6}
			className={'mb-8'}>
			<LayoutGap gap={4}>
				<GovSkeleton
					width={'80px'}
					height={'22px'}
					variant={'secondary'}
					wcagLabel={t('loading.wcag.processing')}
					className={'mb-0 pb-0'}
				/>
				<div className={'flex items-center gap-5'}>
					<GovSkeleton
						width={'24px'}
						height={'24px'}
						shape={'circle'}
						wcagLabel={t('loading.wcag.processing')}
					/>
					<GovSkeleton
						width={'120px'}
						height={'20px'}
						wcagLabel={t('loading.wcag.processing')}
					/>
				</div>
				<div className={'flex items-center gap-5'}>
					<GovSkeleton
						width={'24px'}
						height={'24px'}
						shape={'circle'}
						wcagLabel={t('loading.wcag.processing')}
					/>
					<GovSkeleton
						width={'95px'}
						height={'20px'}
						wcagLabel={t('loading.wcag.processing')}
					/>
				</div>
			</LayoutGap>
			<div className={'[ md:w-[350px] ]'}>
				<GovSkeleton
					width={'100%'}
					height={'40px'}
					variant={'secondary'}
					wcagLabel={t('loading.wcag.processing')}
					className={'mb-0 pb-0'}
				/>
			</div>
		</LayoutGap>
	);
};
