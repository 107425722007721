import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useGlobalLocale } from '@gov-nx/core/service';
import { ContentLayout, LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const HealthDocumentationSkeleton = () => {
	const { t } = useGlobalLocale();
	return (
		<ContentLayout>
			{[0, 1, 2].map((i) => {
				return (
					<div
						key={i}
						className={
							'w-full py-6 px-4 border-b border-secondary-300 [ last-of-type:border-0 ]'
						}>
						<LayoutGap gap={2}>
							<div>
								<GovSkeleton
									width={generateRandomNumber(60, 100) + '%'}
									height={'30px'}
									variant={'secondary'}
									wcagLabel={t('loading.wcag.processing')}
								/>
								<GovSkeleton
									width={generateRandomNumber(30, 60) + '%'}
									height={'30px'}
									variant={'secondary'}
									wcagLabel={t('loading.wcag.processing')}
									className={'[ lg:hidden ]'}
								/>
							</div>
							<div>
								<div className={'[ lg:w-[390px] ]'}>
									<GovSkeleton
										width={'100%'}
										height={'24px'}
										variant={'secondary'}
										wcagLabel={t('loading.wcag.processing')}
									/>
								</div>
								<GovSkeleton
									width={'85px'}
									height={'24px'}
									variant={'secondary'}
									wcagLabel={t('loading.wcag.processing')}
									className={'[ lg:hidden ]'}
								/>
							</div>
							<div className={'flex flex-col gap-4 pt-2 [ md:flex-row ]'}>
								<div className={'[ md:w-[220px] ]'}>
									<GovSkeleton
										width={'100%'}
										height={'40px'}
										variant={'secondary'}
										wcagLabel={t('loading.wcag.processing')}
									/>
								</div>
								<div className={'[ md:w-[220px] ]'}>
									<GovSkeleton
										width={'100%x'}
										height={'40px'}
										variant={'secondary'}
										wcagLabel={t('loading.wcag.processing')}
									/>
								</div>
							</div>
						</LayoutGap>
					</div>
				);
			})}
		</ContentLayout>
	);
};
