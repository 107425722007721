import { createContext, ReactNode, useContext } from 'react';
import {
	DatovkaApiType,
	OsobyFyzickaOsobaDto,
	sendSubmissionQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useIdentity } from '@gov-nx/auth/common';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	FormWizardHook,
	PersonCommunicationData,
	useCountryCodes,
	usePersonCommunicationData,
	usePersonCommunicationForm,
	useRobPersonQuery,
	useWizard,
} from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { useDataBoxAccess } from '@gov-nx/module/data-box';
import { dataBoxParams, phoneShapeToString } from '@gov-nx/utils/common';
import { useKorespondencniVolbyLocale } from '../useKorespondencniVolbyLocale';
import {
	KorespondencniHlasovaniVymazFormStep1,
	KorespondencniHlasovaniVymazStep1,
	useFormStep1,
} from './useFormStep1';
import {
	KorespondencniHlasovaniVymazFormStep2,
	KorespondencniHlasovaniVymazStep2,
	useFormStep2,
} from './useFormStep2';
import {
	KorespondencniHlasovaniVymazFormStep3,
	KorespondencniHlasovaniVymazStep3,
	useFormStep3,
} from './useFormStep3';

interface KorespondencniHlasovaniVymazContext {
	wizard: FormWizardHook<VymazForm>;
	step1: KorespondencniHlasovaniVymazFormStep1;
	step2: KorespondencniHlasovaniVymazFormStep2;
	step3: KorespondencniHlasovaniVymazFormStep3;
	personCommunicationData: PersonCommunicationData;
	isLoading: boolean;
	isSubmitting: boolean;
	person?: OsobyFyzickaOsobaDto;
	selectedOffice?: string;
	selectedDistrict?: string;
	selectedCountry?: string;
	feedBackMethod?: 'POSTA' | 'OSOBNE' | 'DATOVA_SCHRANKA';
	hideAddressInputs: boolean;
	showConnectDataboxBanner: boolean;
	databoxTypes: DatovkaApiType['typSchranky'][];
}

type VymazForm = KorespondencniHlasovaniVymazStep1 &
	KorespondencniHlasovaniVymazStep2 &
	KorespondencniHlasovaniVymazStep3;

const ServiceContext =
	createContext<Nullable<KorespondencniHlasovaniVymazContext>>(null);

interface Props {
	children: ReactNode;
	onSubmitSuccess: () => void;
}

export const KorespondencniHlasovaniVymazContextProvider = ({
	children,
	onSubmitSuccess,
}: Props) => {
	const databoxTypes: DatovkaApiType['typSchranky'][] = ['FO', 'PFO'];

	const personQuery = useRobPersonQuery();
	const countryCodes = useCountryCodes();
	const { t } = useKorespondencniVolbyLocale();
	const { toastMessageSuccess } = useMessageEvents();
	const { isDataBoxLoginType } = useIdentity();
	const { areAnyConnected } = useDataBoxAccess({
		requiredTypes: databoxTypes,
	});

	const submitMutation = usePoMutation({
		mutationFn: async (variables: VymazForm) => {
			const { datovaSchrankaId, ...params } = dataBoxParams(variables);

			return sendSubmissionQuery({
				params: {
					odesilatelDSId: datovaSchrankaId,
					...params,
				},
				body: {
					kodPodani: 'ZADOST_VYMAZ_ZVLASTNI_SEZNAM_VOLICU',
					zuKod: variables.officeId,
					adresaUlice: variables.street,
					adresaMesto: variables.city,
					adresaPsc: variables.zip,
					adresaStat: variables.country,
					zpusobPrevzeti: variables.feedBackMethod,
					email: variables._useEmail ? variables.email : undefined,
					telefon:
						variables._usePhone && variables.phone
							? phoneShapeToString(variables.phone)
							: undefined,
				},
			});
		},
		onSuccess: () => {
			toastMessageSuccess(t('vymaz.zpravy.odeslano'));
			wizard.resetForms();
			onSubmitSuccess();
		},
	});

	const isSubmitting = submitMutation.isLoading;
	const personCommunicationData = usePersonCommunicationData();

	const step1 = useFormStep1({ isDisabled: isSubmitting });
	const step2 = useFormStep2({ isDisabled: isSubmitting });
	const step3 = useFormStep3({
		isDisabled: isSubmitting,
		databoxTypes,
	});

	usePersonCommunicationForm(step1, {
		countryCodes,
		email: personCommunicationData.emailVerified,
		phone: personCommunicationData.phoneVerified,
	});

	const wizard = useWizard<VymazForm>({
		steps: [step1, step2.form, step3],
		onSubmit: async (values) => submitMutation.mutate(values),
		onStepInvalid: (index, errors) => {
			if (index === 0) {
				personCommunicationData.onFormValidated(errors);
			}
		},
	});

	const feedBackMethod = step2.form.formMethods.getValues('feedBackMethod');

	return (
		<ServiceContext.Provider
			value={{
				isLoading:
					personQuery.isLoading || step2.isLoading || countryCodes.isLoading,
				wizard,
				personCommunicationData,
				step1,
				step2: step2.form,
				step3,
				isSubmitting,
				person: personQuery.data,
				feedBackMethod,
				selectedOffice: step2.selectedOffice,
				selectedDistrict: step2.selectedDistrict,
				selectedCountry: step2.selectedCountry,
				hideAddressInputs: step2.hideAddressInputs,
				showConnectDataboxBanner: isDataBoxLoginType && !areAnyConnected,
				databoxTypes,
			}}>
			{children}
		</ServiceContext.Provider>
	);
};

export const useKorespondencniHlasovaniVymazContext = () => {
	const context = useContext(ServiceContext);
	if (!context) {
		throw new Error('Context must be used within a ContextProvider');
	}
	return context;
};
