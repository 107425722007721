import { pageRouteResolver } from '@gov-nx/web';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTruncatedText } from '@gov-nx/core/hooks';
import { useLocale } from '@gov-nx/core/service';
import { PageCode, useEPeticeDetailContext } from '@gov-nx/module/page';
import {
	Button,
	ContentLayout,
	ErrorStatusBanner,
	ToggleButton,
} from '@gov-nx/ui/web';
import { stripHTMLTagsAndGetParagraphs } from '@gov-nx/utils/common';
import { CmsPageRender } from '../../../Cms/CmsPageRender';
import { EPetitionAttachments } from './EPetitionAttachments';
import { EPetitionDetailSkeleton } from './EPetitionDetailSkeleton';
import { EPetitionExtend } from './EPetitionExtend';
import { EPetitionInfo } from './EPetitionInfo';
import { EPetitionShareMessage } from './EPetitionShareMessage';
import { EPetitionSign } from './EPetitionSign';
import { EPetitionCloseAndSubmit } from './ePetitionCloseAndSubmit';

export interface EPetitionDetailViewProps {
	code: PageCode;
}

export const EPetitionDetailView = ({ code }: EPetitionDetailViewProps) => {
	const {
		petition,
		individualPerson,
		ePetitionSign,
		ePetitionExtend,
		controls,
		isEditable,
	} = useEPeticeDetailContext();
	const { t } = useLocale(code);
	const navigate = useNavigate();

	const maxSymbol = 400;
	const maxParagraphs = 5;

	const { displayedParagraphs, shouldShowToggle, toggleExpand } =
		useTruncatedText(petition?.text ?? '', maxSymbol, maxParagraphs);

	const getContent = () => {
		if (controls.initialLoading) {
			return <EPetitionDetailSkeleton />;
		}

		if (controls.initialError) {
			return <ErrorStatusBanner code={code} />;
		}

		const leads = petition?.perex
			? stripHTMLTagsAndGetParagraphs(petition?.perex)
			: [];

		return (
			<>
				<EPetitionCloseAndSubmit code={code} />
				{ePetitionExtend.canBeExtended && <EPetitionExtend code={code} />}

				<ContentLayout customClasses={'flex flex-col gap-8'}>
					{leads.length && (
						<h2 className={'text-xl text-secondary-800'}>
							{leads.map((line, index) => (
								// eslint-disable-next-line react/no-array-index-key
								<React.Fragment key={index}>
									{line}
									{index < leads.length - 1 && <br />}
								</React.Fragment>
							))}
						</h2>
					)}
					{displayedParagraphs?.length && (
						<div className="flex flex-col gap-0">
							<div>
								{displayedParagraphs.map((text, index) => (
									// eslint-disable-next-line react/no-array-index-key
									<p key={index}>{text}</p>
								))}
							</div>
							{shouldShowToggle && (
								<div className={'mt-4'}>
									<ToggleButton
										showLabel={t('akce.zobrazit-vice')}
										hideLabel={t('akce.zobrazit-mene')}
										onClick={toggleExpand}
									/>
								</div>
							)}
						</div>
					)}
					<section>
						<EPetitionAttachments code={code} />
					</section>
					<section>
						<EPetitionInfo code={code} />
					</section>
					{isEditable && (
						<Button
							variant={'primary'}
							type={'solid'}
							onClick={() =>
								navigate(
									pageRouteResolver('epetice.detail.editace', {
										petitionId: petition?.id,
									})
								)
							}
							wcagLabel={t('akce.wcag.editovat')}>
							{t('akce.editovat')}
						</Button>
					)}
				</ContentLayout>
				{individualPerson && ePetitionSign.isPublic && (
					<EPetitionSign code={code} />
				)}

				{petition?.datumZverejneni && (
					<EPetitionShareMessage
						petition={petition}
						code={code}
					/>
				)}
			</>
		);
	};

	return (
		<CmsPageRender
			code={code}
			override={{ name: petition?.nazev }}
			showDescription={false}>
			{getContent()}
		</CmsPageRender>
	);
};
