import { MutationFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { OnDocumentSaveParams } from '@gov-nx/component/common';
import {
	delay,
	getAntiVirusWarningHeader,
	getDispositionResponseHeader,
	getFileNameFromDispositionHeader,
} from '@gov-nx/utils/common';
import { usePoMutation } from './usePoMutation';

export interface DocumentDownloadProps<ID> {
	onError?: (id: ID, error: Error) => void;
	onSuccess?: (data: OnDocumentSaveParams<ID>) => void;
}
export const useDownloadPoMutation = <
	Variables extends Record<'id', string | number>
>(
	mutationFn: MutationFunction<AxiosResponse, Variables>,
	props: DocumentDownloadProps<Variables['id']>
) => {
	const mutation = usePoMutation({
		mutationFn,
		onError: (error, variables, context) => {
			props?.onError && props.onError(variables.id, error);
		},
		onSuccess: async (response, variables) => {
			if (response.status === 202) {
				await delay(2000);
				mutation.mutate(variables);
			} else {
				try {
					const disposition = getDispositionResponseHeader(response);
					const antivirus = getAntiVirusWarningHeader(response);
					const fileName = getFileNameFromDispositionHeader(disposition);
					const blob = response.data;
					props?.onSuccess &&
						props.onSuccess({ antivirus, fileName, blob, id: variables.id });
				} catch (error) {
					props?.onError && props.onError(variables.id, error as Error);
				}
			}
		},
	});

	return mutation;
};
