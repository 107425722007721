import { AxiosResponse } from 'axios';
import {
	axiosInstance,
	PpdfSouhlasySouhlasDto,
	PpdfSouhlasySouhlasSeznamDto,
} from '@gov-nx/api/portal-obcana';
import { govApiLog } from '@gov-nx/core/app';

export const ppdfConsentsQuery = async <Params>(
	params: Params
): Promise<PpdfSouhlasySouhlasSeznamDto> => {
	govApiLog('/api/v1/ppdf');
	const { data } = await axiosInstance.get('/api/v1/ppdf', {
		params,
	});
	return data;
};

const searchOnFE = async <Params>(
	params: Params,
	search: string,
	start = 0,
	buffer: PpdfSouhlasySouhlasSeznamDto = { seznam: [] }
): Promise<PpdfSouhlasySouhlasSeznamDto> => {
	const pocet = 500;
	const result = await ppdfConsentsQuery({
		...params,
		start,
		pocet,
	});

	const filtered =
		result.seznam?.filter((item) =>
			item.kodSpravceUdaju?.toLowerCase().includes(search.toLowerCase())
		) ?? [];

	const newBuffer: PpdfSouhlasySouhlasSeznamDto = {
		seznam: [...(buffer.seznam ?? []), ...filtered],
	};

	return newBuffer;
};

export const ppdfConsentsSearchQuery = async <
	Params extends { start?: number; pocet?: number }
>(
	search: string,
	params: Params
): Promise<PpdfSouhlasySouhlasSeznamDto> => {
	const result = await searchOnFE(params, search);
	const start = params.start ?? 0;
	const pocet = params.pocet ?? 10;

	return {
		seznam: result.seznam?.slice(start, start + pocet),
	};
};

export const ppdfConsentDetailQuery = async (
	consentId: number
): Promise<PpdfSouhlasySouhlasDto> => {
	const { data } = await axiosInstance.get('/api/v1/ppdf/' + consentId);
	return data;
};

export const ppdfConsentDownloadQuery = async ({
	id,
}: {
	id: string;
}): Promise<AxiosResponse<Blob>> => {
	const response = await axiosInstance.get(`/api/v1/ppdf/${id}/stahnout`, {
		responseType: 'blob',
	});

	return response;
};
