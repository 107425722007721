import { GovIcon } from '@gov-design-system-ce/react';
import { FormProvider } from 'react-hook-form';
import { useLocale } from '@gov-nx/core/service';
import { PageCode, useMojeAutorizaceSeznamContext } from '@gov-nx/module/page';
import {
	Button,
	FormInput,
	FormWidthElementLayout,
	HeaderLayout,
} from '@gov-nx/ui/web';
import { PoFormWeb } from '../../../Form/PoForm';

export const MyAuthorizationsListFilter = () => {
	const { onSubmit, filterForm } = useMojeAutorizaceSeznamContext();
	const { t } = useLocale(PageCode['moje-podani']);

	return (
		<FormProvider {...filterForm.formMethods}>
			<PoFormWeb
				formDefinition={{ ...filterForm }}
				onSubmit={onSubmit}>
				<HeaderLayout>
					<FormWidthElementLayout
						type={'max'}
						className={'relative'}>
						<FormInput {...filterForm.fields.text} />
						<div className={'absolute top-1 right-1'}>
							<Button
								nativeType={'submit'}
								variant="primary"
								type="solid"
								size="s"
								wcagLabel={t('akce.vyhledat')}>
								<GovIcon
									name={'search'}
									type={'basic'}
									slot="left-icon"></GovIcon>
							</Button>
						</div>
					</FormWidthElementLayout>
				</HeaderLayout>
			</PoFormWeb>
		</FormProvider>
	);
};
