import React from 'react';
import { MySubmissionEGSB2 } from '@gov-nx/api/portal-obcana';
import { MarkdownRender } from '@gov-nx/component/web';
import { useLocale } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { ContentLayout } from '@gov-nx/ui/web';
import { toDateTimeReadable } from '@gov-nx/utils/common';

interface EGSBSubmissionProps {
	submission: MySubmissionEGSB2;
}

export const EGSBSubmission = ({ submission }: EGSBSubmissionProps) => {
	const { t } = useLocale(PageCode['moje-podani']);

	if (submission.automatickeZpracovani) {
		return (
			<ContentLayout>
				<MarkdownRender>
					{t('moje-podani.egsb.automaticke-zpracovani', {
						datumVyrizeni: submission?.datumVyrizeni
							? toDateTimeReadable(submission.datumVyrizeni)
							: '-',
					})}
				</MarkdownRender>
			</ContentLayout>
		);
	}

	if (!submission.datumVyrizeni) {
		return (
			<ContentLayout>
				<MarkdownRender>
					{t('moje-podani.egsb.manualni-zpracovani', {
						lhutaVyrizeni: submission?.lhutaVyrizeni
							? toDateTimeReadable(submission.lhutaVyrizeni)
							: '-',
						email: submission.parametry.email ?? '-',
					})}
				</MarkdownRender>
			</ContentLayout>
		);
	}

	return (
		<ContentLayout>
			<MarkdownRender>
				{t('moje-podani.egsb.manualni-zpracovani-vyrizeno', {
					datumVyrizeni: submission?.datumVyrizeni
						? toDateTimeReadable(submission.datumVyrizeni)
						: '-',
					email: submission.parametry.email ?? '-',
				})}
			</MarkdownRender>
		</ContentLayout>
	);
};
