import React, { createContext, useContext } from 'react';
import {
	Form602ListQueryParams,
	form602ListQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { Nullable, hasProperty } from '@gov-nx/core/types';
import { useFilter } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import { useFilterForm } from './FormDefinitions';
import { MyAuthorizationListContextType } from './context.types';

const MojeAutorizaceSeznamContext =
	createContext<Nullable<MyAuthorizationListContextType>>(null);

interface MojeAutorizaceContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
}

export function MojeAutorizaceSeznamContextProvider({
	children,
	code,
}: MojeAutorizaceContextProviderProps) {
	const { filter, setPartialFilter } = useFilter<Form602ListQueryParams>({
		key: PageCode['moje-podani-autorizace'],
		initialState: { text: undefined, start: 0, pocet: 10 },
	});

	const query = usePoQuery(
		{
			queryKey: ['form602-submissions', filter],
			queryFn: () => form602ListQuery(filter),
		},
		{ errorIgnoreFilter: () => true }
	);

	const filterForm = useFilterForm();

	const onSubmit = filterForm.formMethods.handleSubmit(() =>
		setPartialFilter(filterForm.formMethods.getValues())
	);

	return (
		<MojeAutorizaceSeznamContext.Provider
			value={{
				code,
				controls: {
					initialLoading: query.isLoading,
					initialError: query.error,
				},
				form602Submissions:
					query.data?.seznam?.filter(hasProperty('formularPodaniId')) ?? [],
				number602Submissions:
					query.data?.pocet ?? query.data?.seznam?.length ?? 0,
				isNoSubmissions:
					query.data?.pocet === 0 || query.data?.seznam?.length === 0,
				filter,
				currentPage: filter.start / filter.pocet + 1,
				goToPage: (page) =>
					setPartialFilter({ start: (page - 1) * filter.pocet }),
				filterForm,
				onSubmit,
			}}>
			{children}
		</MojeAutorizaceSeznamContext.Provider>
	);
}

export const useMojeAutorizaceSeznamContext =
	(): MyAuthorizationListContextType =>
		useContext(MojeAutorizaceSeznamContext) as MyAuthorizationListContextType;
