import { PageContextControls } from '../../types/page.types';

export interface PpdfSouhlasyFormControls extends PageContextControls {
	initialLoading: boolean;
	createLoading: boolean;
	saveLoading: boolean;
	deleteLoading: boolean;
}

export enum PpdfSouhlasyTypOsobyEnum {
	FO = 'FO',
	PO = 'PO',
}
