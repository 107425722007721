import { JSX } from '@gov-design-system-ce/components/dist/types/components';
import { GovIcon } from '@gov-design-system-ce/react';
import cx from 'classnames';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Nullable, Optional } from '@gov-nx/core/types';
import { Button } from '../Button/Button';

const ICON_WIDTH_CLASSES = {
	small: 'w-3',
	medium: 'w-4',
	large: 'w-5',
	default: 'w-3',
};

export enum TileIconSize {
	small = 'small',
	medium = 'medium',
	large = 'large',
}

interface DateAndIconProps {
	icon?: Nullable<JSX.GovIcon>;
	date?: string;
	iconSize?: TileIconSize;
	iconWrapper?: (children: ReactNode) => ReactNode;
}

const DateAndIcon = ({
	date,
	icon,
	iconSize = TileIconSize.small,
	iconWrapper,
}: DateAndIconProps) => {
	const iconWidth = ICON_WIDTH_CLASSES[iconSize] || ICON_WIDTH_CLASSES.default;
	return (
		<>
			{date && (
				<time
					className={
						'relative top-auto right-auto inline-block mt-2 text-secondary-700 [ md:absolute md:top-7 md:right-12 md:mt-0 ]'
					}>
					{date}
				</time>
			)}
			{icon ? (
				<span className={iconWidth}>
					{iconWrapper ? (
						iconWrapper(
							<GovIcon
								type={icon.type}
								name={icon.name}
								className={`absolute top-7 right-4 ${iconWidth} h-6 text-primary-600`}></GovIcon>
						)
					) : (
						<GovIcon
							type={icon.type}
							name={icon.name}
							className={`absolute top-7 right-4 ${iconWidth} h-6 text-primary-600`}></GovIcon>
					)}
				</span>
			) : null}
		</>
	);
};

const LoadingIcon = () => {
	return (
		<Button
			loading={true}
			variant="primary"
			type="base"
			size="s">
			<GovIcon
				name="download"
				slot="left-icon"
			/>
		</Button>
	);
};

export interface TileProps {
	children?: React.ReactNode;
	customClasses?: string;
	icon?: Nullable<JSX.GovIcon>;
	iconSize?: TileIconSize;
	name: string;
	target?: JSX.GovButton['target'];
	to?: Optional<string>;
	date?: string;
	headlineLink?: boolean;
	iconLink?: boolean;
	loading?: boolean;
	onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const Tile = ({
	children,
	customClasses,
	icon,
	iconSize = TileIconSize.small,
	name,
	target,
	to,
	date,
	headlineLink,
	iconLink,
	loading,
	onClick,
}: TileProps) => {
	const linkTarget = { target };

	const tileLink = (children: ReactNode, classNames?: Optional<string>) => {
		if (to) {
			return (
				<Link
					className={classNames}
					to={to}
					{...linkTarget}>
					{children}
				</Link>
			);
		} else if (onClick) {
			return (
				<Link
					className={classNames}
					to={'#'}
					onClick={(event) => {
						onClick(event);
					}}>
					{children}
				</Link>
			);
		}
		return children;
	};

	return (
		<li
			className={cx(
				'-mx-4 border-b border-secondary-500 [ last-of-type:border-b-0 ] [ md:mx-0 ]',
				customClasses
			)}>
			{to || onClick ? (
				<div>
					{headlineLink || iconLink ? (
						<div className={'relative py-6 px-4 rounded'}>
							{tileLink(
								<h3
									className={cx(
										'inline-block pr-6 mb-2 text-xl underline [ hover:no-underline ]',
										date ? '[ md:max-w-[70%] ] [ xl:max-w-[70%] xl:pr-0 ]' : ''
									)}>
									{loading && <LoadingIcon />}
									{name}
								</h3>
							)}
							<div>{children}</div>
							<DateAndIcon
								icon={icon}
								date={date}
								iconSize={iconSize}
								iconWrapper={
									iconLink ? (children) => tileLink(children) : undefined
								}
							/>
						</div>
					) : (
						tileLink(
							<>
								<h3
									className={
										'pr-6 mb-2 text-xl text-primary-600 [ md:max-w-[70%] ] [ xl:max-w-[70%] xl:pr-0 ]'
									}>
									{loading && <LoadingIcon />}
									{name}
								</h3>
								<div>{children}</div>
								<DateAndIcon
									icon={icon}
									date={date}
									iconSize={iconSize}
								/>
							</>,
							'anim-bg-hover relative block py-6 px-4 rounded no-underline'
						)
					)}
				</div>
			) : (
				<div className={'relative py-6 px-4 rounded text-secondary-700'}>
					<h3
						className={cx(
							'inline-block pr-6 mb-2 text-xl',
							date ? '[ md:max-w-[70%] ] [ xl:max-w-[70%] xl:pr-0 ]' : ''
						)}>
						{loading && <LoadingIcon />}
						{name}
					</h3>
					<div>{children}</div>
					<DateAndIcon
						icon={icon}
						date={date}
						iconSize={iconSize}
					/>
				</div>
			)}
		</li>
	);
};
