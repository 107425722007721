import { useLocale } from '@gov-nx/core/service';
import {
	LocaleDefinition,
	LocaleNoParams,
	LocaleVariables,
} from '@gov-nx/core/types';
import { ServiceCode } from '../definitions/codes';

// mozno recyklovat tenhle uz nepouzity preklad: dokumenty.formular.zpusob-prevzeti.datovka

type KorespondencniVolbyLocaleObject = {
	banner: {
		chyba: {
			'zadne-vyhlasene-volby': LocaleNoParams;
		};
	};
	formular: {
		zeme: {
			label: LocaleNoParams;
			'default-label': LocaleNoParams;
			validace: {
				povinny: LocaleNoParams;
			};
		};
		'zastupitelsky-urad': {
			label: LocaleNoParams;
			'default-label': LocaleNoParams;
			validace: {
				povinny: LocaleNoParams;
			};
		};
		'uzemni-obvod': {
			label: LocaleNoParams;
			'default-label': LocaleNoParams;
			validace: {
				povinny: LocaleNoParams;
			};
		};
		tlacitko: {
			pokracovat: LocaleNoParams;
			odeslat: LocaleNoParams;
			'pouze-odeslat': LocaleNoParams;
			'pozadat-a-odeslat': LocaleNoParams;
			editovat: {
				label: LocaleNoParams;
				'wcag-label': LocaleNoParams;
			};
		};
		ulice: {
			label: LocaleNoParams;
			placeholder: LocaleNoParams;
			validace: {
				povinny: LocaleNoParams;
			};
		};
		mesto: {
			label: LocaleNoParams;
			placeholder: LocaleNoParams;
			validace: {
				povinny: LocaleNoParams;
			};
		};
		psc: {
			label: LocaleNoParams;
			placeholder: LocaleNoParams;
			validace: {
				povinny: LocaleNoParams;
			};
		};
		stat: {
			label: LocaleNoParams;
			placeholder: LocaleNoParams;
			validace: {
				povinny: LocaleNoParams;
			};
		};
	};
	rekapitulace: {
		'udaje-o-zadateli': {
			prijmeni: LocaleNoParams;
			jmeno: LocaleNoParams;
			'datum-narozeni': LocaleNoParams;
			adresa: LocaleNoParams;
			email: LocaleNoParams;
			telefon: LocaleNoParams;
			neuvedeno: LocaleNoParams;
		};
	};
	rozcestnik: {
		'seznam-volicu': {
			titulek: LocaleNoParams;
			popis: LocaleNoParams;
			zapis: {
				titulek: LocaleNoParams;
				popis: LocaleNoParams;
			};
			vymaz: {
				titulek: LocaleNoParams;
				popis: LocaleNoParams;
			};
		};
		dokumenty: {
			titulek: LocaleNoParams;
		};
		volby: {
			popis: {
				POSLANECKA_SNEMOVNA: LocaleNoParams;
				PREZIDENT: LocaleNoParams;
				EVROPSKY_PARLAMENT: LocaleNoParams;
			};
		};
	};
	'zadatel-info': {
		jmeno: LocaleNoParams;
		prijmeni: LocaleNoParams;
		'rodne-prijmeni': LocaleNoParams;
		'datum-narozeni': LocaleNoParams;
		adresa: LocaleNoParams;
		pohlavi: {
			label: LocaleNoParams;
			zena: LocaleNoParams;
			muz: LocaleNoParams;
		};
	};
	'kontaktni-udaje': {
		email: {
			label: LocaleNoParams;
			placeholder: LocaleNoParams;
			edit: {
				'wcag-label': LocaleNoParams;
			};
			cancel: {
				'wca-label': LocaleNoParams;
			};
		};
		phone: {
			label: LocaleNoParams;
			placeholder: LocaleNoParams;
			edit: {
				'wcag-label': LocaleNoParams;
			};
			cancel: {
				'wca-label': LocaleNoParams;
			};
		};
	};
	zapis: {
		zpravy: {
			odeslano: LocaleNoParams;
		};
		pruvodce: {
			krok1: {
				label: LocaleNoParams;
			};
			krok2: {
				label: LocaleNoParams;
			};
			krok3: {
				label: LocaleNoParams;
			};
		};
		'zadatel-info': {
			popis: LocaleNoParams;
		};
		'zapis-v-terminu': {
			popis: LocaleNoParams;
		};
		'kontaktni-udaje': {
			popis: LocaleNoParams;
		};
		'zastupitelsky-urad': {
			popis: LocaleNoParams;
		};
		adresa: {
			popis: LocaleNoParams;
		};
		rekapitulace: {
			popis: LocaleNoParams;
			'udaje-o-zadateli': {
				title: LocaleNoParams;
			};
			'udaje-pro-zapis': {
				title: LocaleNoParams;
				'zastupitelsky-urad': LocaleNoParams;
				'adresa-pobytu': LocaleNoParams;
			};
		};
		formular: {
			'neni-na-seznamu': {
				label: LocaleNoParams;
				validace: {
					povinny: LocaleNoParams;
				};
			};
			'zapis-v-terminu': {
				label: LocaleNoParams;
				validace: {
					povinny: LocaleNoParams;
				};
			};
			'datova-schranka': {
				label: LocaleNoParams;
			};
		};
		modal: {
			odeslat: {
				titulek: LocaleNoParams;
				text: LocaleNoParams;
			};
		};
	};
	vymaz: {
		zpravy: {
			odeslano: LocaleNoParams;
		};
		pruvodce: {
			krok1: {
				label: LocaleNoParams;
			};
			krok2: {
				label: LocaleNoParams;
			};
			krok3: {
				label: LocaleNoParams;
			};
		};
		formular: {
			'neni-duplicitni-zadost': {
				label: LocaleNoParams;
				validace: {
					povinny: LocaleNoParams;
				};
			};
			'zpusob-potvrzeni': {
				label: LocaleNoParams;
				posta: LocaleNoParams;
				osobne: LocaleNoParams;
				datovka: LocaleNoParams;
				validace: {
					povinny: LocaleNoParams;
				};
			};
			'datova-schranka': {
				label: LocaleNoParams;
			};
		};
		'zadatel-info': {
			popis: LocaleNoParams;
		};
		'kontaktni-udaje': {
			popis: LocaleNoParams;
		};
		'udaje-pro-vymaz': {
			popis: LocaleNoParams;
		};
		rekapitulace: {
			popis: LocaleNoParams;
			'udaje-o-zadateli': {
				title: LocaleNoParams;
			};
			'udaje-pro-vymaz': {
				title: LocaleNoParams;
				'zastupitelsky-urad': LocaleNoParams;
				'zpusob-prevzeti': LocaleNoParams;
			};
		};
	};
	dokumenty: {
		zpravy: {
			odeslano: LocaleNoParams;
		};
		pruvodce: {
			krok1: {
				label: LocaleNoParams;
			};
			krok2: {
				label: LocaleNoParams;
			};
			krok3: {
				label: LocaleNoParams;
			};
		};
		formular: {
			'neni-duplicitni-zadost': {
				label: LocaleNoParams;
				validace: {
					povinny: LocaleNoParams;
				};
			};
			'na-jinem-seznamu': {
				label: LocaleNoParams;
				varovani: LocaleVariables<'url'>;
				validace: {
					povinny: LocaleNoParams;
				};
				ano: LocaleNoParams;
				ne: LocaleNoParams;
			};
			'zpusob-prevzeti': {
				label: LocaleNoParams;
				posta: LocaleNoParams;
				osobne: LocaleNoParams;
				// datovka: LocaleNoParams;
				validace: {
					povinny: LocaleNoParams;
				};
				'plna-moc': {
					tlacitko: LocaleNoParams;
					text: LocaleNoParams;
				};
			};
			'datova-schranka': {
				label: LocaleNoParams;
			};
		};
		'zadatel-info': {
			popis: LocaleNoParams;
		};
		'kontaktni-udaje': {
			popis: LocaleNoParams;
		};
		'udaje-pro-zapis': {
			popis: LocaleNoParams;
		};
		rekapitulace: {
			popis: LocaleNoParams;
			'udaje-o-zadateli': {
				title: LocaleNoParams;
			};
			'udaje-pro-prevzeti': {
				title: LocaleNoParams;
				'zastupitelsky-urad': LocaleNoParams;
				'zpusob-prevzeti': LocaleNoParams;
			};
		};
	};
};

export const useKorespondencniVolbyLocale =
	(): LocaleDefinition<KorespondencniVolbyLocaleObject> =>
		useLocale(ServiceCode['zadost-o-korespondencni-hlasovani']);
