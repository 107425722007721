import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { prop } from '@gov-nx/core/types';
import {
	PERSIST_ZUSTAND_STORE_KEY,
	StoreKeys,
	storeGlobalConfig,
} from '../constants';
import { AppState } from './store.types';

export enum OfflineDataDownloadState {
	Init = 'init',
	Enabled = 'enabled',
	Disabled = 'disabled',
}

const initialState = {
	lastActivityTime: 0,
	pushNotificationWindowShown: false,
	offlineDataDownloadState: OfflineDataDownloadState.Init,
	dismissedAlertIds: [],
};

export const useAppStoreBase = create<AppState>()(
	persist(
		(set) => ({
			...initialState,

			saveLastActivityTime: (lastActivityTime: number) =>
				set({ lastActivityTime }),

			setPushNotificationWindowShown: (pushNotificationWindowShown: boolean) =>
				set({ pushNotificationWindowShown }),

			setOfflineDataDownloadState: (
				offlineDataDownloadState: OfflineDataDownloadState
			) => set({ offlineDataDownloadState }),
			setDismissedAlertIds: (dismissedAlertIds: number[]) =>
				set({ dismissedAlertIds }),

			appReset: () => set(initialState),
		}),
		{
			...storeGlobalConfig,
			name: PERSIST_ZUSTAND_STORE_KEY + ':' + StoreKeys.app,
		}
	)
);

export const useAppStore = <T extends keyof AppState>(
	property: T
): AppState[T] => useAppStoreBase(prop(property));
