import {
	BaseSyntheticEvent,
	createContext,
	PropsWithChildren,
	useContext,
	useEffect,
} from 'react';
import { RezaOpravneniDto } from '@gov-nx/api/portal-obcana';
import { useRezaEligibleList } from '@gov-nx/core/hooks';
import { FormDefinition2 } from '@gov-nx/core/service';
import { FilterParam } from '@gov-nx/core/types';
import { IUseBoolean, useBoolean } from '@gov-nx/utils/common';
import { ComplaintsForm, useComplaintsForm } from '../useComplaintsForm';
import { useFilters } from './useFilters';
import { RezaFilterForm, RezaFormFields, useForm } from './useForm';

interface RezaListContexts {
	isLoading: boolean;
	data: RezaOpravneniDto[];
	formDefinition: FormDefinition2<RezaFilterForm, RezaFormFields>;
	onSubmit: (event: BaseSyntheticEvent) => void;
	onFilterReset: () => void;
	filterParams: FilterParam[];
	isFilterModalOpen: IUseBoolean;
	isFilterModalActive: boolean;
	complaintsForm: ComplaintsForm;
}

const Context = createContext<RezaListContexts | null>(null);

export const RezaListContextProvider = ({ children }: PropsWithChildren) => {
	const isFilterModalOpen = useBoolean();

	const {
		filterParams,
		filter,
		updateFilters,
		updateRole,
		reset,
		isFilterModalActive,
		initialState,
	} = useFilters();

	const formDefinition = useForm({ filter, initialState });

	const query = useRezaEligibleList({ filter });

	useEffect(() => {
		const subscription = formDefinition.formMethods.watch((value, { name }) => {
			if (name === 'role' && value['role']) {
				updateRole({
					zmocnenec: value['role'].zmocnenec ?? false,
					zmocnitel: value['role'].zmocnitel ?? false,
				});
			}
		});

		return () => subscription.unsubscribe();
	}, [formDefinition.formMethods]);

	const onSubmit = formDefinition.formMethods.handleSubmit(() => {
		updateFilters(formDefinition.formMethods.getValues());
		isFilterModalOpen.setFalse();
	});

	const onFilterReset = () => {
		formDefinition.formReset();
		reset();
		isFilterModalOpen.setFalse();
	};

	return (
		<Context.Provider
			value={{
				isLoading: query.isLoading,
				data: query.data?.seznam ?? [],
				formDefinition,
				onSubmit,
				onFilterReset,
				filterParams: filterParams(formDefinition),
				isFilterModalOpen,
				isFilterModalActive,
				complaintsForm: useComplaintsForm(),
			}}>
			{children}
		</Context.Provider>
	);
};

export const useRezaListContext = (): RezaListContexts => {
	const context = useContext(Context);
	if (!context) {
		throw new Error(
			'useRezaListContext must be used within a RezaListContextProvider'
		);
	}
	return context;
};
