import { parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { AnySchema } from 'yup/lib/schema';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { isString } from '@gov-nx/core/types';
import { FormInputProps } from '@gov-nx/ui/types';
import { isValidDate } from '../Date/utils';

interface DateShape {
	isRequired?: boolean;
	isRequiredMessage?: string;
	isInvalidMessage?: string;
}

export const useDateShape = () => {
	const { t } = useTranslation([LocalizeNameSpaceTypes.Form]);

	return {
		getDatePickerShape: (props?: DateShape): yup.DateSchema => {
			const schema = yup
				.date()
				.notRequired()
				.transform((value, orig) => {
					if (orig === '') return undefined;

					if (isString(orig)) {
						return parse(orig, 'd.M.yyyy', new Date());
					}

					return value;
				})
				.typeError(
					props?.isInvalidMessage ??
						t('datum.validace.datum-neni-ve-spravnem-tvaru')
				);

			if (props?.isRequired) {
				return schema.required(
					props?.isRequiredMessage ?? t('datum.validace.zadejte-datum')
				);
			}

			return schema;
		},
	};
};

export interface DateDefinition {
	defaultValue: null;
	schema: AnySchema; // todo: this could be better
	field: FormInputProps;
}

interface FieldProps {
	name: string;
	label: string;
	isRequired?: boolean;
	isDisabled?: boolean;
	min?: { min: Date; errorMessage: string };
	max?: { max: Date; errorMessage: string };
	noOlderThan?: { fieldName: string; errorMessage: string };
}

export const useDateDefinition = (props: FieldProps): DateDefinition => {
	const { getDatePickerShape } = useDateShape();

	let shape = getDatePickerShape({ isRequired: props.isRequired }).nullable();
	if (props.min) {
		shape = shape.min(props.min.min, props.min.errorMessage);
	}
	if (props.max) {
		shape = shape.max(props.max.max, props.max.errorMessage);
	}

	if (props.noOlderThan) {
		shape = shape.when(props.noOlderThan.fieldName, (from, schema) => {
			if (isValidDate(from)) {
				return schema.min(from, props.noOlderThan?.errorMessage);
			}
			return schema;
		});
	}

	return {
		defaultValue: null,
		schema: shape,
		field: {
			field: {
				name: props.name,
				isDisabled: props.isDisabled,
			},
			label: { children: props.label },
		},
	};
};
