import { GovIcon, GovModal } from '@gov-design-system-ce/react';
import { serviceRouteResolver } from '@gov-nx/web';
import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	PageCode,
	ProfilROBReklamaceContextProvider,
	useProfilROBContextInstance,
} from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	ContentLayout,
	ErrorStatusBanner,
	LayoutGap,
} from '@gov-nx/ui/web';
import { MarkdownRender } from '../../Markdown/MarkdownRender';
import { ChangePhotoView } from './ChangePhotoView';
import { ProfilROBSkeleton } from './ProfilROBSkeleton';
import { ProfileROBComplaintView } from './ProfileROBComplaintView';
import { ProfileROBDataView } from './ProfileROBDataView';

export interface RegistrObyvatelFormProps {
	navigation: React.ReactNode;
	code: PageCode;
}

export const ProfileROBView = ({
	navigation,
	code,
}: RegistrObyvatelFormProps) => {
	const { data, photo, controls, setControls } = useProfilROBContextInstance();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	if (controls.initialLoading) {
		return <ProfilROBSkeleton />;
	}

	if (controls.initialDataError) {
		return <ErrorStatusBanner code={code} />;
	}

	return (
		<LayoutGap>
			<ContentLayout customClasses={'!pt-4'}>
				{navigation}
				<section
					className={
						'flex flex-col gap-4 [ sm:flex sm:flex-row sm:items-start ]'
					}>
					{photo ? (
						<div className={'relative w-[100px]'}>
							<img
								src={`data:image/png;base64, ${photo}`}
								alt=""
								width={100}
								height={100}
								className={'rounded-lg border border-secondary-500'}
							/>
							<Button
								onClick={() => setControls({ displayPhoto: true })}
								variant={'primary'}
								wcagLabel={ts('akce.neni-vase-foto-aktualni')}
								type={'base'}
								className={
									'absolute bottom-[1px] right-[1px] rounded-tl-lg rounded-br-md overflow-hidden bg-neutral-white'
								}>
								<GovIcon
									name="info"
									type="basic"
								/>
							</Button>
						</div>
					) : null}
					<div>
						<LayoutGap gap={6}>
							<ProfileROBDataView
								code={code}
								data={data}
							/>
							<ButtonGroupLayout>
								<Button
									onClick={() => setControls({ displayComplaint: true })}
									nativeType={'submit'}
									variant={'primary'}
									size={'m'}
									type={'outlined'}
									wcagLabel={ts('akce.podnet-k-oprave-udaju')}>
									{ts('akce.podnet-k-oprave-udaju')}
								</Button>
							</ButtonGroupLayout>
						</LayoutGap>
					</div>
				</section>
				<GovModal
					open={controls.displayPhoto}
					label={ts('foto.moje-fotografie')}
					onGov-close={() => setControls({ displayPhoto: false })}
					wcagCloseLabel={ts('akce.wcag.zavrit-fotografii')}>
					<ChangePhotoView
						photo={photo}
						code={code}
					/>
				</GovModal>
				<GovModal
					open={controls.displayComplaint}
					label={ts('formular.podnet-k-oprave-udaju')}
					onGov-close={() => setControls({ displayComplaint: false })}>
					<div>
						{controls.displayComplaint ? (
							<ProfilROBReklamaceContextProvider
								code={code}
								data={data}
								onComplaintSubmit={() =>
									setControls({ displayComplaint: false })
								}>
								<ProfileROBComplaintView code={code} />
							</ProfilROBReklamaceContextProvider>
						) : null}
					</div>
				</GovModal>
			</ContentLayout>

			<MarkdownRender>
				{ts('paticka.smazani-uctu', {
					link: serviceRouteResolver('smazani-uzivatelskeho-uctu'),
				})}
			</MarkdownRender>
		</LayoutGap>
	);
};
