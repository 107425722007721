import {
	ePetitionDownloadQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { OnEpetitionDownloadParams } from '@gov-nx/component/common';
import { GovError } from '@gov-nx/core/app';
import {
	delay,
	getAntiVirusWarningHeader,
	getDispositionResponseHeader,
	getFileNameFromDispositionHeader,
} from '@gov-nx/utils/common';

export interface PetititionDownloadProps {
	onError?: (petitionId: number, error: Error) => void;
	onSuccess?: (data: OnEpetitionDownloadParams) => void;
}

export const usePetitionDownload = (props?: PetititionDownloadProps) => {
	const mutation = usePoMutation({
		mutationFn: ePetitionDownloadQuery,
		onError: (error, petitionId) => {
			props?.onError && props.onError(petitionId, error as GovError);
		},
		onSuccess: async (response, petitionId) => {
			if (response.status === 202) {
				await delay(2000);
				mutation.mutate(petitionId);
			} else {
				try {
					const disposition = getDispositionResponseHeader(response);
					const antivirus = getAntiVirusWarningHeader(response);
					const fileName = getFileNameFromDispositionHeader(disposition);
					const blob = response.data;
					props?.onSuccess &&
						props.onSuccess({ antivirus, fileName, blob, petitionId });
				} catch (e) {
					props?.onError && props.onError(petitionId, e as Error);
				}
			}
		},
	});
	return mutation;
};
