import {
	embassyOfficeListPostalVotingQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { FormDefinition2 } from '@gov-nx/core/service';
import { useKorespondencniVolbyLocale } from '@gov-nx/module/service';
import { CommonSelectProps } from '@gov-nx/ui/types';
import { getSelectDefinition } from '@gov-nx/utils/common';
import { useAppLanguage } from '../Localization/useAppLanguage';
import { getCountries, getDistrict } from './utils';

export const useEmbassyOfficeListPostalVotingQuery = () => {
	const { language } = useAppLanguage();

	const query = usePoQuery({
		queryKey: ['embassy-offices-postal-voting'],
		queryFn: embassyOfficeListPostalVotingQuery,
	});

	const embassyOffices = query.data?.seznam ?? [];

	const getDistrictOptions = (countryCode: string) =>
		getDistrict(embassyOffices)(countryCode).map((district) => ({
			value: district.name,
			label: district.name,
		}));

	const getOfficeOptions = (countryCode: string, districtName: string) => {
		const districts = getDistrict(embassyOffices)(countryCode);
		return (
			districts
				.find((district) => district.name === districtName)
				?.offices.map((office) => ({
					value: office.code,
					label: language === 'cs' ? office.nazevCs : office.nazevEn,
				})) ?? []
		);
	};

	return {
		countryOptions: getCountries(embassyOffices).map((country) => ({
			value: country.code,
			label: country.name,
		})),
		getDistrictOptions,
		getOfficeOptions,
		error: query.error,
		isLoading: query.isLoading,
	};
};

export const useEmbassyOfficeFields = ({
	isDisabled,
}: {
	isDisabled: boolean;
}) => {
	const { t } = useKorespondencniVolbyLocale();

	const { isLoading, countryOptions } = useEmbassyOfficeListPostalVotingQuery();

	const countryId = getSelectDefinition({
		name: 'countryId',
		label: t('formular.zeme.label'),
		isDisabled,
		isRequired: true,
		isRequiredMessage: t('formular.zeme.validace.povinny'),
		options: [
			{ label: t('formular.zeme.default-label'), value: '' },
			...countryOptions,
		],
	});

	const officeId = getSelectDefinition({
		name: 'officeId',
		label: t('formular.zastupitelsky-urad.label'),
		isDisabled: isDisabled,
		isRequired: true,
		isRequiredMessage: t('formular.zastupitelsky-urad.validace.povinny'),
		options: [
			{ label: t('formular.zastupitelsky-urad.default-label'), value: '' },
		],
	});

	const districtId = getSelectDefinition({
		name: 'districtId',
		label: t('formular.uzemni-obvod.label'),
		isDisabled: isDisabled,
		isRequired: true,
		isRequiredMessage: t('formular.uzemni-obvod.validace.povinny'),
		options: [{ label: t('formular.uzemni-obvod.default-label'), value: '' }],
	});

	return {
		fields: [countryId, officeId, districtId],
		isLoading: isLoading,
	};
};

export interface EmbassyOfficeFields {
	countryId: CommonSelectProps;
	officeId: CommonSelectProps;
	districtId: CommonSelectProps;
}

export interface EmbassyOfficeValues {
	countryId: string;
	officeId: string;
	districtId: string;
}

export const useEmbassyOfficeSelects = <
	Values extends EmbassyOfficeValues,
	Fields extends EmbassyOfficeFields
>(
	form: FormDefinition2<Values, Fields>,
	isDisabled: boolean
): {
	selectedOffice?: string;
	selectedDistrict?: string;
	selectedCountry?: string;
	form: FormDefinition2<Values, Fields>;
} => {
	const { t } = useKorespondencniVolbyLocale();

	const { getDistrictOptions, getOfficeOptions, countryOptions } =
		useEmbassyOfficeListPostalVotingQuery();
	const { countryId, officeId, districtId } = form.formMethods.watch();

	const districtOptions = countryId ? getDistrictOptions(countryId) : [];

	const officeOptions =
		countryId && districtId ? getOfficeOptions(countryId, districtId) : [];

	return {
		selectedOffice: officeOptions.find((office) => office.value === officeId)
			?.label,
		selectedDistrict: districtOptions.find(
			(district) => district.value === districtId
		)?.value,
		selectedCountry: countryOptions.find(
			(country) => country.value === countryId
		)?.label,
		form: {
			...form,
			fields: {
				...form.fields,
				districtId: {
					...form.fields.districtId,
					field: {
						...form.fields.districtId.field,
						options: [
							{ label: t('formular.uzemni-obvod.default-label'), value: '' },
							...districtOptions,
						],
						isDisabled: isDisabled || districtOptions.length === 0,
						disabled: isDisabled || districtOptions.length === 0,
					},
				},
				officeId: {
					...form.fields.officeId,
					field: {
						...form.fields.officeId.field,
						options: [
							{
								label: t('formular.zastupitelsky-urad.default-label'),
								value: '',
							},
							...officeOptions,
						],
						isDisabled: isDisabled || officeOptions.length === 0,
						disabled: isDisabled || officeOptions.length === 0,
					},
				},
			},
		},
	};
};
