import {
	ePetitionRevokeQuery,
	ePetitionSignQuery,
	PeticePeticeDto,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useIdentity } from '@gov-nx/auth/common';
import { Nullable } from '@gov-nx/core/types';
import { EPetitionStatus } from '@gov-nx/module/page';
import { usePersonStore } from '@gov-nx/store/portal-obcana';

export interface PetitionSignProps {
	petition: Nullable<PeticePeticeDto>;
	onSignSuccess: () => void;
	onSignError: () => void;
	onRevokeSuccess: () => void;
	onRevokeError: () => void;
}

export const usePetitionSign = ({
	petition,
	onSignSuccess,
	onRevokeSuccess,
	onSignError,
	onRevokeError,
}: PetitionSignProps) => {
	const isPersonAdult = usePersonStore('isPersonAdult');
	const isAdult = isPersonAdult();
	const { isLowLoa, isDataBoxLoginType } = useIdentity();

	const signQuery = usePoMutation({
		mutationKey: ['e-petition-sign', petition?.id],
		mutationFn: ePetitionSignQuery,
		onSuccess: () => onSignSuccess && onSignSuccess(),
		onError: () => onSignError && onSignError(),
	});
	const revokeQuery = usePoMutation({
		mutationKey: ['e-petition-revoke', petition?.id],
		mutationFn: ePetitionRevokeQuery,
		onSuccess: () => onRevokeSuccess && onRevokeSuccess(),
		onError: () => onRevokeError && onRevokeError(),
	});

	const isPublic = petition?.stav === EPetitionStatus.zverejnena;
	const isFounder = petition?.mnouZalozene;
	const isSigned = petition?.mnouPodepsane;

	const canSign =
		isPublic &&
		!isSigned &&
		isAdult &&
		!isLowLoa &&
		!isDataBoxLoginType &&
		!isFounder;
	const canRevoke = isSigned && !isLowLoa && !isDataBoxLoginType && !isFounder;

	return { signQuery, revokeQuery, canRevoke, canSign, isPublic, isFounder };
};
