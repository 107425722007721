import { JSX } from '@gov-design-system-ce/components/dist/types/components';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { ServiceCode } from '@gov-nx/module/service';

type CmsNavigationService = {
	id: number;
	kind: 'service';
	service: {
		id: number;
		name: string;
		code: ServiceCode;
		icon: CmsIcon;
	};
};
type CmsNavigationPage = {
	id: number;
	kind: 'page';
	page: {
		id: number;
		name: string;
		code: PageCode;
		icon: CmsIcon;
	};
};

export const isCmsNavigationService = (
	navigation: CmsNavigation
): navigation is CmsNavigationService => navigation.kind === 'service';

export type CmsNavigation = CmsNavigationService | CmsNavigationPage;

export interface CmsIcon {
	id: number;
	icon: Nullable<string>;
	file: Nullable<string>;
}

export interface CmsCookbookListItem {
	id: number;
	text: string;
	icon: Nullable<CmsIcon>;
	order: number;
}

export interface CmsFaqListItem {
	id: number;
	question: string;
	answer: string;
	open: boolean;
	order: number;
}

export interface CmsService {
	id: number;
	code: string;
	name: string;
	description: string;
	icon: Nullable<CmsIcon>;
	cookbook?: CmsCookbookListItem[];
	navigations: CmsNavigation[];
	faq: CmsFaqListItem[];
	favoriteAllowed: boolean;
	descriptionDisplayed: boolean;
}

export interface CmsPage extends Omit<CmsService, 'cookbook'> {
	overrideName: string;
	id: number;
}

export interface CmsServiceResponse {
	data: CmsService;
}

export interface CmsPageResponse {
	data: CmsPage;
}

export interface CmsMessage {
	id: number;
	headline: Nullable<string>;
	message: string;
	properties: {
		variant: JSX.GovMessage['variant'];
	};
	icon: Nullable<string>;
	onlyLogin: boolean;
	onlyDashboard: boolean;
	serviceCodes: ServiceCode[];
	pageCodes: PageCode[];
}

export interface CmsMessageResponse {
	data: CmsMessage[];
}

export interface CmsExternalServiceLink {
	id: number;
	label: string;
	link: string;
	properties: Pick<JSX.GovButton, 'type' | 'variant' | 'size' | 'inverse'>;
	rightIcon: Nullable<CmsIcon>;
	leftIcon: Nullable<CmsIcon>;
}

export interface CmsExternalServiceParameter {
	id: number;
	key: string;
	value: string;
	order: number;
}

export interface CmsExternalService {
	id: number;
	name: string;
	description: string;
	code: string;
	icon: Nullable<CmsIcon>;
	links: CmsExternalServiceLink[];
	parameters: CmsExternalServiceParameter[];
}

export interface CmsExternalServiceResponse {
	data: CmsExternalService;
}

export interface CmsSignpostResponse {
	data: CmsSignpost[];
}

export interface CmsSignpost {
	id: number;
	name: string;
	description: string;
	icon: Nullable<CmsIcon>;
	pageCode: Nullable<PageCode>;
	serviceCode: Nullable<ServiceCode>;
	externalServiceId: Nullable<number>;
	externalServiceCode: Nullable<string>;
	order: number;
}

export interface CmsSignpostGroupResponse {
	data: CmsSignpostGroup[];
}

export interface CmsSignpostGroup {
	id: number;
	label: string;
	order: number;
}
