import { useScroll } from '@gov-nx/web';
import React from 'react';
import { FormWizardHook } from '@gov-nx/core/hooks';
import { Button } from '@gov-nx/ui/web';

interface TitleProps<T extends object> {
	wizard: FormWizardHook<T>;
	title: string;
	stepIndex: number;
	elementId: string;
	strings: {
		label: string;
		wcagLabel: string;
	};
}

export const LastStepTitle = <T extends object>({
	title,
	strings,
	stepIndex,
	elementId,
	wizard,
}: TitleProps<T>) => {
	const { scrollToElement } = useScroll();

	const focusToElement = () => {
		const step = wizard.step(stepIndex);
		const element = document.getElementById(elementId);

		if (step.isExpanded) {
			scrollToElement(element);
		} else {
			wizard.openStep(stepIndex);
			// wait for the gov-wizard to expand the step and scroll to it
			setTimeout(() => {
				scrollToElement(element);
			}, 100);
		}
	};

	return (
		<div className={'flex gap-2'}>
			<h3 className={'text-xl text-secondary-700'}>{title}</h3>
			<Button
				onClick={focusToElement}
				wcagLabel={strings.wcagLabel}
				variant="primary"
				type="base"
				size="s">
				{strings.label}
			</Button>
		</div>
	);
};
