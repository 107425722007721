import { FormSelectOption } from '@asseco/react-native-gov-components';
import {
	CiselnikyCiselnikPolozkaDto,
	getPhoneCountryCodesQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { LocalizationLanguage } from '@gov-nx/core/app';
import { has, Optional } from '@gov-nx/core/types';
import { PhoneWithCountryCodeShape } from '@gov-nx/ui/types';
import { digitsOnly } from '@gov-nx/utils/common';

const stringToPhoneShape =
	(countryCodes: CiselnikyCiselnikPolozkaDto[]) =>
	(phone?: string): Optional<PhoneWithCountryCodeShape> => {
		if (!phone) return;
		const digits = digitsOnly(phone);
		const countryCode = countryCodes.find(
			(code) => code.kod && digits.startsWith(code.kod)
		);
		if (!countryCode?.kod) {
			return;
		}

		return {
			countryCode: countryCode.kod,
			number: digits.slice(countryCode.kod.length),
		};
	};

const getOptions =
	(countryCodes: CiselnikyCiselnikPolozkaDto[]) =>
	(appLanguage: LocalizationLanguage): FormSelectOption[] => {
		return countryCodes.filter(has('kod')).map((code) => {
			return {
				value: code.kod,
				label:
					appLanguage === 'cs'
						? `${code.nazevCs} (+${code.kod})`
						: `${code.nazevEn} (+${code.kod})`,
			};
		});
	};

export const useCountryCodes = () => {
	const query = usePoQuery(getPhoneCountryCodesQuery());
	const countryCodes = query.data?.seznam ?? [];

	return {
		countryCodes,
		getOptions: getOptions(countryCodes),
		isFetched: query.isFetched,
		isLoading: query.isLoading,
		error: query.error,
		stringToPhoneShape: stringToPhoneShape(countryCodes),
	};
};
