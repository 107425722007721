import { useLocale } from '@gov-nx/core/service';
import { DeepIndex, Leaves } from '@gov-nx/core/types';
import { PageCode } from '../definitions/codes';

type NoParams = string;

type PpdfSouhlasyLocaleObject = {
	akce: {
		'podnet-k-oprave-udaju': NoParams;
		'zobrazit-detail': {
			'wcag-label': NoParams;
		};
		'odvolat-souhlas': {
			label: NoParams;
			'wcag-label': NoParams;
		};
		'stahnout-dokument-souhlasu': {
			label: NoParams;
			'wcag-label': NoParams;
		};
		filtrovat: NoParams;
		'zrusit-filtr': NoParams;
	};
	formular: {
		// Step 1
		'typ-osoby': {
			'fyzicka-osoba': {
				label: NoParams;
			};
			'pravnicka-osoba': {
				label: NoParams;
			};
			label: NoParams;
			'default-label': NoParams;
			validace: {
				povinny: NoParams;
			};
		};
		'pravnicka-osoba': {
			label: NoParams;
			'default-label': NoParams;
			validace: {
				povinny: NoParams;
			};
		};
		// Step 2
		'spravce-udaju': {
			label: NoParams;
			'default-label': NoParams;
			validace: {
				povinny: NoParams;
			};
		};
		'prijemce-udaju': {
			label: NoParams;
			'default-label': NoParams;
			validace: {
				povinny: NoParams;
			};
		};
		'agenda-spravce': {
			label: NoParams;
			'default-label': NoParams;
			validace: {
				povinny: NoParams;
			};
		};
		'agenda-prijemce': {
			label: NoParams;
			'default-label': NoParams;
			validace: {
				povinny: NoParams;
			};
		};
		'souhlas-do-data': {
			label: NoParams;
			validace: {
				povinny: NoParams;
				min: NoParams;
			};
		};
		'sdilene-udaje': {
			label: NoParams;
			'default-label': NoParams;
			validace: {
				povinny: NoParams;
				'neexistuji-hodnoty': NoParams;
			};
		};
		'dalsi-sdilene-udaje': {
			label: NoParams;
			'default-label': NoParams;
			validace: {
				povinny: NoParams;
			};
		};
		// Step 2
		'datova-schranka': {
			label: NoParams;
		};
		// Navigation
		tlacitko: {
			pokracovat: NoParams;
			odeslat: NoParams;
			editovat: {
				label: NoParams;
				'wcag-label': NoParams;
			};
		};
	};
	seznam: {
		titulek: NoParams;
		'od-spravce-udaju': {
			nazev: NoParams;
		};
		'do-prijemce-udaju': {
			nazev: NoParams;
		};
		'datum-vytvoreni-souhlasu': {
			nazev: NoParams;
		};
		'tlacitko-udeleni': {
			label: NoParams;
		};
	};
	detail: {
		modal: {
			nazev: NoParams;
		};
		'udaje-o-zadateli': {
			title: NoParams;
			prijmeni: NoParams;
			jmeno: NoParams;
			'datum-narozeni': NoParams;
			adresa: NoParams;
			'nazev-pravnicke-osoby': NoParams;
			ico: NoParams;
			'id-datove-schranky': NoParams;
		};
		detaily: {
			title: NoParams;
			'spravce-udaju': NoParams;
			'prijemce-udaju': NoParams;
			'agenda-spravce': NoParams;
			'agenda-prijemce': NoParams;
			'souhlas-do-data': NoParams;
			'sdilene-udaje': NoParams;
		};
	};
	rekapitulace: {
		'udaje-o-zadateli': {
			title: NoParams;
			prijmeni: NoParams;
			jmeno: NoParams;
			'datum-narozeni': NoParams;
			adresa: NoParams;
			'nazev-pravnicke-osoby': NoParams;
			ico: NoParams;
			'id-datove-schranky': NoParams;
		};
		detaily: {
			title: NoParams;
			'spravce-udaju': NoParams;
			'prijemce-udaju': NoParams;
			'agenda-spravce': NoParams;
			'agenda-prijemce': NoParams;
			'souhlas-do-data': NoParams;
			'sdilene-udaje': NoParams;
		};
		odeslani: {
			title: NoParams;
		};
	};
	'evidence-souhlasu': {
		title: NoParams;
	};
	rozcestnik: {
		'ppdf-souhlasy': {
			titulek: NoParams;
			popis: NoParams;
			udeleni: {
				titulek: NoParams;
				popis: NoParams;
			};
			odvolani: {
				titulek: NoParams;
				popis: NoParams;
			};
		};
	};
	'zadatel-info': {
		popis: NoParams;
		jmeno: NoParams;
		prijmeni: NoParams;
		'datum-narozeni': NoParams;
		adresa: NoParams;
		ico: NoParams;
		'id-datove-schranky': NoParams;
	};
	zpravy: {
		odeslano: NoParams;
	};
	udeleni: {
		'zadatel-info': {
			popis: NoParams;
		};
		pruvodce: {
			krok1: {
				label: NoParams;
			};
			krok2: {
				label: NoParams;
			};
			krok3: {
				label: NoParams;
			};
		};
	};
	filtr: {
		nazev: NoParams;
		odvolano: {
			label: NoParams;
		};
		vyhledat: {
			'detail-label': NoParams;
		};
	};
	stav: {
		chyba: {
			'zatim-jste-nic-neudelili': NoParams;
			'zrejme-jste-udeleni-nevytvorili': NoParams;
		};
		varovani: {
			'low-loa': NoParams;
		};
	};
};

interface PpdfSouhlasyLocale {
	t: <T extends Leaves<PpdfSouhlasyLocaleObject>>(
		key: T,
		params?: Record<DeepIndex<PpdfSouhlasyLocaleObject, T>, string> //TODO: optional params neni uplne type safe, na pretezovani typu neni cas
	) => string;
}

export const usePpdfSouhlasyLocale = (): PpdfSouhlasyLocale =>
	useLocale(PageCode['ppdf-souhlasy']);
