import { createContext, ReactNode, useContext } from 'react';
import {
	DatovkaApiType,
	OsobyFyzickaOsobaDto,
	sendSubmissionQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useIdentity } from '@gov-nx/auth/common';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	FormWizardHook,
	PersonCommunicationData,
	useCountryCodes,
	usePersonCommunicationData,
	usePersonCommunicationForm,
	useRobPersonQuery,
	useWizard,
} from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { useDataBoxAccess } from '@gov-nx/module/data-box';
import {
	dataBoxParams,
	delay,
	IUseBoolean,
	phoneShapeToString,
	useBoolean,
} from '@gov-nx/utils/common';
import { useElections } from '../useElections';
import { useKorespondencniVolbyLocale } from '../useKorespondencniVolbyLocale';
import {
	KorespondencniHlasovaniZapisFormStep1,
	KorespondencniHlasovaniZapisStep1,
	useFormStep1,
} from './useFormStep1';
import {
	KorespondencniHlasovaniZapisFormStep2,
	KorespondencniHlasovaniZapisStep2,
	useFormStep2,
} from './useFormStep2';
import {
	KorespondencniHlasovaniZapisFormStep3,
	KorespondencniHlasovaniZapisStep3,
	useFormStep3,
} from './useFormStep3';

interface KorespondencniHlasovaniZapisContext {
	wizard: FormWizardHook<ZapisForm>;
	step1: KorespondencniHlasovaniZapisFormStep1;
	step2: KorespondencniHlasovaniZapisFormStep2;
	step3: KorespondencniHlasovaniZapisFormStep3;
	personCommunicationData: PersonCommunicationData;
	isLoading: boolean;
	isSubmitting: boolean;
	person?: OsobyFyzickaOsobaDto;
	selectedOffice?: string;
	selectedDistrict?: string;
	selectedCountry?: string;
	canVote: boolean;
	isSubmitModalOpen: IUseBoolean;
	showConnectDataboxBanner: boolean;
	databoxTypes: DatovkaApiType['typSchranky'][];
}

type ZapisForm = KorespondencniHlasovaniZapisStep1 &
	KorespondencniHlasovaniZapisStep2 &
	KorespondencniHlasovaniZapisStep3;

const ServiceContext =
	createContext<Nullable<KorespondencniHlasovaniZapisContext>>(null);

interface Props {
	onSubmitSuccess: () => void;
	children: ReactNode;
}

export const KorespondencniHlasovaniZapisContextProvider = ({
	children,
	onSubmitSuccess,
}: Props) => {
	const databoxTypes: DatovkaApiType['typSchranky'][] = ['FO', 'PFO'];
	const personQuery = useRobPersonQuery();
	const countryCodes = useCountryCodes();
	const { t } = useKorespondencniVolbyLocale();
	const { toastMessageSuccess } = useMessageEvents();
	const { isDataBoxLoginType } = useIdentity();
	const { areAnyConnected } = useDataBoxAccess({
		requiredTypes: databoxTypes,
	});

	const isSubmitModalOpen = useBoolean();

	const submitMutation = usePoMutation({
		mutationFn: async (variables: ZapisForm) => {
			const { datovaSchrankaId, ...params } = dataBoxParams(variables);
			return sendSubmissionQuery({
				params: {
					odesilatelDSId: datovaSchrankaId,
					...params,
				},
				body: {
					kodPodani: 'ZADOST_ZAPIS_ZVLASTNI_SEZNAM_VOLICU',
					zuKod: variables.officeId,
					adresaUlice: variables.street,
					adresaMesto: variables.city,
					adresaPsc: variables.zip,
					adresaStat: variables.country,
					email: variables._useEmail ? variables.email : undefined,
					telefon:
						variables._usePhone && variables.phone
							? phoneShapeToString(variables.phone)
							: undefined,
				},
			});
		},
		onSuccess: async () => {
			wizard.resetForms();
			toastMessageSuccess(t('zapis.zpravy.odeslano'));

			isSubmitModalOpen.setFalse();

			// kdyz se zavre modal a nasledny redirect se provede moc brzo, tak asi jeste nekde visi nejaka class ktera pak znemoznuje scrolovat, chvilku pockat to resi krasne
			await delay(10);

			onSubmitSuccess();
		},
	});

	const isSubmitting = submitMutation.isLoading;

	const step1 = useFormStep1({ isDisabled: isSubmitting });
	const step2 = useFormStep2({ isDisabled: isSubmitting });
	const step3 = useFormStep3({
		isDisabled: isSubmitting,
		databoxTypes,
	});

	const personCommunicationData = usePersonCommunicationData();
	usePersonCommunicationForm(step1, {
		countryCodes,
		email: personCommunicationData.emailVerified,
		phone: personCommunicationData.phoneVerified,
	});

	const wizard = useWizard<ZapisForm>({
		steps: [step1, step2.form, step3],
		onSubmit: async (values) => submitMutation.mutate(values),
		onStepInvalid: (index, errors) => {
			if (index === 0) {
				personCommunicationData.onFormValidated(errors);
			}
		},
	});

	const elections = useElections();

	return (
		<ServiceContext.Provider
			value={{
				isLoading:
					personQuery.isLoading ||
					step2.isLoading ||
					countryCodes.isLoading ||
					elections.isLoading,
				wizard,
				personCommunicationData,
				step1,
				step2: step2.form,
				step3,
				isSubmitting,
				person: personQuery.data,
				selectedOffice: step2.selectedOffice,
				selectedDistrict: step2.selectedDistrict,
				selectedCountry: step2.selectedCountry,
				canVote: elections.canVote,
				isSubmitModalOpen,
				showConnectDataboxBanner: isDataBoxLoginType && !areAnyConnected,
				databoxTypes,
			}}>
			{children}
		</ServiceContext.Provider>
	);
};

export const useKorespondencniHlasovaniZapisContext = () => {
	const context = useContext(ServiceContext);
	if (!context) {
		throw new Error('Context must be used within a ContextProvider');
	}
	return context;
};
