import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import {
	LocalizeNameSpaceTypes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const RezaListSkeleton = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const t = getLocalizeCurried(LocalizeNameSpaceTypes.Global);

	return (
		<LayoutGap>
			{[0, 1, 2, 3, 4].map((i) => {
				return (
					<div
						className={
							'flex flex-col gap-2 mb-4 pb-8 border-b border-secondary-300'
						}
						key={i}>
						<GovSkeleton
							width={'75%'}
							height={'22px'}
							variant={'secondary'}
							wcagLabel={t('loading.wcag.processing')}
							className={'mb-4 pb-0'}
						/>
						<GovSkeleton
							width={generateRandomNumber(80, 100) + '%'}
							height={'22px'}
							variant={'secondary'}
							wcagLabel={t('loading.wcag.processing')}
							className={'mb-0 pb-0'}
						/>
						<GovSkeleton
							width={generateRandomNumber(40, 100) + '%'}
							height={'22px'}
							variant={'secondary'}
							wcagLabel={t('loading.wcag.processing')}
							className={'mb-0 pb-0'}
						/>
					</div>
				);
			})}
		</LayoutGap>
	);
};
