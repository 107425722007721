import { useQueryClient } from '@tanstack/react-query';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
	DatoveSchrankyDatovaZpravaDto,
	fetchDataBoxesMessagesQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useDataBoxEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { useMyFilesStorage } from '@gov-nx/module/page';
import {
	useDataBoxSearchStore,
	useDataBoxStore,
} from '@gov-nx/store/portal-obcana';
import { toStringDate, today } from '@gov-nx/utils/common';
import { useDataBoxUnreadMessages } from '../hooks/useDataBoxUnreadMessagesCounts';
import { useMassOperations } from '../hooks/useMassOperations';
import { useMessagesFilter } from '../hooks/useMessagesFilter';
import { useMessagesSelect } from '../hooks/useMessagesSelect';
import { divideMessagesByTime } from '../utils/divideMessagesByTime';
import { useDataboxDateUtils } from '../utils/message';
import {
	DataBoxConfirmModalType,
	DataBoxFolderType,
	DataBoxTimeDividedMessageList,
} from './DataBoxes.types';
import { useDataBoxesContext } from './DataBoxesContext';
import {
	DataBoxMessageListContextType,
	DataBoxListStatesType,
	DataBoxMessageListLoadingItemsType,
} from './MessageListContext.types';

export const DataBoxMessageListContext =
	createContext<Nullable<DataBoxMessageListContextType>>(null);

const MESSAGES_PER_PAGE = 50;

interface DataBoxMessageListProviderProps {
	dataBoxId: string;
	folderType: DataBoxFolderType;
	messageId?: number;
	children: React.ReactNode;
}

export function DataBoxMessageListProvider({
	dataBoxId,
	folderType,
	messageId,
	children,
}: DataBoxMessageListProviderProps) {
	const queryClient = useQueryClient();
	const { controls, setControls } = useProcessControl({ processLoading: true });
	const setLastOpenedDatabox = useDataBoxStore('setLastOpenedDatabox');
	const deleteDataboxSearchHistory = useDataBoxSearchStore(
		'deleteDataboxSearchHistory'
	);
	const setDataBoxSearchHistory = useDataBoxSearchStore(
		'setDataBoxSearchHistory'
	);
	const previousSuggestions = useDataBoxSearchStore('searchHistory');

	const { setMobileMenuDataBox } = useDataBoxesContext();
	const dataBoxes = useDataBoxStore('list');
	const getConnectedDataBoxesList = useDataBoxStore(
		'getConnectedDataBoxesList'
	);
	const connectedDataBoxes = getConnectedDataBoxesList();
	const storage = useMyFilesStorage();

	const [messageList, setMessageList] = useState<
		DatoveSchrankyDatovaZpravaDto[]
	>([]);
	const [timeDividedMessageList, setTimeDividedMessageList] =
		useState<DataBoxTimeDividedMessageList>([]);

	const DEFAULT_START_POSITION = [
		DataBoxFolderType.Archived,
		DataBoxFolderType.Trash,
	].includes(folderType)
		? 0
		: 1;

	const [startPosition, setStartPosition] = useState(DEFAULT_START_POSITION);
	const [rewriteAllData, setRewriteAllData] = useState(false);
	const [isLoadMorePossible, setIsLoadMorePossible] = useState(false);
	const [listState, setListState] = useState(DataBoxListStatesType.List);
	const [isMessageListHidden, setIsMessageListHidden] = useState(false);

	const messagesSelect = useMessagesSelect({
		dataBoxId,
		folderType,
		listState,
		messageList,
	});
	const messagesFilter = useMessagesFilter({
		dataBoxId,
		folderType,
		messageId,
		listState,
	});

	usePoQuery({
		queryKey: [
			'data-box-messages',
			dataBoxId,
			folderType,
			startPosition,
			messagesFilter.query,
		],
		queryFn: () =>
			fetchDataBoxesMessagesQuery({
				dataBoxId,
				folderType,
				messageCount: rewriteAllData
					? MESSAGES_PER_PAGE + startPosition - DEFAULT_START_POSITION
					: MESSAGES_PER_PAGE,
				startPosition: rewriteAllData ? DEFAULT_START_POSITION : startPosition,
				query: messagesFilter.query,
			}),
		retry: 0,
		refetchOnWindowFocus: false,
		cacheTime: 0,
		onError: () => {
			setControls({ processLoading: false });
		},
		onSuccess: (data) => {
			if (rewriteAllData) {
				setRewriteAllData(false);
				setMessageList([...(data?.seznam ?? [])]);
			} else {
				if (!messageList[startPosition - DEFAULT_START_POSITION]) {
					setMessageList((prevMessageList: DatoveSchrankyDatovaZpravaDto[]) => [
						...prevMessageList,
						...(data?.seznam ?? []),
					]);
				}
			}
			if (messagesFilter.query) {
				setDataBoxSearchHistory(messagesFilter.query);
			}

			const dataLength = (data?.seznam ?? []).length;
			setIsLoadMorePossible(
				dataLength % MESSAGES_PER_PAGE === 0 && !!dataLength
			);
			setTimeout(() => {
				setControls({ processLoading: false });
			});
			setLastOpenedDatabox(dataBoxId);
		},
	});

	const loadMore = () => {
		setControls({ processLoading: true });
		// TODO M.P. unsafe
		setStartPosition(
			(prevStartPosition) => prevStartPosition + MESSAGES_PER_PAGE
		);
	};

	useEffect(() => {
		setControls({ processLoading: true });
		setMessageList([]);
		setStartPosition(DEFAULT_START_POSITION);
		// eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are correct
	}, [dataBoxId, folderType, messagesFilter.query]);

	useEffect(() => {
		if (!messagesFilter.hasSearch) {
			messagesFilter.setQuery(undefined);
		}
		messagesSelect.setSelectedMessageList([]);
		messagesSelect.setIsAllSelected(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are correct
	}, [dataBoxId, folderType, listState]);

	const { listStateChange } = useDataBoxEvents();

	useEffect(() => {
		setListState(DataBoxListStatesType.List);
		setMobileMenuDataBox(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are correct
	}, [dataBoxId, folderType]);

	useEffect(() => {
		setTimeDividedMessageList(
			divideMessagesByTime(
				messageList,
				folderType === DataBoxFolderType.Trash
					? 'datumSmazani'
					: 'datumACasDodani'
			)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are correct
	}, [messageList]);

	useEffect(() => {
		setTimeout(() => {
			listStateChange({
				variant: listState,
				isEmptyFolder: !messageList.length,
			});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are correct
	}, [listState, messageList]);

	useDataBoxEvents({
		onMessageRead: (_, { messageId }) => {
			setMessageList((messageList) =>
				messageList.map((message) =>
					message.datovaZpravaId === messageId
						? { ...message, statusDz: '7' }
						: message
				)
			);
		},
		onMessagesArchive: (_, { messageIds }) => {
			setMessageList((messageList) =>
				messageList.map((message) =>
					messageIds.includes(message.datovaZpravaId as number)
						? { ...message, datumArchivace: toStringDate(today()) }
						: message
				)
			);
		},
		onMessagesUnselectAll: () => {
			messagesSelect.setSelectedMessageList([]);
			messagesSelect.setIsAllSelected(false);
		},
		onMessageListUpdate: async () => {
			setRewriteAllData(true);
			await queryClient.invalidateQueries(['data-box-messages']);
		},
		onHideMessageList: (_, { hidden }) => {
			setIsMessageListHidden(hidden);
		},
		onResetActionInfo: () => {
			setTimeout(() => {
				listStateChange({
					variant: listState,
					isEmptyFolder: !messageList.length,
				});
			});
		},
	});

	const handleOpenMobileMenu = () => {
		setMobileMenuDataBox(dataBoxId);
	};

	const [loadingItems, setLoadingItems] = useState<
		Partial<Record<DataBoxMessageListLoadingItemsType, boolean>>
	>({});

	const updateLoadingItems = (
		loadingItem: DataBoxMessageListLoadingItemsType,
		add: boolean
	) => {
		if (add) {
			setLoadingItems((loadingItems) => ({
				...loadingItems,
				[loadingItem]: true,
			}));
		} else {
			setLoadingItems((loadingItems) => ({
				...loadingItems,
				[loadingItem]: false,
			}));
		}
	};

	const [confirmModal, setConfirmModal] =
		useState<Nullable<DataBoxConfirmModalType>>(null);

	const massOperations = useMassOperations({
		messageList,
		selectedMessageList: messagesSelect.selectedMessageList,
		dataBoxId,
		folderType,
		updateLoadingItems,
		setConfirmModal,
		cancelMassOperations: () => {
			setListState(DataBoxListStatesType.List);
		},
	});

	const { getUnreadMessagesCounts } = useDataBoxUnreadMessages();

	const messageListCount = messageList.length;

	const deleteSuggestion = (suggestion: string) => {
		deleteDataboxSearchHistory(suggestion);
	};

	const nextDataBoxForSearch =
		connectedDataBoxes?.length > 1
			? connectedDataBoxes.find(
					(dataBox) => dataBox.datovaSchrankaId !== dataBoxId
			  )
			: undefined;

	const dataBox = dataBoxes.find(
		(dataBox) => dataBox.datovaSchrankaId === dataBoxId
	);

	const unreadMessagesCount =
		dataBox && dataBox?.datovaSchrankaId
			? getUnreadMessagesCounts(dataBox.datovaSchrankaId)
			: 0;

	const dataBoxDateUtils = useDataboxDateUtils();

	return (
		<DataBoxMessageListContext.Provider
			value={{
				dataBox,
				storage,
				messageList: timeDividedMessageList,
				messageListCount,
				controls,
				isLoadMorePossible,
				loadMore,
				listState,
				setListState,
				messagesFilter,
				messagesSelect,
				massOperations,
				confirmModal,
				setConfirmModal,
				loadingItems,
				handleOpenMobileMenu,
				isMessageListHidden,
				previousSuggestions,
				deleteSuggestion,
				nextDataBoxForSearch,
				showUnreadMessagesCount:
					!!unreadMessagesCount && folderType === DataBoxFolderType.Received,
				unreadMessagesCount,
				dataBoxDateUtils,
			}}>
			{children}
		</DataBoxMessageListContext.Provider>
	);
}

export const useDataBoxMessageListContext = (): DataBoxMessageListContextType =>
	useContext(DataBoxMessageListContext) as DataBoxMessageListContextType;
