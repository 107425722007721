import type { UseMutationResult } from '@tanstack/react-query/src/types';
import { AxiosError } from 'axios';
import { ResponseError } from '@gov-nx/api/portal-obcana';
import { hasProperties } from '@gov-nx/core/types';
import { useMySubmissionsQuery } from './queries';
import {
	ConnectToAnotherSubmissionMutation,
	useConnectToAnotherSubmissionMutation,
} from './useConnectToAnotherSubmissionMutation';
import {
	DisconnectFromSubmissionMutationVariables,
	useDisconnectFromSubmissionMutation,
} from './useDisconnectFromSubmissionMutation';

export interface MySubmissionConnection {
	isLoading: boolean;
	submissions: { id: number; nazev: string }[];
	isConnected: boolean;
	onDisconnect: UseMutationResult<
		void,
		AxiosError<ResponseError>,
		DisconnectFromSubmissionMutationVariables
	>['mutate'];
	isDisconnecting: boolean;
	connect: ConnectToAnotherSubmissionMutation;
}

export const useMySubmissionConnection = ({
	messageId,
}: {
	messageId: number;
}): MySubmissionConnection => {
	const submissionsQuery = useMySubmissionsQuery(messageId);

	const submissions =
		submissionsQuery.data?.seznam?.filter(hasProperties(['id', 'nazev'])) ?? [];

	const disconnectMutation = useDisconnectFromSubmissionMutation(
		submissionsQuery.invalidate
	);

	return {
		isLoading: submissionsQuery.isLoading,
		isConnected: submissions.length > 0,
		submissions,
		onDisconnect: disconnectMutation.mutate,
		isDisconnecting: disconnectMutation.isLoading,
		connect: useConnectToAnotherSubmissionMutation(),
	};
};
