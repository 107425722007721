import { useEffect } from 'react';
import { RezaFilter } from '@gov-nx/core/hooks';
import { FormDefinition2, useFormBuilder } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { useRezaLocale } from '@gov-nx/module/page';
import {
	FormBadgeListProps,
	FormCheckboxProps,
	WebInputProps,
} from '@gov-nx/ui/types';
import {
	useDateDefinition,
	useInputDefinition,
	toDate,
	toDateReadable,
	today,
	getBadgeListDefinition,
	getCheckboxDefinition,
} from '@gov-nx/utils/common';

export interface RezaFormFields {
	text: WebInputProps;
	role: FormBadgeListProps;
	includeArchived: FormCheckboxProps;
	platnostOdOd: WebInputProps;
	platnostOdDo: WebInputProps;
	platnostDoOd: WebInputProps;
	platnostDoDo: WebInputProps;
	button: {
		wcagLabel: string;
	};
}

export interface RezaFilterForm {
	text: Nullable<string>;
	role: {
		zmocnenec: boolean;
		zmocnitel: boolean;
	};
	includeArchived: Nullable<boolean>;
	platnostOdOd: Nullable<Date>;
	platnostOdDo: Nullable<Date>;
	platnostDoOd: Nullable<Date>;
	platnostDoDo: Nullable<Date>;
}

interface Props {
	filter: RezaFilter;
	initialState: RezaFilter;
}

export const useForm = ({
	filter,
	initialState,
}: Props): FormDefinition2<RezaFilterForm, RezaFormFields> => {
	const { t } = useRezaLocale();

	const min = today('2023-01-01');
	const minErrorMessage = t('seznam.validace.platnost.min', {
		datum: toDateReadable(min),
	});

	const text = useInputDefinition({
		name: 'text',
		placeholder: t('seznam.search.placeholder'),
		label: t('seznam.search.label'),
		wcagLabelledBy: 'vyhledat-plnou-moc',
	});

	const role = getBadgeListDefinition({
		name: 'role',
		options: [
			{
				name: 'zmocnitel',
				label: t('seznam.filters.role.zmocnitel'),
			},
			{
				name: 'zmocnenec',
				label: t('seznam.filters.role.zmocnenec'),
			},
		],
		defaultValues: {
			zmocnenec: initialState.role === 'zmocnenec',
			zmocnitel: initialState.role === 'zmocnitel',
		},
	});

	const platnostOdOd = useDateDefinition({
		name: 'platnostOdOd',
		label: t('seznam.modal.platnost-od-od'),
		min: { min, errorMessage: minErrorMessage },
	});

	const platnostOdDo = useDateDefinition({
		name: 'platnostOdDo',
		label: t('seznam.modal.platnost-od-do'),
		min: { min, errorMessage: minErrorMessage },
		noOlderThan: {
			fieldName: platnostOdOd.field.field.name,
			errorMessage: t('seznam.validace.platnost-do.starsi-nez-datum-od'),
		},
	});

	const platnostDoOd = useDateDefinition({
		name: 'platnostDoOd',
		label: t('seznam.modal.platnost-do-od'),
		min: { min, errorMessage: minErrorMessage },
	});

	const platnostDoDo = useDateDefinition({
		name: 'platnostDoDo',
		label: t('seznam.modal.platnost-do-do'),
		min: { min, errorMessage: minErrorMessage },
		noOlderThan: {
			fieldName: platnostDoOd.field.field.name,
			errorMessage: t('seznam.validace.platnost-do.starsi-nez-datum-od'),
		},
	});

	const includeArchived = getCheckboxDefinition({
		name: 'includeArchived',
		label: t('seznam.filters.zahrnout-archivovane'),
	});

	const formDefinition = useFormBuilder<
		RezaFilterForm,
		Omit<RezaFormFields, 'button'>
	>([
		text,
		role,
		includeArchived,
		platnostOdOd,
		platnostOdDo,
		platnostDoOd,
		platnostDoDo,
	]);

	const { setValue } = formDefinition.formMethods;

	useEffect(() => {
		setValue('role', {
			zmocnenec: filter.role === 'zmocnenec',
			zmocnitel: filter.role === 'zmocnitel',
		});
		setValue('text', filter.text ?? null);
		if (filter.platnostOdOd) {
			setValue('platnostOdOd', toDate(filter.platnostOdOd) ?? null);
		}
		if (filter.platnostOdDo) {
			setValue('platnostOdDo', toDate(filter.platnostOdDo) ?? null);
		}
		if (filter.platnostDoOd) {
			setValue('platnostDoOd', toDate(filter.platnostDoOd) ?? null);
		}
		if (filter.platnostDoDo) {
			setValue('platnostDoDo', toDate(filter.platnostDoDo) ?? null);
		}
	}, []);

	return {
		...formDefinition,
		fields: {
			...formDefinition.fields,
			button: { wcagLabel: t('seznam.search.button.placeholder') },
		},
	};
};
