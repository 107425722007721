import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LocalizeNameSpaceTypes, useCmsPage } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { BackButton, LayoutGap, ToggleButton } from '@gov-nx/ui/web';
import { useTitle } from '@gov-nx/utils/web';
import { FavoriteToggle } from '../Favorite/FavoriteToggle';
import { MarkdownRender } from '../Markdown/MarkdownRender';
import { CmsFaq } from './CmsFaq';
import { CsmNavigationSignpost } from './CsmNavigationSignpost';

export interface CmsPageRenderProps {
	code: PageCode;
	children: React.ReactNode;
	showDescription?: boolean;
	showFavorite?: boolean;
	override?: {
		name?: string;
		description?: string;
	};
}

export const CmsPageRender = ({
	code,
	children,
	showDescription = true,
	showFavorite = true,
	override = {},
}: CmsPageRenderProps) => {
	const navigate = useNavigate();
	const cmsContent = useCmsPage({ code });
	const localizeNameSpace = LocalizeNameSpaceTypes.Page;
	const title = override?.name
		? override.name
		: cmsContent
		? cmsContent.overrideName || cmsContent.name
		: null;

	const description = override?.description
		? override.description
		: cmsContent
		? cmsContent.description
		: null;
	useTitle(title);

	if (cmsContent === null) {
		return null;
	}

	return (
		<div className={'gov-layout py-6'}>
			<div className={'gov-layout__section gov-layout__section-text-left'}>
				<div className={'gov-layout-column'}>
					<div className={'mr-4 mb-10 [ print:hidden ]'}>
						<BackButton onClick={() => navigate(-1)} />
					</div>
					<main className={'flex flex-col gap-4'}>
						<h1 className={'text-4xl mb-0'}>
							{title}
							{cmsContent.favoriteAllowed && showFavorite ? (
								<FavoriteToggle {...{ code, name: `${title}` }} />
							) : null}
						</h1>
						<LayoutGap>
							{description && showDescription ? (
								cmsContent.descriptionDisplayed ? (
									<MarkdownRender>{description}</MarkdownRender>
								) : (
									<ToggleButton>
										<MarkdownRender>{description}</MarkdownRender>
									</ToggleButton>
								)
							) : null}
							{children}
						</LayoutGap>
					</main>
					<LayoutGap
						gap={20}
						className={'mt-20'}>
						{cmsContent.navigations.length ? (
							<CsmNavigationSignpost
								navigations={cmsContent.navigations}
								localizeNameSpace={localizeNameSpace}
							/>
						) : null}
						{cmsContent.faq.length ? (
							<CmsFaq
								faq={cmsContent.faq}
								localizeNameSpace={localizeNameSpace}
							/>
						) : null}
					</LayoutGap>
				</div>
			</div>
		</div>
	);
};
