import { compose } from './compose';

export type Optional<T> = T | undefined;
export type Nullable<T> = T | null;
export type Maybe<T> = T | undefined | null;
export type RequiredProperty<T, Key extends keyof T> = Required<Pick<T, Key>> &
	T;
export type OptionalProperty<T, K extends keyof T> = Pick<Partial<T>, K> &
	Omit<T, K>;
export type ValueOf<T> = T[keyof T];

export type DeepPartial<T> = {
	[P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export type ObjectOrArrayType<T> = T | T[];
export const ObjectOrArray = {
	first: <T>(item: ObjectOrArrayType<T>): T =>
		Array.isArray(item) ? item[0] : item,
	last: <T>(item: ObjectOrArrayType<T>): T =>
		Array.isArray(item) ? item[item.length - 1] : item,
	array: <T>(item: ObjectOrArrayType<T>): T[] =>
		Array.isArray(item) ? item : [item],
};

export const Omit = <T extends object, P extends keyof T, R extends Omit<T, P>>(
	properties: P[],
	object: T
): R =>
	Object.entries(object).reduce(
		(all, [key, value]) =>
			properties.includes(key as P) ? all : { ...all, [key]: value },
		{} as R
	);

export const whenDefined =
	<I, O>(fn: (input: I) => O) =>
	(value?: I): O | undefined =>
		value ? fn(value) : undefined;

export const defaultsTo =
	<T, R>(value: T) =>
	(condition: R) =>
		condition ?? value;

/**
 * Returns a new array [A, B] with {B} appended when {condition} is true , otherwise returns the original array [A].
 * Be aware it is currently typed only for 2 item array
 *
 * @param {boolean} condition
 * @param {B} item
 * @return {(array: [A]) => ([A, B] | [A])}
 */
export const addWhen =
	<A, B>(condition: boolean, item: B) =>
	(array: [A]): [A, B] | [A] => {
		return condition ? [...array, item] : array;
	};

export const pushWhen =
	<T>(cond: boolean, el: T) =>
	(arr: T[]) =>
		cond ? [...arr, el] : arr;

/**
 * Comparator function which defines the sort order
 * @example
 * ```
 * // sorts objects by `id` property
 * [{ id: 3 }, { id: 2 }, { id: 1 }].sort(compare(['id']))
 *
 * // sorts objects by `id` and `name` properties
 * [{ id: 3, name: 'b' }, { id: 2, name: 'a' }, { id: 1, name: 'b' }].sort(compare(['id', 'name']))
 * ```
 *
 * @param {Array<keyof T>} properties
 * @return {number}
 */
export const compare =
	<T extends object>(properties: Array<keyof T>) =>
	(a: T, b: T): number => {
		const [property, ...rest] = properties;
		if (!property) {
			return 0;
		}
		if (a[property] < b[property]) {
			return -1;
		}
		if (a[property] > b[property]) {
			return 1;
		}
		return rest.length > 0 ? compare(rest)(a, b) : 0;
	};

export const compareProperty =
	<T>(property: keyof T, order: 'ASC' | 'DESC' = 'ASC') =>
	(a: T, b: T): 0 | -1 | 1 => {
		const aValue = a[property];
		const bValue = b[property];

		if (order === 'ASC') {
			return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
		} else {
			return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
		}
	};

export const compareProperties =
	<T>(properties: { property: keyof T; order?: 'ASC' | 'DESC' }[]) =>
	(a: T, b: T): 0 | -1 | 1 => {
		const [property, ...rest] = properties;

		const result = compareProperty(property.property, property.order)(a, b);
		if (result === 0 && rest.length > 0) {
			return compareProperties(rest)(a, b);
		}
		return result;
	};

export const is = <T>(value: T | undefined | null): value is T =>
	value !== undefined && value !== null;

export const hasSomeProperties =
	<T, U extends Array<keyof T>>(fields: U) =>
	(obj: T): boolean =>
		fields.some((field) => !!obj[field]);

/**
 * @deprecated use hasProperty/hasProperties instead - they use better type inferences
 */
export const has =
	<T extends object, P extends keyof T>(property: P) =>
	(object: OptionalProperty<T, P>): object is T => {
		return !!object[property];
	};

export const hasProperties =
	<T, U extends Array<keyof T>>(fields: U) =>
	(obj: T): obj is RequiredProperty<T, U[number]> =>
		fields.every((field) => !!obj[field]);

export const hasProperty =
	<T, U extends keyof T>(property: U) =>
	(obj: T): obj is RequiredProperty<T, U> => {
		return !!obj[property];
	};

export const equals =
	<A>(a?: A) =>
	(b?: A): boolean =>
		a === b;

export const arrayEquals = <T>(array1: T[], array2: T[]) =>
	array1.length === array2.length &&
	array1.every((value, index) => value === array2[index]);

export const prop =
	<T, P extends keyof T>(property: P) =>
	(object: T): T[P] =>
		object[property];

export const propOptional =
	<T, P extends keyof T>(property: P) =>
	(object?: T): Optional<T[P]> =>
		object ? object[property] : undefined;

export const propEq = <T, P extends keyof T>(
	property: P,
	equalsTo: T[typeof property]
) => compose(equals(equalsTo), prop(property));
export const propEqPartial =
	<T>(property: keyof T) =>
	(equalsTo: T[typeof property]) =>
		propEq(property, equalsTo);

export const hasOwnProperty = <X extends object, Y extends PropertyKey>(
	obj: X,
	prop: Y
): obj is X & Record<Y, ValueOf<X>> => {
	return Object.prototype.hasOwnProperty.call(obj, prop);
};

export const getProperty = <T, Key extends keyof T>(
	obj: T,
	property: Key
): undefined | T[Key] => {
	return isObject(obj) && hasOwnProperty(obj, property)
		? obj[property]
		: undefined;
};

export const isArray = <T>(obj: T | T[]): obj is T[] => Array.isArray(obj);
export const isObject = <T extends object>(obj: unknown): obj is T =>
	typeof obj === 'object';
export const isString = (input: unknown): input is string =>
	is(input) && typeof input === 'string';

export const isNumber = (input: unknown): input is number =>
	is(input) && typeof input === 'number';

type Entries<T> = {
	[K in keyof T]: [K, T[K]];
}[keyof T][];
export const getEntries = <T extends object>(obj: T) =>
	Object.entries(obj) as Entries<T>;

export const getKeys = <T extends object>(obj: T) =>
	Object.keys(obj) as Array<keyof T>;
export const getValues = <T extends object>(obj: T) =>
	Object.values(obj) as Array<ValueOf<T>>;

export const first = <T>(array: T[]): Optional<T> => array[0];

export const pairs = <T>(arr: T[]): Array<[string, string]> =>
	arr.flatMap(
		(item1, index1) =>
			arr.flatMap((item2, index2) => (index1 > index2 ? [[item1, item2]] : []))
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	) as any;

export const find =
	<T, S extends T>(predicate: (item: T) => item is S) =>
	(array?: T[]): Optional<S> =>
		(array || []).find(predicate);

export const filter =
	<T, S extends T>(predicate: (item: T) => item is S) =>
	(array?: T[]): S[] =>
		(array || []).filter(predicate);

export const map =
	<I, O>(fn: (input: I) => O) =>
	(arr: I[]) =>
		arr.map(fn);

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const voided = () => {};

export type Split<S extends string, D extends string> = string extends S
	? string[]
	: S extends ''
	? []
	: S extends `${infer T}${D}${infer U}`
	? [T, ...Split<U, D>]
	: [S];

export const splitByDot = <T extends string>(string: T): Split<T, '.'> =>
	string.split('.') as Split<T, '.'>;

export type Leaves<T> = T extends object
	? {
			[K in keyof T]: `${Exclude<K, symbol>}${Leaves<T[K]> extends never
				? ''
				: `.${Leaves<T[K]>}`}`;
	  }[keyof T]
	: never;

export type Paths<T> = T extends object
	? {
			[K in keyof T]: `${Exclude<K, symbol>}${'' | `.${Paths<T[K]>}`}`;
	  }[keyof T]
	: never;

export type DeepIndex<T, K extends string> = T extends object
	? string extends K
		? never
		: K extends keyof T
		? T[K]
		: K extends `${infer F}.${infer R}`
		? F extends keyof T
			? DeepIndex<T[F], R>
			: never
		: never
	: never;
