import {
	axiosInstance,
	CiselnikyCiselnikPolozkaDto,
	CiselnikyVolbySeznamDto,
} from '@gov-nx/api/portal-obcana';

export const electionsQuery = async (): Promise<CiselnikyVolbySeznamDto> => {
	const { data } = await axiosInstance.get('/api/v1/volby');
	// hodi se pro lokalni mockovani
	// return {
	// 	seznam: [
	// 		{
	// 			druh: 'SENAT',
	// 			volbyId: 14494,
	// 			kola: [
	// 				{
	// 					kolo: 1,
	// 					terminOd: '2022-11-23T00:00:00Z',
	// 					terminDo: '2022-11-24T00:00:00Z',
	// 					lzePodatZadost: true,
	// 					zadostPodana: false,
	// 				},
	// 				{
	// 					kolo: 2,
	// 					terminOd: '2022-11-30T00:00:00Z',
	// 					terminDo: '2022-12-01T00:00:00Z',
	// 					lzePodatZadost: true,
	// 					zadostPodana: false,
	// 				},
	// 			],
	// 		},
	// 	],
	// 	pocet: 1,
	// };
	return data;
};

export interface CiselnikyCiselnikPolozkaDtoEmbassyOffice
	extends CiselnikyCiselnikPolozkaDto {
	parametry: {
		adresaText?: string;
	};
}

export const embassyOfficeListQuery = async (): Promise<{
	seznam?: CiselnikyCiselnikPolozkaDtoEmbassyOffice[];
}> => {
	const { data } = await axiosInstance.get(
		'/api/v1/ciselniky/VOLBY_ZAHRANICNI_VO/polozky'
	);
	return data;
};

export interface EmbassyOfficePostalVoting {
	id: number;
	kod: string;
	nazevCs: string; // zastupitelsky	urad
	nazevEn: string;
	vlastnost: string; // --- kod státu (pro groupovani podle státu …napr v nemecku jsou tri ZU)
	parametry: {
		id?: string;
		nazev: string;
		kodStatu: string;
		nazevStatu: string;
		uzemniObvody: string[]; //- uzemni obvod
	};
}

export const embassyOfficeListPostalVotingQuery = async (): Promise<{
	seznam?: EmbassyOfficePostalVoting[];
}> => {
	const { data } = await axiosInstance.get(
		'/api/v1/ciselniky/ZU_KORESPONDENCNI_HLASOVANI/polozky'
	);
	return data;
};
