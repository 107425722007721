import { createContext, ReactNode, useContext } from 'react';
import { Nullable, RequiredProperty } from '@gov-nx/core/types';
import { AllowedElection, useElections } from '../useElections';

interface KorespondencniHlasovaniSeznamContext {
	isLoading: boolean;
	elections: Array<
		RequiredProperty<AllowedElection, 'volbyId'> & { title: string }
	>;
	canUseDocuments: boolean;
}

const ServiceContext =
	createContext<Nullable<KorespondencniHlasovaniSeznamContext>>(null);

interface Props {
	children: ReactNode;
}

export const KorespondencniHlasovaniSeznamContextProvider = ({
	children,
}: Props) => {
	const { elections, isLoading, isCzechCitizen } = useElections();

	return (
		<ServiceContext.Provider
			value={{
				isLoading,
				elections,
				canUseDocuments: isCzechCitizen && elections.length > 0,
			}}>
			{children}
		</ServiceContext.Provider>
	);
};

export const useKorespondencniHlasovaniSeznamContext = () => {
	const context = useContext(ServiceContext);
	if (!context) {
		throw new Error('Context must be used within a ContextProvider');
	}
	return context;
};
