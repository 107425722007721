import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {
	PERSIST_ZUSTAND_STORE_KEY,
	StoreKeys,
	storeGlobalConfig,
} from '../constants';
import { TranslationsState } from './store.types';

const initialState = {
	translations: {},
};

export const useTranslationsStore = create<TranslationsState>()(
	persist(
		(set, get) => ({
			...initialState,

			updateTranslations: (translations, key) =>
				set((state: TranslationsState) => ({
					translations: {
						...state.translations,
						[key]: translations,
					},
				})),

			getTranslationsByKey: (key: string) => get().translations?.[key],
		}),
		{
			...storeGlobalConfig,
			name: PERSIST_ZUSTAND_STORE_KEY + ':' + StoreKeys.translations,
		}
	)
);
