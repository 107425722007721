import {
	hasOwnProperty,
	Optional,
	Split,
	splitByDot,
} from '@gov-nx/core/types';
import { getServiceUrlByCode, ServiceCode } from '@gov-nx/module/service';
import { replaceVariablesInUrl } from '@gov-nx/utils/common';
import { pageList } from '../../../module/page/src/definitions/pages';
import { PagePath } from './helpertypes';

export const serviceRouteResolver = (
	service: keyof typeof ServiceCode
): string => getServiceUrlByCode(ServiceCode[service]) ?? '#';

type OtherChildren = Record<
	string,
	{
		locales: { cs: { slug: string } };
		children?: OtherChildren;
	}
>;

export const pageRouteResolver = (
	path: PagePath,
	params?: Record<string, Optional<string | number>>
): string => {
	const getPageUrlRecursive = (
		keys: Split<PagePath, '.'> | string[],
		obj: OtherChildren,
		slugs: string[]
	): string[] => {
		if (keys.length === 0) {
			return slugs;
		}
		const [name, ...remainingKeys] = keys;
		const currentObj = hasOwnProperty(obj, name) ? obj[name] : undefined;
		if (!currentObj) {
			return slugs;
		}

		const newSlugs = [...slugs, currentObj.locales.cs.slug];
		if (remainingKeys.length > 0 && currentObj.children) {
			return getPageUrlRecursive(remainingKeys, currentObj.children, newSlugs);
		}

		return newSlugs;
	};

	const chunks = getPageUrlRecursive(splitByDot(path), pageList.children, []);
	const link = `/${chunks.join('/')}`;

	return replaceVariablesInUrl(link, params ?? null);
};
