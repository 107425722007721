import * as yup from 'yup';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	useLocale,
	usePoForm,
} from '@gov-nx/core/service';
import { DataBoxSelectProps } from '@gov-nx/ui/types';
import {
	dataBoxParams,
	useDataBoxShape,
	useIcNumberShape,
} from '@gov-nx/utils/common';
import { ServiceCode } from '../definitions/codes';
import { DataRequest, FormData } from './service.types';

export interface FormInstanceProps {
	code: ServiceCode;
	isEmail: boolean;
	email?: string;
	dataBoxTypes: DatovkaApiType['typSchranky'][];
}

export interface Fields {
	databox: DataBoxSelectProps;
}

interface Form {
	getFields: (isProcessing: boolean) => Fields;
	formDefinition: FormDefinition<FormData>;
}

export function FormInstance({
	code,
	isEmail,
	email,
	dataBoxTypes,
}: FormInstanceProps): Form {
	const { t } = useLocale(code);
	const { getIcNumberShape } = useIcNumberShape();
	const { getDataBoxShape, getDataBoxDefaultValues, dataBoxesOptions } =
		useDataBoxShape({
			types: dataBoxTypes,
		});

	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			_registeredLocation: yup.string().optional(),

			...getDataBoxShape({}),

			email: yup
				.string()
				.required(t('formular.validace.zadejte-kontaktni-email'))
				.email(t('formular.validace.email-neni-ve-spravnem-tvaru')),

			ico: getIcNumberShape({})
				.optional()
				.when('_registeredLocation', {
					is: 'czech',
					then: (schema) => schema.required(t('formular.validace.ic')),
				}),
			nazevSpolecnosti: yup
				.string()
				.optional()
				.when('_registeredLocation', {
					is: 'foreign',
					then: (schema) =>
						schema.required(t('formular.validace.nazev-spolecnosti')),
				}),
			pravniForma: yup
				.string()
				.optional()
				.when('_registeredLocation', {
					is: 'foreign',
					then: (schema) =>
						schema.required(t('formular.validace.pravni-forma')),
				}),
			adresaSidla: yup
				.string()
				.optional()
				.when('_registeredLocation', {
					is: 'foreign',
					then: (schema) =>
						schema.required(t('formular.validace.adresa-sidla')),
				}),
			vypisZeZahranici: yup
				.string()
				.optional()
				.when('_registeredLocation', {
					is: 'foreign',
					then: (schema) =>
						schema.required(t('formular.validace.vypis-ze-zahranici')),
				}),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			...getDataBoxDefaultValues(),
			_registeredLocation: 'czech',
			email: isEmail ? email : undefined,
		},
	});

	return {
		getFields: (isDisabled) => {
			return {
				databox: {
					field: {
						name: 'datovaSchrankaId',
						isDisabled,
						options: dataBoxesOptions,
					},
					label: {
						children: t('formular.pole.datova-schranka'),
					},
				},
			};
		},
		formDefinition: getFormDefinition({ formMethods, formSchema }),
	};
}

export function prepareSubmitData(formData: FormData): DataRequest {
	return {
		params: {
			...dataBoxParams(formData),
			kontrola: false,
		},
		body: {
			kodPodani: 'REJSTRIK_TRESTU_VYPIS_EGSB_2',
			typOsoby: 'PO',
			...(formData._registeredLocation === 'foreign'
				? {
						nazevSpolecnosti: formData.nazevSpolecnosti,
						pravniForma: formData.pravniForma,
						adresaSidla: formData.adresaSidla,
						vypisZeZahranici: formData.vypisZeZahranici,
				  }
				: { ico: formData.ico }),
		},
	};
}

export function prepareSubmitDataDS(formData: FormData, download = false) {
	return {
		params: {
			...dataBoxParams(formData),
		},
		body: {
			typVypisu: 'REJSTRIK_TRESTU_PO',
			data: {
				...(formData._registeredLocation === 'foreign'
					? {
							nazev: formData.nazevSpolecnosti,
							pravniForma: formData.pravniForma,
							sidlo: formData.adresaSidla,
							stat: formData.vypisZeZahranici,
					  }
					: { ico: formData.ico }),
			},
		},
	};
}
