import React from 'react';
import { OsobyFyzickaOsobaDto } from '@gov-nx/api/portal-obcana';
import { is } from '@gov-nx/core/types';
import { DataTable } from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';

interface ApplicantFOProps {
	person: OsobyFyzickaOsobaDto;
	showDateOfBirth?: Array<
		keyof Pick<OsobyFyzickaOsobaDto, 'datumNarozeni' | 'mistoNarozeni'>
	>;
	showGender?: boolean;
	strings: {
		nameLabel: string;
		surnameLabel: string;
		maidenNameLabel?: string;
		addressLabel: string;
		dateOfBirthLabel?: string;
		gender?: {
			label: string;
			value: string;
		};
	};
}

export const ApplicantFO = ({
	person,
	strings,
	showDateOfBirth,
	showGender,
}: ApplicantFOProps) => {
	return (
		<DataTable responsive>
			<tbody>
				<tr>
					<td>{strings.nameLabel}</td>
					<td>
						<strong>{person.jmeno}</strong>
					</td>
				</tr>
				<tr>
					<td>{strings.surnameLabel}</td>
					<td>
						<strong>{person.prijmeni}</strong>
					</td>
				</tr>
				{person.rodnePrijmeni && strings.maidenNameLabel ? (
					<tr>
						<td>{strings.maidenNameLabel}</td>
						<td>
							<strong>{person.rodnePrijmeni}</strong>
						</td>
					</tr>
				) : null}

				{showDateOfBirth && person.datumNarozeni && (
					<tr>
						<td>{strings.dateOfBirthLabel}</td>
						<td>
							<strong>
								{showDateOfBirth
									?.map((dateType) => {
										if (dateType === 'datumNarozeni' && person.datumNarozeni) {
											return toDateReadable(person.datumNarozeni);
										}
										if (dateType === 'mistoNarozeni') {
											return person.mistoNarozeni;
										}
										return null;
									})
									.filter(is)
									.join(', ')}
							</strong>
						</td>
					</tr>
				)}
				<tr>
					<td>{strings.addressLabel}</td>
					<td>
						<strong>{person.adresaPobytu?.adresaText}</strong>
					</td>
				</tr>
				{showGender && strings.gender?.label ? (
					<tr>
						<td>{strings.gender.label}</td>
						<td>
							<strong>{strings.gender.value}</strong>
						</td>
					</tr>
				) : null}
			</tbody>
		</DataTable>
	);
};
