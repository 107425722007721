import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { SecurityExtendedUserInfoDto } from '@gov-nx/api/portal-obcana';
import { Optional, prop } from '@gov-nx/core/types';
import {
	PERSIST_ZUSTAND_STORE_KEY,
	StoreKeys,
	storeGlobalConfig,
} from '../constants';
import { AuthState } from './store.types';

const initialState = {
	token: undefined,
	user: undefined,
	remainingPinAttempts: -1,
};

export const useAuthStoreBase = create<AuthState>()(
	persist(
		(set, get) => ({
			...initialState,

			setUser: (user: SecurityExtendedUserInfoDto) =>
				set({
					user,
				}),
			setAccessToken: (token: Optional<string>) =>
				set({
					token,
				}),
			logout: () => set(initialState),
			authReset: () => set(initialState),

			isLoggedIn: () => !!(get().token && get().user),
			getUserLoginType: () => get().user?.typ_prihlaseni,
			getUserLoa: () => get().user?.loa,
			setRemainingPinAttempts: (remainingPinAttempts: number) =>
				set({ remainingPinAttempts }),
		}),
		{
			...storeGlobalConfig,
			name: PERSIST_ZUSTAND_STORE_KEY + ':' + StoreKeys.auth,
		}
	)
);

export const useAuthStore = <T extends keyof AuthState>(
	property: T
): AuthState[T] => useAuthStoreBase(prop(property));
