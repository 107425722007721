import { GovModal } from '@gov-design-system-ce/react';
import React from 'react';
import { useRezaListContext, useRezaLocale } from '@gov-nx/module/page';
import {
	Button,
	FormCheckbox,
	FormDatePicker,
	FormWidthElementLayout,
	LayoutGap,
} from '@gov-nx/ui/web';

export const FilterModal = () => {
	const { t } = useRezaLocale();
	const {
		isFilterModalOpen,
		onFilterReset,
		isFilterModalActive,
		formDefinition,
	} = useRezaListContext();

	return (
		<GovModal
			label={t('seznam.modal.title')}
			open={isFilterModalOpen.isTrue}
			onGov-close={isFilterModalOpen.setFalse}>
			<fieldset>
				<legend>{t('seznam.modal.platnost-od.label')}</legend>

				<LayoutGap className="mb-4 [ md:flex-row ]">
					<FormWidthElementLayout>
						<FormDatePicker {...formDefinition.fields.platnostOdOd} />
					</FormWidthElementLayout>
					<FormWidthElementLayout>
						<FormDatePicker {...formDefinition.fields.platnostOdDo} />
					</FormWidthElementLayout>
				</LayoutGap>
			</fieldset>

			<fieldset>
				<legend>{t('seznam.modal.platnost-do.label')}</legend>

				<LayoutGap className="mb-4 [ md:flex-row ]">
					<FormWidthElementLayout>
						<FormDatePicker {...formDefinition.fields.platnostDoOd} />
					</FormWidthElementLayout>
					<FormWidthElementLayout>
						<FormDatePicker {...formDefinition.fields.platnostDoDo} />
					</FormWidthElementLayout>
				</LayoutGap>
			</fieldset>

			<fieldset>
				<FormCheckbox
					field={{
						...formDefinition.fields.includeArchived.field,
						label: formDefinition.fields.includeArchived.label,
					}}
				/>
				<div>{t('seznam.filters.zahrnout-archivovane-popis')}</div>
			</fieldset>

			<div
				slot="footer"
				className={'flex gap-4'}>
				<Button
					variant={'primary'}
					type={'solid'}
					nativeType={'submit'}
					wcagLabel={t('akce.filtrovat.wcag')}>
					{t('akce.filtrovat.label')}
				</Button>
				{isFilterModalActive ? (
					<Button
						onClick={onFilterReset}
						variant="error"
						type="outlined"
						wcagLabel={t('akce.zrusit-filtr.wcag')}>
						{t('akce.zrusit-filtr.label')}
					</Button>
				) : null}
			</div>
		</GovModal>
	);
};
