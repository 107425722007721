import { LocalizationLanguage } from '@gov-nx/core/app';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { ServiceCode } from '@gov-nx/module/service';

export type FavoriteCode = PageCode | ServiceCode | string;

interface UserSettingBase {
	key: CommonSettingKey;
	value: NastaveniApiValueType;
}

export interface UserSettingLanguage extends UserSettingBase {
	key: CommonSettingKey.Language;
	value: LocalizationLanguage;
}

export interface UserSettingFavorites extends UserSettingBase {
	key: CommonSettingKey.Favorites;
	value: FavoriteCode[];
}

export type UserSetting = UserSettingLanguage | UserSettingFavorites;

export type FirebaseTokenPayload = {
	key: Nullable<string>;
	value?: string;
};

// NOTE: The keys used in storage are defined by us, so feel free to add new ones.
export enum CommonSettingKey {
	Favorites = 'oblibene',
	Language = 'jazyk',
}

// Generated NastaveniNastaveniDto is not correct, so we need to use following DTOs instead:
export type NastaveniApiValueType = string | number | boolean | null | object;

export interface NastaveniApiActualDto {
	klic: string;
	hodnota: NastaveniApiValueType;
}

export const isFavoritesDTO = (
	dto: NastaveniApiActualDto
): dto is {
	klic: CommonSettingKey.Favorites;
	hodnota: string[];
} => dto.klic === CommonSettingKey.Favorites;

export interface NastaveniSeznamApiActualDto {
	seznam: NastaveniApiActualDto[];
}
