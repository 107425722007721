import { pageRouteResolver } from '@gov-nx/web';
import { useLocale } from '@gov-nx/core/service';
import { useProfilROSContextInstance } from '@gov-nx/module/page';
import { CopyButton, Tile, Tiles } from '@gov-nx/ui/web';

export const POListView = () => {
	const { POData, code } = useProfilROSContextInstance();
	const { t } = useLocale(code);

	if (!POData.length) return null;

	return (
		<section>
			<h1 className="text-2xl text-secondary-700">
				{t('nadpis.pravnicka-osoba')}
			</h1>
			<Tiles>
				{POData.map((subject) => (
					<Tile
						key={subject.ico}
						name={subject.nazevSpolecnosti ?? ''}
						headlineLink={true}
						to={pageRouteResolver('profil.ros.detail', {
							ico: subject.ico,
						})}
						icon={{ name: 'chevron-right', type: 'basic' }}>
						<div
							className={
								'flex flex-col justify-start gap-2 [ sm:flex-row sm:items-center ]'
							}>
							{subject.ico ? (
								<div className={'flex items-center gap-1'}>
									<span className={'inline-block mr-1 text-secondary-700'}>
										{t('zaznam.ico')}
									</span>
									<strong className={'gov-color--secondary-900'}>
										{subject.ico}
									</strong>
									<CopyButton
										label={'akce.kopirovat-cislo-dokladu' + subject.ico}
										value={subject.ico}
									/>
								</div>
							) : null}

							{subject?.sidloSpolecnosti?.adresaText ? (
								<div className={'flex items-center'}>
									<span className={'inline-block mr-1 text-secondary-700'}>
										{t('zaznam.sidlo')}
									</span>
									<strong className={'gov-color--secondary-900'}>
										{subject?.sidloSpolecnosti?.adresaText}
									</strong>
								</div>
							) : null}
						</div>
					</Tile>
				))}
			</Tiles>
		</section>
	);
};
