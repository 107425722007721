import { useState } from 'react';
import { formSessionQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { Nullable } from '@gov-nx/core/types';

interface Variables {
	paramsString: string;
	rezaCode?: string;
}
export const useFormSession = () => {
	const [redirectUrl, setRedirectUrl] = useState<Nullable<string>>(null);
	const submitMutation = usePoMutation({
		mutationFn: async ({ rezaCode, paramsString }: Variables) => {
			const params = Object.fromEntries(new URLSearchParams(paramsString));

			return formSessionQuery(params.typ, params.formId, rezaCode);
		},
		onMutate: () => setRedirectUrl(null),
		onSuccess: (data) => {
			if (data?.redirectUrl) {
				setRedirectUrl(data.redirectUrl);
				window.location.href = data.redirectUrl;
			}
		},
	});

	return {
		submit: submitMutation.mutate,
		isLoading: submitMutation.isLoading,
		isSuccess: submitMutation.isSuccess,
		redirectUrl,
	};
};
