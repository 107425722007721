import { RezaFilter } from '@gov-nx/core/hooks';
import { FormDefinition } from '@gov-nx/core/service';
import { FilterParam } from '@gov-nx/core/types';
import { toDateReadable, toStringDate, useFilter } from '@gov-nx/utils/common';
import { useRezaLocale } from '../useRezaLocale';
import { RezaFilterForm } from './useForm';

export const useFilters = () => {
	const { t } = useRezaLocale();

	const initialState: RezaFilter = {
		text: undefined,
		role: 'zmocnitel',
		includeArchived: false,
		platnostOdDo: undefined,
		platnostOdOd: undefined,
		platnostDoDo: undefined,
		platnostDoOd: undefined,
	};

	const { filter, setPartialFilter, reset } = useFilter<RezaFilter>({
		key: 'reza',
		initialState,
	});

	const fields: Array<{
		name: keyof Pick<
			RezaFilter,
			'platnostOdOd' | 'platnostOdDo' | 'platnostDoOd' | 'platnostDoDo'
		>;
		label: string;
	}> = [
		{ name: 'platnostOdOd', label: t('seznam.filters.platnost-od-od') },
		{ name: 'platnostOdDo', label: t('seznam.filters.platnost-od-do') },
		{ name: 'platnostDoOd', label: t('seznam.filters.platnost-do-od') },
		{ name: 'platnostDoDo', label: t('seznam.filters.platnost-do-do') },
	];

	const filterParams = (
		formDefinition: FormDefinition<RezaFilterForm>
	): FilterParam[] => [
		{
			label: t('seznam.filters.text'),
			value: filter.text,
			onRemove: () => {
				setPartialFilter({ text: undefined });
				formDefinition.formMethods.setValue('text', '');
			},
			isActive: !!filter.text,
		},
		{
			label: t('seznam.filters.zahrnout-archivovane'),
			value: !!filter.includeArchived,
			onRemove: () => {
				setPartialFilter({ includeArchived: false });
				formDefinition.formMethods.setValue('includeArchived', false);
			},
			isActive: filter.includeArchived,
		},
		...fields.map(({ name, label }) => {
			return {
				label: label,
				value: filter[name] ? toDateReadable(filter[name]) : undefined,
				onRemove: () => {
					setPartialFilter({ [name]: undefined });
					formDefinition.formMethods.resetField(name);
				},
				isActive: !!filter[name],
			};
		}),
	];

	const determineRole = (role?: RezaFilterForm['role']): RezaFilter['role'] => {
		if (role?.zmocnenec) return 'zmocnenec';
		if (role?.zmocnitel) return 'zmocnitel';
		return undefined;
	};

	const formToFilter = (values: RezaFilterForm): RezaFilter => {
		return {
			role: determineRole(values.role),
			includeArchived: values.includeArchived ?? undefined,
			text: values.text ?? undefined,
			platnostOdOd: values.platnostOdOd
				? toStringDate(values.platnostOdOd)
				: undefined,
			platnostOdDo: values.platnostOdDo
				? toStringDate(values.platnostOdDo)
				: undefined,
			platnostDoOd: values.platnostDoOd
				? toStringDate(values.platnostDoOd)
				: undefined,
			platnostDoDo: values.platnostDoDo
				? toStringDate(values.platnostDoDo)
				: undefined,
		};
	};

	const updateFilters = (values: RezaFilterForm): void =>
		setPartialFilter(formToFilter(values));

	const updateRole = (role: RezaFilterForm['role']) => {
		setPartialFilter({
			role: determineRole(role),
		});
	};

	const isFilterModalActive = !!(
		filter.platnostOdOd ||
		filter.platnostOdDo ||
		filter.platnostDoOd ||
		filter.platnostDoDo
	);

	return {
		filterParams,
		filter,
		initialState,
		updateFilters,
		updateRole,
		reset,
		isFilterModalActive,
	};
};
