import { useEffect } from 'react';
import * as yup from 'yup';
import { OsobyFyzickaOsobaDto } from '@gov-nx/api/portal-obcana';
import { PersonCommunicationData } from '@gov-nx/core/hooks';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	useLocale,
	usePoForm,
} from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { toDateReadable, useAddressSearchShape } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import {
	DataRequest,
	FormData,
	ProfileRobComplaintProcessControl,
} from './context.types';

export interface FormInstanceProps {
	code: PageCode;
	data: Nullable<OsobyFyzickaOsobaDto>;
	communicationData: Nullable<PersonCommunicationData>;
	controls: ProfileRobComplaintProcessControl;
}

export function FormInstance({
	code,
	data,
	communicationData,
	controls,
}: FormInstanceProps): FormDefinition<FormData> {
	const { t } = useLocale(code);

	const { getAddressSearchDefaultValues, getAddressSearchShape } =
		useAddressSearchShape();

	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			jmeno: yup.string().optional(),
			prijmeni: yup.string().optional(),
			rodnePrijmeni: yup.string().optional(),
			rodinnyStavPartnerstvi: yup.string().optional(),
			pohlavi: yup.string().optional(),
			datumNarozeni: yup.string().optional(),
			mistoNarozeni: yup.string().optional(),
			stat: yup.string().when('newPlaceOfBirth', {
				is: (val: string) => !!val,
				then: yup.string().required(t('formular.disclaimer.stat')),
				otherwise: yup.string().optional(),
			}),
			adresaPobytu: yup.string().optional(),
			nazevObceDorucovaciAdresa: yup.string().when([], {
				is: () => controls.displaySearchDeliveryAddress,
				then: yup
					.string()
					.required(t('formular.validace.popisReklamace.povinny')),
				otherwise: yup.string().optional(),
			}),
			nazevUliceDorucovaciAdresa: yup.string().optional(),
			cisloDorucovaciAdresa: yup.string().when([], {
				is: () => controls.displaySearchDeliveryAddress,
				then: yup
					.string()
					.required(t('formular.validace.popisReklamace.povinny'))
					.min(0)
					.max(4, t('formular.validace.max-9999'))
					.matches(/^[0-9]+$/, t('formular.validace.pouze-cislice')),
				otherwise: yup.string().optional(),
			}),
			omezeniSvepravnosti: yup.string().optional(),
			popisReklamace: yup
				.string()
				.required(t('formular.validace.popisReklamace.povinny')),

			...getAddressSearchShape({
				controls: controls.displaySearchAddress,
				nazevObce: {
					requiredMessage: t('formular.validace.popisReklamace.povinny'),
				},
				cislo: {
					requiredMessage: t('formular.validace.popisReklamace.povinny'),
					max9999Message: t('formular.validace.max-9999'),
					onlyNumbersMessage: t('formular.validace.pouze-cislice'),
				},
			}),
			poskytnoutEmail: yup.boolean(),
			poskytnoutTelefon: yup.boolean(),
			email: yup.string().when('poskytnoutEmail', {
				is: (poskytnoutEmail: boolean) => poskytnoutEmail === true,
				then: (schema) => schema.required(t('formular.disclaimer.email')),
			}),
			telefonniCislo: yup.string().when('poskytnoutTelefon', {
				is: (poskytnoutTelefon: boolean) => poskytnoutTelefon === true,
				then: (schema) => schema.required(t('formular.disclaimer.phone')),
			}),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			jmeno: undefined,
			prijmeni: undefined,
			rodnePrijmeni: undefined,
			rodinnyStavPartnerstvi: undefined,
			pohlavi: undefined,
			datumNarozeni:
				data && data.datumNarozeni
					? toDateReadable(data.datumNarozeni) || ''
					: '',
			stat: undefined,
			popisReklamace: undefined,
			mistoNarozeni: undefined,
			adresaPobytu: undefined,
			adresaDorucovaci: undefined,
			omezeniSvepravnosti: undefined,
			...getAddressSearchDefaultValues(),
			email: communicationData?.emailVerified,
			telefonniCislo: communicationData?.phoneVerified,
		},
	});

	const poskytnoutEmail = formMethods.watch('poskytnoutEmail');
	const poskytnoutTelefon = formMethods.watch('poskytnoutTelefon');

	useEffect(() => {
		if (!poskytnoutEmail) {
			formMethods.clearErrors('email');
		}
		if (!poskytnoutTelefon) {
			formMethods.clearErrors('telefonniCislo');
		}
	}, [formMethods, poskytnoutEmail, poskytnoutTelefon]);

	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData =
	(
		newAddress?: string,
		newDeliveryAddress?: string,
		newPlaceOfBirth?: string
	) =>
	(data: FormData): DataRequest => {
		const seznam = [];
		if (data.jmeno) {
			seznam.push({
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'Jmeno',
				zpusobNaplneni: data.jmeno,
			});
		}
		if (data.prijmeni) {
			seznam.push({
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'Prijmeni',
				zpusobNaplneni: data.prijmeni,
			});
		}
		if (data.rodnePrijmeni) {
			seznam.push({
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'RodnePrijmeni',
				zpusobNaplneni: data.rodnePrijmeni,
			});
		}
		if (data.rodinnyStavPartnerstvi) {
			seznam.push({
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'RodinnyStavPartnerstvi',
				zpusobNaplneni: data.rodinnyStavPartnerstvi,
			});
		}
		if (data.pohlavi) {
			seznam.push({
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'Pohlavi',
				zpusobNaplneni: data.pohlavi,
			});
		}
		if (data.stat) {
			seznam.push({
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'MistoNarozeniSvetStat',
				zpusobNaplneni: data.stat,
			});
		}
		if (data.stat) {
			seznam.push({
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'MistoNarozeniSvetMisto',
				zpusobNaplneni: newPlaceOfBirth,
			});
		}
		if (data.adresaPobytu) {
			seznam.push({
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'AdresaPobytu',
				zpusobNaplneni: newAddress,
			});
		}
		if (data.adresaDorucovaci) {
			seznam.push({
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'DorucovaciAdresa',
				zpusobNaplneni: newDeliveryAddress,
			});
		}
		if (data.omezeniSvepravnosti) {
			seznam.push({
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'OmezeniSvepravnosti',
				zpusobNaplneni: data.omezeniSvepravnosti,
			});
		}
		return {
			params: {},
			body: {
				typReklamace: 'REKLAMACE_EXISTUJICIHO_SUBJEKTU',
				navrhovaneHodnoty: { seznam },
				popisReklamace: data.popisReklamace,
				kontaktniEmail: data.poskytnoutEmail ? data.email : undefined,
				kontaktniTelefon: data.poskytnoutTelefon
					? data.telefonniCislo
					: undefined,
			},
		};
	};
