import {
	sendForm602SubmissionQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';

interface Form602SubmissionSendQuery {
	onSuccess?: () => void;
}

export const useForm602SubmissionSendQuery = (
	props?: Form602SubmissionSendQuery
) => {
	const mutation = usePoMutation({
		mutationFn: sendForm602SubmissionQuery,
		onSuccess: () => {
			props?.onSuccess && props.onSuccess();
		},
	});

	return mutation;
};
