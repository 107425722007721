import { robPersonQuery, usePoQuery } from '@gov-nx/api/portal-obcana';

export const useRobPersonQuery = () => {
	const query = usePoQuery({
		queryKey: ['osoby', 'fyzicke'],
		queryFn: robPersonQuery,
	});

	const isCzechCitizen = query.data?.statniObcanstvi === '203 Česká republika';

	return {
		data: query.data,
		isLoading: query.isLoading,
		isCzechCitizen,
	};
};
