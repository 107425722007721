import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { ProcessControlCore } from '@gov-nx/core/hooks';
import { Nullable, Optional } from '@gov-nx/core/types';
import { useMyFilesStorage } from '@gov-nx/module/page';
import { DataBoxSearchState } from '@gov-nx/store/portal-obcana';
import { useDataboxDateUtils } from '../utils/message';
import {
	DataBoxConfirmModalType,
	DataBoxFolderType,
	DataBoxTimeDividedMessageList,
} from './DataBoxes.types';

export type DataBoxMessageListContextType = {
	controls: ProcessControlCore;
	dataBox: Optional<DatovkaApiType>;
	storage: ReturnType<typeof useMyFilesStorage>;
	messageList: DataBoxTimeDividedMessageList;
	messageListCount: number;
	isLoadMorePossible: boolean;
	loadMore: () => void;
	listState: DataBoxListStatesType;
	setListState: (state: DataBoxListStatesType) => void;
	messagesFilter: {
		dataBoxId: string;
		folderType: DataBoxFolderType;
		messageId?: number;
		query: Optional<string>;
		setQuery: (query: string) => void;
		hasSearch: boolean;
	};
	messagesSelect: {
		showCheckboxes: boolean;
		selectedMessageList: number[];
		setSelectedMessageList: (list: number[]) => void;
		updateSelectedMessageList: (id: number) => void;
		updateAllMessagesSelection: (check: boolean) => void;
		isAllSelected: boolean;
		setIsAllSelected: (isAllSelected: boolean) => void;
	};
	massOperations: {
		handleArchiveMessages: () => void;
		handleArchiveAllMessages: () => void;
		handleDeleteMessages: (permanently: boolean) => void;
		handleRestoreMessages: () => void;
		handleEmptyTrash: () => void;
	};
	confirmModal: Nullable<DataBoxConfirmModalType>;
	setConfirmModal: (modal: Nullable<DataBoxConfirmModalType>) => void;
	loadingItems: Partial<Record<DataBoxMessageListLoadingItemsType, boolean>>;
	handleOpenMobileMenu: () => void;
	isMessageListHidden: boolean;
	previousSuggestions: DataBoxSearchState['searchHistory'];
	deleteSuggestion: (suggestion: string) => void;
	nextDataBoxForSearch?: DatovkaApiType;
	showUnreadMessagesCount: boolean;
	unreadMessagesCount: number;
	dataBoxDateUtils: ReturnType<typeof useDataboxDateUtils>;
};

export enum DataBoxListStatesType {
	List = 'list',
	Search = 'search',
	MassArchive = 'massArchive',
	SearchMassArchive = 'searchMassArchive',
	MassRemove = 'massRemove',
	SearchMassRemove = 'searchMassRemove',
	MassPermanentRemove = 'massPermanentRemove',
	SearchMassPermanentRemove = 'searchMassPermanentRemove',
	MassRestore = 'massRestore',
	SearchMassRestore = 'searchMassRestore',
}

export enum DataBoxMessageListLoadingItemsType {
	Archive = 'archive',
	Remove = 'remove',
	RemovePermanently = 'removePermanently',
	Restore = 'restore',
}
