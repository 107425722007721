import {
	forwardRef,
	PropsWithChildren,
	useEffect,
	useImperativeHandle,
} from 'react';
import { Configuration, useConfigurationQuery } from '@gov-nx/api/common';
import { useConnection } from '@gov-nx/core/hooks';
import { useConfigurationStore } from '@gov-nx/store/portal-obcana';
import { useAppContext } from '../context/AppContext';
import { GovError } from '../error/gov-error';
import { TranslationInitProvider } from './TranslationInitProvider';

interface InitProviderProps extends PropsWithChildren {
	environment: string;
	application: string;
	onSuccess?: (configuration: Configuration) => void;
}

export interface InitProviderRef {
	refetch: () => void;
}

export const InitProvider = forwardRef<InitProviderRef, InitProviderProps>(
	({ children, onSuccess, environment, application }, ref) => {
		const { setAppError } = useAppContext();
		const { isOnline } = useConnection();

		const initMeta = useConfigurationStore('initMeta');
		const saveConfiguration = useConfigurationStore('saveConfiguration');
		const { isFetched, refetch } = useConfigurationQuery({
			onError: (e) => {
				isOnline && setAppError(new GovError(e));
			},
			onSuccess: (configuration) => {
				saveConfiguration(configuration);
				onSuccess && onSuccess(configuration);
			},
		});

		useImperativeHandle(
			ref,
			() => {
				return {
					refetch,
				};
			},
			[refetch]
		);

		useEffect(() => {
			initMeta(application, environment);
		}, [initMeta]);

		if (isFetched) {
			return <TranslationInitProvider>{children}</TranslationInitProvider>;
		}
		return null;
	}
);
