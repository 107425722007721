import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import React from 'react';
import { MarkdownRender } from '@gov-nx/component/web';
import { useReferenceNumberFieldDisable } from '@gov-nx/core/hooks';
import {
	LocalizeNameSpaceTypes,
	useLocale,
	usePoFormContext,
} from '@gov-nx/core/service';
import { hasOwnProperty } from '@gov-nx/core/types';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import {
	CommonDataBoxSelectProps,
	DataBoxOnlyOptionProps,
	WebDataBoxDetailsProps,
	WebDataBoxSelectProps,
} from '@gov-nx/ui/types';
import { prepareDataBoxOptions } from '@gov-nx/utils/common';
import { ToggleButton } from '../../Button/ToggleButton';
import { FormWidthElementLayout } from '../../Layout/FormWidthElementLayout';
import { LayoutGap } from '../../Layout/LayoutGap';
import { FormInput } from '../FormInput';
import { FormSelect } from '../FormSelect';

const DataBoxDetails = (props: WebDataBoxDetailsProps) => {
	const referenceFieldDisable = useReferenceNumberFieldDisable();
	const { t } = useLocale(LocalizeNameSpaceTypes.Form);

	return (
		<ToggleButton
			id={props.toggleButtonID}
			wcagHideLabel={t('data-box.wcag.skryt-doplnkove-udaje')}
			wcagShowLabel={t('data-box.wcag.zobrazit-doplnkove-udaje')}
			showLabel={t('data-box.labels.zobrazit-doplnkove-udaje')}
			hideLabel={t('data-box.labels.skryt-doplnkove-udaje')}>
			<LayoutGap gap={4}>
				<MarkdownRender>
					{props.titleCopy ?? t('data-box.messages.doplnujici-udaje-obsag')}
				</MarkdownRender>
				<FormWidthElementLayout>
					<FormInput
						field={{
							name: props.fileNumberField?.name ?? 'naseCisloJednaci',
							placeholder:
								props.fileNumberField?.labelCopy ??
								t('data-box.placeholders.cislo-jednaci') ??
								undefined,
							disabled: props.fileNumberField?.disabled || props.disabled,
						}}
						label={{
							children:
								props.fileNumberField?.placeholderCopy ??
								t('data-box.fields.cislo-jednaci'),
						}}
					/>
				</FormWidthElementLayout>
				<FormWidthElementLayout>
					<FormInput
						field={{
							name: props.referenceNumberField?.name ?? 'naseSpisovaZnacka',
							placeholder:
								referenceFieldDisable.placeholderCopy ??
								props.referenceNumberField?.placeholderCopy ??
								t('data-box.placeholders.spisova-znacka'),
							disabled:
								referenceFieldDisable.disabled ||
								props.referenceNumberField?.disabled ||
								props.disabled,
						}}
						label={{
							children:
								props.referenceNumberField?.labelCopy ??
								t('data-box.fields.spisova-znacka'),
						}}
					/>
				</FormWidthElementLayout>
			</LayoutGap>
		</ToggleButton>
	);
};

const DataBoxOnlyOption = ({
	isRequired,
	label,
	option,
}: DataBoxOnlyOptionProps) => {
	return (
		<div className={'flex flex-col gap-2 mb-1'}>
			<span className={'gov-color--secondary-700'}>
				{label.children}
				{isRequired ? <span className="gov-color--error-500"> *</span> : null}
			</span>
			<span className={'gov-color--secondary-800 font-semibold'}>
				{option.label}
			</span>
		</div>
	);
};

const isOldProps = (
	props: CommonDataBoxSelectProps
): props is WebDataBoxSelectProps => hasOwnProperty(props, 'types');

export const DataBoxSelect = (props: CommonDataBoxSelectProps) => {
	const { t } = useLocale(LocalizeNameSpaceTypes.Form);
	const { required } = usePoFormContext().propsFromSchema(props.field.name);
	const getConnectedDataboxesListByTypes = useDataBoxStore(
		'getConnectedDataboxesListByTypes'
	);

	const dataBoxesOptions = isOldProps(props)
		? prepareDataBoxOptions(getConnectedDataboxesListByTypes(props.types))
		: props.field.options;

	if (dataBoxesOptions.length === 0) {
		return (
			<GovMessage variant="warning">
				<MarkdownRender>
					{t('data-box.messages.nemate-datovou-schranku')}
				</MarkdownRender>
				<GovIcon
					type="basic"
					name="exclamation-triangle-fill"
					slot="icon"></GovIcon>
			</GovMessage>
		);
	}

	return (
		<fieldset className={'flex flex-col gap-2'}>
			<legend className={'sr-only'}>
				{t('data-box.wcag.vyber-datove-schranky')}
			</legend>
			{dataBoxesOptions.length === 1 ? (
				<DataBoxOnlyOption
					isRequired={required}
					label={props.label}
					option={dataBoxesOptions[0]}
				/>
			) : (
				<FormWidthElementLayout type={'xl'}>
					<FormSelect
						{...props}
						field={{ ...props.field, options: dataBoxesOptions }}
					/>
				</FormWidthElementLayout>
			)}
			{!props.isDetailDisabled && (
				<DataBoxDetails
					{...props.details}
					disabled={
						isOldProps(props) ? props.field.disabled : props.field.isDisabled
					}
				/>
			)}
		</fieldset>
	);
};
