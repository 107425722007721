import {
	BaseSyntheticEvent,
	createContext,
	ReactNode,
	useContext,
	useEffect,
} from 'react';
import {
	DatovkaApiType,
	OsobyFyzickaOsobaDto,
	OsobyPodnikatelDto,
	ppdfConsentDetailQuery,
	PpdfSouhlasySouhlasDto,
	sendSubmissionQuery,
	usePoMutation,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useIdentity } from '@gov-nx/auth/common';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	FormWizardHook,
	useProcessControl,
	useRobPersonQuery,
	useWizard,
	WizardFormStep,
} from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { dataBoxParams, today, toStringDate } from '@gov-nx/utils/common';
import { useRosBusinessPersonQuery } from '../../nastaveni/kontaktni-udaje-ros/useRosBusinessPersonQuery';
import { usePpdfSouhlasyLocale } from '../usePpdfSouhlasyLocale';
import {
	PpdfSouhlasyFormControls,
	PpdfSouhlasyTypOsobyEnum,
} from './context.types';
import {
	useFormStep1,
	PpdfSouhlasyUdeleniFormStep1,
	PpdfSouhlasyUdeleniStep1,
} from './useFormStep1';
import {
	useFormStep2,
	PpdfSouhlasyUdeleniFormStep2,
	PpdfSouhlasyUdeleniStep2,
	AgendaDataSelectedLabels,
} from './useFormStep2';
import {
	PpdfSouhlasyUdeleniFormStep3,
	PpdfSouhlasyUdeleniStep3,
	useFormStep3,
} from './useFormStep3';

interface PpdfConsentsUdeleniContext {
	wizard: FormWizardHook<ZapisForm>;
	step1: PpdfSouhlasyUdeleniFormStep1;
	step2: PpdfSouhlasyUdeleniFormStep2;
	step3: PpdfSouhlasyUdeleniFormStep3;
	isLoading: boolean;
	noValuesForSharedAgendaData: boolean;
	isSubmitting: boolean;
	person?: OsobyFyzickaOsobaDto;
	rosContacts: OsobyPodnikatelDto[];
	isNew: boolean;
	selectedLabels: AgendaDataSelectedLabels;
	submit: (onSuccess?: () => void) => void;
	isPOTabVisible: boolean;
	loginInfo: ReturnType<typeof useIdentity>;
	getRosContactByDataBoxId: (
		datBoxId: string
	) => OsobyPodnikatelDto | undefined;
	dataBoxes: DatovkaApiType[];
}

type ZapisForm = PpdfSouhlasyUdeleniStep1 &
	PpdfSouhlasyUdeleniStep2 &
	PpdfSouhlasyUdeleniStep3;

const ServiceContext =
	createContext<Nullable<PpdfConsentsUdeleniContext>>(null);

export interface PpdfSouhlasyUdeleniPrefillForm {
	kodSpravceUdaju?: string;
	kodAgendySpravceUdaju?: string;
	kodPrijemceUdaju?: string;
	udaje?: string[];
}

interface Props {
	consentId?: number;
	children: ReactNode;
	revoke?: boolean;
	prefillForm?: PpdfSouhlasyUdeleniPrefillForm;
}

export const PpdfSouhlasyUdeleniContextProvider = ({
	children,
	consentId,
	revoke,
	prefillForm,
}: Props) => {
	const personQuery = useRobPersonQuery();
	const { t } = usePpdfSouhlasyLocale();
	const { toastMessageSuccess } = useMessageEvents();
	const loginInfo = useIdentity();

	const { setControls, controls } = useProcessControl<PpdfSouhlasyFormControls>(
		{
			initialLoading: true,
			createLoading: false,
			saveLoading: false,
			deleteLoading: false,
		}
	);

	const { rosContacts } = useRosBusinessPersonQuery();

	const queryPpdfConsentDetail = usePoQuery({
		queryKey: ['ppdf-souhlasy', consentId],
		queryFn: () => (consentId ? ppdfConsentDetailQuery(consentId) : {}),
	});

	const submitMutation = usePoMutation({
		mutationFn: async (variables: ZapisForm) => {
			const { datovaSchrankaId, ...params } = dataBoxParams(variables);

			const submitDataBoxId =
				variables.personType === PpdfSouhlasyTypOsobyEnum.FO
					? datovaSchrankaId
					: variables.poDataBoxId;

			const dataBoxInfo = step1.dataBoxes.find(
				(item) => item.datovaSchrankaId === poDataBoxId
			);

			return sendSubmissionQuery({
				params: {
					odesilatelDSId: submitDataBoxId,
					...params,
				},
				body: {
					...(!(consentId || revoke) && { kodPodani: 'PPDF_POSKYTNUTI' }),
					...(consentId && { id: consentId, kodPodani: 'PPDF_ODVOLANI' }),
					...(revoke && { kodPodani: 'PPDF_ODVOLANI' }),
					typOsoby:
						variables.personType === PpdfSouhlasyTypOsobyEnum.FO
							? 'FO'
							: dataBoxInfo?.typSchranky ?? 'PO',
					...(variables.personType === PpdfSouhlasyTypOsobyEnum.PO && {
						poIco: getRosContactByDataBoxId(variables.poDataBoxId)?.ico,
						poDs: submitDataBoxId,
					}),
					kodSpravceUdaju: variables.fromOvmId,
					kodAgendySpravceUdaju: variables.fromAgendaId,
					kodPrijemceUdaju: variables.toOvmId,
					kodAgendyPrijemceUdaju: variables.toAgendaId,
					datumTrvaniUdeleniSouhlasu: variables.dateOfValidityConsent
						? toStringDate(variables.dateOfValidityConsent)
						: undefined,
					udaje: [...new Set(variables.sharedAgendaData)],
				},
			});
		},
		onSuccess: () => {
			toastMessageSuccess(t('zpravy.odeslano'));
		},
	});

	const setFormValuesFromQueryParams = () => {
		prefillForm?.kodSpravceUdaju &&
			step2.form.formMethods.setValue('fromOvmId', prefillForm.kodSpravceUdaju);

		prefillForm?.kodAgendySpravceUdaju &&
			step2.form.formMethods.setValue(
				'fromAgendaId',
				prefillForm.kodAgendySpravceUdaju
			);

		prefillForm?.kodPrijemceUdaju &&
			step2.form.formMethods.setValue('toOvmId', prefillForm.kodPrijemceUdaju);

		prefillForm?.udaje?.length &&
			step2.form.formMethods.setValue('sharedAgendaData', [
				...prefillForm.udaje,
				null,
			]);
	};

	const setFormValuesFromDto = ({
		osoba,
		kodSpravceUdaju,
		kodAgendySpravceUdaju,
		kodPrijemceUdaju,
		kodAgendyPrijemceUdaju,
		udaje,
		datumTrvaniUdeleniSouhlasu,
	}: PpdfSouhlasySouhlasDto) => {
		kodSpravceUdaju &&
			step1.form.formMethods.setValue(
				'personType',
				osoba?.pravnickaOsoba
					? PpdfSouhlasyTypOsobyEnum.PO
					: PpdfSouhlasyTypOsobyEnum.FO
			);
		osoba?.pravnickaOsoba?.datovaSchrankaId &&
			step1.form.formMethods.setValue(
				'poDataBoxId',
				osoba.pravnickaOsoba.datovaSchrankaId
			);
		kodSpravceUdaju &&
			step2.form.formMethods.setValue('fromOvmId', kodSpravceUdaju);
		kodAgendySpravceUdaju &&
			step2.form.formMethods.setValue('fromAgendaId', kodAgendySpravceUdaju);
		kodPrijemceUdaju &&
			step2.form.formMethods.setValue('toOvmId', kodPrijemceUdaju);
		kodAgendyPrijemceUdaju &&
			step2.form.formMethods.setValue('toAgendaId', kodAgendyPrijemceUdaju);
		datumTrvaniUdeleniSouhlasu &&
			step2.form.formMethods.setValue(
				'dateOfValidityConsent',
				today(datumTrvaniUdeleniSouhlasu)
			);
		udaje?.length &&
			step2.form.formMethods.setValue('sharedAgendaData', [...udaje, null]);
	};

	useEffect(() => {
		if (controls.initialLoading) return;
		if (queryPpdfConsentDetail.data && consentId) {
			setFormValuesFromDto(queryPpdfConsentDetail.data);
		} else {
			step1.form.formMethods.setValue(
				'personType',
				PpdfSouhlasyTypOsobyEnum.FO
			);
			setFormValuesFromQueryParams();
		}
	}, [queryPpdfConsentDetail.data, controls.initialLoading]);

	useEffect(() => {
		if (queryPpdfConsentDetail.isFetched && controls.initialLoading) {
			setControls({ initialLoading: false });
		}
	}, [queryPpdfConsentDetail.isFetched, controls.initialLoading]);

	const isSubmitting = submitMutation.isLoading;

	const step1 = useFormStep1({
		isDisabled: isSubmitting,
		databoxTypes: ['PFO', 'PO'],
	});

	const { poDataBoxId, personType } = step1.form.formMethods.watch();

	const step2 = useFormStep2({ isDisabled: isSubmitting });
	const step3 = useFormStep3({
		isDisabled: isSubmitting,
		fixedDataBoxId:
			personType === PpdfSouhlasyTypOsobyEnum.FO
				? step1.dataBoxes.find((item) => item.typSchranky === 'FO')
						?.datovaSchrankaId ?? ''
				: poDataBoxId,
		databoxTypes: ['FO', 'PFO', 'PO'],
	});

	const wizard = useWizard<ZapisForm>({
		steps: [step1.form, step2.form, step3],
		onSubmit: async (values, onSuccess) =>
			submitMutation.mutate(values, { onSuccess }),
	});

	const getRosContactByDataBoxId = (
		poDataBoxId: string
	): OsobyPodnikatelDto | undefined =>
		rosContacts.find((contact) => contact.datovaSchrankaId === poDataBoxId);

	return (
		<ServiceContext.Provider
			value={{
				isLoading: personQuery.isLoading || step1.isLoading || step2.isLoading,
				noValuesForSharedAgendaData: step2.noValuesForSharedAgendaData,
				wizard,
				step1: step1.form,
				step2: step2.form,
				step3,
				selectedLabels: step2.selectedLabels,
				isSubmitting,
				person: personQuery.data,
				rosContacts,
				isNew: !consentId && !revoke,
				submit: (onSuccess) => {
					(
						wizard.step(
							2
						) as unknown as WizardFormStep<PpdfSouhlasyUdeleniFormStep3>
					).onSubmit(onSuccess)({} as BaseSyntheticEvent);
				},
				isPOTabVisible: step1.isPOTabVisible,
				loginInfo,
				getRosContactByDataBoxId,
				dataBoxes: step1.dataBoxes,
			}}>
			{children}
		</ServiceContext.Provider>
	);
};

export const usePpdfSouhlasyUdeleniContext = () => {
	const context = useContext(ServiceContext);
	if (!context) {
		throw new Error('Context must be used within a ContextProvider');
	}
	return context;
};
