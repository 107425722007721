import {
	ContactDetailsFormData,
	ContactDetailsFormFields,
	useContactDetailsDefinition,
} from '@gov-nx/core/hooks';
import { FormDefinition2, useFormBuilder } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { useKorespondencniVolbyLocale } from '@gov-nx/module/service';
import { FormCheckboxProps, FormRadioProps } from '@gov-nx/ui/types';
import {
	getCheckboxDefinition,
	getRadioDefinition,
} from '@gov-nx/utils/common';

export interface KorespondencniHlasovaniDokumentyStep1
	extends ContactDetailsFormData {
	isNoTDuplicatedRequest: boolean;
	isOnOtherList: Nullable<'YES' | 'NO'>;
}

interface Fields extends ContactDetailsFormFields {
	isNoTDuplicatedRequest: FormCheckboxProps;
	isOnOtherList: FormRadioProps;
}

export type KorespondencniHlasovaniDolumentyFormStep1 = FormDefinition2<
	KorespondencniHlasovaniDokumentyStep1,
	Fields
>;

export const useFormStep1 = ({
	isDisabled,
}: {
	isDisabled: boolean;
}): {
	form: KorespondencniHlasovaniDolumentyFormStep1;
	isOnOtherListValue: Nullable<'YES' | 'NO'>;
} => {
	const { t } = useKorespondencniVolbyLocale();

	const contactDetails = useContactDetailsDefinition({
		isDisabled,
		strings: {
			_useEmail: { label: t('kontaktni-udaje.email.label') },
			_usePhone: { label: t('kontaktni-udaje.phone.label') },
			email: {
				label: '',
				placeholder: t('kontaktni-udaje.email.placeholder'),
			},
			phone: {
				label: '',
				placeholder: t('kontaktni-udaje.phone.placeholder'),
			},
		},
	});

	const isNoTDuplicatedRequest = getCheckboxDefinition({
		name: 'isNoTDuplicatedRequest',
		isDisabled,
		oneOf: {
			value: [true],
			errorMessage: t(
				'dokumenty.formular.neni-duplicitni-zadost.validace.povinny'
			),
		},
		label: t('dokumenty.formular.neni-duplicitni-zadost.label'),
	});

	const isOnOtherList = getRadioDefinition({
		name: 'isOnOtherList',
		label: t('dokumenty.formular.na-jinem-seznamu.label'),
		isRequired: true,
		isRequiredMessage: t(
			'dokumenty.formular.na-jinem-seznamu.validace.povinny'
		),
		oneOf: {
			value: ['YES'],
			errorMessage: t('dokumenty.formular.na-jinem-seznamu.validace.povinny'),
		},
		options: [
			{ label: t('dokumenty.formular.na-jinem-seznamu.ano'), name: 'YES' },
			{ label: t('dokumenty.formular.na-jinem-seznamu.ne'), name: 'NO' },
		],
		defaultValue: null,
	});

	const form = useFormBuilder<KorespondencniHlasovaniDokumentyStep1, Fields>([
		isNoTDuplicatedRequest,
		isOnOtherList,
		...contactDetails,
	]);

	const isOnOtherListValue = form.formMethods.watch('isOnOtherList');

	return { form, isOnOtherListValue };
};
