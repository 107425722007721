import { createContext, PropsWithChildren, useContext } from 'react';
import * as yup from 'yup';
import {
	rezaTemplates,
	RezaSablonaDto,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
} from '@gov-nx/core/service';
import { WebInputProps } from '@gov-nx/ui/types';
import { useFilter } from '@gov-nx/utils/common';
import { useRezaLocale } from '../../useRezaLocale';

interface RezaTemplateFilterForm {
	search?: string;
}

interface RezaTemplateListContext {
	isLoading: boolean;
	templates: RezaSablonaDto[];
	formDefinition: FormDefinition<RezaTemplateFilterForm>;
	onSubmit: () => void;
	fields: {
		search: WebInputProps;
		button: {
			wcagLabel: string;
		};
	};
}

const Context = createContext<RezaTemplateListContext | null>(null);

type Props = PropsWithChildren;

export const RezaTemplateListContextProvider = ({ children }: Props) => {
	const { t } = useRezaLocale();
	const { filter, setPartialFilter } = useFilter<RezaTemplateFilterForm>({
		key: 'reza-templates',
		initialState: {
			search: undefined,
		},
	});

	const query = usePoQuery({
		queryKey: ['reza', 'templates', filter],
		queryFn: async () =>
			rezaTemplates({
				text: filter.search,
			}),
	});

	const formSchema = yup
		.object<FormSchemaShape<RezaTemplateFilterForm>>({
			search: yup.string().optional(),
		})
		.required();

	const formMethods = usePoForm({
		formSchema,
		defaultValues: {
			search: filter.search,
		},
	});

	const formDefinition = getFormDefinition({ formMethods, formSchema });

	const onSubmit = formDefinition.formMethods.handleSubmit((values) => {
		setPartialFilter({
			search: values.search,
		});
	});

	return (
		<Context.Provider
			value={{
				isLoading: query.isLoading,
				templates: query.data?.seznam ?? [],
				formDefinition,
				onSubmit,
				fields: {
					search: {
						field: {
							name: 'search',
							placeholder: t('sablony.pole.hledat.placeholder'),
							wcagLabelledBy: 'search-label',
						},
						label: { children: t('sablony.pole.hledat.label') },
					},
					button: {
						wcagLabel: t('sablony.buttons.submit.wcag'),
					},
				},
			}}>
			{children}
		</Context.Provider>
	);
};

export const useRezaTemplateListContext = (): RezaTemplateListContext => {
	const context = useContext(Context);
	if (!context) {
		throw new Error(
			'useRezaTemplateListContext must be used within a RezaTemplateListContextProvider'
		);
	}
	return context;
};
