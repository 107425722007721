import { createContext, ReactNode, useContext } from 'react';
import { usePoMutation } from '@gov-nx/api/portal-obcana';
import { FormWizardHook, useWizard } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { IUseBoolean, useBoolean } from '@gov-nx/utils/common';
import { removeAccountQuery } from '../../../../api/portal-obcana/src/queries/account';
import {
	SmazaniUctuFormData,
	SmazaniUctuFormStep1,
	SmazaniUctuFormStep2,
	SmazaniUctuFormStep3,
	useFormStep1,
	useFormStep2,
	useFormStep3,
} from './useForm';

interface SmazaniUzivatelskehoUctuContext {
	wizard: FormWizardHook<SmazaniUctuFormData>;
	step1: SmazaniUctuFormStep1;
	step2: SmazaniUctuFormStep2;
	step3: SmazaniUctuFormStep3;
	isSubmitted: boolean;
	isConfirmModalOpen: IUseBoolean;
}

const ServiceContext =
	createContext<Nullable<SmazaniUzivatelskehoUctuContext>>(null);

interface SmazaniUzivatelskehoUctuProviderProps {
	children: ReactNode;
	onSubmitted: () => void;
}

export const SmazaniUzivatelskehoUctuContextProvider = ({
	children,
	onSubmitted,
}: SmazaniUzivatelskehoUctuProviderProps) => {
	const isConfirmModalOpen = useBoolean();

	const submitMutation = usePoMutation({
		mutationFn: async (formData: SmazaniUctuFormData) =>
			removeAccountQuery({ reason: formData.message }),
		onSettled: isConfirmModalOpen.setFalse,
		onSuccess: onSubmitted,
	});

	const isDisabled = submitMutation.isLoading;

	const step1 = useFormStep1({ isDisabled });
	const step2 = useFormStep2({ isDisabled });
	const step3 = useFormStep3({ isDisabled });

	const wizard = useWizard<SmazaniUctuFormData>({
		steps: [step1, step2, step3],
		onSubmit: async (data) => submitMutation.mutate(data),
	});

	const isSubmitted = submitMutation.isSuccess;

	return (
		<ServiceContext.Provider
			value={{ wizard, step1, step2, step3, isSubmitted, isConfirmModalOpen }}>
			{children}
		</ServiceContext.Provider>
	);
};

export const useSmazaniUzivatelskehoUctuContext =
	(): SmazaniUzivatelskehoUctuContext => {
		const context = useContext(ServiceContext);
		if (!context) {
			throw new Error('Context must be used within a ContextProvider');
		}
		return context;
	};
