import { useAppContext } from '@gov-nx/core/app';
import { LocalizeNameSpaceTypes, useLocale } from '@gov-nx/core/service';
import { ServiceCode } from '@gov-nx/module/service';

export const useReferenceNumberFieldDisable = () => {
	const { currentServiceCode } = useAppContext();
	const { t } = useLocale(LocalizeNameSpaceTypes.Form);

	const disabledServices = [
		ServiceCode['vypis-z-verejneho-rejstriku'],
		ServiceCode['vypis-z-zivnostenskeho-rejstriku'],
		ServiceCode['vypis-bodoveho-hodnoceni-ridice'],
		ServiceCode['vypis-ze-seznamu-KD'],
		ServiceCode['vypis-z-IR'],
		ServiceCode['vypis-z-rejstriku-trestu-FO'],
		ServiceCode['vypis-z-rejstriku-trestu-PO'],
	];

	const disabled = currentServiceCode
		? disabledServices.includes(currentServiceCode)
		: false;

	const placeholderCopy = disabled
		? t('data-box.placeholders.spisova-znacka-automaticky')
		: undefined;

	return {
		disabled,
		placeholderCopy,
	};
};
