import { GovTabs, GovTabsItem } from '@gov-design-system-ce/react';
import { pageRouteResolver } from '@gov-nx/web';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MyAuthorizationsContentView } from '@gov-nx/component/web';
import {
	MojeAutorizaceSeznamContextProvider,
	MojeOsvedceniSeznamContextProvider,
	MojePodaniSeznamContextProvider,
	mySubmissionTabs,
	MySubmissionTabs,
	PageCode,
} from '@gov-nx/module/page';
import { MyVerificationsList } from '../../moje-osvedceni/seznam/MyVerificationsList';
import { MySubmissionsList } from '../seznam/MySubmissionsList';

export interface MyVerificationsTabsViewProps {
	code: PageCode;
	selectedTab: MySubmissionTabs;
}

export const MySubmissionsTabsView = ({
	code,
	selectedTab,
}: MyVerificationsTabsViewProps) => {
	const { t } = useTranslation([code]);
	const navigate = useNavigate();

	const tabLabel = (tabCode: MySubmissionTabs) => {
		return (
			mySubmissionTabs.find((tab) => tab.tabCode === tabCode)?.nameCode ?? ''
		);
	};

	const getRouteFromIdentifier = (identifier: string) => {
		switch (identifier) {
			case MySubmissionTabs.osvedceni:
				return pageRouteResolver('moje-podani.osvedceni');
			case MySubmissionTabs.autorizace:
				return pageRouteResolver('moje-podani.autorizace');
			case MySubmissionTabs.podani:
				return pageRouteResolver('moje-podani');
			default:
				return '';
		}
	};

	return (
		<GovTabs
			color="primary"
			type="text"
			orientation="horizontal"
			onGov-change={(event) =>
				navigate(getRouteFromIdentifier(event.detail.identifier))
			}>
			<GovTabsItem
				label={t(tabLabel(MySubmissionTabs.podani))}
				identifier={MySubmissionTabs.podani}
				default={selectedTab === MySubmissionTabs.podani ? true : undefined}>
				<div>
					{selectedTab === MySubmissionTabs.podani && (
						<MojePodaniSeznamContextProvider code={code}>
							<MySubmissionsList code={code} />
						</MojePodaniSeznamContextProvider>
					)}
				</div>
			</GovTabsItem>
			<GovTabsItem
				label={t(tabLabel(MySubmissionTabs.osvedceni))}
				identifier={MySubmissionTabs.osvedceni}
				default={selectedTab === MySubmissionTabs.osvedceni ? true : undefined}>
				<div>
					{selectedTab === MySubmissionTabs.osvedceni && (
						<MojeOsvedceniSeznamContextProvider code={code}>
							<MyVerificationsList code={code} />
						</MojeOsvedceniSeznamContextProvider>
					)}
				</div>
			</GovTabsItem>
			<GovTabsItem
				label={t(tabLabel(MySubmissionTabs.autorizace))}
				identifier={MySubmissionTabs.autorizace}
				default={
					selectedTab === MySubmissionTabs.autorizace ? true : undefined
				}>
				<div>
					{selectedTab === MySubmissionTabs.autorizace && (
						<MojeAutorizaceSeznamContextProvider code={code}>
							<MyAuthorizationsContentView />
						</MojeAutorizaceSeznamContextProvider>
					)}
				</div>
			</GovTabsItem>
		</GovTabs>
	);
};
