import { OsobyPodnikatelRozsireneDto } from '@gov-nx/api/portal-obcana';
import { is, propEq } from '@gov-nx/core/types';

export const getMyInvolvement = (data: OsobyPodnikatelRozsireneDto[]) => {
	return data
		.map((item) => {
			const myInvolvement = item.angazovaneOsoby?.filter(
				propEq('mojeAngazovanost', true)
			);
			return myInvolvement?.length
				? { ...item, angazovaneOsoby: myInvolvement }
				: null;
		})
		.filter(is);
};
