import { axiosInstance } from '../axios';
import { AgendyAisOvmSeznamDto } from '../generated';

export const ovmQuery = async (
	maAgendy?: boolean
): Promise<AgendyAisOvmSeznamDto> => {
	const { data } = await axiosInstance.get(`/api/v1/ovm`, {
		params: maAgendy ? { maAgendy } : {},
	});
	return data;
};
