import { DrozdRegistrationDto } from '@gov-nx/api/portal-obcana';

export const update =
	<Path extends keyof O, O extends object>(
		path: Path,
		updateFunction: (updatedState: O[Path]) => O[Path]
	) =>
	(state: O): O => {
		return {
			...state,
			[path]: updateFunction(state[path]),
		};
	};

const eq =
	<T, P extends keyof T>(property: P, a: T) =>
	(b: T) =>
		a[property] === b[property];

export const updateByTitle =
	<T extends { title?: string }>(updated: T[]) =>
	(old: T[]): T[] => {
		const newInformation = updated.filter(
			(update) => !old.find(eq('title', update))
		);

		const updates = old.map((curr) => {
			const update = updated.find(eq('title', curr));
			return update ? update : curr;
		});

		return newInformation ? [...updates, ...newInformation] : updates;
	};

export const updateByIsoCode =
	<T extends { isoCode?: string }>(updated: T[]) =>
	(old: T[]): T[] => {
		const newInfo = updated.filter((u) => !old.find(eq('isoCode', u)));

		const updates = old.map((curr) => {
			const ex = updated.find(eq('isoCode', curr));
			return ex ? ex : curr;
		});

		return newInfo ? [...updates, ...newInfo] : updates;
	};

const tokensEquals = (a: DrozdRegistrationDto) => (b: DrozdRegistrationDto) =>
	a.traveller?.token === b.traveller?.token;

export const updateRegistrations =
	(updated: DrozdRegistrationDto[]) =>
	(old: DrozdRegistrationDto[]): DrozdRegistrationDto[] => {
		const newInfo = updated.filter((u) => !old.find(tokensEquals(u)));

		const updates = old.map((curr) => {
			const ex = updated.find(tokensEquals(curr));
			return ex ? ex : curr;
		});

		return newInfo ? [...updates, ...newInfo] : updates;
	};
