import {
	GovFormControl,
	GovFormGroup,
	GovFormMessage,
	GovFormSelect,
	GovIcon,
} from '@gov-design-system-ce/react';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { usePoFormContext } from '@gov-nx/core/service';
import { SelectProps, WebSelectProps } from '@gov-nx/ui/types';
import { FormLabel } from './FormLabel';
import { FormErrorMessage, FormMessage } from './FormMessage';

export const Select = forwardRef<HTMLGovFormSelectElement, SelectProps>(
	({ options, ...props }, fwRef) => {
		const ref = useRef<HTMLGovFormSelectElement | null>(null);

		useEffect(() => {
			if (ref.current && ref.current?.setOptions && Array.isArray(options)) {
				const newOptions = options.map((option) => {
					return { ...option, value: option.value.toString() };
				});
				ref.current?.setOptions(newOptions).finally();
			}
		}, [options]);

		useImperativeHandle(fwRef, () => ref.current!, []);
		return (
			<GovFormSelect
				{...props}
				ref={ref}></GovFormSelect>
		);
	}
);

export const FormSelect = ({
	label,
	messages,
	control,
	toValue,
	...props
}: WebSelectProps) => {
	const formContext = useFormContext();
	const extraProps = usePoFormContext().propsFromSchema(props.field.name);

	return (
		<Controller
			name={props.field.name}
			control={formContext.control}
			render={({ field, fieldState }) => {
				return (
					<GovFormControl {...control}>
						<FormLabel
							slot="top"
							required={extraProps.required}
							{...label}>
							{label.children}
						</FormLabel>
						<GovFormGroup {...props.group}>
							<Select
								{...extraProps}
								{...props.field}
								ref={field.ref}
								invalid={
									fieldState.invalid ||
									(formContext.formState.isSubmitted && extraProps.arrayInvalid)
								}
								value={field.value ?? ''}
								onGov-blur={(event) => {
									field.onBlur();

									props.field['onGov-blur'] && props.field['onGov-blur'](event);
								}}
								onGov-change={(event) => {
									field.onChange(
										toValue ? toValue(event.detail.value) : event.detail.value
									);

									props.field['onGov-change'] &&
										props.field['onGov-change'](event);
								}}
							/>
						</GovFormGroup>
						<div
							slot="bottom"
							className={'!mt-0'}>
							{fieldState.error && (
								<FormErrorMessage error={fieldState.error} />
							)}
							{formContext.formState.isSubmitted &&
								extraProps.arrayInvalid &&
								extraProps.arrayError && (
									<GovFormMessage
										variant="error"
										className={'mt-1'}>
										<GovIcon
											slot="icon"
											name="exclamation-triangle-fill"
										/>
										<span>{extraProps.arrayError}</span>
									</GovFormMessage>
								)}
							{messages && <FormMessage messages={messages} />}
						</div>
					</GovFormControl>
				);
			}}
		/>
	);
};
