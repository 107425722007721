import { pageRouteResolver } from '@gov-nx/web';
import { useLocale } from '@gov-nx/core/service';
import { useProfilROSContextInstance } from '@gov-nx/module/page';
import { CopyButton, Tile, Tiles } from '@gov-nx/ui/web';
import { nameFormatting, toDateReadable } from '@gov-nx/utils/common';

export const PFOListView = () => {
	const { PFOData, code } = useProfilROSContextInstance();
	const { t } = useLocale(code);

	if (!PFOData.length) return null;

	return (
		<section>
			<h1 className="text-2xl text-secondary-700">
				{t('nadpis.podnikajici-fyzicka-osoba')}
			</h1>
			<Tiles>
				{PFOData.map((subject) => {
					const subjectName =
						subject.jmeno && subject.prijmeni
							? nameFormatting(subject.jmeno, subject.prijmeni)
							: '';
					return (
						<Tile
							key={subject.ico}
							name={subjectName}
							headlineLink={true}
							icon={{ name: 'chevron-right', type: 'basic' }}
							to={pageRouteResolver('profil.ros.detail', {
								ico: subject.ico,
							})}>
							<div
								className={
									'flex flex-col justify-start gap-2 [ sm:flex-row sm:items-center ]'
								}>
								{subject.ico ? (
									<div className={'flex items-center gap-1'}>
										<span className={'inline-block mr-1 text-secondary-700'}>
											{t('zaznam.ico')}
										</span>
										<strong className={'gov-color--secondary-900'}>
											{subject.ico}
										</strong>
										<CopyButton
											label={'akce.kopirovat-cislo-dokladu' + subject.ico}
											value={subject.ico}
										/>
									</div>
								) : null}

								{subject.datumVzniku ? (
									<div className={'flex items-center'}>
										<span className={'inline-block mr-1 text-secondary-700'}>
											{t('zaznam.platnost-od')}
										</span>
										<strong className={'gov-color--secondary-900'}>
											{toDateReadable(subject.datumVzniku)}
										</strong>
									</div>
								) : null}
							</div>
						</Tile>
					);
				})}
			</Tiles>
		</section>
	);
};
