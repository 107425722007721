import { GovIcon } from '@gov-design-system-ce/react';
import { pageRouteResolver } from '@gov-nx/web';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DokumentyDokumentDto } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { pipe } from '@gov-nx/core/types';
import { PageCode, useDokladyContext } from '@gov-nx/module/page';
import {
	Button,
	CopyButton,
	ErrorStatusBanner,
	LayoutGap,
	Space,
} from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';
import { DeletePrompt } from './DeletePrompt';

interface OwnDocumentsListProps {
	code: PageCode;
}

export const OwnDocumentsList = ({ code }: OwnDocumentsListProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const navigate = useNavigate();

	const [documentToDelete, setDocumentToDelete] = useState<
		DokumentyDokumentDto | undefined
	>(undefined);
	const {
		ownDocuments,
		onOwnDocumentDelete,
		documentPreviewQuery,
		ownDocumentsError,
	} = useDokladyContext();

	if (ownDocumentsError) {
		return <ErrorStatusBanner code={code} />;
	}
	return (
		<section>
			<DeletePrompt
				code={code}
				documentToDelete={documentToDelete}
				onClose={() => setDocumentToDelete(undefined)}
				onDelete={() => {
					documentToDelete?.dokumentId &&
						onOwnDocumentDelete({
							id: documentToDelete.dokumentId,
							permanent: false,
						});
					setDocumentToDelete(undefined);
				}}
			/>

			<h2 className={'text-l'}>{tsn('vlastni-doklady.titulek')}</h2>

			<ul className={'gov-list--plain'}>
				{ownDocuments.map((document) => {
					return (
						<li
							key={document.dokumentId}
							className={
								'-mx-5 border-b border-secondary-500 [ last-of-type:border-b-0 ] [ md:mx-0 ]'
							}>
							<div className={'relative py-6 px-4'}>
								<div
									className={
										'flex justify-between items-center gap-2 mb-2 [ md:mb-0 ]'
									}>
									<h3 className={'text-xl break-all'}>
										{document.nazev ?? ''}
									</h3>
									<Link
										to={pageRouteResolver('profil.doklady.vlastni-doklad', {
											documentId: document.dokumentId,
										})}
										className={'flex items-center'}>
										<GovIcon
											name="pencil-square"
											className={'w-4 h-4'}
											aria-label={tsn('vlastni-doklady.akce.wcag.upravit')}
										/>
									</Link>
									{document.dokumentId && (
										<Button
											wcagLabel={tsn('vlastni-doklady.akce.wcag.odstranit')}
											variant="primary"
											type="base"
											size="l"
											onClick={() => setDocumentToDelete(document)}
											className={'ml-auto'}>
											<GovIcon
												slot="left-icon"
												type="basic"
												name="x"
											/>
										</Button>
									)}
								</div>
								<LayoutGap gap={2}>
									<div
										className={
											'flex flex-col gap-2 [ md:flex-row md:gap-4 md:items-center ]'
										}>
										{document.nazevSouboru && (
											<span>
												{tsn('vlastni-doklady.klic.nazev-souboru')}
												<Space />
												{document.dokumentId &&
													pipe(
														document.dokumentId,
														documentPreviewQuery.isPreviewNotAvailable,
														(isPreviewNotAvailable) => {
															if (isPreviewNotAvailable) {
																return (
																	<span>
																		{tsn('vlastni-doklady.nahled-neni')}
																	</span>
																);
															}

															return (
																<Button
																	wcagLabel={tsn(
																		'vlastni-doklady.akce.wcag.otevrit'
																	)}
																	variant={'primary'}
																	type={'link'}
																	disabled={!document.dokumentId}
																	loading={
																		documentPreviewQuery.documentPreviewQuery
																			.isFetching &&
																		documentPreviewQuery.downloadingId ===
																			document.dokumentId
																	}
																	onClick={() =>
																		document.dokumentId &&
																		documentPreviewQuery.fetchFile(
																			document.dokumentId,
																			(blob) =>
																				window.open(URL.createObjectURL(blob))
																		)
																	}>
																	{document.nazevSouboru}
																</Button>
															);
														}
													)}
											</span>
										)}

										{document.cisloDokumentu && (
											<span className={'flex items-center gap-1'}>
												{tsn('vlastni-doklady.klic.cislo-dokumentu')}
												<strong className={'break-all'}>
													{document.cisloDokumentu}
												</strong>
												<CopyButton
													label={tsn('akce.kopirovat-cislo-dokladu')}
													value={document.cisloDokumentu}
												/>
											</span>
										)}
									</div>
									<div
										className={'flex flex-col gap-2 [ md:flex-row md:gap-4 ]'}>
										<span className={'whitespace-nowrap'}>
											{tsn('vlastni-doklady.klic.platnost-od')}
											<Space />
											<strong>
												{document.platnostOd
													? toDateReadable(document.platnostOd)
													: tsn('vlastni-doklady.hodnota.nevyplneno')}
											</strong>
										</span>

										<span className={'whitespace-nowrap'}>
											{tsn('vlastni-doklady.klic.platnost-do')}
											<Space />
											<strong>
												{document.platnostDo
													? toDateReadable(document.platnostDo)
													: tsn('vlastni-doklady.hodnota.nevyplneno')}
											</strong>
										</span>

										{document.komentar && (
											<span>
												{tsn('vlastni-doklady.klic.komentar')}
												<Space />
												<strong className={'break-all'}>
													{document.komentar}
												</strong>
											</span>
										)}
									</div>
								</LayoutGap>
							</div>
						</li>
					);
				})}
			</ul>

			<Button
				onClick={() =>
					navigate(
						pageRouteResolver('profil.doklady.vlastni-doklad', {
							documentId: '',
						})
					)
				}
				variant={'primary'}
				wcagLabel={tsn('vlastni-doklady.akce.pridat-novy-dokument')}
				type={'outlined'}
				size="s">
				<GovIcon
					name={'plus-circle'}
					type={'basic'}
					slot="left-icon"></GovIcon>
				{tsn('vlastni-doklady.akce.pridat-novy-dokument')}
			</Button>
		</section>
	);
};
