import { axiosCmsBaseInstance } from '@gov-nx/api/common';
import { SearchSignpostListDto } from '../generated';
import { SignpostRequestParams } from './serviceQuery';

export const searchQuery = async (
	params: SignpostRequestParams
): Promise<SearchSignpostListDto> => {
	const response = await axiosCmsBaseInstance.get<SearchSignpostListDto>(
		'search',
		{ params }
	);

	return response.data;
};
