import { useEffect, useState } from 'react';

type PromiseResolved<T> =
	| {
			isLoading: true;
			data: undefined;
			error: undefined;
	  }
	| {
			isLoading: false;
			data: T;
			error: undefined;
	  }
	| {
			isLoading: false;
			data: undefined;
			error: Error;
	  };

export const usePromise = <T,>(promise: Promise<T>): PromiseResolved<T> => {
	const [state, setState] = useState<PromiseResolved<T>>({
		isLoading: true,
		data: undefined,
		error: undefined,
	});

	useEffect(() => {
		if (!state.isLoading) return;

		promise
			.then((data) => setState({ isLoading: false, data, error: undefined }))
			.catch((error) => setState({ isLoading: false, error, data: undefined }));
	}, [promise, state.isLoading]);

	return state;
};
