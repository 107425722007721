import { FormProvider } from 'react-hook-form';
import { WizardFormStep } from '@gov-nx/core/hooks';
import { useLocale } from '@gov-nx/core/service';
import { FormPersonData, usePodporaContext } from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	DataTable,
	FormInput,
	FormWidthElementLayout,
	LayoutGap,
} from '@gov-nx/ui/web';
import { nameFormatting } from '@gov-nx/utils/common';

interface PodporaPersonFormProps {
	formMethods: WizardFormStep<FormPersonData>['formDefinition'];
}

export const SupportPersonForm = ({ formMethods }: PodporaPersonFormProps) => {
	const { individualPerson, controls, isLogged, code } = usePodporaContext();
	const { t } = useLocale(code);

	return (
		<FormProvider {...formMethods.formMethods}>
			<LayoutGap gap={8}>
				<LayoutGap gap={4}>
					<p>{t('formular.zprava.predvyplneny-udaje')}</p>
					{isLogged ? (
						<DataTable
							responsive
							stripedVariant="even">
							<tbody>
								<tr>
									<td className={'sm:!w-1/2 font-normal'}>
										{t('formular.pole.jmeno')}
									</td>
									<td className={'font-bold'}>
										{nameFormatting(individualPerson?.jmeno)}
									</td>
								</tr>
								<tr>
									<td className={'font-normal'}>
										{t('formular.pole.prijmeni')}
									</td>
									<td className={'font-bold'}>
										{nameFormatting(individualPerson?.prijmeni)}
									</td>
								</tr>
							</tbody>
						</DataTable>
					) : (
						<FormWidthElementLayout>
							<LayoutGap gap={4}>
								<FormInput
									field={{
										name: 'jmeno',
										disabled: controls.processLoading,
										placeholder: t('formular.placeholders.jmeno'),
									}}
									label={{ children: t('formular.pole.jmeno') }}
									group={{ gap: 's' }}
								/>
								<FormInput
									field={{
										name: 'prijmeni',
										disabled: controls.processLoading,
										placeholder: t('formular.placeholders.prijmeni'),
									}}
									label={{ children: t('formular.pole.prijmeni') }}
									group={{ gap: 's' }}
								/>
							</LayoutGap>
						</FormWidthElementLayout>
					)}
				</LayoutGap>
				<FormWidthElementLayout>
					<LayoutGap gap={4}>
						<FormInput
							field={{
								inputType: 'email',
								name: 'email',
								disabled: controls.processLoading,
								placeholder: t('formular.placeholders.email'),
							}}
							label={{ children: t('formular.pole.email') }}
							group={{ gap: 's' }}
						/>
						<FormInput
							field={{
								inputType: 'tel',
								name: 'telefon',
								disabled: controls.processLoading,
								placeholder: t('formular.placeholders.telefon'),
							}}
							label={{ children: t('formular.pole.telefon') }}
							group={{ gap: 's' }}
						/>
					</LayoutGap>
				</FormWidthElementLayout>
				<ButtonGroupLayout>
					<Button
						loading={controls.processLoading}
						wcagLabel={t('form.wcag.pokracovat')}
						disabled={controls.processLoading}
						nativeType="submit"
						variant="primary"
						size="l"
						type="solid">
						{t('formular.tlacitko.pokracovat')}
					</Button>
				</ButtonGroupLayout>
			</LayoutGap>
		</FormProvider>
	);
};
