import { govApiLog } from '@gov-nx/core/app';
import { axiosInstance } from '../axios';
import { PodaniOsvedceniDto, PodaniOsvedceniSeznamDto } from '../generated';

export const myVerificationsQuery = async <Params>(
	params: Params
): Promise<PodaniOsvedceniSeznamDto> => {
	govApiLog('/api/v1/osvedceni');
	const { data } = await axiosInstance.get('/api/v1/osvedceni', {
		params,
	});
	return data;
};

const searchOnFE = async <Params>(
	params: Params,
	search: string,
	start = 0,
	buffer: PodaniOsvedceniSeznamDto = { seznam: [], pocet: 0 }
): Promise<PodaniOsvedceniSeznamDto> => {
	const pocet = 500;
	const result = await myVerificationsQuery({
		...params,
		start,
		pocet,
	});

	const filtered =
		result.seznam?.filter(
			(item) =>
				item.nazevUkonu?.toLowerCase().includes(search.toLowerCase()) ||
				item.adresatUkonu?.toLowerCase().includes(search.toLowerCase())
		) ?? [];

	const newBuffer: PodaniOsvedceniSeznamDto = {
		seznam: [...(buffer.seznam ?? []), ...filtered],
		pocet: (buffer.pocet ?? 0) + filtered.length,
	};

	if (start >= (result.pocet ?? 0)) {
		return newBuffer;
	}
	return searchOnFE(params, search, start + pocet, newBuffer);
};

export const myVerificationsSearchQuery = async <
	Params extends { start?: number; pocet?: number }
>(
	search: string,
	params: Params
): Promise<PodaniOsvedceniSeznamDto> => {
	const result = await searchOnFE(params, search);
	const start = params.start ?? 0;
	const pocet = params.pocet ?? 10;

	return {
		pocet: result.pocet,
		seznam: result.seznam?.slice(start, start + pocet),
	};
};

export const verificationDownloadQuery = async (verificationId: number) => {
	const data = await axiosInstance.get(
		`/api/v1/osvedceni/${verificationId}/stahnout`,
		{
			responseType: 'blob',
		}
	);
	return data;
};

export const myVerificationDetailQuery = async (
	submissionId: number
): Promise<PodaniOsvedceniDto> => {
	govApiLog('/api/v1/osvedceni/' + submissionId);
	const { data } = await axiosInstance.get(`/api/v1/osvedceni/${submissionId}`);
	return data;
};
