import { GovIcon } from '@gov-design-system-ce/react';
import React from 'react';
import { PoFormWeb } from '@gov-nx/component/web';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useSmartFormContext } from '@gov-nx/module/service';
import { Button, FormRadio, FormSelect, LayoutGap } from '@gov-nx/ui/web';
import { SmartFormSkeleton } from './SmartFormSkeleton';

export const SmartForm = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried('global');
	const {
		isEligibleListLoading,
		isFormSessionLoading,
		form,
		link,
		hasRepresentationOptions,
		role,
		onSubmit,
		redirectUrl,
	} = useSmartFormContext();

	return (
		<PoFormWeb
			formDefinition={form}
			onSubmit={onSubmit}>
			{redirectUrl ? (
				<span
					className={
						'gov-button inline-flex h-10 pt-1.5 pb-1.5 pl-7 pr-7 mb-8 bg-primary-600 rounded-[3px] cursor-pointer [ hover:bg-primary-700 ]'
					}>
					<a
						className={
							'element text-neutral-white text-s !font-bold leading-[150%]'
						}
						href={redirectUrl}>
						{tsn('akce.pokracovat-na-fomular')}
					</a>
				</span>
			) : isEligibleListLoading ? (
				<SmartFormSkeleton />
			) : (
				<LayoutGap
					gap={6}
					className={'mb-8'}>
					{hasRepresentationOptions ? (
						<>
							<FormRadio {...form.fields.role} />

							{role === 'v_zastoupeni' ? (
								<FormSelect {...form.fields.rezaCode} />
							) : null}
						</>
					) : null}

					<Button
						nativeType="submit"
						loading={isFormSessionLoading}
						disabled={isFormSessionLoading}
						type={link.properties.type}
						variant={link.properties.variant}
						inverse={link.properties.inverse}
						wcagLabel={link.label}>
						{link.label}
						<GovIcon
							type={'basic'}
							name={'box-arrow-up-right'}
							slot={'right-icon'}></GovIcon>
					</Button>
				</LayoutGap>
			)}
		</PoFormWeb>
	);
};
