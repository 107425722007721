import { ResourceKey } from 'i18next';

export enum LocalizeNameSpaceTypes {
	Form = 'form',
	Global = 'global',
	Service = 'service',
	Page = 'page',
	Auth = 'auth',
	Component = 'component',
}

export interface TranslationResponse {
	data: ResourceKey;
	meta: {
		version: string;
		app: string;
		createdAt: string;
		env: string;
		locale: 'cs' | 'en';
	};
}
