import { pageRouteResolver } from '@gov-nx/web';
import { Fragment } from 'react';
import { MySubmissionConnection } from '@gov-nx/core/hooks';

interface Props {
	submissions: MySubmissionConnection['submissions'];
}

export const DataBoxMessageMySubmission = ({ submissions }: Props) => {
	return (
		<div>
			{submissions.map((submission, index) => (
				<Fragment key={submission.id}>
					{index !== 0 ? ', ' : null}
					<a
						className={'gov-link text-secondary-500'}
						href={pageRouteResolver('moje-podani.detail', {
							submissionId: submission.id,
						})}>
						{submission.nazev}
					</a>
				</Fragment>
			))}
		</div>
	);
};
