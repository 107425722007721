import { GovPagination } from '@gov-design-system-ce/react';
import { pageRouteResolver, useScroll } from '@gov-nx/web';
import { useLocale } from '@gov-nx/core/service';
import { useMojeAutorizaceSeznamContext } from '@gov-nx/module/page';
import { ErrorStatusBanner, InfoBanner, Tile } from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';
import { MyAuthorizationListSkeleton } from './MyAuthorizationsListSkeleton';

export const MyAuthorizationListView = () => {
	const {
		code,
		isNoSubmissions,
		form602Submissions,
		number602Submissions,
		currentPage,
		filter,
		controls,
		goToPage,
	} = useMojeAutorizaceSeznamContext();
	const { scrollToTop } = useScroll();
	const { t } = useLocale(code);

	if (controls.initialLoading) {
		return <MyAuthorizationListSkeleton />;
	}

	if (controls.initialError) {
		return <ErrorStatusBanner code={code} />;
	}

	if (isNoSubmissions) {
		return (
			<InfoBanner
				icon={{ name: 'cactus', type: 'colored' }}
				type="secondary">
				<p className={'text-2xl [ md:text-3xl ]'}>
					{t('stav.chyba.zadne-autorizace')}
				</p>
				<p>{t('stav.chyba.nenasli-jsme-autorizace')}</p>
			</InfoBanner>
		);
	}

	return (
		<>
			<ul className={'gov-list--plain mt-2 [ md:mt-8 ]'}>
				{form602Submissions.map((submission) => (
					<Tile
						key={submission.formularPodaniId}
						to={pageRouteResolver('moje-podani.autorizace.detail', {
							formularPodaniId: submission.formularPodaniId,
						})}
						// TODO: tady prozatim jako jmeno formId, protoze nazev nechodi
						name={submission.nazev ?? submission.formId ?? ''}
						date={
							submission.datumACasVytvoreni &&
							toDateReadable(submission.datumACasVytvoreni)
						}
						icon={{ name: 'chevron-right', type: 'basic' }}
					/>
				))}
			</ul>
			{number602Submissions > filter.pocet ? (
				<GovPagination
					onGov-page={(event) => {
						scrollToTop();
						goToPage(event.detail.pagination.currentPage);
					}}
					current={currentPage}
					page-size={filter.pocet}
					total={number602Submissions}
					wcag-label={t('strankovani')}
				/>
			) : null}
		</>
	);
};
