import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { hasOwnProperty, prop } from '@gov-nx/core/types';
import {
	PERSIST_ZUSTAND_STORE_KEY,
	storeGlobalConfig,
	StoreKeys,
} from '../constants';
import { FiltersState } from './store.types';

const update =
	<T>(property: keyof T, value: object) =>
	(object: T): T => {
		return {
			...object,
			[property]: { ...object[property], ...value },
		};
	};

export const updateByPath =
	<T extends object, V>(path: string[], value: V) =>
	(obj: T): T => {
		const [first, ...rest] = path;

		const objectToUpdate =
			obj && hasOwnProperty(obj, first) ? (obj[first] as object) : {};

		const toUpdate =
			rest.length > 0 ? updateByPath(rest, value)(objectToUpdate) : value;

		return update(first as keyof T, toUpdate as object)(obj);
	};

const initialState = {
	filters: {},
};

export const useFiltersStoreBase = create<FiltersState>()(
	persist(
		(set, get) => ({
			...initialState,

			updateFiltersAction: (key, data) =>
				set(updateByPath(['filters', key], data)),

			updateFiltersWithPathAction: (keys, data) =>
				set(updateByPath(['filters', ...keys], data)),

			filtersResetAction: () => set(initialState),
		}),
		{
			...storeGlobalConfig,
			name: PERSIST_ZUSTAND_STORE_KEY + ':' + StoreKeys.filters,
		}
	)
);

export const useFiltersStore = <T extends keyof FiltersState>(
	property: T
): FiltersState[T] => useFiltersStoreBase(prop(property));

export const useFiltersStoreFilters = <T extends object>(
	key: string
): FiltersState<typeof key, T>['filters'][typeof key] =>
	useFiltersStoreBase<T>((state) => state.filters[key] as T);
