import { useState } from 'react';
import { useCmsEvents } from '@gov-nx/core/events';
import { Nullable } from '@gov-nx/core/types';
import { useConfigurationStore } from '@gov-nx/store/portal-obcana';
import { useCmsExternalServiceLoader } from './useCmsExternalServiceLoader';

interface CmsExternalServiceModalLoaderProps {
	onExternalServiceSuccess?: () => void;
}

export const useCmsExternalServiceModalLoader = (
	props?: CmsExternalServiceModalLoaderProps
) => {
	const getBaseCmsUrlPath = useConfigurationStore('getBaseCmsUrlPath');

	const urlCmsPath = getBaseCmsUrlPath();
	const [id, setId] = useState<Nullable<number>>(null);
	const { loadExternalService, externalService } = useCmsExternalServiceLoader({
		onSuccess: () =>
			props?.onExternalServiceSuccess && props.onExternalServiceSuccess(),
		onError: () => setId(null),
	});
	useCmsEvents({
		onCmsExternalServiceModal: async (_, { id }) => {
			setId(id);
			await loadExternalService(id);
		},
	});

	return { id, setId, externalService, urlCmsPath };
};
