import { useLocale } from '@gov-nx/core/service';
import {
	PageCode,
	useNastaveniKontaktnichUdajuROSItemContext,
} from '@gov-nx/module/page';
import { ContentLayout, LayoutGap } from '@gov-nx/ui/web';
import { ContactEmailView } from '../../nastaveni/kontaktni-udaje/ContactEmailView';
import { ContactPhoneView } from '../../nastaveni/kontaktni-udaje/ContactPhoneView';
import { UsePersonalContactsButton } from '../../nastaveni/kontaktni-udaje/ros/UsePersonalContactsButton';

export const ContactDetailsView = () => {
	const {
		code,
		canUsePersonalContacts,
		isUsingPersonalContactsLoading,
		phone,
		email,
		handleUsePersonalContacts,
	} = useNastaveniKontaktnichUdajuROSItemContext();
	const { t } = useLocale(PageCode['profil-ROS']);

	return (
		<ContentLayout>
			<LayoutGap gap={4}>
				<h3 className="text-secondary-700 text-l">
					{t('nadpis.kontaktni-udaje')}
				</h3>
				<p className="text-secondary-700 text-s">
					{t('popis.kontaktni-udaje')}
				</p>
				<ContactEmailView
					code={code}
					{...email}
				/>

				<ContactPhoneView
					code={code}
					{...phone}
				/>
			</LayoutGap>
			<UsePersonalContactsButton
				canUsePersonalContacts={canUsePersonalContacts}
				isLoading={isUsingPersonalContactsLoading}
				code={code}
				handleUsePersonalContacts={handleUsePersonalContacts}
			/>
		</ContentLayout>
	);
};
