import axios from 'axios';
import {
	useAuthStoreBase,
	useConfigurationStoreBase,
	useUserStoreBase,
} from '@gov-nx/store/portal-obcana';

const instance = axios.create({
	headers: {
		'Content-Type': 'application/json',
	},
	paramsSerializer: {
		indexes: null,
	},
});

const setup = () => {
	instance.interceptors.request.use((config) => {
		const language = useUserStoreBase.getState().poUserLanguage();
		if (language) {
			config.headers['Accept-Language'] = language;
		}

		const token = useAuthStoreBase.getState().token;
		if (token) {
			config.headers.Authorization = 'Bearer ' + token;
		}

		const serverUrl = useConfigurationStoreBase.getState().poServerUrl();
		if (serverUrl) {
			config.baseURL = serverUrl;
		}

		return config;
	});
};

export const axiosInstance = instance;
export const setupPoAxiosInterceptors = setup;
