import { EmbassyOfficePostalVoting } from '@gov-nx/api/portal-obcana';

interface Country {
	code: string;
	name: string;
}
export const getCountries = (list: EmbassyOfficePostalVoting[]) => {
	return list.reduce((all, current) => {
		const countryExists = all.find(
			(existing) => existing.code === current.vlastnost
		);

		if (countryExists) {
			return all;
		}
		return [
			...all,
			{ code: current.vlastnost, name: current.parametry.nazevStatu },
		];
	}, [] as Country[]);
};

interface District {
	name: string;
	offices: {
		code: string;
		nazevCs: string;
		nazevEn: string;
	}[];
}

export const getDistrict =
	(list: EmbassyOfficePostalVoting[]) =>
	(countrycode: string): District[] => {
		return list
			.filter((office) => office.vlastnost === countrycode)
			.reduce((all, office) => {
				return [
					...all,
					...office.parametry.uzemniObvody.map((district) => {
						return {
							name: district,
							offices: [
								{
									code: office.kod,
									nazevCs: office.nazevCs,
									nazevEn: office.nazevEn,
								},
							],
						};
					}),
				];
			}, [] as District[]);
	};
