import { useEffect } from 'react';
import { FieldErrors } from 'react-hook-form';
import { OsobyAdresaDto } from '@gov-nx/api/portal-obcana';
import { FormDefinition } from '@gov-nx/core/service';
import { pipe } from '@gov-nx/core/types';
import { usePersonStore } from '@gov-nx/store/portal-obcana';
import {
	getDefaultPhoneValue,
	IUseBoolean,
	useBoolean,
} from '@gov-nx/utils/common';
import { ContactDetailsFormData } from '../Contacts/useContactDetailsFormDefinition';
import { useCountryCodes } from '../Phone/useCountryCodes';

export interface PersonCommunicationData {
	emailVerified?: string;
	unverifiedEmail?: string;

	phoneVerified?: string;
	unverifiedPhone?: string;

	address?: OsobyAdresaDto;

	control: {
		isEditingEmail: IUseBoolean;
		isEditingPhone: IUseBoolean;
		isEditingAddress: IUseBoolean;
	};

	onFormValidated: <T extends ContactDetailsFormData>(
		errors: FieldErrors<T>
	) => void;
}

export const usePersonCommunicationData = (): PersonCommunicationData => {
	const poCommunicationEmail = usePersonStore('poCommunicationEmail');
	const poCommunicationVerifyEmail = usePersonStore(
		'poCommunicationVerifyEmail'
	);
	const poCommunicationPhone = usePersonStore('poCommunicationPhone');
	const poCommunicationVerifyPhone = usePersonStore(
		'poCommunicationVerifyPhone'
	);
	const poCommunicationAddress = usePersonStore('poCommunicationAddress');
	const emailVerified = poCommunicationEmail();
	const unverifiedEmail = poCommunicationVerifyEmail();

	const phoneVerified = poCommunicationPhone();
	const unverifiedPhone = poCommunicationVerifyPhone();

	const address = poCommunicationAddress();

	const isEditingEmail = useBoolean();
	const isEditingPhone = useBoolean();
	const isEditingAddress = useBoolean();

	useEffect(() => {
		if (unverifiedPhone) {
			isEditingPhone.setTrue();
		}
		if (unverifiedEmail) {
			isEditingEmail.setTrue();
		}
		if (!address) {
			isEditingAddress.setTrue();
		}
	}, [unverifiedPhone, unverifiedEmail, address]);

	return {
		emailVerified,
		phoneVerified,
		unverifiedEmail,
		unverifiedPhone,
		address,
		control: {
			isEditingEmail,
			isEditingPhone,
			isEditingAddress,
		},
		onFormValidated: (errors) => {
			if (errors.phone) {
				isEditingPhone.setTrue();
			}
			if (errors.email) {
				isEditingEmail.setTrue();
			}
		},
	};
};

export const usePersonCommunicationForm = <T extends ContactDetailsFormData>(
	form: FormDefinition<T>,
	{
		countryCodes,
		phone,
		email,
	}: {
		countryCodes: ReturnType<typeof useCountryCodes>;
		email?: string;
		phone?: string;
	}
) => {
	const valuesSet = useBoolean();

	const { formMethods } =
		form as unknown as FormDefinition<ContactDetailsFormData>;

	useEffect(() => {
		if (valuesSet.isFalse && countryCodes.isFetched) {
			formMethods.setValue('_useEmail', !!email);
			if (email) {
				formMethods.setValue('email', email);
			}

			formMethods.setValue('_usePhone', !!phone);
			if (phone) {
				formMethods.setValue(
					'phone',
					pipe(
						phone,
						countryCodes.stringToPhoneShape,
						(shape) => shape ?? getDefaultPhoneValue()
					)
				);
			}

			valuesSet.setTrue();
		}
	}, [valuesSet, formMethods, countryCodes, email, phone]);
};
