import { GovModal, GovWizard } from '@gov-design-system-ce/react';
import { pageRouteResolver } from '@gov-nx/web';
import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { MarkdownRender } from '@gov-nx/component/web';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useEPeticeEditContext } from '@gov-nx/module/page';
import { Button, ButtonGroupLayout, ErrorStatusBanner } from '@gov-nx/ui/web';
import { WizardStepWeb } from '../../../Wizard/WizardStepWeb';
import { EPetitionFormSkeleton } from '../form/EPetitionFormSkeleton';
import { WizardStep1 } from '../form/WizardStep1';
import { WizardStep2 } from '../form/WizardStep2';
import { WizardStep3 } from '../form/WizardStep3';
import { WizardStep4 } from '../form/WizardStep4';
import { ContactAddressForm } from './ContactAddressForm';
import { PetitionMemberForm } from './PetitionMemberForm';

export const EPetitionEditView = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const {
		code,
		addressLookup,
		controls,
		personCommunicationData,
		isPetitionMemberAddressLookupModalOpen,
		isSubmitConfirmModalOpen,
		isDeleteConfirmModalOpen,
		wizard,
		formStep1,
		formStep2,
		petitionId,
		zakladatelTyp,
		isProcessing,
		isEditable,
		applicant,
		onDelete,
	} = useEPeticeEditContext();

	const tsn = getLocalizeCurried(code);
	const navigate = useNavigate();

	if (controls.initialLoading || !formStep2) {
		return <EPetitionFormSkeleton />;
	}

	if (controls.initialError) {
		return <ErrorStatusBanner code={code} />;
	}

	if (!isEditable) {
		return (
			<Navigate to={pageRouteResolver('epetice.detail', { petitionId })} />
		);
	}

	return (
		<div>
			<GovModal
				open={
					zakladatelTyp === 'FO' &&
					personCommunicationData.control.isEditingAddress.value
				}
				label={tsn('formular.nadpis.editace-adresy')}
				onGov-close={personCommunicationData.control.isEditingAddress.setFalse}>
				<ContactAddressForm
					code={code}
					addressLookup={addressLookup}
				/>
			</GovModal>

			<GovModal
				open={isPetitionMemberAddressLookupModalOpen.value}
				label={tsn('formular.nadpis.pridat-clena-vyboru')}
				onGov-close={isPetitionMemberAddressLookupModalOpen.setFalse}>
				<PetitionMemberForm />
			</GovModal>

			<GovModal
				open={isSubmitConfirmModalOpen.value}
				label={tsn('formular.nadpis.opravdu-zalozit')}
				onGov-close={isSubmitConfirmModalOpen.setFalse}>
				<MarkdownRender>{tsn('formular.popis.opravdu-zalozit')}</MarkdownRender>
				<ButtonGroupLayout customClasses={'mt-8'}>
					<Button
						onClick={() =>
							wizard.submit(() => {
								navigate(pageRouteResolver('epetice.detail', { petitionId }));
							})
						}
						disabled={isProcessing}
						loading={controls.createLoading}
						wcagLabel={tsn('akce.wcag.zalozit')}
						variant="primary"
						type="solid"
						size="m">
						{tsn('formular.tlacitko.zalozit')}
					</Button>
				</ButtonGroupLayout>
			</GovModal>

			<GovModal
				label={tsn('akce.opravdu-chcete-smazat')}
				open={isDeleteConfirmModalOpen.value}
				onGov-close={isDeleteConfirmModalOpen.setFalse}>
				<ButtonGroupLayout customClasses={'mt-2'}>
					<Button
						onClick={() => {
							onDelete(() => {
								isDeleteConfirmModalOpen.setFalse();
								navigate(pageRouteResolver('epetice'));
							});
						}}
						loading={controls.deleteLoading}
						wcagLabel={tsn('akce.wcag.smazat')}
						disabled={isProcessing}
						variant={'error'}
						size={'l'}
						type={'solid'}>
						{tsn('formular.tlacitko.smazat')}
					</Button>
					<Button
						onClick={isDeleteConfirmModalOpen.setFalse}
						wcagLabel={tsn('akce.wcag.smazat')}
						disabled={isProcessing}
						variant={'primary'}
						size={'l'}
						type={'outlined'}>
						{tsn('formular.tlacitko.zrusit')}
					</Button>
				</ButtonGroupLayout>
			</GovModal>

			<GovWizard>
				<WizardStepWeb
					index={0}
					label={tsn('formular.zprava.zakladni-informace')}
					wizard={wizard}>
					<WizardStep1
						formDefinition={formStep1}
						isLoading={false}
						isDisabled={true}
						applicant={applicant}
						code={code}
					/>
				</WizardStepWeb>
				<WizardStepWeb
					index={1}
					label={tsn('formular.zprava.informace-o-petici')}
					wizard={wizard}>
					<WizardStep2 />
				</WizardStepWeb>
				<WizardStepWeb
					index={2}
					label={tsn('formular.zprava.peticni-vybor')}
					wizard={wizard}>
					<WizardStep3 />
				</WizardStepWeb>
				<WizardStepWeb
					index={3}
					label={tsn('formular.zprava.rekapitulace')}
					wizard={wizard}>
					<WizardStep4 />
				</WizardStepWeb>
			</GovWizard>
		</div>
	);
};
