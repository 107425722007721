import {
	downloadForm602SubmissionQuery,
	useDownloadPoMutation,
} from '@gov-nx/api/portal-obcana';
import { useDownloadEvents, useMessageEvents } from '@gov-nx/core/events';
import { LocalizeNameSpaceTypes, useLocale } from '@gov-nx/core/service';

export const DownloadForm602AttachmentListener = () => {
	const { toastMessageSuccess, toastMessageError } = useMessageEvents();
	const { t } = useLocale(LocalizeNameSpaceTypes.Service);

	const { mutate } = useDownloadPoMutation(downloadForm602SubmissionQuery, {
		onSuccess: (props) =>
			download({
				...props,
				callback: (id, success, error) => {
					if (success) {
						toastMessageSuccess(
							t('dokument.stazeni-dokumentu-uspech', {
								fileName: props.fileName,
							})
						);
					}
					if (error) {
						toastMessageError();
					}
				},
			}),
	});

	const { download } = useDownloadEvents({
		onForm602AttachmentDownload: (_, payload) =>
			mutate(
				{ id: payload.formularPodaniId },
				{
					onSuccess: () =>
						payload.callback &&
						payload.callback(payload.formularPodaniId, null, null),
					onError: () =>
						payload.callback &&
						payload.callback(payload.formularPodaniId, null, null),
				}
			),
	});

	return null;
};
