import { useLocale } from '@gov-nx/core/service';
import { LocaleDefinition, LocaleNoParams } from '@gov-nx/core/types';
import { ServiceCode } from '@gov-nx/module/service';

type SmazaniUctuLocale = {
	pruvodce: {
		krok1: {
			label: LocaleNoParams;
		};
		krok2: {
			label: LocaleNoParams;
		};
		krok3: {
			label: LocaleNoParams;
		};
		'uspesne-odeslano': {
			titulek: LocaleNoParams;
			popis: LocaleNoParams;
		};
		'potvrzeni-odeslani': {
			titulek: LocaleNoParams;
			popis: LocaleNoParams;
		};
	};
	formular: {
		'souhlas-se-smazanim': {
			label: LocaleNoParams;
			validace: {
				povinny: LocaleNoParams;
			};
		};
		'souhlas-s-ponechanim-informaci': {
			label: LocaleNoParams;
			validace: {
				povinny: LocaleNoParams;
			};
		};
		zprava: {
			label: LocaleNoParams;
			placeholder: LocaleNoParams;
		};
		tlacitko: {
			pokracovat: LocaleNoParams;
			smazat: LocaleNoParams;
			nemazat: LocaleNoParams;
			'potvrdit-smazani': LocaleNoParams;
		};
	};
	'ktere-informace-se-smazou': {
		titulek: LocaleNoParams;
		seznam: LocaleNoParams;
	};
	'ktere-sluzby-se-odpoji': {
		titulek: LocaleNoParams;
		seznam: LocaleNoParams;
	};
	'ktere-informace-zustanou': {
		titulek: LocaleNoParams;
		seznam: LocaleNoParams;
	};
	'potvrzeni-smazani': {
		titulek: LocaleNoParams;
		zprava: LocaleNoParams;
	};
};

export const useSmazaniUctuLocale = (): LocaleDefinition<SmazaniUctuLocale> =>
	useLocale(ServiceCode['smazani-uzivatelskeho-uctu']);
