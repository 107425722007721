export const AlphabeticalComparator = <T extends { label: string }>(
	a: T,
	b: T
): number => {
	return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
};

interface Indexed {
	letter: string;
	index: number;
}

const splitLetters = (text: string): string[] => {
	return text.split('').reduce((all, current) => {
		if (current.trim() === '') return all;

		if (all[all.length - 1] === 'c' && current === 'h') {
			return all.map((letter, index) =>
				index === all.length - 1 ? 'ch' : letter
			);
		}

		return [...all, current];
	}, [] as string[]);
};

const toIndexed = (text: string): Indexed[] =>
	splitLetters(text.toLocaleLowerCase()).map((letter) => ({
		letter,
		index: customAlphabeticalIndexes.indexOf(letter),
	}));

const compareIndexed = (a: Indexed[], b: Indexed[]): -1 | 1 | 0 => {
	const [a1, ...aRest] = a;
	const [b1, ...bRest] = b;

	if (a1.index < b1.index) return -1;
	if (a1.index > b1.index) return 1;

	if (aRest.length && bRest.length) {
		return compareIndexed(aRest, bRest);
	}

	return 0;
};

const compare = <T extends string>(a: T, b: T) =>
	compareIndexed(toIndexed(a), toIndexed(b));

export const sortCountryNames = (list: string[]) => list.sort(compare);

export const sortAlphabetical = <T extends { value: string }>(list: T[]) =>
	list.sort((a, b) => compare(a.value, b.value));

const customAlphabeticalIndexes = [
	'a',
	'á',
	'b',
	'c',
	'č',
	'd',
	'ď',
	'e',
	'é',
	'ě',
	'f',
	'g',
	'h',
	'ch',
	'i',
	'í',
	'j',
	'k',
	'l',
	'm',
	'n',
	'ň',
	'o',
	'ó',
	'p',
	'q',
	'r',
	'ř',
	's',
	'š',
	't',
	'ť',
	'u',
	'ú',
	'ů',
	'v',
	'w',
	'x',
	'y',
	'ý',
	'z',
	'ž',
];
