import { useEffect } from 'react';
import { axiosCmsInstance } from '@gov-nx/api/common';
import { LocalizationLanguage } from '@gov-nx/core/app';
import { useConnection } from '@gov-nx/core/hooks';
import { useBoolean } from '@gov-nx/utils/common';
import { useConfiguration } from '../configuration/useConfiguration';
import { initTranslations } from './i18n';

interface TranslationInitProps {
	initialLanguage: LocalizationLanguage;
	onError: (errorMessage: string) => void;
}

export const useTranslationInit = ({
	initialLanguage,
	onError,
}: TranslationInitProps): { translationInitialized: boolean } => {
	const initialized = useBoolean(false);
	const { cmsKeyCache } = useConfiguration();
	const { isOffline } = useConnection();

	useEffect(() => {
		if (initialized.isTrue) {
			return;
		}

		initTranslations(initialLanguage, axiosCmsInstance, cmsKeyCache, isOffline)
			.then(initialized.setTrue)
			.catch((error) => {
				initialized.setFalse();
				onError(`Chyba nacitani lokalizace: ${(error as Error).message}`);
			});
	}, [initialLanguage]);

	return { translationInitialized: initialized.isTrue };
};
