import {
	FileState,
	FileUpload,
	FileUploadItem,
	FileUploadProps,
	InitialUploadItemState,
	isFileUploadNative,
} from '@gov-nx/core/hooks';
import { LocalizeNameSpaceTypes, useLocale } from '@gov-nx/core/service';
import { MAX_NORMAL_FILE_SIZE } from '@gov-nx/module/data-box';
import { formatBytes } from '@gov-nx/utils/common';

export const hasLargeFile = (files?: FileUploadItem[]) =>
	!!files?.some((file) => file.size > MAX_NORMAL_FILE_SIZE);

const getSize = (file: FileUpload): number =>
	isFileUploadNative(file) ? file.size : file.file.size;

export const sumSizes = (files: FileUpload[]): number =>
	files.reduce((total, fileUpload) => total + getSize(fileUpload), 0);

export const getAttributes = (fileUpload: FileUpload) => {
	const isNative = isFileUploadNative(fileUpload);

	const name = isNative ? fileUpload.name : fileUpload.file.name;
	const size = isNative ? fileUpload.size : fileUpload.file.size;
	const type = isNative ? fileUpload.type : fileUpload.file.type;
	const extension = '.' + name.split('.').pop();

	return { size, type, name, extension };
};

const overwriteFileName = (fileUpload: FileUpload): FileUpload => {
	const isNative = isFileUploadNative(fileUpload);

	const name = isNative ? fileUpload.name : fileUpload.file.name;

	return isNative
		? {
				...fileUpload,
				name,
		  }
		: {
				...fileUpload,
				file: new File([fileUpload.file], name, {
					type: fileUpload.file.type,
					lastModified: fileUpload.file.lastModified,
				}),
		  };
};

export const useFileUtils = (props: FileUploadProps) => {
	const { t } = useLocale(LocalizeNameSpaceTypes.Form);

	const getInitialFileState = (size: number): InitialUploadItemState => {
		const isLargeFile = size > MAX_NORMAL_FILE_SIZE;
		return {
			state: FileState.uploading,
			message: isLargeFile
				? t('nahrani-souboru.status.probiha-nahravani-velkeho-souboru')
				: t('nahrani-souboru.status.probiha-nahravani'),
			showLargeFileLoader: isLargeFile,
		};
	};

	const toFileUploadItem =
		(lastIndex: number) =>
		(fileUpload: FileUpload, index: number): FileUploadItem => {
			const fileUploadModified = overwriteFileName(fileUpload);
			const { name, type, size } = getAttributes(fileUploadModified);
			return {
				index: lastIndex + index,
				name,
				size,
				type,
				...fileUploadModified,
				...getInitialFileState(size),
			};
		};

	const validate = (
		file: FileUploadItem,
		index: number,
		files: FileUploadItem[]
	): FileUploadItem => {
		const { extension, size } = getAttributes(file);

		if (
			!props.extensions
				.map((extension) => extension.toLowerCase())
				.includes(extension.toLowerCase())
		) {
			return {
				...file,
				state: FileState.error,
				showLargeFileLoader: false,
				message: t('nahrani-souboru.status.spatny-format'),
			};
		}

		const isNative = isFileUploadNative(file);
		if (
			(isNative && size > props.maxFileSize) ||
			(!isNative && file.sizeValid === false)
		) {
			return {
				...file,
				state: FileState.error,
				showLargeFileLoader: false,
				message: t('nahrani-souboru.status.maximalni-velikost-souboru', {
					fileSize: formatBytes(props.maxFileSize),
				}),
			};
		}

		const filesValidated = files.slice(0, index + 1);
		if (filesValidated.length > props.maxAttachments) {
			return {
				...file,
				state: FileState.error,
				message: t('nahrani-souboru.status.maximalni-pocet-souboru', {
					maxFiles: props.maxAttachments,
				}),
			};
		}

		if (sumSizes(filesValidated) > props.maxSumFileSize) {
			return {
				...file,
				state: FileState.error,
				showLargeFileLoader: false,
				message: t('nahrani-souboru.status.maximalni-velikost-vsech-souboru', {
					namespace: LocalizeNameSpaceTypes.Form,
					filesSum: formatBytes(props.maxSumFileSize),
				}),
			};
		}

		// when file was in error state before but something changed and file is valid again
		if (file.state === FileState.error) {
			return {
				...file,
				...getInitialFileState(file.size),
			};
		}

		return file;
	};

	return { toFileUploadItem, validate };
};
