import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAuthStore } from '@gov-nx/store/portal-obcana';
import { useBoolean } from '@gov-nx/utils/common';

interface IEventRegister {
	addEventListener: (key: string, callback: () => void) => void;
	removeEventListener: (key: string, callback: () => void) => void;
}

export const useUserInactivity = (
	config: {
		inactiveTimeout: number;
		autoLogoutTimeout: number;
		onInactive: () => void;
		onLogout: () => void;
	},
	eventRegister?: IEventRegister
) => {
	const isLoggedIn = useAuthStore('isLoggedIn');
	const isLogged = isLoggedIn();
	const isActive = useBoolean();
	const inActiveTimerId = useRef<NodeJS.Timeout>();
	const logoutTimerId = useRef<NodeJS.Timeout>();

	const events = useMemo(
		() => [
			'mousemove',
			'mousedown',
			'touchstart',
			'touchmove',
			'click',
			'keydown',
			'scroll',
		],
		[]
	);

	const startTimers = useCallback(() => {
		if (isLogged) {
			inActiveTimerId.current = setTimeout(
				config.onInactive,
				config.inactiveTimeout
			);
			logoutTimerId.current = setTimeout(() => {
				config.onLogout();
			}, config.autoLogoutTimeout);
		}
	}, [config, isLogged]);

	const cancelTimers = useCallback(() => {
		clearInterval(inActiveTimerId.current);
		clearInterval(logoutTimerId.current);
	}, []);

	useEffect(() => {
		startTimers();

		return () => {
			cancelTimers();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const resetTimers = useCallback(() => {
		cancelTimers();
		startTimers();
	}, [cancelTimers, startTimers]);

	useEffect(() => {
		if (!isActive.value) return;

		resetTimers();

		events.forEach((eventType) => {
			eventRegister?.addEventListener?.(eventType, resetTimers);
		});
		return () => {
			events.forEach((eventType) => {
				eventRegister?.removeEventListener?.(eventType, resetTimers);
			});
		};
	}, [eventRegister, events, isActive, resetTimers]);

	return { start: isActive.setTrue };
};
