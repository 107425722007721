import { GovChip } from '@gov-design-system-ce/react';
import { useLocale } from '@gov-nx/core/service';
import { PageCode, useSpravaSouhlasuDetailContext } from '@gov-nx/module/page';
import { BadgeNav, ContentLayout, ErrorStatusBanner } from '@gov-nx/ui/web';
import { CmsPageRender } from '../../../Cms/CmsPageRender';
import { RecallConsentPrompt } from '../RecallConsentPrompt';
import { ConsentManagementDetailSkeleton } from './ConsentManagementDetailSkeleton';
import { ConsentManagementInfo } from './ConsentManagementInfo';
import { ConsentManagementUsers } from './ConsentManagementUsers';

interface ConsentManagementDetailProps {
	code: PageCode;
}

export const ConsentManagementDetailView = ({
	code,
}: ConsentManagementDetailProps) => {
	const {
		controls,
		setControls,
		filteredDetail,
		handleSubmit,
		refInfoBadges,
		view,
	} = useSpravaSouhlasuDetailContext();
	const { t } = useLocale(code);

	const getContent = () => {
		if (controls.initialLoading) {
			return <ConsentManagementDetailSkeleton />;
		}

		if (controls.initialError) {
			return <ErrorStatusBanner code={code} />;
		}

		return (
			<>
				<ConsentManagementInfo code={code} />
				<ConsentManagementUsers code={code} />
				<ContentLayout customClasses={'!pt-4'}>
					<BadgeNav>
						{refInfoBadges.map((badge) => (
							<li key={badge.name}>
								<GovChip
									variant={'primary'}
									size="m"
									tag="button"
									inverse={!badge.isActive}
									wcagLabel={`${t('formular.wcag.filtrovat')} ${badge.name}`}
									onGov-click={() => badge.viewFunction()}>
									{badge.name}
								</GovChip>
							</li>
						))}
					</BadgeNav>
					{view.referencniUdaje && 'referencni udaje placeholder'}
					{view.nereferencniUdaje && 'nereferencni udaje placeholder'}
					<RecallConsentPrompt
						isOpen={controls.displayModal}
						isConsentRecalling={controls.modalProcessLoading}
						onConsentRecallConfirm={() => handleSubmit()}
						onClose={() => setControls({ displayModal: false })}
					/>
				</ContentLayout>
			</>
		);
	};

	return (
		<CmsPageRender
			code={code}
			override={{ name: filteredDetail?.nazevOsoby }}
			showDescription={false}>
			{getContent()}
		</CmsPageRender>
	);
};
