import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { AnySchema } from 'yup/lib/schema';
import {
	agendasCiselnikyQuery,
	ovmAgendasQuery,
	ovmQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { FormDefinition2, useFormBuilder } from '@gov-nx/core/service';
import {
	CommonSelectProps,
	FormInputProps,
	WebInputProps,
} from '@gov-nx/ui/types';
import {
	getSelectDefinition,
	toDateReadable,
	today,
	useDateDefinition,
} from '@gov-nx/utils/common';
import { usePpdfSouhlasyLocale } from '../usePpdfSouhlasyLocale';

export interface PpdfSouhlasyUdeleniStep2 {
	fromOvmId: string;
	toOvmId: string;
	fromAgendaId: string;
	toAgendaId: string;
	sharedAgendaData: (string | null)[];
	dateOfValidityConsent: Date;
}

interface Fields {
	fromOvmId: CommonSelectProps;
	toOvmId: CommonSelectProps;
	fromAgendaId: CommonSelectProps;
	toAgendaId: CommonSelectProps;
	sharedAgendaData: CommonSharedAgendaProps[];
	dateOfValidityConsent: WebInputProps;
}

export type PpdfSouhlasyUdeleniFormStep2 = FormDefinition2<
	PpdfSouhlasyUdeleniStep2,
	Fields
>;

export interface CommonSharedAgendaProps {
	label: { children: string };
	field: {
		name: string;
		disabled?: boolean;
		isDisabled?: boolean;
		options: { value: string | number; label: string; isDisabled?: boolean }[];
	};
}

export interface SharedDataDefinition {
	defaultValue: null;
	schema: AnySchema;
	field: FormInputProps;
}

export interface AgendaDataSelectedLabels {
	selectedFromOvm?: string;
	selectedToOvm?: string;
	selectedFromAgenda?: string;
	selectedToAgenda?: string;
	selectedSharedAgendaData?: string[];
}

export const useFormStep2 = ({ isDisabled }: { isDisabled: boolean }) => {
	const { t } = usePpdfSouhlasyLocale();
	const [prevFromOvmId, setPrevFromOvmId] = useState<string>();

	const { isLoading, ovmOptions } = useAgendaDataQuery();
	const [prevFromAgendaId, setPrevFromAgendaId] = useState<string>();

	const fromOvmId = getSelectDefinition({
		name: 'fromOvmId',
		label: t('formular.spravce-udaju.label'),
		isDisabled,
		isRequired: true,
		isRequiredMessage: t('formular.spravce-udaju.validace.povinny'),
		options: [
			{ label: t('formular.spravce-udaju.default-label'), value: '' },
			...ovmOptions,
		],
	});

	const fromAgendaId = getSelectDefinition({
		name: 'fromAgendaId',
		label: t('formular.agenda-spravce.label'),
		isDisabled: isDisabled,
		isRequired: true,
		isRequiredMessage: t('formular.agenda-spravce.validace.povinny'),
		options: [{ label: t('formular.agenda-spravce.default-label'), value: '' }],
	});

	const toOvmId = getSelectDefinition({
		name: 'toOvmId',
		label: t('formular.prijemce-udaju.label'),
		isDisabled,
		isRequired: true,
		isRequiredMessage: t('formular.prijemce-udaju.validace.povinny'),
		options: [
			{ label: t('formular.prijemce-udaju.default-label'), value: '' },
			...ovmOptions,
		],
	});

	const toAgendaId = getSelectDefinition({
		name: 'toAgendaId',
		label: t('formular.agenda-prijemce.label'),
		isDisabled: isDisabled,
		isRequired: true,
		isRequiredMessage: t('formular.agenda-prijemce.validace.povinny'),
		options: [
			{ label: t('formular.agenda-prijemce.default-label'), value: '' },
		],
	});

	const min = today(undefined, true);
	const minErrorMessage = t('formular.souhlas-do-data.validace.min', {
		datum: toDateReadable(min),
	});
	const dateOfValidityConsent = useDateDefinition({
		name: 'dateOfValidityConsent',
		label: t('formular.souhlas-do-data.label'),
		min: { min, errorMessage: minErrorMessage },
		isRequired: true,
		isDisabled: isDisabled,
	});

	const atLeastOneValue = (input: unknown): boolean =>
		Array.isArray(input) &&
		input.some((item) => typeof item === 'string' && item !== '');

	const sharedAgendaData: SharedDataDefinition = {
		defaultValue: null,
		schema: yup
			.array()
			.of(yup.string().nullable())
			.test(
				'at-least-one',
				t('formular.sdilene-udaje.validace.povinny'),
				atLeastOneValue
			),
		field: {
			field: {
				name: 'sharedAgendaData',
				isDisabled: isDisabled,
			},
			label: { children: t('formular.sdilene-udaje.label') },
		},
	};

	const form = useFormBuilder<PpdfSouhlasyUdeleniStep2, Fields>([
		fromOvmId,
		toOvmId,
		fromAgendaId,
		toAgendaId,
		sharedAgendaData,
		dateOfValidityConsent,
	]);

	const updatedForm = useFormStep2Selects(form, isDisabled);

	const {
		sharedAgendaData: sharedAgendaDataValue,
		fromAgendaId: fromAgendaIdValue,
		fromOvmId: fromOvmIdValue,
	} = form.formMethods.watch();

	useEffect(() => {
		if (sharedAgendaDataValue?.some((item) => item === '')) {
			form.formMethods.setValue(
				'sharedAgendaData',
				sharedAgendaDataValue.filter((item) => item !== '')
			);
		}
		if (
			sharedAgendaDataValue?.length &&
			!sharedAgendaDataValue?.some((item) => item === null)
		) {
			form.formMethods.setValue('sharedAgendaData', [
				...(form.formMethods.getValues('sharedAgendaData') ?? []),
				null,
			]);
		}
	}, [sharedAgendaDataValue, form]);

	// cleanup fromAgendaId
	useEffect(() => {
		if (
			(prevFromOvmId && prevFromOvmId !== fromOvmIdValue) ||
			(prevFromOvmId && fromOvmIdValue === undefined)
		) {
			form.formMethods.setValue('fromAgendaId', '', { shouldValidate: false });
		}

		setPrevFromOvmId(fromOvmIdValue);
	}, [fromOvmIdValue, prevFromOvmId, setPrevFromOvmId, form.formMethods]);

	// cleanup sharedAgendaData
	useEffect(() => {
		if (
			(prevFromAgendaId && prevFromAgendaId !== fromAgendaIdValue) ||
			(prevFromAgendaId && fromAgendaIdValue === undefined)
		) {
			form.formMethods.setValue('sharedAgendaData', [null], {
				shouldValidate: false,
			});
		}

		setPrevFromAgendaId(fromAgendaIdValue);
	}, [
		fromAgendaIdValue,
		prevFromAgendaId,
		setPrevFromAgendaId,
		form.formMethods,
	]);

	return {
		isLoading: isLoading,
		form: updatedForm.form,
		selectedLabels: updatedForm.selectedLabels,
		noValuesForSharedAgendaData: updatedForm.noValuesForSharedAgendaData,
	};
};

export const useAgendaDataQuery = () => {
	const queryOvm = usePoQuery({
		queryKey: ['ovm'],
		queryFn: () => ovmQuery(true),
	});

	const ovmList = queryOvm.data?.seznam ?? [];

	return {
		ovmOptions: ovmList.map((ovm) => ({
			value: ovm.kod ?? '',
			label: ovm.nazev ?? '',
		})),
		error: queryOvm.error,
		isLoading: queryOvm.isLoading,
	};
};

export const useFormStep2Selects = <
	Values extends PpdfSouhlasyUdeleniStep2,
	Fields2 extends Fields
>(
	form: FormDefinition2<Values, Fields2>,
	isDisabled: boolean
): {
	noValuesForSharedAgendaData: boolean;
	selectedLabels: AgendaDataSelectedLabels;
	form: FormDefinition2<Values, Fields2>;
} => {
	const { t } = usePpdfSouhlasyLocale();

	const { ovmOptions } = useAgendaDataQuery(); //getDistrictOptions, getOfficeOptions,
	const { fromOvmId, toOvmId, fromAgendaId, toAgendaId, sharedAgendaData } =
		form.formMethods.watch();

	const queryFromAgenda = usePoQuery({
		queryKey: ['agenda', fromOvmId],
		queryFn: () => ovmAgendasQuery(fromOvmId, true),
		enabled: !!fromOvmId,
	});

	const fromAgendaOptions =
		queryFromAgenda.data?.seznam?.map(({ kod, nazev }) => ({
			value: kod ?? '',
			label: nazev ?? '',
		})) ?? [];

	const queryToAgenda = usePoQuery({
		queryKey: ['agenda', toOvmId],
		queryFn: () => ovmAgendasQuery(toOvmId, true),
		enabled: !!toOvmId,
	});

	const toAgendaOptions =
		queryToAgenda.data?.seznam?.map(({ kod, nazev }) => ({
			value: kod ?? '',
			label: nazev ?? '',
		})) ?? [];

	const querySharedAgendaData = usePoQuery({
		queryKey: ['agenda-ciselniky', fromOvmId, fromAgendaId],
		queryFn: async () => {
			return agendasCiselnikyQuery(
				fromAgendaOptions.find((agenda) => agenda.value === fromAgendaId)
					?.value ?? ''
			);
		},
		enabled: !!fromAgendaId && !!fromOvmId && !!fromAgendaOptions.length,
	});

	const sharedAgendaDataOptions =
		querySharedAgendaData.data?.seznam?.map(({ kod, nazevCs }) => ({
			value: kod ?? '',
			label: nazevCs ?? '',
		})) ?? [];

	const noValuesForSharedAgendaData =
		!!fromAgendaId &&
		!querySharedAgendaData.isLoading &&
		!sharedAgendaDataOptions.length;

	/** In case when all shared agendas are selected remove new empty field on the end of the form.
	 * Empty field has value null by default.
	 * */
	const sharedAgendaDataFiltered =
		sharedAgendaDataOptions?.length &&
		sharedAgendaDataOptions?.every((item) =>
			sharedAgendaData?.includes(item.value)
		)
			? sharedAgendaData?.filter((item) => item !== null)
			: sharedAgendaData;

	return {
		noValuesForSharedAgendaData,
		selectedLabels: {
			selectedFromOvm: ovmOptions.find((ovm) => ovm.value === fromOvmId)?.label,
			selectedFromAgenda: fromAgendaOptions.find(
				(agenda) => agenda.value === fromAgendaId
			)?.label,
			selectedToOvm: ovmOptions.find((ovm) => ovm.value === toOvmId)?.label,
			selectedToAgenda: toAgendaOptions.find(
				(agenda) => agenda.value === toAgendaId
			)?.label,
			selectedSharedAgendaData: sharedAgendaDataOptions
				.filter((item) => sharedAgendaData.includes(item.value))
				.map((item) => item.label),
		},
		form: {
			...form,
			fields: {
				...form.fields,
				sharedAgendaData: [
					...(sharedAgendaDataFiltered?.map((item, index) => {
						const selectedValues = sharedAgendaDataFiltered.slice(0, index);
						const availableOptions = sharedAgendaDataOptions.filter(
							(option) => !selectedValues.includes(option.value)
						);

						return {
							label: {
								children:
									index > 0
										? t('formular.dalsi-sdilene-udaje.label')
										: t('formular.sdilene-udaje.label'),
							},
							field: {
								name: `sharedAgendaData.${index}`,
								options: [
									{
										label: t('formular.sdilene-udaje.default-label'),
										value: '',
									},
									...availableOptions,
								],
								isDisabled: isDisabled || availableOptions.length === 0,
								disabled: isDisabled || availableOptions.length === 0,
							},
						};
					}) ?? [
						{
							label: { children: t('formular.sdilene-udaje.label') },
							field: {
								name: 'sharedAgendaData.0',
								options: [
									{
										label: t('formular.sdilene-udaje.default-label'),
										value: '',
									},
									...sharedAgendaDataOptions,
								],
								isDisabled: isDisabled || sharedAgendaDataOptions.length === 0,
								disabled: isDisabled || sharedAgendaDataOptions.length === 0,
							},
						},
					]),
				],
				fromAgendaId: {
					...form.fields.fromAgendaId,
					field: {
						...form.fields.fromAgendaId.field,
						options: [
							{ label: t('formular.agenda-spravce.default-label'), value: '' },
							...fromAgendaOptions,
						],
						isDisabled: isDisabled || fromAgendaOptions.length === 0,
						disabled: isDisabled || fromAgendaOptions.length === 0,
					},
				},
				toAgendaId: {
					...form.fields.toAgendaId,
					field: {
						...form.fields.toAgendaId.field,
						options: [
							{ label: t('formular.agenda-prijemce.default-label'), value: '' },
							...toAgendaOptions,
						],
						isDisabled: isDisabled || toAgendaOptions.length === 0,
						disabled: isDisabled || toAgendaOptions.length === 0,
					},
				},
			},
		},
	};
};
