import { createContext, PropsWithChildren, useContext } from 'react';
import {
	rezaTemplate,
	RezaSablonaDto,
	usePoQuery,
	rezaTemplatePortalInfoQuery,
	RezaPortalInfo,
	isResponseStatus,
	isCorsError,
	ignoreErrors,
} from '@gov-nx/api/portal-obcana';
import { useAppLanguage } from '@gov-nx/core/hooks';
import { toDateReadable } from '@gov-nx/utils/common';
import { useRezaLocale } from '../../useRezaLocale';

interface RezaTemplateDetailContexts {
	isLoading: boolean;
	template?: RezaSablonaDto;
	keyValues: Record<string, string>;
	portalInfo?: RezaPortalInfo;
}

const Context = createContext<RezaTemplateDetailContexts | null>(null);

type Props = PropsWithChildren<{ id: string }>;

export const RezaTemplateDetailContextProvider = ({ children, id }: Props) => {
	const { language } = useAppLanguage();
	const { t } = useRezaLocale();
	const query = usePoQuery({
		queryKey: ['reza', 'templates', id],
		queryFn: async () => rezaTemplate(id),
	});

	const portalInfoQuery = usePoQuery(
		{
			queryKey: ['reza', 'portal-info', language, id],
			queryFn: async () => rezaTemplatePortalInfoQuery(id, language),
			retry: false,
		},
		{
			errorIgnoreFilter: ignoreErrors(isCorsError, isResponseStatus(404)),
		}
	);

	const keyValues = {
		[t('sablony.detail.table.identifikator')]: query.data?.kod ?? '-',
		[t('sablony.detail.table.verze')]: '-',
		[t('sablony.detail.table.platnost-od')]: query.data?.platnostOd
			? toDateReadable(query.data?.platnostOd)
			: '-',
		[t('sablony.detail.table.platnost-do')]: query.data?.platnostDo
			? toDateReadable(query.data?.platnostDo)
			: t('sablony.detail.table.platnost-do-neomezeno'),
		[t('sablony.detail.table.popis')]: query.data?.popis ?? '-',
		[t('sablony.detail.table.postupitelny.klic')]: query.data?.postupitelny
			? t('sablony.detail.table.postupitelny.ano')
			: t('sablony.detail.table.postupitelny.ne'),
	};

	return (
		<Context.Provider
			value={{
				isLoading: query.isLoading || portalInfoQuery.isLoading,
				template: query.data,
				keyValues,
				portalInfo: portalInfoQuery.data,
			}}>
			{children}
		</Context.Provider>
	);
};

export const useRezaTemplateDetailContext = () => {
	const context = useContext(Context);
	if (!context) {
		throw new Error(
			'useRezaTemplateDetailContext must be used within a RezaTemplateDetailContextProvider'
		);
	}
	return context;
};
