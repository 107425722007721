import { pageRouteResolver } from '@gov-nx/web';
import React from 'react';
import { Link } from 'react-router-dom';
import { OsobySouhlasDto } from '@gov-nx/api/portal-obcana';
import { useLocale } from '@gov-nx/core/service';
import { PageCode, useSpravaSouhlasuSeznamContext } from '@gov-nx/module/page';
import {
	Button,
	ContentLayout,
	ErrorStatusBanner,
	InfoBanner,
} from '@gov-nx/ui/web';
import { RecallConsentPrompt } from '../RecallConsentPrompt';
import { ConsentManagementSkeleton } from './ConsentManagementSkeleton';

interface ConsentManagementProps {
	code: PageCode;
}

interface RecallButtonProps {
	item: OsobySouhlasDto;
}

const RecallButton = ({ item }: RecallButtonProps) => {
	const { setControls, code } = useSpravaSouhlasuSeznamContext();
	const { t } = useLocale(code);

	return (
		<Button
			onClick={() => setControls({ consentToRecall: item })}
			wcagLabel={t('akce.tlacitko.wcag.odvolat-souhlas')}
			nativeType="button"
			variant="error"
			size="s"
			type="base"
			focusable={false}>
			{t('akce.tlacitko.odvolat-souhlas')}
		</Button>
	);
};

export const ConsentManagementView = ({ code }: ConsentManagementProps) => {
	const { consentData, controls, handleSubmit, setControls } =
		useSpravaSouhlasuSeznamContext();
	const { t } = useLocale(code);

	if (controls.initialLoading) {
		return <ConsentManagementSkeleton />;
	}

	if (controls.initialError) {
		return <ErrorStatusBanner code={code} />;
	}

	if (Array.isArray(consentData) && consentData.length === 0) {
		return (
			<InfoBanner
				icon={{ name: 'empty-file', type: 'colored' }}
				type="secondary">
				<p className={'text-2xl'}>{t('stav.chyba.zadny-zaznam')}</p>
				<p>{t('stav.chyba.nic-jsme-nenasli')}</p>
			</InfoBanner>
		);
	}

	return (
		<ContentLayout>
			<ul className={'gov-list--plain'}>
				{consentData?.map((item) => (
					<li
						key={[item.id, item.ico].join(',')}
						className={
							'-mx-5 py-6 px-4 border-b border-secondary-500 [ last-of-type:border-b-0 ] [ md:mx-0 ]'
						}>
						<div className={'flex justify-between'}>
							<Link
								to={pageRouteResolver('sprava-souhlasu.detail', {
									consentId: item.id,
								})}>
								<h3
									className={
										'inline-block pr-6 mb-2 text-xl underline [ hover:no-underline ]'
									}>
									{item.nazevOsoby}
								</h3>
							</Link>
							<div className={'hidden [ sm:block ]'}>
								<RecallButton item={item} />
							</div>
						</div>
						<p className={'block text-secondary-700 [ sm:inline ]'}>
							{t('pole.udaje.id-souhlasu')}: <strong>{item.id}</strong>
						</p>
						<div className={'pt-2 block [ sm:hidden ]'}>
							<RecallButton item={item} />
						</div>
					</li>
				))}
			</ul>
			<RecallConsentPrompt
				isOpen={!!controls.consentToRecall}
				isConsentRecalling={controls.processLoading}
				onConsentRecallConfirm={() => handleSubmit(controls.consentToRecall)}
				onClose={() => setControls({ consentToRecall: null })}
			/>
		</ContentLayout>
	);
};
