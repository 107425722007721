import { useQueryClient } from '@tanstack/react-query';
import { DataMessage } from '@gov-nx/module/page';
import { useBoolean } from '@gov-nx/utils/common';
import { invalidateMySubmissionDetailQuery } from './queries';
import {
	ConnectToAnotherSubmissionMutation,
	useConnectToAnotherSubmissionMutation,
} from './useConnectToAnotherSubmissionMutation';
import { useDisconnectFromSubmissionMutation } from './useDisconnectFromSubmissionMutation';

export interface SubmissionConnection {
	disconnect: (message: DataMessage) => void;
	isDisconnected: boolean;
	isDisconnecting: boolean;
	connect: ConnectToAnotherSubmissionMutation;
}

interface Props {
	submissionId: number;
}

export const useSubmissionConnection = ({
	submissionId,
}: Props): SubmissionConnection => {
	const queryClient = useQueryClient();

	const isDisconnected = useBoolean();

	const disconnectMutation = useDisconnectFromSubmissionMutation(
		isDisconnected.setTrue
	);
	const connectMutation = useConnectToAnotherSubmissionMutation(async () => {
		await invalidateMySubmissionDetailQuery(queryClient, submissionId);
		isDisconnected.setFalse();
	});

	return {
		disconnect: (variables) =>
			disconnectMutation.mutate({ ...variables, submissionId }),
		isDisconnected: isDisconnected.value,
		isDisconnecting: disconnectMutation.isLoading,
		connect: connectMutation,
	};
};
