import { JSX } from '@gov-design-system-ce/components/dist/types/components';
import { useTranslation } from 'react-i18next';
import {
	CommonSettingKey,
	FavoriteCode,
	saveUserSettingQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import { PageCode } from '@gov-nx/module/page';
import { useUserStore } from '@gov-nx/store/portal-obcana';
import { isPageCode } from '../../../../module/page/src/types/pageTypeHelpers';

const favoriteCodeToI18nKeypart = (code: FavoriteCode) =>
	isPageCode(code) ? 'stranka' : 'sluzba';

const blacklistedCodes: FavoriteCode[] = [
	PageCode['podpora'],
	PageCode['uzivatelska-prirucka'],
];

export interface FavoriteToggleCommonProps {
	code: FavoriteCode;
	name?: string;
	size?: JSX.GovButton['size'];
	withLabel?: boolean;
	disabled?: boolean;
}

export const useFavoriteToggle = ({
	code,
	name,
}: FavoriteToggleCommonProps) => {
	const poUserFavorites = useUserStore('poUserFavorites');
	const setUserSettingFavorites = useUserStore('setUserSettingFavorites');
	const favorites = poUserFavorites();
	const isFavorite = favorites.includes(code);

	const { t } = useTranslation();
	const { toastMessageSuccess } = useMessageEvents();

	const i18nStaticParts = ['uzivatel', 'oblibene'];
	const i18nLabelVariableParts = [
		favoriteCodeToI18nKeypart(code),
		isFavorite ? 'odebrat' : 'pridat',
	];

	const label = t([...i18nStaticParts, ...i18nLabelVariableParts].join('.'));
	const ariaLabel = t(
		[...i18nStaticParts, 'wcag', ...i18nLabelVariableParts].join('.'),
		{ name }
	);

	const toggleFavorites = (): string[] =>
		isFavorite
			? favorites.filter((item) => item !== code)
			: [...favorites, code];

	const mutation = usePoMutation({
		mutationFn: async (): Promise<boolean> => {
			const toggled = toggleFavorites();
			await saveUserSettingQuery({
				key: CommonSettingKey.Favorites,
				value: toggled,
			});
			setUserSettingFavorites(toggled);
			return toggled.includes(code);
		},
		onSuccess: async (updatedIsFavorite) => {
			const i18nToastParts = [
				...i18nStaticParts,
				favoriteCodeToI18nKeypart(code),
				updatedIsFavorite ? 'pridano' : 'odebrano',
			];
			toastMessageSuccess(t(i18nToastParts.join('.')));
		},
	});

	return {
		isEnabled: !blacklistedCodes.includes(code),
		label,
		ariaLabel,
		isFavorite,
		toggle: mutation.mutate,
		isSaving: mutation.isLoading,
	};
};
