import { useCallback } from 'react';
import {
	fetchUserSettingsQuery,
	isUnauthorizedResponse,
	NastaveniSeznamApiActualDto,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { PoIdentityType, useIdentity } from '@gov-nx/auth/common';
import { LocalizationLanguage, useAppContext } from '@gov-nx/core/app';
import { Nullable } from '@gov-nx/core/types';
import { useUserStore } from '@gov-nx/store/portal-obcana';
import { getFavoritesFromDTO } from '@gov-nx/utils/common';
import { useUserLanguageLoader } from './userLanguageLoader';

const queryKey = ['user-settings-app-portal-obcana'];

interface UserSettingsLoader {
	persistLanguage: (language: LocalizationLanguage) => Promise<void>;
	initUserSettings: () => Promise<void>;
}

export const useUserSettingsLoader = (): UserSettingsLoader => {
	const { setAppError } = useAppContext();
	const setUserSettingFavorites = useUserStore('setUserSettingFavorites');
	const setUserSettingLanguage = useUserStore('setUserSettingLanguage');
	const poIdentityContext = useIdentity() as Nullable<PoIdentityType>;
	const { updateLanguage } = useUserLanguageLoader();

	const persistLanguage = async (language: LocalizationLanguage) => {
		setUserSettingLanguage(language);

		if (poIdentityContext?.isAuthenticated) {
			updateLanguage(language);
		}
	};

	const initQuery = usePoQuery({
		queryFn: async (): Promise<NastaveniSeznamApiActualDto> => {
			const data = await fetchUserSettingsQuery();

			const favorites = data.seznam
				? getFavoritesFromDTO(data.seznam)
				: undefined;
			if (favorites) {
				setUserSettingFavorites(favorites.value);
			}

			return data;
		},
		onError: (error) => {
			if (isUnauthorizedResponse(error) === false) {
				setAppError(error as Error);
			}
		},
		queryKey,
		enabled: false,
		retry: 1,
	});

	const fetchUserSettings = useCallback(async (): Promise<void> => {
		await initQuery.refetch();
	}, [initQuery]);

	return { initUserSettings: fetchUserSettings, persistLanguage };
};
