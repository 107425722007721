import { useMemo } from 'react';
import {
	stripHTMLTagsAndGetParagraphs,
	useBoolean,
} from '@gov-nx/utils/common';

export const useTruncatedText = (
	text: string,
	maxCharacters = 400,
	maxParagraphs = 3
) => {
	const expanded = useBoolean(false);

	const paragraphs = stripHTMLTagsAndGetParagraphs(text);

	const { displayedParagraphs, shouldShowToggle, hiddenParagraphsCount } =
		useMemo(() => {
			const truncatedResult: string[] = [];
			let totalChars = 0;
			let needsTruncation = false;

			if (
				paragraphs.length > maxParagraphs ||
				paragraphs.join('').length > maxCharacters
			) {
				needsTruncation = true;
			}

			if (expanded.value || !needsTruncation) {
				return {
					displayedParagraphs: paragraphs,
					shouldShowToggle: needsTruncation,
					hiddenParagraphsCount: 0,
				};
			}

			for (const paragraph of paragraphs) {
				if (totalChars >= maxCharacters) {
					break;
				}

				const remainingChars = maxCharacters - totalChars;

				if (totalChars + paragraph.length <= maxCharacters) {
					truncatedResult.push(paragraph);
					totalChars += paragraph.length;
				} else {
					truncatedResult.push(paragraph.slice(0, remainingChars) + '...');
					break;
				}

				if (truncatedResult.length >= maxParagraphs) {
					break;
				}
			}

			return {
				displayedParagraphs: truncatedResult,
				shouldShowToggle: needsTruncation,
				hiddenParagraphsCount: paragraphs.length - truncatedResult.length,
			};
		}, [paragraphs, maxCharacters, maxParagraphs, expanded.value]);

	const toggleExpand = () => expanded.setValue(!expanded.value);

	return {
		displayedParagraphs,
		shouldShowToggle,
		hiddenParagraphsCount,
		expanded,
		toggleExpand,
	};
};
