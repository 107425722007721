import { useCallback } from 'react';
import { SecurityExtendedUserInfoDto } from '@gov-nx/api/portal-obcana';
import { useAuthEvents } from '@gov-nx/core/events';
import { useUserLoginInfo } from '@gov-nx/core/hooks';
import { useAuthStore } from '@gov-nx/store/portal-obcana';
import { useAuthMeQuery } from './useAuthMeQuery';
import { useAuthNiaRegisterQuery } from './useAuthNiaRegisterQuery';

export const useIdentity = (): PoIdentityType => {
	const { fetch: userMeQuery } = useAuthMeQuery();
	const { fetch: authNiaRegisterQuery } = useAuthNiaRegisterQuery();
	const { userLogout } = useAuthEvents();
	const loggedUser = useAuthStore('user');
	const accessToken = useAuthStore('token');
	const isLoggedIn = useAuthStore('isLoggedIn');
	const getUserLoginType = useAuthStore('getUserLoginType');

	const isAuthenticated = isLoggedIn();
	const loginType = getUserLoginType();
	const isNiaLoginType = loginType ? loginType.toUpperCase() === 'NIA' : false;
	const isDataBoxLoginType = loginType
		? loginType.toUpperCase() === 'DS'
		: false;
	const { isLowLoa, isSubstantialLoa, isHighLoa } = useUserLoginInfo();

	const fetchMe = useCallback(async (): Promise<void> => {
		return new Promise((resolve, reject) => userMeQuery({ resolve, reject }));
	}, [userMeQuery]);

	const poLogin = useCallback(
		async (niaToken: string): Promise<void> => {
			// eslint-disable-next-line no-console
			console.log('PO LOGIN');
			return new Promise((resolve, reject) =>
				authNiaRegisterQuery(niaToken, { resolve, reject })
			);
		},
		[authNiaRegisterQuery]
	);

	return {
		fetchMe,
		isAuthenticated,
		accessToken,
		loggedUser,
		doLogout: userLogout,
		poLogin,
		isNiaLoginType,
		isDataBoxLoginType,
		isLowLoa,
		isSubstantialLoa,
		isHighLoa,
	};
};

export type PoIdentityType = {
	isAuthenticated: boolean;
	loggedUser: SecurityExtendedUserInfoDto | undefined;
	accessToken: string | undefined;
	fetchMe: () => Promise<void>;
	poLogin: (niaToken: string) => Promise<void>;
	doLogout: () => void;
	isNiaLoginType: boolean;
	isDataBoxLoginType: boolean;
	isLowLoa: boolean;
	isSubstantialLoa: boolean;
	isHighLoa: boolean;
};
