import { Optional } from './helpers.types';

export enum FilterSortDirection {
	DESC = 'DESC',
	ASC = 'ASC',
}

export interface FilterParam {
	label: string;
	value: Optional<string | number | boolean>;
	isActive: boolean;
	onRemove: () => void;
}
