import {
	EmbassyOfficeFields,
	EmbassyOfficeValues,
	useEmbassyOfficeFields,
	useEmbassyOfficeSelects,
} from '@gov-nx/core/hooks';
import { FormDefinition2, useFormBuilder } from '@gov-nx/core/service';
import { useKorespondencniVolbyLocale } from '@gov-nx/module/service';
import { FormInputProps } from '@gov-nx/ui/types';
import { useInputDefinition } from '@gov-nx/utils/common';

export interface KorespondencniHlasovaniZapisStep2 extends EmbassyOfficeValues {
	street: string;
	city: string;
	zip: string;
	country: string;
}

interface Fields extends EmbassyOfficeFields {
	street: FormInputProps;
	city: FormInputProps;
	zip: FormInputProps;
	country: FormInputProps;
}

export type KorespondencniHlasovaniZapisFormStep2 = FormDefinition2<
	KorespondencniHlasovaniZapisStep2,
	Fields
>;

export const useFormStep2 = ({ isDisabled }: { isDisabled: boolean }) => {
	const { t } = useKorespondencniVolbyLocale();

	const street = useInputDefinition({
		name: 'street',
		label: t('formular.ulice.label'),
		placeholder: t('formular.ulice.placeholder'),
		isDisabled,
		isRequired: true,
		isRequiredMessage: t('formular.ulice.validace.povinny'),
	});
	const city = useInputDefinition({
		name: 'city',
		label: t('formular.mesto.label'),
		placeholder: t('formular.mesto.placeholder'),
		isDisabled,
		isRequired: true,
		isRequiredMessage: t('formular.mesto.validace.povinny'),
	});
	const zip = useInputDefinition({
		name: 'zip',
		label: t('formular.psc.label'),
		placeholder: t('formular.psc.placeholder'),
		isDisabled,
		isRequired: true,
		isRequiredMessage: t('formular.psc.validace.povinny'),
	});
	const country = useInputDefinition({
		name: 'country',
		label: t('formular.stat.label'),
		placeholder: t('formular.stat.placeholder'),
		isDisabled,
		isRequired: true,
		isRequiredMessage: t('formular.stat.validace.povinny'),
	});

	const { isLoading, fields } = useEmbassyOfficeFields({ isDisabled });

	const form = useFormBuilder<KorespondencniHlasovaniZapisStep2, Fields>([
		...fields,
		street,
		city,
		zip,
		country,
	]);

	const updatedForm = useEmbassyOfficeSelects(form, isDisabled);

	return {
		isLoading: isLoading,
		selectedOffice: updatedForm.selectedOffice,
		selectedDistrict: updatedForm.selectedDistrict,
		selectedCountry: updatedForm.selectedCountry,
		form: updatedForm.form,
	};
};
