import { GovIcon, GovPagination } from '@gov-design-system-ce/react';
import { pageRouteResolver, useScroll } from '@gov-nx/web';
import { FormProvider } from 'react-hook-form';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { addWhen, pipe } from '@gov-nx/core/types';
import { useRegistrSVSeznamContext } from '@gov-nx/module/page';
import {
	Button,
	ContentLayout,
	ErrorStatusBanner,
	FilterParameterList,
	FormBadges,
	FormInput,
	FormWidthElementLayout,
	HeaderLayout,
	InfoBanner,
	KeyValues,
	Tile,
	Tiles,
} from '@gov-nx/ui/web';
import { PoFormWeb } from '../../../Form/PoForm';
import { FilterModal } from './FilterModal';
import {
	RegisterSVListAndFilterSkeleton,
	RegisterSVListSkeleton,
} from './RegisterSVListSkeleton';

export const RegisterSVListView = () => {
	const {
		code,
		controls,
		totalFilteredVehicles,
		vehiclesPaginated,
		showNoVehiclesBanner,
		vehicleGetters,
		formDefinition,
		openFilterModal,
		onSubmit,
		onReset,
		filterParams,
		ico,
		filter,
		setPartialFilter,
	} = useRegistrSVSeznamContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const { scrollToTop } = useScroll();
	const tsn = getLocalizeCurried(code);

	if (controls.initialLoading) {
		return <RegisterSVListAndFilterSkeleton />;
	}

	if (controls.initialError) {
		return <ErrorStatusBanner code={code} />;
	}

	return (
		<ContentLayout>
			<FormProvider {...formDefinition.formMethods}>
				<PoFormWeb
					formDefinition={formDefinition}
					onSubmit={onSubmit}>
					<FilterModal />

					<HeaderLayout
						filterParams={
							<FilterParameterList
								items={filterParams}
								onReset={onReset}
							/>
						}>
						<FormWidthElementLayout
							type="l"
							className={'relative'}>
							<FormInput
								field={{
									name: 'filterText',
									placeholder: tsn('formular.placeholder.filter-text'),
									wcagLabelledBy: 'vyhledat-vozidlo',
								}}
								label={{
									children: tsn('formular.pole.filter-text'),
								}}
							/>
							<label
								id="vyhledat-vozidlo"
								className={'hidden'}>
								{tsn('filtr.pole.wcag.vyhledat-vozidlo')}
							</label>
							<div className={'absolute top-2 right-1'}>
								<Button
									nativeType={'submit'}
									variant="primary"
									type="solid"
									size="s"
									wcagLabel={tsn('akce.wcag.vyhledat')}>
									<GovIcon
										name={'search'}
										type={'basic'}
										slot="left-icon"></GovIcon>
								</Button>
							</div>
						</FormWidthElementLayout>

						<Button
							onClick={openFilterModal}
							variant="primary"
							type="outlined"
							className={'ml-auto'}
							wcagLabel={tsn('akce.wcag.zobrazit-filtr')}>
							<GovIcon
								name={'funnel-fill'}
								type={'basic'}></GovIcon>
						</Button>
					</HeaderLayout>

					<FormBadges
						field={{
							name: 'filterVehicleStatus',
						}}
						options={[
							{
								name: 'actual',
								label: tsn('formular.pole.filtr-aktualnost-aktualni'),
							},
							{
								name: 'historical',
								label: tsn('formular.pole.filtr-aktualnost-historie'),
							},
						]}
					/>

					{controls.resultsLimitExceeded && (
						<InfoBanner
							icon={{ name: 'cactus', type: 'colored' }}
							type="secondary"
							className={'!px-0 !py-4'}>
							{controls.resultsLimitExceeded}
						</InfoBanner>
					)}
				</PoFormWeb>
			</FormProvider>
			{controls.processLoading ? (
				<RegisterSVListSkeleton />
			) : showNoVehiclesBanner ? (
				<InfoBanner
					icon={{ name: 'empty-file', type: 'colored' }}
					type="secondary"
					className={'!p-0 !pb-4'}>
					<p className={'text-3xl'}>
						{tsn('formular.zprava.nemate-zadne-vozidlo.titulek')}
					</p>
					<p>{tsn('formular.zprava.nemate-zadne-vozidlo.popis')}</p>
				</InfoBanner>
			) : (
				<>
					<Tiles>
						{vehiclesPaginated.map(vehicleGetters).map((vehicle) => {
							return (
								<Tile
									key={vehicle.id}
									name={vehicle.name()}
									to={pipe(
										pageRouteResolver('registr-SV.detail', {
											vehicleId: vehicle.id,
										}),
										(url) => (ico ? `${url}?ico=${ico}` : url)
									)}
									icon={{ name: 'chevron-right', type: 'basic' }}>
									<p className="text-secondary-700">
										<KeyValues
											options={pipe(
												[
													{
														label: tsn('formular.klic.rz'),
														value: vehicle.rz(),
													},
												],
												addWhen(vehicle.isActive(), {
													label: tsn('formular.klic.status'),
													value: vehicle.statusName(),
												})
											)}
										/>
									</p>
								</Tile>
							);
						})}
					</Tiles>
					{totalFilteredVehicles > filter.pageSize ? (
						<GovPagination
							pageSize={filter.pageSize}
							current={filter.page}
							onGov-page={(event) => {
								scrollToTop();
								setPartialFilter({ page: event.detail.pagination.currentPage });
							}}
							total={totalFilteredVehicles}
						/>
					) : null}
				</>
			)}
		</ContentLayout>
	);
};
