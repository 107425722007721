import {
	form602SubmissionDeleteQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';

interface Form602SubmissionDeleteQueryProps {
	formularPodaniId: string;
	onSuccess?: () => void;
}

export const useForm602SubmissionDeleteQuery = (
	props: Form602SubmissionDeleteQueryProps
) => {
	const mutation = usePoMutation({
		mutationFn: form602SubmissionDeleteQuery,
		onSuccess: () => {
			props?.onSuccess && props.onSuccess();
		},
	});

	return {
		...mutation,
		deleteFormSubmission: () => mutation.mutate(props.formularPodaniId),
	};
};
