import { GovIcon } from '@gov-design-system-ce/react';
import cx from 'classnames';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import type { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import { Link } from 'react-router-dom';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import { findAndActivatePhoneNumbersInMarkdown } from '@gov-nx/utils/common';
import { UrlChecker, isUrlExternal } from '@gov-nx/utils/web';

type MarkdownRenderExtraProps = {
	/**
	 * Find phone numbers in content and convert to tel: links.
	 * The phone numbers must start with + sign to be detected.
	 */
	activatePhoneNumbers?: boolean;
	/** Apply custom classes to markdown elements. */
	applyClasses?: {
		paragraph?: string;
		link?: string;
	};
	/** Additional checker function to test if a link should open in new window. */
	checkIfUrlShouldOpenNewWindowWith?: UrlChecker;
	/** Disable GitHub Flavored Markdown plugin. */
	disableGithubFlavoredMarkdown?: boolean;
	/** Disable remark breaks plugin. */
	disableRemarkBreaks?: boolean;
	/** Disable wrapping with <div.markdown-wrapper> element. */
	disableWrapper?: boolean;
	/**
	 * URLs in plain text are automatically converted to <a> elements.
	 * When processing them, we render the link without the protocol visible to a user.
	 *  default: https://example.com => <a href="https://example.com">example.com</a>
	 *
	 * This option allows you to switch this behavior off.
	 *  when true: https://example.com => <a href="https://example.com">https://example.com</a>
	 */
	includeProtocolInLinkLabels?: boolean;
	/**
	 * Override used plugins or disable all with an empty array.
	 *
	 * Note that disableRemarkBreaks and disableGithubFlavoredMarkdown
	 * have no effect if this property is set.
	 */
	remarkPlugins?: ReactMarkdownOptions['remarkPlugins'];
	/**
	 * CSS class/es to apply to wrapping <div> element. "markdown-wrapper" class is always applied.
	 * Ignored if disableWrapper is set to true.
	 */
	wrapperClassName?: string;
	testId?: string;
	id?: string;
};

type PoMarkdownRenderWebProps = ReactMarkdownOptions & MarkdownRenderExtraProps;

/**
 * Components using remark/react-markdown transparently.
 *
 * @remarks KNOWN INCOMPABILITY:
 * Usage inside of GovAccordionItem with custom paragraph classes.
 *   => throws NotFoundError: Node.removeChild: The node to be removed is not a child of this node.
 */
export const MarkdownRender: React.FC<PoMarkdownRenderWebProps> = (
	renderProps
) => {
	const {
		activatePhoneNumbers,
		applyClasses,
		checkIfUrlShouldOpenNewWindowWith: extraUrlChecker,
		components,
		disableGithubFlavoredMarkdown,
		disableRemarkBreaks,
		disableWrapper,
		includeProtocolInLinkLabels,
		remarkPlugins,
		wrapperClassName,
	} = renderProps;

	const customComponentRenderers: ReactMarkdownOptions['components'] = {
		a: (props) => {
			if (!props.href) {
				// eslint-disable-next-line jsx-a11y/anchor-has-content
				return <a {...props} />;
			}

			const { href, children } = props;

			let linkLabel: string | null = null;

			// [https://example.com](https://example.com) => <a href="https://example.com">example.com</a>
			const hrefEqualsLabel = href === `${children}`;
			if (hrefEqualsLabel && !includeProtocolInLinkLabels) {
				linkLabel = `${children}`.replace(/^https?:\/\//i, '');
			}

			if (isUrlExternal(href, extraUrlChecker)) {
				return (
					<a
						className={cx('gov-link inline-flex gap-2', applyClasses?.link)}
						href={href}
						target="_blank"
						rel="noreferrer">
						{linkLabel || children}
						<GovIcon
							name="box-arrow-up-right"
							className={'w-4'}
						/>
					</a>
				);
			}

			return (
				<Link
					className={cx('gov-link', applyClasses?.link)}
					to={href}>
					{linkLabel || children}
				</Link>
			);
		},
	};

	if (applyClasses?.paragraph) {
		customComponentRenderers.p = (props) => (
			<p className={applyClasses?.paragraph}>{props.children}</p>
		);
	}

	let contents = renderProps.children;
	if (activatePhoneNumbers && typeof contents === 'string') {
		contents = findAndActivatePhoneNumbersInMarkdown(contents);
	}

	const finalProps = {
		...renderProps,

		skipHtml: true,
		remarkPlugins: remarkPlugins ?? [
			...(disableRemarkBreaks ? [] : [remarkBreaks]),
			...(disableGithubFlavoredMarkdown ? [] : [remarkGfm]),
		],
		components: {
			...customComponentRenderers,
			...components,
		},

		children: contents,
	};

	if (disableWrapper) {
		return <ReactMarkdown {...finalProps} />;
	}

	return (
		<div
			className={cx('markdown-wrapper', wrapperClassName)}
			id={finalProps.id}
			data-testid={finalProps.testId}>
			<ReactMarkdown {...finalProps} />
		</div>
	);
};
