import { GovButton, GovPrompt } from '@gov-design-system-ce/react';
import { saveAs } from 'file-saver';
import React from 'react';
import { OnSaveParams } from '@gov-nx/component/common';
import { useGlobalLocale } from '@gov-nx/core/service';
import { DownloadListenerCore } from './DownloadListenerCore';

export const DownloadListener = () => {
	const onSave = async ({ blob, fileName }: OnSaveParams) =>
		saveAs(blob, fileName);
	const { t } = useGlobalLocale();

	return (
		<DownloadListenerCore
			onSave={onSave}
			onAntivirus={({ onAccept, onDeny, fileName }) => {
				// @TODO: GovPrompt se nezobrazi pri startu, lepsi zkouset s obyc html
				// return <div>
				// 	<div>Opravdu chcete stáhnout soubor "{fileName}" ?</div>
				// 	<button onClick={onAccept}>Stáhnout</button>
				// 	<button onClick={onDeny}>Zrušit</button>
				// </div>
				return (
					<GovPrompt
						id="antivirus-check"
						label={t('antivirus.text.problem-s-antivirem')}
						onGov-close={onDeny}
						open={true}>
						{t('antivirus.text.opravdu-chcete-stahnout', { fileName })}
						<GovButton
							variant="error"
							type="solid"
							slot="actions"
							onGov-click={onAccept}>
							{t('antivirus.action.stahnout')}
						</GovButton>
						<GovButton
							variant="primary"
							type="base"
							slot="actions"
							onGov-click={onDeny}>
							{t('antivirus.action.zrusit')}
						</GovButton>
					</GovPrompt>
				);
			}}
		/>
	);
};
