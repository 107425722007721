import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useEPeticeDetailContext } from '@gov-nx/module/page';
import { Button, ContentLayout, Prompt } from '@gov-nx/ui/web';
import { MarkdownRender } from '../../../Markdown/MarkdownRender';

export interface EPetitionDetailViewProps {
	code: PageCode;
}

export const EPetitionSign = ({ code }: EPetitionDetailViewProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const { petition, controls, setControls, ePetitionSign, loginInfo } =
		useEPeticeDetailContext();

	return (
		<>
			{loginInfo.isDataBoxLoginType ? (
				<GovMessage variant={'warning'}>
					<GovIcon
						slot={'icon'}
						type={'basic'}
						name={'info'}
					/>
					<MarkdownRender>{ts('prihlaseni.datovka-varovani')}</MarkdownRender>
				</GovMessage>
			) : null}
			<ContentLayout customClasses={'flex flex-col gap-8'}>
				<div
					className={
						'flex flex-col gap-4 [ md:flex-row md:justify-between md:items-center ]'
					}>
					<h4 className={'text-2xl text-secondary-800'}>
						{ts('podpis.aktualni-pocet-podpisu')} {petition?.pocetPodpisu}
					</h4>
					{ePetitionSign.isFounder ? (
						<Button
							variant={'primary'}
							type={'solid'}
							disabled={ePetitionSign.isFounder}
							wcagLabel={ts('akce.wcag.podepsat')}>
							{ts('akce.podepsat')}
						</Button>
					) : null}
					{ePetitionSign.canSign ? (
						<Button
							variant={'primary'}
							type={'solid'}
							onClick={() => setControls({ isSignModal: true })}
							wcagLabel={ts('akce.wcag.podepsat')}>
							{ts('akce.podepsat')}
						</Button>
					) : null}
					{ePetitionSign.canRevoke ? (
						<Button
							variant={'primary'}
							type={'outlined'}
							onClick={() => setControls({ isRevokeModal: true })}
							wcagLabel={ts('akce.wcag.odvolat')}>
							{ts('akce.odvolat')}
						</Button>
					) : null}
				</div>
				{ePetitionSign.isFounder ? (
					<GovMessage variant={'primary'}>
						<GovIcon
							slot={'icon'}
							type={'basic'}
							name={'lightbulb-fill'}
						/>
						{ts('info.automaticky-podpis')}
					</GovMessage>
				) : null}

				<Prompt
					label={ts('podpis.podepsat-petici')}
					open={controls.isSignModal}
					onGov-close={() => setControls({ isSignModal: false })}
					wcagCloseLabel={ts('akce.wcag.zavrit')}>
					<p dangerouslySetInnerHTML={{ __html: ts('podpis.proc-podepsat') }} />
					<div
						slot={'actions'}
						className={'flex justify-end gap-4'}>
						{controls.isSignModal ? (
							<>
								<Button
									disabled={controls.isSignProcessing}
									loading={controls.isSignProcessing}
									onClick={() => {
										setControls({ isSignProcessing: true });
										ePetitionSign.signQuery.mutate(petition?.id as number);
									}}
									variant={'primary'}
									type={'solid'}
									wcagLabel={`${ts('akce.wcag.souhlasim')} ${petition?.nazev}`}>
									{ts('akce.souhlasim')}
								</Button>
								<Button
									disabled={controls.isSignProcessing}
									onClick={() => setControls({ isSignModal: false })}
									variant={'error'}
									type={'outlined'}
									wcagLabel={ts('akce.wcag.nesouhlasim')}>
									{ts('akce.nesouhlasim')}
								</Button>
							</>
						) : null}
					</div>
				</Prompt>

				<Prompt
					label={ts('podpis.odvolat-souhlas')}
					open={controls.isRevokeModal}
					onGov-close={() => setControls({ isRevokeModal: false })}
					wcagCloseLabel={ts('akce.wcag.zavrit')}>
					<p dangerouslySetInnerHTML={{ __html: ts('podpis.proc-odvolat') }} />
					<div
						slot={'actions'}
						className={'flex justify-end gap-4'}>
						{controls.isRevokeModal ? (
							<>
								<Button
									disabled={controls.isRevokeProcessing}
									loading={controls.isRevokeProcessing}
									onClick={() => {
										setControls({ isRevokeProcessing: true });
										ePetitionSign.revokeQuery.mutate(petition?.id as number);
									}}
									variant={'primary'}
									type={'solid'}
									wcagLabel={`${ts('akce.wcag.odvolat')} ${petition?.nazev}`}>
									{ts('akce.odvolat')}
								</Button>
								<Button
									disabled={controls.isRevokeProcessing}
									onClick={() => setControls({ isRevokeModal: false })}
									variant={'error'}
									type={'outlined'}
									wcagLabel={ts('akce.wcag.zrusit')}>
									{ts('akce.zrusit')}
								</Button>
							</>
						) : null}
					</div>
				</Prompt>
			</ContentLayout>
		</>
	);
};
