import { has, Optional } from '@gov-nx/core/types';
import { NotificationDto } from '@gov-nx/module/page';
import { axiosInstance } from '../axios';
import { OznameniOznameniSeznamDto } from '../generated';

export interface NotificationsFilter {
	razeni: Optional<NotificationsOrder>;
	start: number;
	pocet: number;
}

export enum NotificationsOrder {
	'-datumACasVytvoreni' = '-datumACasVytvoreni',
}

export const notificationsQuery = async (
	filter: NotificationsFilter
): Promise<{ seznam: NotificationDto[]; pocet: number }> => {
	const { data } = await axiosInstance.get<OznameniOznameniSeznamDto>(
		`/api/v1/oznameni`,
		{
			params: filter,
			transformResponse: (response) => {
				const replaced = response.replaceAll(
					/("id":[0-9]+,)/g,
					(matched: string) => {
						const digits = matched.match(/\d+/)?.[0];
						if (!digits) return matched;

						return `"id":"${digits}",`; // long number to string
					}
				);
				return JSON.parse(replaced);
			},
		}
	);

	return {
		pocet: data.pocet ?? 0,
		seznam:
			data.seznam?.filter(has('id')).map((item) => ({
				...item,
				id: BigInt(item.id), // string to bigint
			})) ?? [],
	};
};

export const notificationsDetailQuery = async (
	id: bigint
): Promise<NotificationDto> => {
	const { data } = await axiosInstance.get(`/api/v1/oznameni/${id.toString()}`);
	return data;
};
