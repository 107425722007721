import * as yup from 'yup';
import {
	FormDefinition,
	getFormDefinition,
	usePoForm,
} from '@gov-nx/core/service';
import { FilterSortDirection } from '@gov-nx/core/types';
import { PageCode } from '../../definitions/codes';
import {
	DataRequest,
	PpdfConsentsListFilter,
	PpdfConsentsListFilterForm,
	PpdfConsentsListOrder,
} from './context.types';

export interface FormInstanceProps {
	code: PageCode;
	defaultValues: PpdfConsentsListFilterForm;
}

export function FormInstance({
	defaultValues,
}: FormInstanceProps): FormDefinition<PpdfConsentsListFilterForm> {
	const formSchema = yup
		.object({
			odvolano: yup.boolean().optional(),
			razeni: yup.string().optional(),
			razeniSmer: yup.string().optional(),
			hledat: yup.string().optional(),
		})
		.required();

	const formMethods = usePoForm<PpdfConsentsListFilterForm>({
		formSchema,
		defaultValues,
	});
	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData = (
	formData: PpdfConsentsListFilter
): DataRequest => {
	const order = (
		formData.razeniSmer === FilterSortDirection.DESC
			? '-' + formData.razeni
			: formData.razeni
	) as PpdfConsentsListOrder;

	return {
		params: {
			razeni: order,
			odvolano: formData.odvolano ?? false,
			start: formData.start,
			pocet: formData.pocet,
		},
	};
};
