import { AxiosError } from 'axios';
import { useEffect } from 'react';
import {
	OsobyFyzickaOsobaDto,
	ResponseError,
	robPersonQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { usePersonStore } from '@gov-nx/store/portal-obcana';

interface IndividualLoaderProps {
	onError?: (error: GovError) => void;
}

interface PoIndividualDataLoad {
	isLoading: boolean;
	individualPerson?: OsobyFyzickaOsobaDto;
	error?: AxiosError<ResponseError>;
}

export const usePoIndividualDataLoad = (
	props?: IndividualLoaderProps
): PoIndividualDataLoad => {
	const individualPerson = usePersonStore('individual');
	const setIndividualPersonData = usePersonStore('setIndividualPersonData');

	const query = usePoQuery({
		queryKey: ['osoby', 'fyzicke'],
		queryFn: robPersonQuery,
		onError: async (err) => {
			if (props?.onError) {
				return props.onError(new GovError(err.message));
			}
		},
		refetchOnWindowFocus: false,
		retry: 0,
		enabled: !individualPerson,
	});

	useEffect(() => {
		if (!individualPerson && query.data) {
			setIndividualPersonData(query.data);
		}
	}, [individualPerson, query.data]);

	return {
		individualPerson,
		isLoading: query.isInitialLoading,
		error: query.error ?? undefined,
	};
};
