import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { Optional, is } from '@gov-nx/core/types';
import { useDataBoxCredit } from '@gov-nx/module/data-box';
import { useBoolean } from '@gov-nx/utils/common';

interface LowCreditPromptProps {
	senderId: Optional<string>;
	recipient: Optional<DatovkaApiType>;
	messagePrice: number;
	onPromptClose: () => void;
}

export const useLowCreditPrompt = ({
	senderId,
	recipient,
	messagePrice,
	onPromptClose,
}: LowCreditPromptProps) => {
	const { credit } = useDataBoxCredit(senderId);
	const isLowCreditPrompt = useBoolean(false);
	const isLowCredit = is(credit) && credit < messagePrice;

	const isOpen =
		isLowCreditPrompt.value &&
		is(recipient) &&
		!recipient?.typSchranky?.startsWith('OVM') &&
		isLowCredit;

	const handleOpen = () => isLowCreditPrompt.setTrue();

	const handleClose = () => {
		isLowCreditPrompt.setFalse();
		onPromptClose();
	};

	return {
		isOpen,
		recipient,
		senderId,
		handleOpen,
		handleClose,
	};
};
