import { GovModal } from '@gov-design-system-ce/react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { PoFormWeb, MarkdownRender } from '@gov-nx/component/web';
import { ComplaintsForm, useRezaLocale } from '@gov-nx/module/page';
import { Button, FormInput, LayoutGap } from '@gov-nx/ui/web';

interface Props {
	complaintsForm: ComplaintsForm;
}

export const ComplaintsModal = ({ complaintsForm }: Props) => {
	const { t } = useRezaLocale();
	const { isModalOpen, onSubmit, fields, formDefinition } = complaintsForm;

	return (
		<FormProvider {...formDefinition.formMethods}>
			<PoFormWeb
				formDefinition={formDefinition}
				onSubmit={onSubmit}>
				<GovModal
					label={t('complaints.title')}
					open={isModalOpen.isTrue}
					onGov-close={isModalOpen.setFalse}>
					<LayoutGap>
						<MarkdownRender>{t('complaints.description')}</MarkdownRender>

						<FormInput {...fields.complaint} />

						<Button
							variant={'primary'}
							type={'solid'}
							nativeType={'submit'}
							{...fields.submit}>
							{fields.submit.children}
						</Button>
					</LayoutGap>
				</GovModal>
			</PoFormWeb>
		</FormProvider>
	);
};
