import { GovIcon, GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { LocalizeNameSpaceTypes, useLocale } from '@gov-nx/core/service';
import { ObjectOrArray, pipe } from '@gov-nx/core/types';
import { useRegistrSVDetailContext } from '@gov-nx/module/page';
import {
	Button,
	ContentLayout,
	ErrorStatusBanner,
	FormBadges,
	LayoutGap,
} from '@gov-nx/ui/web';
import { CmsPageRender } from '../../../Cms/CmsPageRender';
import { PoFormWeb } from '../../../Form/PoForm';
import { MarkdownRender } from '../../../Markdown/MarkdownRender';
import { RegisterSVDetailSkeleton } from './RegisterSVDetailSkeleton';
import { Table } from './Table';
import { TableChassis } from './TableChassis';
import { TableDimensions } from './TableDimensions';
import { TableMotor } from './TableMotor';
import { TableNoise } from './TableNoise';
import { TableOthers } from './TableOthers';
import { TableVehicle } from './TableVehicle';
import { TableWeight } from './TableWeight';

export const RegisterSVDetailView = () => {
	const {
		code,
		controls,
		formDefinition,
		vehicle,
		vehicleGetters,
		technicalInspectionURL,
		isLoading,
	} = useRegistrSVDetailContext();
	const { t } = useLocale(LocalizeNameSpaceTypes.Global);
	const { t: tsn } = useLocale(code);

	if (controls.initialError) {
		return <ErrorStatusBanner code={code} />;
	}

	const detailsFilter = formDefinition.formMethods.watch(
		'filterVehicleDetails'
	);
	const documentsFilter = formDefinition.formMethods.watch('filterDocuments');
	const getter = vehicle && vehicleGetters(vehicle);

	return (
		<CmsPageRender
			code={code}
			override={{ name: getter?.name() }}
			showDescription={false}>
			{isLoading && <RegisterSVDetailSkeleton />}
			{vehicle && (
				<FormProvider {...formDefinition.formMethods}>
					<PoFormWeb formDefinition={formDefinition}>
						<LayoutGap>
							<ContentLayout customClasses={'!pt-0 !pb-8 [ md:!pb-10 ]'}>
								<FormBadges
									field={{
										name: 'filterVehicleDetails',
									}}
									options={[
										{
											name: 'vehicle',
											label: tsn('formular.filtr.detail-vozidlo'),
										},
										{
											name: 'motor',
											label: tsn('formular.filtr.detail-motor'),
										},
										{
											name: 'dimensions',
											label: tsn('formular.filtr.detail-rozmety'),
										},
										{
											name: 'weight',
											label: tsn('formular.filtr.detail-hmotnost'),
										},
										{
											name: 'chassis',
											label: tsn('formular.filtr.detail-spojovaci-zarizeni'),
										},
										{ name: 'noise', label: tsn('formular.filtr.detail-hluk') },
										{
											name: 'others',
											label: tsn('formular.filtr.detail-ostatni'),
										},
									]}
								/>

								{detailsFilter.vehicle && getter && (
									<TableVehicle
										tsn={tsn}
										vozidloUdaje={vehicle.vozidloUdaje}
										getter={getter}
									/>
								)}

								{detailsFilter.motor && (
									<TableMotor
										tsn={tsn}
										vehicle={vehicle}
									/>
								)}

								{detailsFilter.dimensions && (
									<TableDimensions
										tsn={tsn}
										vehicle={vehicle}
									/>
								)}
								{detailsFilter.weight && (
									<TableWeight
										tsn={tsn}
										vehicle={vehicle}
									/>
								)}

								{detailsFilter.chassis && (
									<TableChassis
										tsn={tsn}
										vehicle={vehicle}
									/>
								)}
								{detailsFilter.noise && (
									<TableNoise
										tsn={tsn}
										vozidloUdaje={vehicle.vozidloUdaje}
									/>
								)}
								{detailsFilter.others && (
									<TableOthers
										tsn={tsn}
										vozidloUdaje={vehicle.vozidloUdaje}
									/>
								)}
							</ContentLayout>
							<ContentLayout customClasses={'!pt-0 !pb-8 [ md:!pb-10 ]'}>
								<FormBadges
									field={{
										name: 'filterDocuments',
									}}
									options={[
										{
											name: 'prehledRz',
											label: tsn('formular.filtr.detail-dokumenty-rz'),
										},
										{
											name: 'prehledTp',
											label: tsn('formular.filtr.detail-dokumenty-tp'),
										},
										{
											name: 'prehledOrv',
											label: tsn('formular.filtr.detail-dokumenty-orv'),
										},
										{
											name: 'vehicleStatus',
											label: tsn('formular.filtr.detail-vozidlo-status'),
										},
									]}
								/>

								{documentsFilter.prehledRz && (
									<>
										<Table
											data={pipe(
												getter?.documents('rz'),
												ObjectOrArray.array,
												(rz) =>
													rz?.map((rz, index) => {
														return [
															index === 0
																? tsn('tabulka.dokumenty.cislo-rz')
																: '',
															rz?.cisloRz?.xVal,
														];
													})
											)}
										/>
										<Table
											data={pipe(
												getter?.documentsHistory('rz'),
												ObjectOrArray.array,
												(rz) =>
													rz?.map((rz, index) => {
														return [
															index === 0
																? tsn('tabulka.dokumenty-historie.cislo-rz')
																: '',
															rz?.cisloRz?.xVal,
														];
													})
											)}
										/>
									</>
								)}
								{documentsFilter.prehledTp && (
									<>
										<Table
											data={pipe(
												getter?.documents('tp'),
												ObjectOrArray.array,
												(rz) =>
													rz?.map((tp, index) => {
														return [
															index === 0
																? tsn('tabulka.dokumenty.cislo-tp')
																: '',
															tp?.cisloTp?.xVal,
														];
													})
											)}
										/>
										<Table
											data={pipe(
												getter?.documentsHistory('tp'),
												ObjectOrArray.array,
												(rz) =>
													rz?.map((rz, index) => {
														return [
															index === 0
																? tsn('tabulka.dokumenty-historie.cislo-tp')
																: '',
															rz?.cisloTp?.xVal,
														];
													})
											)}
										/>
									</>
								)}
								{documentsFilter.prehledOrv && (
									<>
										<Table
											data={pipe(
												getter?.documents('orv'),
												ObjectOrArray.array,
												(orvs) =>
													orvs?.map((orv, index) => {
														return [
															index === 0
																? tsn('tabulka.dokumenty.cislo-orv')
																: '',
															orv?.cisloOrv?.xVal,
														];
													})
											)}
										/>
										<Table
											data={pipe(
												getter?.documentsHistory('orv'),
												ObjectOrArray.array,
												(rz) =>
													rz?.map((rz, index) => {
														return [
															index === 0
																? tsn('tabulka.dokumenty-historie.cislo-orv')
																: '',
															rz?.cisloOrv?.xVal,
														];
													})
											)}
										/>
									</>
								)}
								{documentsFilter.vehicleStatus && (
									<Table
										data={[
											[
												tsn('tabulka.dokumenty.vozidlo-status'),
												getter?.statusName(),
											],
										]}
									/>
								)}
							</ContentLayout>
							{getter?.vin && (
								<ContentLayout>
									<LayoutGap gap={4}>
										{controls.isTechnicalInspectionInfoLoading ? (
											<GovSkeleton
												height={'40px'}
												width={'360px'}
												wcagLabel={t('loading.wcag.processing')}
											/>
										) : (
											<>
												<h2 className={'text-xl'}>
													{tsn('formular.nadpis.technicke-prohlidky')}
												</h2>
												<MarkdownRender>
													{tsn('formular.popis.technicke-prohlidky')}
												</MarkdownRender>

												<Button
													onClick={() =>
														window.open(technicalInspectionURL, '_blank')
													}
													variant="primary"
													type="outlined"
													wcagLabel={tsn('akce.wcag.zobrazit-filtr')}>
													<GovIcon
														slot="right-icon"
														name="box-arrow-up-right"
														className={'flex-none h-3 text-secondary-500'}
													/>
													{tsn('formular.odkaz.technicke-prohlidky')}
												</Button>
											</>
										)}
									</LayoutGap>
								</ContentLayout>
							)}
						</LayoutGap>
					</PoFormWeb>
				</FormProvider>
			)}
		</CmsPageRender>
	);
};
