import { GovIcon, GovModal } from '@gov-design-system-ce/react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	PageCode,
	useMojeOsvedceniSeznamContextInstance,
} from '@gov-nx/module/page';
import {
	Button,
	FilterParameterList,
	FormDatePicker,
	FormWidthElementLayout,
	HeaderLayout,
	LayoutGap,
} from '@gov-nx/ui/web';
import { PoFormWeb } from '../../../Form/PoForm';

export interface MySubmissionsListFilterProps {
	code: PageCode;
}

export const MyVerificationsListFilter = ({
	code,
}: MySubmissionsListFilterProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const {
		controls,
		resetFilter,
		filterParams,
		setControls,
		filter,
		formDefinition,
		onSubmit,
	} = useMojeOsvedceniSeznamContextInstance();

	return (
		<FormProvider {...formDefinition.formMethods}>
			<PoFormWeb
				formDefinition={formDefinition}
				onSubmit={onSubmit}>
				<>
					<HeaderLayout className={'!items-start'}>
						<FilterParameterList
							className={'pt-0'}
							items={filterParams}
							onReset={() => resetFilter()}
						/>
						<Button
							onClick={() => setControls({ displayFilter: true })}
							variant="primary"
							type="outlined"
							className={'ml-auto'}
							wcagLabel={ts('akce.wcag.zobrazit-filtr')}>
							<GovIcon
								name={'funnel-fill'}
								type={'basic'}></GovIcon>
						</Button>
					</HeaderLayout>
					<GovModal
						open={controls.displayFilter}
						label={ts('osvedceni.filtr.nazev')}
						onGov-close={() => setControls({ displayFilter: false })}
						wcagCloseLabel={ts('akce.wcag.zavrit-filtr')}>
						<fieldset>
							<legend>{ts('osvedceni.filtr.obdobi.nazev')}</legend>
							<LayoutGap className="mb-4 [ md:flex-row ]">
								<FormWidthElementLayout className={'flex flex-col gap-4'}>
									<FormDatePicker
										field={{
											name: 'obdobiOd',
										}}
										label={{
											children: ts('osvedceni.filtr.pole.obdobi-od'),
										}}
										group={{ gap: 's' }}
									/>
								</FormWidthElementLayout>
								<FormWidthElementLayout className={'flex flex-col gap-4'}>
									<FormDatePicker
										field={{
											name: 'obdobiDo',
										}}
										label={{
											children: ts('osvedceni.filtr.pole.obdobi-do'),
										}}
										group={{ gap: 's' }}
									/>
								</FormWidthElementLayout>
							</LayoutGap>
						</fieldset>
						<div
							slot="footer"
							className={'flex gap-4'}>
							<Button
								variant={'primary'}
								type={'solid'}
								nativeType={'submit'}
								wcagLabel={ts('akce.wcag.filtrovat')}>
								{ts('akce.filtrovat')}
							</Button>
							{filter.obdobiOd || filter.obdobiDo ? (
								<Button
									onClick={() => resetFilter()}
									variant="error"
									type="outlined"
									wcagLabel={ts('akce.wcag.zrusit-filtr')}>
									{ts('akce.zrusit-filtr')}
								</Button>
							) : null}
						</div>
					</GovModal>
				</>
			</PoFormWeb>
		</FormProvider>
	);
};
