import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import React from 'react';
import { getEntries } from '@gov-nx/core/types';
import { PageCode, useRezaTemplateDetailContext } from '@gov-nx/module/page';
import { Button, ContentLayout, DataTable, LayoutGap } from '@gov-nx/ui/web';
import { CmsPageRender } from '../../../../Cms/CmsPageRender';
import { MarkdownRender } from '../../../../Markdown/MarkdownRender';
import { RezaTemplateDetailSkeleton } from './RezaTemplateDetailSkeleton';

export const RezaTemplateDetail = () => {
	const { isLoading, template, keyValues, portalInfo } =
		useRezaTemplateDetailContext();

	if (isLoading) {
		return <RezaTemplateDetailSkeleton />;
	}

	return (
		<CmsPageRender
			code={PageCode['reza']}
			override={{ name: template?.nazev }}>
			<ContentLayout>
				<LayoutGap>
					<DataTable responsive={true}>
						<tbody>
							{getEntries(keyValues).map(([key, value]) => (
								<tr key={key}>
									<td>{key}</td>
									<td>
										<strong>{value}</strong>
									</td>
								</tr>
							))}
						</tbody>
					</DataTable>

					{portalInfo?.portalURL && portalInfo?.portalName ? (
						<GovMessage variant={'primary'}>
							<GovIcon
								type="basic"
								name={'lightbulb-fill'}
								slot="icon"></GovIcon>
							<div
								className={'flex-row flex justify-between items-start gap-4'}>
								{portalInfo?.portalDescription ? (
									<MarkdownRender>
										{portalInfo.portalDescription}
									</MarkdownRender>
								) : null}

								<Button
									onClick={() => window.open(portalInfo.portalURL, '_blank')}
									variant={'primary'}
									type={'outlined'}>
									{portalInfo.portalName}
								</Button>
							</div>
						</GovMessage>
					) : null}
				</LayoutGap>
			</ContentLayout>
		</CmsPageRender>
	);
};
