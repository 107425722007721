import { FormProvider } from 'react-hook-form';
import { CmsPageRender, Item, PoFormWeb } from '@gov-nx/component/web';
import { useLocale } from '@gov-nx/core/service';
import { PageCode, useMojeAutorizaceDetailContext } from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	ContentLayout,
	DataBoxSelect,
	ErrorStatusBanner,
	LayoutGap,
} from '@gov-nx/ui/web';
import { Form602Attachments } from './Form602Attachments';
import { MyAuthorizationDetailSkeleton } from './MyAuthorizationDetailSkeleton';

export const MyAuthorizationDetailView = () => {
	const {
		code,
		form602Submission,
		form,
		isProcessing,
		deleteQuery,
		sendQuery,
		onSubmit,
	} = useMojeAutorizaceDetailContext();
	const { t } = useLocale(PageCode['moje-podani']);

	return (
		<CmsPageRender
			code={code}
			showDescription={false}
			showFavorite={false}
			override={{
				// TODO: tady prozatim jako jmeno formId, protoze nazev nechodi
				name: form602Submission.data?.nazev ?? form602Submission.data?.formId,
			}}>
			{form602Submission.isLoading ? (
				<MyAuthorizationDetailSkeleton />
			) : form602Submission.error ? (
				<ErrorStatusBanner code={code} />
			) : (
				<>
					<dl>
						<Item
							label={t('formular.pole.datum-odesilani')}
							date={form602Submission.data?.datumACasVytvoreni}
						/>
						<Item
							label={t('formular.pole.datum-vyrizeni')}
							date={form602Submission.data?.datumACasPodani}
						/>
					</dl>

					<h2 className={'text-xl'}>
						{t('moje-podani.komunikace-v-ramci-podani')}
					</h2>

					<ContentLayout>
						<FormProvider {...form.formMethods}>
							<PoFormWeb
								onSubmit={onSubmit}
								formDefinition={{ ...form }}>
								<LayoutGap gap={8}>
									{form.showDataboxSelect ? (
										<DataBoxSelect {...form.fields.datovaSchrankaId} />
									) : null}

									<Form602Attachments />

									<ButtonGroupLayout>
										<Button
											nativeType={'submit'}
											loading={sendQuery.isLoading}
											disabled={isProcessing}
											variant="primary"
											type="solid"
											wcagLabel={t('akce.autorizovat-odeslat')}>
											{t('akce.autorizovat-odeslat')}
										</Button>

										<Button
											onClick={() => deleteQuery.deleteFormSubmission()}
											loading={deleteQuery.isLoading}
											disabled={isProcessing}
											variant="error"
											type="base"
											wcagLabel={t('akce.smazat')}>
											{t('akce.smazat')}
										</Button>
									</ButtonGroupLayout>
								</LayoutGap>
							</PoFormWeb>
						</FormProvider>
					</ContentLayout>
				</>
			)}
		</CmsPageRender>
	);
};
