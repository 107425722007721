import { GovIcon } from '@gov-design-system-ce/react';
import React from 'react';
import { MarkdownRender } from '@gov-nx/component/web';
import { CookbookItemCoreProps } from '@gov-nx/ui/types';

export const CookbookItem = ({ icon, copy }: CookbookItemCoreProps) => {
	return (
		<li className={'bg-neutral-white flex gap-2 pr-4 rounded'}>
			{icon ? (
				<span className={'inline-flex bg-secondary-400 p-2 pb-1 rounded-l'}>
					<GovIcon
						className={'w-4 h-4'}
						name={icon.name}
						type={icon.type}></GovIcon>
				</span>
			) : null}
			<span className={'inline-flex text-l pt-1 pb-1 mb-0'}>
				<MarkdownRender>{copy}</MarkdownRender>
			</span>
		</li>
	);
};
