import { useQueryClient } from '@tanstack/react-query';
import * as yup from 'yup';
import {
	rezaSubmitInvalidation,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { invalidateRezaEligibleListQuery } from '@gov-nx/core/hooks';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
} from '@gov-nx/core/service';
import { useRezaLocale } from '@gov-nx/module/page';
import { WebInputProps, WebRadioProps } from '@gov-nx/ui/types';
import { FormButtonProps } from '@gov-nx/ui/web';
import {
	IUseBoolean,
	toStringDate,
	useBoolean,
	useDateShape,
} from '@gov-nx/utils/common';

export interface RezaInvalidationForm {
	terminationDate: 'now' | 'onDate';
	date?: Date;
}

export interface InvalidationForm {
	isModalOpen: IUseBoolean;
	formDefinition: FormDefinition<RezaInvalidationForm>;
	onSubmit: () => void;
	fields: {
		terminationDate: WebRadioProps;
		date: WebInputProps;
		submit: FormButtonProps;
	};
}

export const useInvalidationForm = (
	id: string,
	onSuccess: () => void
): InvalidationForm => {
	const { t } = useRezaLocale();
	const isModalOpen = useBoolean();
	const { getDatePickerShape } = useDateShape();
	const queryClient = useQueryClient();

	const formSchema = yup
		.object<FormSchemaShape<RezaInvalidationForm>>({
			terminationDate: yup.string().oneOf(['now', 'onDate']),
			date: getDatePickerShape().when('terminationDate', {
				is: () => 'onDate',
				then: (schema) =>
					schema.required(
						t('invalidation.form.pole.termination-date.validace.povinne-pole')
					),
				otherwise: (schema) => schema.optional(),
			}),
		})
		.required();
	const formMethods = usePoForm({
		formSchema,
		defaultValues: {
			terminationDate: 'now',
			date: new Date(),
		},
	});

	const formDefinition = getFormDefinition({ formMethods, formSchema });

	const mutation = usePoMutation({
		mutationFn: rezaSubmitInvalidation,
		onSuccess: async () => {
			await invalidateRezaEligibleListQuery(queryClient);
			isModalOpen.setFalse();
			onSuccess();
		},
	});

	const terminationDateValue =
		formDefinition.formMethods.watch('terminationDate');

	const onSubmit = formDefinition.formMethods.handleSubmit((data) => {
		return mutation.mutate({
			datumUkonceni:
				data.terminationDate === 'onDate' && data.date
					? toStringDate(data.date)
					: undefined,
			opravneniKod: id,
		});
	});

	const fields: InvalidationForm['fields'] = {
		terminationDate: {
			field: {
				name: 'terminationDate',
				disabled: mutation.isLoading,
				options: [
					{
						value: 'now',
						label: {
							children: t('invalidation.form.pole.termination-date.now.label'),
						},
					},
					{
						value: 'onDate',
						label: {
							children: t(
								'invalidation.form.pole.termination-date.on-date.label'
							),
						},
					},
				],
			},
			label: {
				children: undefined, // t('invalidation.form.pole.termination-date.label'),
			},
		},
		date: {
			field: {
				name: 'date',
				required: terminationDateValue === 'onDate',
				disabled: mutation.isLoading || terminationDateValue === 'now',
			},
			label: {
				children: t('invalidation.form.pole.date.label'),
			},
		},
		submit: {
			children: t('invalidation.form.buttons.submit.label'),
			disabled: mutation.isLoading,
			loading: mutation.isLoading,
			wcagLabel: t('invalidation.form.buttons.submit.wcag'),
		},
	};

	return {
		formDefinition,
		onSubmit,
		fields,
		isModalOpen,
	};
};
