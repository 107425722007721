import { useEffect } from 'react';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { FormDefinition2, useFormBuilder } from '@gov-nx/core/service';
import { useDataBoxAccess } from '@gov-nx/module/data-box';
import { CommonSelectProps, FormRadioProps } from '@gov-nx/ui/types';
import { getRadioDefinition, getSelectDefinition } from '@gov-nx/utils/common';
import { useRosBusinessPersonQuery } from '../../nastaveni/kontaktni-udaje-ros/useRosBusinessPersonQuery';
import { usePpdfSouhlasyLocale } from '../usePpdfSouhlasyLocale';
import { PpdfSouhlasyTypOsobyEnum } from './context.types';

export interface PpdfSouhlasyUdeleniStep1 {
	personType: PpdfSouhlasyTypOsobyEnum.PO | PpdfSouhlasyTypOsobyEnum.FO;
	poDataBoxId: string;
	isPOTabVisible: boolean;
	dataBoxes: DatovkaApiType[];
}

interface Fields {
	personType: FormRadioProps;
	poDataBoxId: CommonSelectProps;
}

export type PpdfSouhlasyUdeleniFormStep1 = FormDefinition2<
	PpdfSouhlasyUdeleniStep1,
	Fields
>;

export const useFormStep1 = ({
	isDisabled,
	databoxTypes,
}: {
	isDisabled: boolean;
	databoxTypes: DatovkaApiType['typSchranky'][];
}) => {
	const { t } = usePpdfSouhlasyLocale();
	const { isLoading } = useRosBusinessPersonQuery();
	const { connectedList: dataBoxes } = useDataBoxAccess();

	const personType = getRadioDefinition({
		name: 'personType',
		label: t('formular.typ-osoby.label'),
		isRequired: true,
		isRequiredMessage: t('formular.typ-osoby.validace.povinny'),
		options: [],
		defaultValue: null,
	});

	// for PFO/PO dropdown
	const availablePODataBoxes = dataBoxes.filter((dataBox) =>
		databoxTypes.includes(dataBox.typSchranky)
	);

	const poDataBoxId = getSelectDefinition({
		name: 'poDataBoxId',
		label: t('formular.pravnicka-osoba.label'),
		isDisabled,
		options: [
			{ label: t('formular.pravnicka-osoba.default-label'), value: '' },
			...availablePODataBoxes.map((dataBox) => ({
				value: dataBox.datovaSchrankaId!,
				label:
					`${dataBox.firma} (${dataBox.datovaSchrankaId!} | ${
						dataBox.typSchranky
					})` ?? '',
			})),
		],
		isRequiredWhen: {
			fieldName: personType.field.field.name,
			is: PpdfSouhlasyTypOsobyEnum.PO,
			errorMessage: t('formular.pravnicka-osoba.validace.povinny'),
		},
	});

	const form = useFormBuilder<PpdfSouhlasyUdeleniStep1, Fields>([
		poDataBoxId,
		personType,
	]);

	const personTypeValue = form.formMethods.watch('personType');
	useEffect(() => {
		if (personTypeValue === PpdfSouhlasyTypOsobyEnum.FO) {
			form.formMethods.setValue('poDataBoxId', '');
		}
	}, [personTypeValue, form.formMethods]);

	return {
		isLoading,
		form,
		isPOTabVisible: availablePODataBoxes.length > 0,
		dataBoxes,
	};
};
