import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { prop, propEq } from '@gov-nx/core/types';
import {
	PERSIST_ZUSTAND_STORE_KEY,
	StoreKeys,
	storeGlobalConfig,
} from '../../constants';
import { updateByPath } from '../../filters/store';
import { update } from '../utils';
import { updateByTitle, updateByIsoCode } from '../utils';
import { DrozdSignpostState } from './store.types';

const initialState = {
	countries: {},
	countriesWarnings: [],
	countriesInformation: [],
	countriesOffices: [],
	staticWebsites: {},
	updatedAt: undefined,
};

export const useDrozdSignpostStoreBase = create<DrozdSignpostState>()(
	persist(
		(set, get) => ({
			...initialState,

			saveCountries: (countries) => set({ countries }),
			updateCountriesWarning: (countriesWarnings) =>
				set(update('countriesWarnings', updateByIsoCode(countriesWarnings))),
			saveCountriesWarning: (countriesWarnings) => set({ countriesWarnings }),
			saveCountriesInformation: (countriesInformation) =>
				set({ countriesInformation }),
			updateCountriesInformation: (information) =>
				set(update('countriesInformation', updateByTitle(information))),
			saveCountriesOffices: (countriesOffices) => set({ countriesOffices }),
			updateCountriesOffices: (offices) =>
				set(update('countriesOffices', updateByIsoCode(offices))),
			updateStaticWebsites: (staticWebsites, path) => {
				set(updateByPath(['staticWebsites', path], staticWebsites));
			},

			getCountryOfficesByCode: (isoCode) =>
				get().countriesOffices.filter(propEq('isoCode', isoCode)),
			getCountryInformation: (title) =>
				get().countriesInformation.find(propEq('title', title)),
			getStaticWebsiteByKey: (key) => {
				return get().staticWebsites?.[key];
			},

			saveUpdatedAt: (updatedAt) => set({ updatedAt }),
			appReset: () => set(initialState),
		}),
		{
			...storeGlobalConfig,
			name: PERSIST_ZUSTAND_STORE_KEY + ':' + StoreKeys.drozdSignpost,
		}
	)
);

export const useDrozdSignpostStore = <T extends keyof DrozdSignpostState>(
	property: T
): DrozdSignpostState[T] => useDrozdSignpostStoreBase(prop(property));
