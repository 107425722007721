import { GovChip } from '@gov-design-system-ce/react';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormBadgeListProps } from '@gov-nx/ui/types';
import { BadgeNav } from '../Navigation/BadgeNav';

export const FormBadges = (props: FormBadgeListProps) => {
	const formContext = useFormContext();

	return (
		<Controller
			name={props.field.name}
			control={formContext.control}
			render={({ field }) => {
				return (
					<BadgeNav className={'mt-4 !mb-0'}>
						{props.options.map((option) => {
							const value = field.value?.[option.name];
							return (
								<li key={option.name}>
									<GovChip
										variant={'primary'}
										size="m"
										tag="button"
										inverse={!value}
										onGov-click={() => {
											const updated = props.options.reduce((all, current) => {
												return {
													...all,
													[current.name]: option.name === current.name,
												};
											}, {});

											field.onChange(updated);

											field.onBlur();
										}}>
										{option.label}
									</GovChip>
								</li>
							);
						})}
					</BadgeNav>
				);
			}}
		/>
	);
};
