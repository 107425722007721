import { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import { getAutocompleteShape } from '@gov-nx/utils/common';
import { useDataBoxMessageDetailContext } from '../providers/MessageDetailContext';
import { DataBoxMessageFormInputs } from '../providers/MessageFormContext.types';

interface useNewMessageFormProps {
	isAnswerForm: boolean;
}

export const useNewMessageForm = ({ isAnswerForm }: useNewMessageFormProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const getConnectedDataBoxesList = useDataBoxStore(
		'getConnectedDataBoxesList'
	);
	const lastOpenedDataBoxId = useDataBoxStore('lastOpenedDatabox');
	const dataBoxes = getConnectedDataBoxesList();
	const messageContext = useDataBoxMessageDetailContext();
	const message = messageContext?.message;
	const [dataLoading, setDataLoading] = useState(isAnswerForm);

	const formSchema = yup
		.object<FormSchemaShape<DataBoxMessageFormInputs>>({
			senderId: yup.string().required(tn('nova-zprava.chyba.odesilatel')),
			recipientAutocomplete: getAutocompleteShape({
				requiredMessage: tn('nova-zprava.chyba.prijemce'),
			}),
			subject: yup.string().required(tn('nova-zprava.chyba.predmet')),
			message: yup
				.string()
				.max(5000, tn('nova-zprava.chyba.zprava-delka'))
				.test(
					'test empty string',
					tn('nova-zprava.chyba.zprava-zadny-text'),
					(value) => {
						const contentLength = value?.length || 0;
						const trimmedLength = value?.trim().length || 0;

						return (
							(contentLength > 0 && trimmedLength > 0) || contentLength === 0
						);
					}
				)
				.when(['documents', 'files'], {
					is: (documents: number[], files: string[]) =>
						documents.length === 0 && files.length === 0,
					then: (schema) => schema.required(tn('nova-zprava.chyba.zprava')),
				}),
			toTheHandsOf: yup.string(),
			intoTheirOwnHands: yup.boolean(),
			senderIdentification: yup.boolean(),
			naseCisloJednaci: yup.string(),
			naseSpisovaZnacka: yup.string(),
			vaseCisloJednaci: yup.string(),
			vaseSpisovaZnacka: yup.string(),
			documents: yup.array().of(yup.number()),
			files: yup.array().of(yup.object()),
			temporary: yup.object(),
		})
		.required();

	const defaultValues: DataBoxMessageFormInputs = {
		message: undefined,
		subject: undefined,
		senderId: lastOpenedDataBoxId,
		recipientAutocomplete: undefined,
		files: [],
		documents: [],
		temporary: {
			additionalData: {},
			files: [],
		},
	};

	const formMethods = usePoForm<DataBoxMessageFormInputs>({
		mode: 'onTouched',
		reValidateMode: 'onBlur',
		formSchema,
		defaultValues: defaultValues,
	});
	const formDefinition = getFormDefinition({ formMethods, formSchema });

	useEffect(() => {
		if (message && dataBoxes.length && dataLoading) {
			formMethods.setValue('recipientAutocomplete', {
				value: '',
				selected: messageContext.senderDataBox,
			});
			formMethods.setValue(
				'subject',
				message.vec?.startsWith('Re:') ? message.vec : `Re: ${message.vec}`
			);
			formMethods.setValue('naseCisloJednaci', message.naseCisloJednaci ?? '');
			formMethods.setValue(
				'naseSpisovaZnacka',
				message.naseSpisovaZnacka ?? ''
			);
			formMethods.setValue('vaseCisloJednaci', message.vaseCisloJednaci ?? '');
			formMethods.setValue(
				'vaseSpisovaZnacka',
				message.vaseSpisovaZnacka ?? ''
			);
			setDataLoading(false);
		}
	}, [dataLoading, dataBoxes, message]);

	return {
		form: formMethods,
		formSchema,
		formDefinition,
		dataLoading,
	};
};

export const prepareNewMessageSubmitData = (data: DataBoxMessageFormInputs) => {
	const requestBody = new FormData();

	if (data.senderId) {
		requestBody.append('odesilatelId', data.senderId);
	}
	if (data.recipientAutocomplete?.selected?.datovaSchrankaId) {
		requestBody.append(
			'prijemceId',
			data.recipientAutocomplete.selected.datovaSchrankaId
		);
	}
	if (data.subject) {
		requestBody.append('vec', data.subject);
	}

	if (data.message) {
		requestBody.append('text', data.message);
	}

	if (data.toTheHandsOf) {
		requestBody.append('kRukam', data.toTheHandsOf);
	}

	if (data.intoTheirOwnHands) {
		requestBody.append('doVlastnichRukou', data.intoTheirOwnHands.toString());
	}

	if (data.senderIdentification) {
		requestBody.append(
			'pridatIdentifikaciOdesilatele',
			data.senderIdentification.toString()
		);
	}

	if (data.naseCisloJednaci) {
		requestBody.append('naseCisloJednaci', data.naseCisloJednaci);
	}

	if (data.naseSpisovaZnacka) {
		requestBody.append('naseSpisovaZnacka', data.naseSpisovaZnacka);
	}

	if (data.vaseCisloJednaci) {
		requestBody.append('vaseCisloJednaci', data.vaseCisloJednaci);
	}

	if (data.vaseSpisovaZnacka) {
		requestBody.append('vaseSpisovaZnacka', data.vaseSpisovaZnacka);
	}

	data.documents?.forEach((documentId) =>
		requestBody.append('prilohyZDokumentu[]', documentId.toString())
	);

	data.files?.forEach((file) =>
		requestBody.append('soubory[]', file.fileId ?? '')
	);

	return requestBody;
};
