import { useSearchParams } from 'react-router-dom';

export const useSearchParamsOperations = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const getParam = (key: string) => searchParams.get(key) ?? undefined;

	const hasParam = (key: string) => searchParams.has(key);

	const setParam = (key: string, value: string) => {
		const newParams = new URLSearchParams(searchParams);
		newParams.set(key, value);
		setSearchParams(newParams, { replace: true });
	};

	const deleteParam = (key: string) => {
		const newParams = new URLSearchParams(searchParams);
		newParams.delete(key);
		setSearchParams(newParams, { replace: true });
	};

	const clearAllParams = () => {
		setSearchParams(new URLSearchParams(), { replace: true });
	};

	return {
		searchParams,
		getParam,
		hasParam,
		setParam,
		deleteParam,
		clearAllParams,
	};
};
