import { useEffect } from 'react';
import * as yup from 'yup';
import {
	OsobyEditorDto,
	OsobyPodnikatelRozsireneDto,
} from '@gov-nx/api/portal-obcana';
import { usePersonCommunicationData } from '@gov-nx/core/hooks';
import {
	FormDefinition,
	FormSchemaShape,
	LocalizeNameSpaceTypes,
	getFormDefinition,
	useLocale,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { is } from '@gov-nx/core/types';
import {
	getAutocompleteDefaultValues,
	getAutocompleteShape,
	isValidDate,
	toStringDate,
	today,
	useDateShape,
	useIcNumberShape,
	usePhoneShape,
} from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import {
	DataRequest,
	FormData,
	ProfileRosComplaintProcessControl,
} from './context.types';
import { EmailRadioOption, PhoneRadioOption } from './options';
import { interruptionToBoolean } from './utils';

export interface FormInstanceProps {
	code: PageCode;
	data: OsobyPodnikatelRozsireneDto;
	controls: ProfileRosComplaintProcessControl;
}

export function FormInstance({
	code,
	data,
	controls,
}: FormInstanceProps): FormDefinition<FormData> {
	const { getLocalize } = useTranslationWithNamespace();
	const { t } = useLocale(code);
	const { getPhoneShape } = usePhoneShape();
	const { getIcNumberShape } = useIcNumberShape();
	const { getDatePickerShape } = useDateShape();
	const { emailVerified, phoneVerified } = usePersonCommunicationData();

	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			ico: getIcNumberShape({}),
			nazevSpolecnosti: yup.string().optional(),
			agenda: getAutocompleteShape(),
			popisReklamace: yup
				.string()
				.required(t('formular.validace.popisReklamace.povinny')),
			nazevObce: yup.string().when([], {
				is: () => controls.displaySearchAddress,
				then: yup.string().required(t('formular.validace.povinne')),
				otherwise: yup.string().optional(),
			}),
			nazevUlice: yup.string().optional(),
			cislo: yup.string().when([], {
				is: () => controls.displaySearchAddress,
				then: yup
					.string()
					.required(t('formular.validace.povinne'))
					.min(0)
					.max(4, t('formular.validace.max-9999'))
					.matches(/^[0-9]+$/, t('formular.validace.pouze-cislice')),
				otherwise: yup.string().optional(),
			}),
			datumOd: getDatePickerShape(),
			datumDo: getDatePickerShape().when('datumOd', (datumOd, schema) => {
				const dateFrom =
					datumOd || (data.datumVzniku ? today(data.datumVzniku) : undefined);

				if (dateFrom && isValidDate(dateFrom)) {
					return schema.min(dateFrom, t('form.validace.starsi-nez-datum-od'));
				}
				return schema;
			}),
			kontaktTelefon: yup.boolean(),
			newPhone: getPhoneShape().when(['kontaktTelefon', '_telefon'], {
				is: (kontaktTelefon: boolean, _telefon?: string) =>
					kontaktTelefon &&
					(_telefon === PhoneRadioOption.ANOTHER_PHONE || !_telefon),

				then: (schema) => schema.required(t('formular.validace.povinne')),
			}),
			kontaktEmail: yup.boolean(),
			newEmail: yup
				.string()
				.optional()
				.email(
					getLocalize(
						LocalizeNameSpaceTypes.Form,
						'validations.email-neni-ve-spravnem-tvaru'
					)
				)
				.when(['kontaktEmail', '_email'], {
					is: (kontaktEmail: boolean, _email?: string) =>
						kontaktEmail &&
						(_email === EmailRadioOption.ANOTHER_EMAIL || !_email),

					then: (schema) => schema.required(t('formular.validace.povinne')),
				}),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			ico: undefined,
			nazevSpolecnosti: undefined,
			agenda: getAutocompleteDefaultValues(),
			datumOd: undefined,
			datumDo: undefined,
			pozastaveniPreruseni: undefined,
			pravniForma: undefined,
			nazevObce: undefined,
			cislo: undefined,
			nazevUlice: undefined,
			kontaktTelefon: false,
			kontaktEmail: false,
			newPhone: undefined,
			newEmail: undefined,
			_email: emailVerified,
			_telefon: phoneVerified,
		},
	});

	const kontaktTelefon = formMethods.watch('kontaktTelefon');
	const kontaktEmail = formMethods.watch('kontaktEmail');

	const _telefon = formMethods.watch('_telefon');
	const _email = formMethods.watch('_email');

	useEffect(() => {
		if (!kontaktEmail || _email !== EmailRadioOption.ANOTHER_EMAIL) {
			formMethods.resetField('newEmail');
		}
		if (!kontaktTelefon || _telefon !== PhoneRadioOption.ANOTHER_PHONE) {
			formMethods.resetField('newPhone');
		}
	}, [kontaktEmail, kontaktTelefon, _email, _telefon, formMethods]);

	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData =
	(newAddress?: string, editors?: OsobyEditorDto[], ico?: number) =>
	(data: FormData): DataRequest => {
		const seznam = [];
		if (data.ico) {
			seznam.push({
				kodAgendyEditora: editors?.[0].kodAgendy,
				kodOvmEditora: editors?.[0].kodOvm,
				kodUdaje: 'Ico',
				stavUdaje: 'NESPRAVNY',
				zpusobNaplneni: data.ico,
			});
		}
		if (data.nazevSpolecnosti) {
			seznam.push({
				kodAgendyEditora: editors?.[0].kodAgendy,
				kodOvmEditora: editors?.[0].kodOvm,
				kodUdaje: 'ObchodniNazev',
				stavUdaje: 'NESPRAVNY',
				zpusobNaplneni: data.nazevSpolecnosti,
			});
		}
		/*
		Zatím není technicky možné za BE reklamovat agendu. 
		Dočasně odebráno z payloadu a bude dále upraveno 
		po zapracování úpravy designu reklamace

		if (data.agenda?.selected) {
			seznam.push({
				kodAgendyEditora: editors?.[0].kodAgendy,
				kodOvmEditora: editors?.[0].kodOvm,
				kodUdaje: 'Agenda',
				stavUdaje: 'NESPRAVNY',
				zpusobNaplneni: data.agenda.selected.kod,
			});
		}
		*/
		if (data.datumOd) {
			seznam.push({
				kodAgendyEditora: editors?.[0].kodAgendy,
				kodOvmEditora: editors?.[0].kodOvm,
				kodUdaje: 'DatumVznikuOpravneni',
				stavUdaje: 'NESPRAVNY',
				zpusobNaplneni: toStringDate(data.datumOd),
			});
		}
		if (data.datumDo) {
			seznam.push({
				kodAgendyEditora: editors?.[0].kodAgendy,
				kodOvmEditora: editors?.[0].kodOvm,
				kodUdaje: 'DatumZanikuOpravneni',
				stavUdaje: 'NESPRAVNY',
				zpusobNaplneni: toStringDate(data.datumDo),
			});
		}
		if (data.pravniForma) {
			seznam.push({
				kodAgendyEditora: editors?.[0].kodAgendy,
				kodOvmEditora: editors?.[0].kodOvm,
				kodUdaje: 'PravniForma',
				stavUdaje: 'NESPRAVNY',
				zpusobNaplneni: data.pravniForma,
			});
		}
		if (data.pozastaveniPreruseni) {
			seznam.push({
				kodAgendyEditora: editors?.[0].kodAgendy,
				kodOvmEditora: editors?.[0].kodOvm,
				kodUdaje: 'PozastaveniPreruseni',
				stavUdaje: 'NESPRAVNY',
				zpusobNaplneni: interruptionToBoolean(data.pozastaveniPreruseni),
			});
		}
		if (newAddress) {
			seznam.push({
				kodAgendyEditora: editors?.[0].kodAgendy,
				kodOvmEditora: editors?.[0].kodOvm,
				stavUdaje: 'NESPRAVNY',
				kodUdaje: 'AdresaSidla',
				zpusobNaplneni: newAddress,
			});
		}
		const kontaktValue = [data._telefon, data._email].filter(is).join(', ');

		return {
			params: {},
			body: {
				agenda: null,
				ico: ico,
				kontakt: kontaktValue,
				typReklamace: 'REKLAMACE_EXISTUJICIHO_SUBJEKTU',
				navrhovaneHodnoty: { seznam },
				popisReklamace: data.popisReklamace,
			},
		};
	};
