import { QueryClient, useQueryClient } from '@tanstack/react-query';
import {
	MySubmission,
	mySubmissionDetailQuery,
	mySubmissionsQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { isNumber } from '@gov-nx/core/types';

const getListKey = (): ['my-submissions-list'] => ['my-submissions-list'];

export const invalidateMySubmissionsListQuery = async (
	queryClient: QueryClient
) =>
	queryClient.invalidateQueries({
		queryKey: getListKey(),
	});

export const useMySubmissionsListQuery = (isEnabled: boolean) => {
	const queryClient = useQueryClient();

	const query = usePoQuery({
		queryKey: getListKey(),
		queryFn: mySubmissionsQuery,
		enabled: isEnabled,
	});
	return {
		data: query.data,
		isLoading: query.isLoading,
		invalidate: async () => invalidateMySubmissionsListQuery(queryClient),
	};
};

const getDetailKey = (
	submissionId: string | number
): ['my-submission-detail', string] => [
	'my-submission-detail',
	isNumber(submissionId) ? `${submissionId}` : submissionId,
];

export const invalidateMySubmissionDetailQuery = async (
	queryClient: QueryClient,
	submissionId: number
) =>
	queryClient.invalidateQueries({
		queryKey: getDetailKey(submissionId),
	});

export const useMySubmissionDetailQuery = (submissionId: number) => {
	const query = usePoQuery<MySubmission>({
		queryKey: getDetailKey(submissionId),
		queryFn: () => mySubmissionDetailQuery(submissionId),
	});
	return {
		data: query.data,
		isLoading: query.isLoading,
		error: query.error,
	};
};

export const invalidateMySubmissionsQuery = async (
	queryClient: QueryClient,
	messageId: number
) =>
	queryClient.invalidateQueries({
		queryKey: ['my-submissions-message', messageId],
	});

export const useMySubmissionsQuery = (messageId: number) => {
	const queryClient = useQueryClient();

	const query = usePoQuery({
		queryKey: ['my-submissions-message', messageId],
		queryFn: async () => mySubmissionsQuery({ datovaZpravaId: messageId }),
	});

	return {
		data: query.data,
		isLoading: query.isLoading,
		invalidate: async () =>
			invalidateMySubmissionsQuery(queryClient, messageId),
	};
};
