import {
	ContactDetailsFormData,
	ContactDetailsFormFields,
	useContactDetailsDefinition,
} from '@gov-nx/core/hooks';
import { FormDefinition2, useFormBuilder } from '@gov-nx/core/service';
import { useKorespondencniVolbyLocale } from '@gov-nx/module/service';
import { FormCheckboxProps } from '@gov-nx/ui/types';
import { getCheckboxDefinition } from '@gov-nx/utils/common';

export interface KorespondencniHlasovaniZapisStep1
	extends ContactDetailsFormData {
	isNotOnOtherList: boolean;
	isNoTooLate: boolean;
}

interface Fields extends ContactDetailsFormFields {
	isNotOnOtherList: FormCheckboxProps;
	isNoTooLate: FormCheckboxProps;
}

export type KorespondencniHlasovaniZapisFormStep1 = FormDefinition2<
	KorespondencniHlasovaniZapisStep1,
	Fields
>;

export const useFormStep1 = ({
	isDisabled,
}: {
	isDisabled: boolean;
}): KorespondencniHlasovaniZapisFormStep1 => {
	const { t } = useKorespondencniVolbyLocale();

	const contactDetails = useContactDetailsDefinition({
		isDisabled,
		strings: {
			_useEmail: { label: t('kontaktni-udaje.email.label') },
			_usePhone: { label: t('kontaktni-udaje.phone.label') },
			email: {
				label: '',
				placeholder: t('kontaktni-udaje.email.placeholder'),
			},
			phone: {
				label: '',
				placeholder: t('kontaktni-udaje.phone.placeholder'),
			},
		},
	});

	const isNotOnOtherList = getCheckboxDefinition({
		name: 'isNotOnOtherList',
		isDisabled,
		oneOf: {
			value: [true],
			errorMessage: t('zapis.formular.neni-na-seznamu.validace.povinny'),
		},
		label: t('zapis.formular.neni-na-seznamu.label'),
	});

	const isNoTooLate = getCheckboxDefinition({
		name: 'isNoTooLate',
		isDisabled,
		oneOf: {
			value: [true],
			errorMessage: t('zapis.formular.zapis-v-terminu.validace.povinny'),
		},
		label: t('zapis.formular.zapis-v-terminu.label'),
	});

	return useFormBuilder<KorespondencniHlasovaniZapisStep1, Fields>([
		isNotOnOtherList,
		isNoTooLate,
		...contactDetails,
	]);
};
