import { GovTabs, GovTabsItem } from '@gov-design-system-ce/react';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormBadgeListProps } from '@gov-nx/ui/types';

type FormTabsProps = FormBadgeListProps;

export const FormTabs = (props: FormTabsProps) => {
	const { control } = useFormContext();

	return (
		<Controller
			name={props.field.name}
			control={control}
			render={({ field }) => {
				return (
					<GovTabs
						type="text"
						onGov-change={({ detail }) => {
							const updated = props.options.reduce((all, current) => {
								return {
									...all,
									[current.name]: detail.identifier === current.name,
								};
							}, {});
							field.onChange(updated);
							field.onBlur();
						}}>
						{props.options.map((option) => {
							const isSelected = field.value?.[option.name];
							return (
								<GovTabsItem
									key={option.name}
									label={option.label}
									default={isSelected ? true : undefined}
									identifier={option.name}
								/>
							);
						})}
					</GovTabs>
				);
			}}
		/>
	);
};
