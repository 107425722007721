import { createContext, ReactNode, useContext } from 'react';
import {
	isVoterRegistrationSubmission,
	MySubmissionVoterCardAplication,
	PodaniDatovaZpravaIdDto,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import {
	useDataBoxMessagesLoader,
	useDocumentsDownload,
	useDownloadBlob,
	useMySubmissionDetailQuery,
	useProcessControl,
} from '@gov-nx/core/hooks';
import { useSubmissionConnection } from '@gov-nx/core/hooks';
import { useLocale } from '@gov-nx/core/service';
import { compose, equals, is, Nullable, prop } from '@gov-nx/core/types';
import { pickupDates } from '@gov-nx/module/service';
import { PageCode } from '../../definitions/codes';
import { removeIdFromSubmissionName } from '../../epetice/seznam/utils';
import {
	DataMessage,
	MySubmissionDetailContext,
	MySubmissionMessage,
} from './context.types';

const isDataMessage = (
	message: PodaniDatovaZpravaIdDto
): message is DataMessage =>
	!!(message.datovaSchrankaId && message.datovaSchrankaId);

const MojePodaniDetailContext =
	createContext<Nullable<MySubmissionDetailContext>>(null);

interface MojePodaniDetailContextProviderProps {
	children: ReactNode;
	code: PageCode;
	submissionId: number;
}

export function MojePodaniDetailContextProvider({
	code,
	children,
	submissionId,
}: MojePodaniDetailContextProviderProps) {
	const { t } = useLocale(code);
	const { setControls, controls } = useProcessControl();
	const { handleDownloadBlob } = useDownloadBlob();

	const { error, isLoading, data } = useMySubmissionDetailQuery(submissionId);

	const submissionConnection = useSubmissionConnection({
		submissionId,
	});

	const dataMessages: DataMessage[] =
		data && Array.isArray(data.datoveZpravy) && data.datoveZpravy.length
			? data.datoveZpravy.filter(isDataMessage)
			: [];

	const messagesQuery = useDataBoxMessagesLoader({
		messageIds: dataMessages.map(prop('datovaZpravaId')),
		onError: () => setControls({ initialLoading: false }),
	});

	const messages: MySubmissionMessage[] = dataMessages
		.map((dataMessage) => {
			const message = (messagesQuery.data ?? []).find(
				compose(equals(dataMessage.datovaZpravaId), prop('datovaZpravaId'))
			);
			if (!message) return null;

			return {
				message: message ?? null,
				isDeleted: !message,
				dataMessage,
			};
		})
		.filter(is);

	const isInitialLoading =
		isLoading || (dataMessages.length > 0 && messagesQuery.isLoading);

	const { prepare } = useDocumentsDownload();

	return (
		<MojePodaniDetailContext.Provider
			value={{
				code,
				controls: {
					...controls,
					initialError: error ? new GovError(error.message) : null,
					initialLoading: isInitialLoading,
				},
				submission: data,
				messages,
				submissionConnection,
				powerOfAttorney: handleDownloadBlob(() => {
					const params =
						data && isVoterRegistrationSubmission(data)
							? data.parametry
							: undefined;

					return {
						body: {
							kodPodani: 'ZADOST_VOLEBNI_PRUKAZ_PLNA_MOC',
							kodVoleb: params?.kod,
							kola: params ? getElectionRounds(params) : undefined,
							zpusobPrevzeti: params?.zpusobPrevzeti,
						},
					};
				}),
				documents:
					data?.dokumenty?.map(prop('dokumentId')).filter(is).map(prepare) ??
					[],
				strings: {
					submission: {
						name: data?.nazev
							? removeIdFromSubmissionName(data.nazev)
							: undefined,
					},
					voterRegistration:
						data && isVoterRegistrationSubmission(data)
							? {
									infoMessage: t('volicsky-prukaz.info-o-volicim-prukazu', {
										termin: pickupDates(data),
										adresaUradu: data.parametry?.vyzvednutiNaUradu
											? data.parametry?.vyzvednutiNaUradu
											: '&nbsp;',
									}),
							  }
							: undefined,
				},
			}}>
			{children}
		</MojePodaniDetailContext.Provider>
	);
}

export const useMojePodaniDetailContextInstance =
	(): MySubmissionDetailContext =>
		useContext(MojePodaniDetailContext) as MySubmissionDetailContext;

const getElectionRounds = (
	params: MySubmissionVoterCardAplication['parametry']
) => {
	if (params.kolo1zadost === 'true' && params.kolo2zadost === 'true') {
		return 'ALL';
	}
	if (params.kolo2zadost === 'true') {
		return 'II';
	}
	if (params.kolo1zadost === 'true') {
		return 'I';
	}
	return;
};
