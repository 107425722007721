import {
	CiselnikyCiselnikPolozkaDtoEmbassyOffice,
	embassyOfficeListQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { hasProperty } from '@gov-nx/core/types';
import { AlphabeticalComparator } from '@gov-nx/utils/common';
import { useAppLanguage } from '../Localization/useAppLanguage';

export const useEmbassyOfficeQuery = () => {
	const { language } = useAppLanguage();

	const getValue = (office: CiselnikyCiselnikPolozkaDtoEmbassyOffice) =>
		language === 'cs' ? office.nazevCs : office.nazevEn;

	const query = usePoQuery({
		queryKey: ['embassy-offices'],
		queryFn: embassyOfficeListQuery,
	});

	const embassyOffices = (query.data?.seznam ?? []).filter(hasProperty('id'));

	const embassyOfficesOptions = embassyOffices
		.map((embassy) => {
			return {
				value: embassy.id,
				label: getValue(embassy) ?? '',
			};
		})
		.sort(AlphabeticalComparator);

	return {
		getValue,
		embassyOffices,
		embassyOfficesOptions,
		data: query.data,
		error: query.error,
		isLoading: query.isLoading,
	};
};
