import {
	FormDefinition,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { FilterParam, isString, Leaves, splitByDot } from '@gov-nx/core/types';
import { toDate, toDateReadable, useFilter } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import { RegistrSVFormData } from './context.types';

interface FilterParamsProps {
	code: PageCode;
	formDefinition: FormDefinition<RegistrSVFormData>;
	defaultValues: RegistrSVFormData;
}

export const useFilters = ({
	code,
	formDefinition,
	defaultValues,
}: FilterParamsProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { filter, setPartialFilter, setPartialFilterWithPath } =
		useFilter<RegistrSVFormData>({
			key: code,
			initialState: defaultValues,
			rehydrate: (data) => {
				return {
					...data,
					datumOd: isString(data.datumOd)
						? toDate(data.datumOd) ?? null
						: data.datumOd,
					datumDo: isString(data.datumDo)
						? toDate(data.datumDo) ?? null
						: data.datumDo,
				};
			},
		});

	const onRemove =
		(
			path:
				| keyof Pick<RegistrSVFormData, 'filterText' | 'datumOd' | 'datumDo'>
				| Leaves<Pick<RegistrSVFormData, 'filterStatus' | 'filterVztah'>>
		) =>
		() => {
			const [name, key] = splitByDot(path);
			switch (name) {
				case 'filterText':
				case 'datumOd':
				case 'datumDo':
					setPartialFilter({ [name]: defaultValues[name] });
					formDefinition.formMethods.setValue(name, defaultValues[name]);
					break;
				case 'filterStatus':
					setPartialFilterWithPath({ [key]: defaultValues[name][key] }, name);
					formDefinition.formMethods.setValue(path, defaultValues[name][key]);
					break;
				case 'filterVztah':
					setPartialFilterWithPath({ [key]: defaultValues[name][key] }, name);
					formDefinition.formMethods.setValue(path, defaultValues[name][key]);
					break;
			}
		};

	const filterParams: FilterParam[] = [
		{
			label: tsn('formular.label.text-filtr'),
			value: filter.filterText ?? undefined,
			onRemove: onRemove('filterText'),
			isActive: !!filter.filterText,
		},
		{
			label: tsn('formular.label.status'),
			value: tsn('formular.pole.filter-status-provozovane'),
			onRemove: onRemove('filterStatus.provozovane'),
			isActive: filter.filterStatus.provozovane,
		},
		{
			label: tsn('formular.label.status'),
			value: tsn('formular.pole.filter-status-zanik'),
			onRemove: onRemove('filterStatus.zanik'),
			isActive: filter.filterStatus.zanik,
		},
		{
			label: tsn('formular.label.vztah-k-vozidlu'),
			value: tsn('formular.pole.filtr-vztah-vlastnik'),
			onRemove: onRemove('filterVztah.vlastnik'),
			isActive: filter.filterVztah.vlastnik,
		},
		{
			label: tsn('formular.label.vztah-k-vozidlu'),
			value: tsn('formular.pole.filtr-vztah-provozovatel'),
			onRemove: onRemove('filterVztah.provozovatel'),
			isActive: filter.filterVztah.provozovatel,
		},
		{
			label: tsn('formular.label.obdobi-od'),
			value: filter.datumOd ? toDateReadable(filter.datumOd) : undefined,
			onRemove: onRemove('datumOd'),
			isActive: !!filter.datumOd,
		},
		{
			label: tsn('formular.label.obdobi-do'),
			value: filter.datumDo ? toDateReadable(filter.datumDo) : undefined,
			onRemove: onRemove('datumDo'),
			isActive: !!filter.datumDo,
		},
	];

	return { filterParams, filter, setPartialFilter };
};
