import { GovModal } from '@gov-design-system-ce/react';
import { useLocale } from '@gov-nx/core/service';
import {
	NastaveniKontaktniUdajeROSItemContextProvider,
	PageCode,
	ProfilROSReklamaceContextProvider,
	useProfilROSDetailContext,
} from '@gov-nx/module/page';
import { ErrorStatusBanner, LayoutGap } from '@gov-nx/ui/web';
import { CmsPageRender } from '../../../Cms/CmsPageRender';
import { ProfileROSComplaintView } from '../ProfileROSComplaintView';
import { ContactDetailsView } from './ContactDetailsView';
import { EstablishmentsListModal } from './EstablishmentsListModal';
import { MembersListModal } from './MembersListModal';
import { PFODataView } from './PFODataView';
import { PODataView } from './PODataView';
import { PersonsListModal } from './PersonsListModal';
import { ProfileROSDetailSkeleton } from './ProfileROSDetailSkeleton';

export const ProfileROSDetailView = () => {
	const { detailData, controls, setControls, code, name } =
		useProfilROSDetailContext();
	const { t } = useLocale(code);

	const getContent = () => {
		if (controls.initialLoading) {
			return <ProfileROSDetailSkeleton />;
		}

		if (controls.initialError) {
			return <ErrorStatusBanner code={code} />;
		}

		return (
			<LayoutGap gap={4}>
				{detailData?.typ === 'PFO' ? <PFODataView /> : null}
				{detailData?.typ === 'PO' ? <PODataView /> : null}

				{detailData ? (
					<NastaveniKontaktniUdajeROSItemContextProvider
						code={PageCode['nastaveni']}
						contact={detailData}>
						<ContactDetailsView />
					</NastaveniKontaktniUdajeROSItemContextProvider>
				) : null}
				<GovModal
					open={!!controls.complaintModal}
					label={t('formular.podnet-k-oprave-udaju')}
					onGov-close={() => setControls({ complaintModal: null })}
					wcagCloseLabel={'akce.wcag.zavrit-formular'}>
					<div>
						{controls.complaintModal ? (
							<ProfilROSReklamaceContextProvider
								code={code}
								data={controls.complaintModal}
								variant={controls.complaintVariant}
								onComplaintSubmit={() => setControls({ complaintModal: null })}>
								<ProfileROSComplaintView code={code} />
							</ProfilROSReklamaceContextProvider>
						) : null}
					</div>
				</GovModal>
				<MembersListModal />
				<EstablishmentsListModal />
				<PersonsListModal />
			</LayoutGap>
		);
	};

	return (
		<CmsPageRender
			code={code}
			showFavorite={false}
			override={{
				name: name,
				description: t('profil-ros.detail.popis'),
			}}>
			{getContent()}
		</CmsPageRender>
	);
};
