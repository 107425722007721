import * as yup from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';
import { AnyObject } from 'yup/lib/types';
import { LocalizeNameSpaceTypes, useLocale } from '@gov-nx/core/service';
import { Maybe } from '@gov-nx/core/types';
import { FormInputProps } from '@gov-nx/ui/types';

interface FieldProps {
	name: string;
	placeholder: string;
	label: string;
	wcagLabelledBy?: string;
	isDisabled?: boolean;
	isEmail?: boolean;
	isMultiline?: boolean;
	rows?: number;
	isRequiredWhen?: {
		fieldName: string;
		is: boolean | string;
		errorMessage: string;
	};
	max?: { max: number; errorMessage: string };
}

interface RequiredProps extends FieldProps {
	isRequired: true;
	isRequiredMessage?: string;
}

interface OptionalProps extends FieldProps {
	isRequired?: false;
}

interface Output {
	defaultValue: null;
	field: FormInputProps;
}

interface OptionalOutput extends Output {
	schema: yup.StringSchema<Maybe<string>, AnyObject, Maybe<string>>;
}

interface RequiredOutput extends Output {
	schema: RequiredStringSchema<string>;
}

export type InputDefinition = RequiredOutput | OptionalOutput;

export function useInputDefinition(props: RequiredProps): RequiredOutput;

export function useInputDefinition(props: OptionalProps): OptionalOutput;

export function useInputDefinition(
	props: OptionalProps | RequiredProps
): InputDefinition {
	const { t } = useLocale(LocalizeNameSpaceTypes.Form);

	let schema = yup.string().nullable();
	if (props.isRequired) {
		schema = schema.required(props.isRequiredMessage);
	} else {
		schema = schema.optional();
	}
	if (props.isEmail) {
		schema = schema.email(t('validations.email-neni-ve-spravnem-tvaru'));
	}

	if (props.max) {
		schema = schema.max(props.max.max, props.max.errorMessage);
	}

	if (props.isRequiredWhen) {
		schema = schema.when(props.isRequiredWhen.fieldName, {
			is: props.isRequiredWhen.is,
			then: (schema) => schema.required(props.isRequiredWhen?.errorMessage),
			otherwise: (schema) => schema.optional(),
		});
	}
	return {
		schema,
		defaultValue: null,
		field: {
			field: {
				name: props.name,
				placeholder: props.placeholder,
				wcagLabelledBy: props.wcagLabelledBy,
				// isRequired: props?.isRequired,
				isDisabled: props.isDisabled,
				// isMultiline: props.isMultiline,
				multiline: props.isMultiline,
				rows: props.rows,
			},
			label: {
				children: props.label,
			},
		},
	};
}
