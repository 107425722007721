import * as yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import { FormCheckboxProps } from '@gov-nx/ui/types';

interface FieldProps {
	name: string;
	label: string;
	isDisabled?: boolean;
	// isRequired?: boolean;
	oneOf?: { value: boolean[]; errorMessage: string };
}

export type CheckboxDefinition = {
	schema: yup.BooleanSchema<
		boolean | undefined,
		AnyObject,
		boolean | undefined
	>;
	defaultValue: false;
	field: FormCheckboxProps;
};
export const getCheckboxDefinition = (
	props: FieldProps
): CheckboxDefinition => {
	let schema = yup.boolean();

	if (props.oneOf) {
		schema = schema
			.required()
			.oneOf(props.oneOf.value, props.oneOf.errorMessage);
	}

	return {
		schema,
		defaultValue: false,
		field: {
			field: {
				name: props.name,
				isDisabled: props.isDisabled,
				// isRequired: props.isRequired,
			},
			label: {
				children: props.label,
			},
		},
	};
};
