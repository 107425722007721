import {
	differenceInCalendarDays,
	endOfYear,
	isAfter,
	isToday,
	subYears,
} from 'date-fns';
import { DatoveSchrankyDatovaZpravaDto } from '@gov-nx/api/portal-obcana';
import { useAppLanguage } from '@gov-nx/core/hooks';
import { useLocale } from '@gov-nx/core/service';
import { Optional } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import {
	isUnreadMessage,
	toDateReadable,
	toDateShortReadable,
	today,
	toTimeReadable,
	toWeekDay,
} from '@gov-nx/utils/common';
import { isReceivedBefore10Days } from '../hooks/useMessageOperations';
import {
	DataBoxFolderType,
	DataBoxTimeDivisionType,
} from '../providers/DataBoxes.types';

export const getDaysToAutomaticRemoval = (deleteDate: Optional<string>) => {
	const DAYS_TO_INFORM_BEFORE_REMOVAL = 30;

	const daysToAutomaticRemoval = differenceInCalendarDays(
		today(deleteDate),
		today()
	);

	const isWithinDaysToInform =
		daysToAutomaticRemoval > 0 &&
		daysToAutomaticRemoval <= DAYS_TO_INFORM_BEFORE_REMOVAL;

	const isDeleteDateToday = deleteDate ? isToday(today(deleteDate)) : false;

	return {
		showRemovalInfo: isWithinDaysToInform || isDeleteDateToday,
		daysToAutomaticRemoval,
		isDeleteDateToday,
	};
};

export const useAutomaticRemovalInfo = (deleteDate: Optional<string>) => {
	const { t } = useLocale(PageCode['datove-schranky']);
	const removalInfo = getDaysToAutomaticRemoval(deleteDate);

	return {
		...removalInfo,
		archivedMessageRemovalInfo: removalInfo.isDeleteDateToday
			? t('zpravy.po-odstraneni-archiv-dnes')
			: t('zpravy.po-odstraneni-archiv', {
					count: removalInfo.daysToAutomaticRemoval,
			  }),
		messageRemovalInfo: removalInfo.isDeleteDateToday
			? t('zpravy.automaticke-trvale-odstraneni-dnes')
			: t('zpravy.automaticke-trvale-odstraneni-za', {
					count: removalInfo.daysToAutomaticRemoval,
			  }),
	};
};

export const useDataboxDateUtils = () => {
	const { language } = useAppLanguage();

	const getMessageDate = (
		date: string,
		timePeriod: DataBoxTimeDivisionType
	) => {
		if (timePeriod === DataBoxTimeDivisionType.Today) {
			return toTimeReadable(today(date));
		} else if (differenceInCalendarDays(today(), today(date)) < 7) {
			return toWeekDay(today(date), language);
		} else if (
			[
				DataBoxTimeDivisionType.LastYear,
				DataBoxTimeDivisionType.Older,
			].includes(timePeriod)
		) {
			return toDateReadable(today(date));
		} else {
			return toDateShortReadable(today(date));
		}
	};

	const getMessageDateLabel = (date: string) => {
		if (isToday(today(date))) {
			return toTimeReadable(today(date));
		} else if (differenceInCalendarDays(today(), today(date)) < 7) {
			return toWeekDay(today(date), language);
		} else if (isAfter(today(date), subYears(endOfYear(today()), 1))) {
			return toDateShortReadable(today(date));
		} else {
			return toDateReadable(today(date));
		}
	};

	return { getMessageDate, getMessageDateLabel };
};

export const getMessageParams = ({
	message,
	dataBoxId,
	folderType,
	query,
}: {
	message: DatoveSchrankyDatovaZpravaDto;
	dataBoxId: string;
	folderType: DataBoxFolderType;
	query?: string;
}) => {
	const isReceivedMessage = dataBoxId === message.prijemceId;

	const isFolder = (...types: DataBoxFolderType[]) =>
		types.includes(folderType);

	const isUnread =
		isFolder(DataBoxFolderType.Received) &&
		isReceivedMessage &&
		isUnreadMessage(message);

	const isLongUnread = isUnread && isReceivedBefore10Days(message);

	const person = (
		isReceivedMessage ? message.odesilatelNazev : message.prijemceNazev
	) as string;

	const normalizedQuery = query?.toLowerCase();

	const queryIsNotPersonNorSubject =
		!!normalizedQuery &&
		!(message.vec as string).toLowerCase().includes(normalizedQuery) &&
		!person.toLowerCase().includes(normalizedQuery);

	const highlightWholePerson =
		queryIsNotPersonNorSubject &&
		(
			(isReceivedMessage ? message.odesilatelId : message.prijemceId) as string
		).includes(normalizedQuery);

	const highlightWholeSubject =
		queryIsNotPersonNorSubject &&
		(message.datovaZpravaId as number).toString().includes(normalizedQuery);

	return {
		isUnread,
		isLongUnread,
		isReceivedMessage,
		isFolder,
		person,
		highlightWholePerson,
		highlightWholeSubject,
	};
};

export const formatIdentification = (
	identification: DatoveSchrankyDatovaZpravaDto['odesilatelJmenoUzivatele']
) => identification?.split(' ', 1).join();

export const useMessagePriceFormat = () => {
	const { t } = useLocale(PageCode['datove-schranky']);

	return {
		formatPrice: (price: number): string => {
			if (price === 0) return t('zprava.cena-zdarma');
			return `${price / 100} ${t('zprava.cena-koruny')}`;
		},
	};
};
