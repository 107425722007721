import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import { pageRouteResolver } from '@gov-nx/web';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useRezaListContext, useRezaLocale } from '@gov-nx/module/page';
import {
	Button,
	ContentLayout,
	FilterParameterList,
	KeyValues,
	LayoutGap,
	Tile,
	Tiles,
	HeaderSearchBar,
	FormTabs,
} from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';
import { PoFormWeb } from '../../../Form/PoForm';
import { MarkdownRender } from '../../../Markdown/MarkdownRender';
import { ComplaintsModal } from '../ComplaintsModal';
import { FilterModal } from './FilterModal';
import { RezaListSkeleton } from './RezaListSkeleton';

export const RezaList = () => {
	const { t } = useRezaLocale();
	const {
		isLoading,
		data,
		formDefinition,
		onSubmit,
		onFilterReset,
		filterParams,
		isFilterModalOpen,
		complaintsForm,
	} = useRezaListContext();

	return (
		<LayoutGap>
			<ContentLayout>
				<FormProvider {...formDefinition.formMethods}>
					<PoFormWeb
						formDefinition={formDefinition}
						onSubmit={onSubmit}>
						<HeaderSearchBar
							fields={{
								search: formDefinition.fields.text,
								button: formDefinition.fields.button,
							}}
							filterParams={
								<FilterParameterList
									items={filterParams}
									onReset={onFilterReset}
								/>
							}>
							<Button
								onClick={isFilterModalOpen.setTrue}
								variant="primary"
								type="outlined"
								className={'ml-auto'}
								wcagLabel={t('akce.zobrazit-filtr.wcag')}>
								<GovIcon
									name={'funnel-fill'}
									type={'basic'}></GovIcon>
							</Button>
						</HeaderSearchBar>

						<FormTabs {...formDefinition.fields.role} />

						<FilterModal />
					</PoFormWeb>
				</FormProvider>
				<ComplaintsModal complaintsForm={complaintsForm} />

				{isLoading ? (
					<RezaListSkeleton />
				) : data.length === 0 ? (
					<GovMessage variant={'primary'}>
						<GovIcon
							slot={'icon'}
							type={'basic'}
							name={'info'}
						/>
						{t('seznam.empty')}
					</GovMessage>
				) : (
					<Tiles>
						{data.map((row) => {
							return (
								<Tile
									key={[
										row.kod,
										row.role,
										row.typ,
										row.stav,
										row.platnostOd,
										row.zastupujiciOsobaNazev,
										row.kodSablony,
									].join('-')}
									name={row.nazevSablony ?? '-'}
									to={pageRouteResolver('reza.detail', { id: row.kod })}
									icon={{ name: 'chevron-right', type: 'basic' }}>
									<div className={'!text-secondary-700'}>
										<KeyValues
											options={[
												{
													label: t('seznam.item.platnost-od.label'),
													value: row.platnostOd
														? toDateReadable(row.platnostOd)
														: '-',
												},
												{
													label: t('seznam.item.platnost-do.label'),
													value: row.platnostDo
														? toDateReadable(row.platnostDo)
														: t('seznam.item.platnost-do.empty'),
												},
												{
													label:
														row.role === 'zmocnenec'
															? t('seznam.item.zmocnenec')
															: t('seznam.item.zmocnitel'),
													value: row.zastupujiciOsobaNazev ?? '-',
												},
												{
													label: t('seznam.item.identifikator'),
													value: row.kod,
												},
											]}
										/>
									</div>
								</Tile>
							);
						})}
					</Tiles>
				)}
			</ContentLayout>

			<MarkdownRender>
				{t('seznam.footer', {
					link: pageRouteResolver('reza.templates'),
				})}
			</MarkdownRender>
			<Button
				variant="primary"
				type="link"
				onClick={complaintsForm.isModalOpen.setTrue}>
				{t('seznam.button.reklamace.label')}
			</Button>
		</LayoutGap>
	);
};
