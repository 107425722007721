import { GovModal } from '@gov-design-system-ce/react';
import { useLocale } from '@gov-nx/core/service';
import { useProfilROSDetailContext } from '@gov-nx/module/page';
import { DataTable, LayoutGap, Space } from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';

export const EstablishmentsListModal = () => {
	const { isEstablishmentsListModalOpen, establishments, code } =
		useProfilROSDetailContext();
	const { t } = useLocale(code);

	return (
		<GovModal
			open={isEstablishmentsListModalOpen.isTrue}
			label={t('modal.label.provozovny')}
			onGov-close={isEstablishmentsListModalOpen.setFalse}>
			<LayoutGap gap={4}>
				{establishments.map((establishment) => {
					return (
						<LayoutGap
							key={establishment.icp}
							gap={2}>
							<p>
								{t('modal.zaznam.icp')}
								<Space />
								{establishment?.icp ? establishment.icp : '-'}
							</p>
							<DataTable responsive>
								<tbody>
									<tr>
										<td>{t('modal.zaznam.adresa')}</td>
										<td>
											<strong>
												{establishment?.adresa?.adresaText
													? establishment.adresa.adresaText
													: '-'}
											</strong>
										</td>
									</tr>
									<tr>
										<td>{t('modal.zaznam.dorucovaci-adresa')}</td>
										<td>
											<strong>
												{establishment?.dorucovaciAdresa?.adresaText
													? establishment.dorucovaciAdresa.adresaText
													: '-'}
											</strong>
										</td>
									</tr>
									<tr>
										<td>{t('modal.zaznam.datum-zahajeni')}</td>
										<td>
											<strong>
												{establishment?.datumZahajeniCinnosti
													? toDateReadable(establishment.datumZahajeniCinnosti)
													: '-'}
											</strong>
										</td>
									</tr>
								</tbody>
							</DataTable>
						</LayoutGap>
					);
				})}
			</LayoutGap>
		</GovModal>
	);
};
