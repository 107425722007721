import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useGlobalLocale } from '@gov-nx/core/service';
import { LayoutGap } from '@gov-nx/ui/web';

export const DrozdInfoSkeleton = () => {
	const { t } = useGlobalLocale();
	return (
		<LayoutGap gap={8}>
			<div
				className={
					'flex flex-col gap-4 w-full p-8 border border-secondary-400 rounded bg-neutral-white [ md:flex-row ]'
				}>
				<GovSkeleton
					height={'48px'}
					width={'48px'}
					className={'py-2'}
					shape={'circle'}
					wcagLabel={t('loading.wcag.processing')}
				/>
				<div className={'w-full'}>
					<GovSkeleton
						height={'28px'}
						width={'70%'}
						wcagLabel={t('loading.wcag.processing')}
						className={'block mb-3'}
					/>
					<GovSkeleton
						height={'21px'}
						width={'100%'}
						wcagLabel={t('loading.wcag.processing')}
					/>
					<GovSkeleton
						height={'21px'}
						width={'90%'}
						wcagLabel={t('loading.wcag.processing')}
					/>
					<GovSkeleton
						height={'21px'}
						width={'98%'}
						wcagLabel={t('loading.wcag.processing')}
					/>
					<GovSkeleton
						height={'21px'}
						width={'70%'}
						wcagLabel={t('loading.wcag.processing')}
					/>
					<div className={'flex gap-4 mt-4'}>
						<GovSkeleton
							height={'40px'}
							width={'160px'}
							wcagLabel={t('loading.wcag.processing')}
						/>
						<GovSkeleton
							height={'40px'}
							width={'175px'}
							wcagLabel={t('loading.wcag.processing')}
						/>
					</div>
				</div>
			</div>
		</LayoutGap>
	);
};
