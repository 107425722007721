import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import {
	connectToAnotherSubmissionQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	FormDefinition,
	useFormBuilder,
	useLocale,
} from '@gov-nx/core/service';
import { has } from '@gov-nx/core/types';
import {
	ConnectToAnotherSubmissionForm,
	DataMessage,
	PageCode,
} from '@gov-nx/module/page';
import { CommonSelectProps } from '@gov-nx/ui/types';
import { getSelectDefinition } from '@gov-nx/utils/common';
import {
	invalidateMySubmissionsQuery,
	useMySubmissionsListQuery,
} from './queries';

interface Fields {
	submissionId: CommonSelectProps;
}

export interface ConnectToAnotherSubmissionMutation {
	form: FormDefinition<ConnectToAnotherSubmissionForm>;
	fields: Fields;
	isConnectModalOpen: boolean;
	isLoading: boolean;
	isConnecting: boolean;
	onConnectSubmit: () => void;
	onConnectCancel: () => void;
	connect: (message: DataMessage) => void;
}

export const useConnectToAnotherSubmissionMutation = (
	onConnected?: () => void
): ConnectToAnotherSubmissionMutation => {
	const queryClient = useQueryClient();
	const { toastMessageSuccess } = useMessageEvents();
	const { t } = useLocale(PageCode['moje-podani']);

	const [messageReadyForConnection, setMessageReadyForConnection] =
		useState<DataMessage>();

	const submissionsQuery = useMySubmissionsListQuery(
		!!messageReadyForConnection
	);

	const [first] = submissionsQuery.data?.seznam ?? [];
	useEffect(() => {
		if (first?.id) {
			formDefinition.formMethods.setValue('submissionId', `${first.id}`);
		}
	}, [first?.id]);

	const submissionOptions = (submissionsQuery.data?.seznam ?? [])
		.filter(has('id'))
		.map((submission) => ({
			label: submission.nazev,
			value: submission.id,
		}));

	const { fields, ...formDefinition } = useFormBuilder<
		ConnectToAnotherSubmissionForm,
		Fields
	>([
		getSelectDefinition({
			name: 'submissionId',
			options: submissionOptions,
			isRequired: true,
			label: t('formular.pripojit-k-jinemu-podani.podani'),
		}),
	]);

	const connectMutation = usePoMutation({
		mutationFn: async ({
			submissionId,
			datovaSchrankaId,
			datovaZpravaId,
		}: {
			submissionId: string;
			datovaSchrankaId: string;
			datovaZpravaId: number;
		}): Promise<void> => {
			await connectToAnotherSubmissionQuery({
				submissionId,
				body: { datovaSchrankaId, datovaZpravaId },
			});
		},
		onSuccess: async (_, variables) => {
			toastMessageSuccess(t('formular.zprava.pripojeno'));
			setMessageReadyForConnection(undefined);

			if (onConnected) {
				onConnected();
			}

			await invalidateMySubmissionsQuery(queryClient, variables.datovaZpravaId);

			await submissionsQuery.invalidate();
		},
	});

	const handleSubmit = useCallback(
		(formData: ConnectToAnotherSubmissionForm) => {
			if (messageReadyForConnection) {
				connectMutation.mutate({
					submissionId: formData.submissionId,
					datovaSchrankaId: messageReadyForConnection.datovaSchrankaId,
					datovaZpravaId: messageReadyForConnection.datovaZpravaId,
				});
			}
		},
		[messageReadyForConnection, connectMutation]
	);

	return {
		isLoading: submissionsQuery.isLoading,
		isConnectModalOpen: !!messageReadyForConnection,
		isConnecting: connectMutation.isLoading,
		form: formDefinition,
		fields,
		connect: setMessageReadyForConnection,
		onConnectSubmit: formDefinition.formMethods.handleSubmit(handleSubmit),
		onConnectCancel: () => setMessageReadyForConnection(undefined),
	};
};
