import React, { createContext, useContext, useEffect } from 'react';
import {
	PodaniOsvedceniDto,
	PodaniOsvedceniSeznamDto,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import {
	myVerificationsQuery,
	myVerificationsSearchQuery,
} from '@gov-nx/api/portal-obcana';
import { useDownloadEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	FilterParam,
	FilterSortDirection,
	Nullable,
	Time,
} from '@gov-nx/core/types';
import { toDateReadable, useFilter } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import {
	MyVerificationsListOrder,
	MyVerificationsListContext,
	MyVerificationsListControl,
	MyVerificationsListFilter,
} from './context.types';

const PageMojeOsvedceniSeznamContext =
	createContext<Nullable<MyVerificationsListContext>>(null);

interface SettingsMPSContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
}

const defaultFilter: MyVerificationsListFilter = {
	pocet: 10,
	razeni: MyVerificationsListOrder.datumVystaveni,
	razeniSmer: FilterSortDirection.DESC,
	start: 0,
	obdobiOd: null,
	obdobiDo: null,
};

export function MojeOsvedceniSeznamContextProvider({
	children,
	code,
}: SettingsMPSContextProviderProps) {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(code);
	const { downloadVerification } = useDownloadEvents();
	const { setControls, controls } =
		useProcessControl<MyVerificationsListControl>({
			displayFilter: false,
			isDownloadProcessing: false,
		});

	const { filter, setPartialFilter } = useFilter<MyVerificationsListFilter>({
		key: 'moje-podani-osvedceni', // use hardcoded key instead of code which is also used in mySubmissions
		initialState: defaultFilter,
	});

	const formDefinition = FormInstance({
		code,
		defaultValues: defaultFilter,
	});

	useEffect(() => {
		filter.obdobiOd &&
			formDefinition.formMethods.setValue('obdobiOd', filter.obdobiOd);
		filter.obdobiDo &&
			formDefinition.formMethods.setValue('obdobiDo', filter.obdobiDo);
		filter.razeni &&
			formDefinition.formMethods.setValue('razeni', filter.razeni);
		filter.razeniSmer &&
			formDefinition.formMethods.setValue('razeniSmer', filter.razeniSmer);
		filter.hledat &&
			formDefinition.formMethods.setValue('hledat', filter.hledat);
	}, [
		filter.obdobiOd,
		filter.obdobiDo,
		filter.razeni,
		filter.razeniSmer,
		filter.hledat,
		formDefinition.formMethods,
	]);

	const query = usePoQuery<PodaniOsvedceniSeznamDto>({
		queryKey: ['my-verifications-list', filter],
		queryFn: async () => {
			const { params } = prepareSubmitData(filter);

			if (filter.hledat) {
				return myVerificationsSearchQuery(filter.hledat, params);
			}

			return myVerificationsQuery(params);
		},
		staleTime: Time['10 seconds'],
	});

	const verifications: PodaniOsvedceniDto[] = query.data?.seznam ?? [];
	const numberOfVerifications: number = query.data?.pocet ?? 0;

	const filterParams: FilterParam[] = [
		{
			label: tn('formular.select.obdobi-od'),
			value: filter.obdobiOd ? toDateReadable(filter.obdobiOd) : undefined,
			isActive: !!filter.obdobiOd,
			onRemove: () => {
				setPartialFilter({ obdobiOd: undefined, start: 0 });
				formDefinition.formMethods.resetField('obdobiOd');
			},
		},
		{
			label: tn('formular.select.obdobi-do'),
			value: filter.obdobiDo ? toDateReadable(filter.obdobiDo) : undefined,
			isActive: !!filter.obdobiDo,
			onRemove: () => {
				setPartialFilter({ obdobiDo: undefined, start: 0 });
				formDefinition.formMethods.resetField('obdobiDo');
			},
		},
	];

	const resetFilter = async () => {
		setPartialFilter(defaultFilter);
		setControls({ displayFilter: false });
		formDefinition.formReset();
	};
	const goToPage = async (page: number) => {
		setPartialFilter({ start: (page - 1) * filter.pocet });
	};

	const currentPage = filter.start / filter.pocet + 1;

	const onSubmit = formDefinition.formMethods.handleSubmit(async () => {
		setPartialFilter({ ...formDefinition.formMethods.getValues(), start: 0 });
		setControls({ displayFilter: false });
	});

	const handleVerificationDownload = (verificationId: number) => {
		setControls({
			isDownloadProcessing: true,
			isDownloadVerificationId: verificationId,
		});
		return downloadVerification({
			verificationId,
			callback: () => {
				setControls({
					isDownloadProcessing: false,
					isDownloadVerificationId: undefined,
				});
			},
		});
	};

	return (
		<PageMojeOsvedceniSeznamContext.Provider
			value={
				{
					filterParams,
					verifications: verifications
						.filter((verification) => verification.id != null)
						.map((verification) => {
							return {
								id: verification.id,
								addressee: verification.adresatUkonu,
								name: verification.nazevUkonu,
								issueDate: verification.datumVystaveni
									? toDateReadable(verification.datumVystaveni)
									: undefined,
							};
						}),
					controls,
					query,
					numberOfVerifications: numberOfVerifications,
					setControls,
					formDefinition,
					resetFilter,
					filter,
					setPartialFilter,
					currentPage,
					onSubmit,
					goToPage,
					handleVerificationDownload,
				} as MyVerificationsListContext
			}>
			{children}
		</PageMojeOsvedceniSeznamContext.Provider>
	);
}

export const useMojeOsvedceniSeznamContextInstance =
	(): MyVerificationsListContext =>
		useContext(PageMojeOsvedceniSeznamContext) as MyVerificationsListContext;
