import {
	ContactDetailsFormData,
	ContactDetailsFormFields,
	useContactDetailsDefinition,
} from '@gov-nx/core/hooks';
import { FormDefinition2, useFormBuilder } from '@gov-nx/core/service';
import { useKorespondencniVolbyLocale } from '@gov-nx/module/service';
import { FormCheckboxProps } from '@gov-nx/ui/types';
import { getCheckboxDefinition } from '@gov-nx/utils/common';

export interface KorespondencniHlasovaniVymazStep1
	extends ContactDetailsFormData {
	isNoTDuplicatedRequest: boolean;
}

interface Fields extends ContactDetailsFormFields {
	isNoTDuplicatedRequest: FormCheckboxProps;
}

export type KorespondencniHlasovaniVymazFormStep1 = FormDefinition2<
	KorespondencniHlasovaniVymazStep1,
	Fields
>;

export const useFormStep1 = ({
	isDisabled,
}: {
	isDisabled: boolean;
}): KorespondencniHlasovaniVymazFormStep1 => {
	const { t } = useKorespondencniVolbyLocale();

	const contactDetails = useContactDetailsDefinition({
		isDisabled,
		strings: {
			_useEmail: { label: t('kontaktni-udaje.email.label') },
			_usePhone: { label: t('kontaktni-udaje.phone.label') },
			email: {
				label: '',
				placeholder: t('kontaktni-udaje.email.placeholder'),
			},
			phone: {
				label: '',
				placeholder: t('kontaktni-udaje.phone.placeholder'),
			},
		},
	});

	const isNoTDuplicatedRequest = getCheckboxDefinition({
		name: 'isNoTDuplicatedRequest',
		isDisabled,
		oneOf: {
			value: [true],
			errorMessage: t('vymaz.formular.neni-duplicitni-zadost.validace.povinny'),
		},
		label: t('vymaz.formular.neni-duplicitni-zadost.label'),
	});

	return useFormBuilder<KorespondencniHlasovaniVymazStep1, Fields>([
		isNoTDuplicatedRequest,
		...contactDetails,
	]);
};
