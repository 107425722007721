import React, { createContext, PropsWithChildren, useContext } from 'react';

export interface ConnectionNotFetched {
	isFetched: false;
}

export interface ConnectionFetched {
	isFetched: true;
	isOnline: boolean;
	isOffline: boolean;
}

export type Connection = ConnectionFetched | ConnectionNotFetched;

interface Props extends PropsWithChildren {
	connectionChecker: () => Connection;
	splashScreen: React.JSX.Element;
}

export const OnlineStatusProvider = ({
	children,
	connectionChecker,
	splashScreen,
}: Props): React.JSX.Element => {
	const state = connectionChecker();
	/* Note: We have to wait for online status to be fetched (to prevent falsy result when online) */
	if (!state.isFetched) {
		return splashScreen;
	}

	return <Context.Provider value={state}>{children}</Context.Provider>;
};

const Context = createContext<ConnectionFetched>({
	isFetched: true,
	isOnline: true,
	isOffline: false,
});

/**
 * Note:
 * On WEB there is no <OnlineStatusProvider /> initialized so this returns the default state: { isFetched: true, isOnline: true, isOffline: false }
 * On NATIVE we have <OnlineStatusProvider /> initialized so this returns the real connection state
 */
export const useConnection = (): ConnectionFetched => useContext(Context);
