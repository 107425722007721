import { GovAccordion, GovAccordionItem } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CmsFaqListItem } from '@gov-nx/api/common';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { MarkdownRender } from '../Markdown/MarkdownRender';

export interface CmsFaqProps {
	faq: CmsFaqListItem[];
	localizeNameSpace?: LocalizeNameSpaceTypes;
}

export const CmsFaq = ({
	faq,
	localizeNameSpace = LocalizeNameSpaceTypes.Page,
}: CmsFaqProps) => {
	const { t } = useTranslation([localizeNameSpace]);
	return (
		<section>
			<h2
				id="titulek-casto-se-ptate"
				className={'mb-4 text-4xl'}>
				{t('casto-se-ptate', { namespace: localizeNameSpace })}
			</h2>
			<GovAccordion
				wcagLabelledBy="titulek-casto-se-ptate"
				size={'s'}
				noBorder>
				{faq.map((accordion) => {
					return (
						<GovAccordionItem key={accordion.id}>
							<h3 slot={'label'}>{accordion.question}</h3>
							<MarkdownRender>{accordion.answer}</MarkdownRender>
						</GovAccordionItem>
					);
				})}
			</GovAccordion>
		</section>
	);
};
