import React, { createContext, PropsWithChildren, useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { CmsExternalServiceLink } from '@gov-nx/api/common';
import { useFormSession } from '@gov-nx/core/hooks';
import { FormDefinition2 } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { Data, Fields, useSmartFormRepresentationForm } from './FormDefinition';

interface ServiceContextTypes {
	isEligibleListLoading: boolean;
	isFormSessionLoading: boolean;
	form: FormDefinition2<Data, Fields>;
	hasRepresentationOptions: boolean;
	role: Data['role'];
	link: CmsExternalServiceLink;
	onSubmit: () => void;
	redirectUrl: Nullable<string>;
}

const Context = createContext<ServiceContextTypes | null>(null);

export function SmartFormContextProvider({
	children,
	link,
}: PropsWithChildren<{ link: CmsExternalServiceLink }>) {
	const { form, role, eligibleList, hasRepresentationOptions } =
		useSmartFormRepresentationForm({ link: link.link });

	const formSession = useFormSession();

	const onSubmit = form.formMethods.handleSubmit((data) =>
		formSession.submit({
			paramsString: link.link,
			rezaCode:
				data.role === 'v_zastoupeni' ? data.rezaCode ?? undefined : undefined,
		})
	);

	return (
		<Context.Provider
			value={{
				link,
				isEligibleListLoading: eligibleList.isLoading,
				isFormSessionLoading: formSession.isLoading || formSession.isSuccess,
				form,
				hasRepresentationOptions,
				role,
				onSubmit,
				redirectUrl: formSession.redirectUrl,
			}}>
			<FormProvider {...form.formMethods}>{children}</FormProvider>
		</Context.Provider>
	);
}

export const useSmartFormContext = (): ServiceContextTypes =>
	useContext(Context) as ServiceContextTypes;
