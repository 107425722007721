import { GovIcon } from '@gov-design-system-ce/react';
import { pageRouteResolver } from '@gov-nx/web';
import { useConfiguration, useLocale } from '@gov-nx/core/service';
import { useDataBoxesContext } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button, CopyButton, Modal } from '@gov-nx/ui/web';

export const DataBoxSupportModal = () => {
	const { t } = useLocale(PageCode['datove-schranky']);
	const { isSupportModalOpen } = useDataBoxesContext();

	const { napovedaDatoveSchrankyUrl, portalObcanaEmail } = useConfiguration();

	return (
		<Modal
			open={isSupportModalOpen.isTrue}
			onGov-close={isSupportModalOpen.setFalse}
			label={t('schranky.napoveda')}>
			<div className="flex flex-col space-y-8">
				{napovedaDatoveSchrankyUrl && (
					<div className="flex flex-col space-y-2">
						<div>{t('napoveda.vice-o-datovych-schrankach')}</div>
						<Button
							href={napovedaDatoveSchrankyUrl}
							enforceLink={true}
							target="_blank"
							variant="primary"
							type="outlined"
							wcagLabel={t('napoveda.prejit-na-gov')}>
							<GovIcon
								name="box-arrow-up-right"
								slot="right-icon"
							/>
							{t('napoveda.prejit-na-gov')}
						</Button>
					</div>
				)}
				<div className="flex flex-col space-y-2">
					<div>{t('napoveda.napiste-na-podporu')}</div>
					<div className="flex flex-col items-start gap-2 [ sm:flex-row sm:gap-4 sm:items-center ]">
						<Button
							href={pageRouteResolver(PageCode['podpora'])}
							enforceLink={true}
							target="_blank"
							variant="primary"
							type="outlined"
							wcagLabel={t('napoveda.formular-podpory')}>
							{t('napoveda.formular-podpory')}
							<GovIcon
								name="question-circle"
								slot="right-icon"
							/>
						</Button>
						<div>
							<a
								href={'mailto:' + portalObcanaEmail}
								className={
									'gov-link text-s text-primary-600 font-semibold max-w-fit'
								}>
								{portalObcanaEmail}
							</a>
							<CopyButton
								variant="primary"
								size="m"
								label={t('akce.zkopirovat-emailovou-adresu')}
								value={portalObcanaEmail}
							/>
						</div>
					</div>
				</div>
			</div>
			<GovIcon
				type="basic"
				name="question-circle"
				slot="icon"
			/>
		</Modal>
	);
};
