import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
	CiselnikyCiselnikPolozkaSeznamDto,
	OsobyEditorSeznamDto,
	OsobyPodnikatelRozsireneDto,
	ResponseError,
	editorsOvmQuery,
	legalFormQuery,
	sendComplaintQueryRos,
	usePoMutation,
	usePoQueries,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useMessageEvents } from '@gov-nx/core/events';
import { useAddressSearch, useProcessControl } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '../../definitions/codes';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import {
	FormData,
	ProfileROSComplaintContext,
	ProfileRosComplaintProcessControl,
} from './context.types';
import { RosComplaintVariant, useRosComplaintOptions } from './options';
import {
	getEmailContact,
	getPhoneContact,
	getSortedLegalFormOptions,
	interruptionToBoolean,
} from './utils';

const ProfilROSReklamaceContext =
	createContext<Nullable<ProfileROSComplaintContext>>(null);

interface ProfilROSComplaintContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
	data: OsobyPodnikatelRozsireneDto;
	variant: RosComplaintVariant;
	onComplaintSubmit?: () => void;
}

export function ProfilROSReklamaceContextProvider({
	children,
	code,
	data,
	variant,
	onComplaintSubmit,
}: ProfilROSComplaintContextProviderProps) {
	const { setControls, controls } =
		useProcessControl<ProfileRosComplaintProcessControl>({
			initialLoading: true,
			displaySearchAddressLoading: false,
			displaySearchAddress: false,
			initialLegalFormError: null,
			initialEditorsError: null,
		});
	const { toastMessageSuccess, toastMessageError } = useMessageEvents();
	const { t } = useTranslation([code]);

	const [rosLegalFormResult, rosEditorsResult] = usePoQueries<
		[
			UseQueryResult<
				CiselnikyCiselnikPolozkaSeznamDto,
				AxiosError<ResponseError>
			>,
			UseQueryResult<OsobyEditorSeznamDto, AxiosError<ResponseError>>
		]
	>({
		queries: [
			{
				queryKey: ['profile-ros-legal-form'],
				queryFn: legalFormQuery,
				onError: async (error: Error) => {
					setControls({ initialLegalFormError: new GovError(error.message) });
				},
			},
			{
				queryKey: ['profile-ros-editors', data.ico],
				queryFn: () => editorsOvmQuery(data.ico),
				onError: async (error: Error) => {
					setControls({ initialEditorsError: new GovError(error.message) });
				},
			},
		],
		onAllSuccess: () => {
			setControls({ initialLoading: false });
		},
	});

	const legalForm = rosLegalFormResult.data?.seznam ?? [];
	const editors = rosEditorsResult.data?.seznam ?? [];

	const formDefinition = FormInstance({ code, data, controls });

	const { address, mainQuery } = useAddressSearch({
		formMethods: formDefinition.formMethods,
		onSuccess: () => setControls({ displaySearchAddressLoading: false }),
		onError: () => setControls({ displaySearchAddressLoading: false }),
	});

	const newAddress = formDefinition.formMethods.watch('newAddress');

	useEffect(() => {
		const selectedOption = address.find((option) => option.kod === newAddress);
		if (selectedOption && address.length > 0) {
			formDefinition.formMethods.setValue(
				'adresaSidla',
				selectedOption.adresaText || ''
			);
		}
	}, [newAddress, address, formDefinition.formMethods]);

	const submitMutation = usePoMutation({
		mutationFn: async (formData: FormData) => {
			const prepared = prepareSubmitData(
				newAddress,
				editors,
				data.ico
			)(formData);
			return sendComplaintQueryRos(prepared);
		},
		onError: (error) => {
			toastMessageError(t('formular.zprava.neodeslano'));
			setControls({ processError: error, processLoading: false });
		},
		onSuccess: async () => {
			toastMessageSuccess(t('formular.zprava.odeslano'));
			setControls({ processLoading: false });
			formDefinition.formMethods.reset();
			onComplaintSubmit && onComplaintSubmit();
		},
	});

	const resetAddressSearchFields = () => {
		formDefinition.formMethods.resetField('nazevObce');
		formDefinition.formMethods.resetField('nazevUlice');
		formDefinition.formMethods.resetField('cislo');
	};

	const handleSubmit = useCallback(async () => {
		setControls({ processError: null, processLoading: true });
		const { getValues } = formDefinition.formMethods;
		const {
			nazevSpolecnosti,
			agenda,
			datumOd,
			datumDo,
			pravniForma,
			pozastaveniPreruseni,
			adresaSidla,
			popisReklamace,
		} = getValues();
		const changedValues: FormData = {};

		if (nazevSpolecnosti !== data.nazevSpolecnosti) {
			changedValues.nazevSpolecnosti = nazevSpolecnosti;
		}

		if (agenda?.selected?.kod !== data.agenda?.kod) {
			changedValues.agenda = agenda;
		}

		if (datumOd !== data.datumVzniku) {
			changedValues.datumOd = datumOd;
		}

		if (datumDo !== data.datumZaniku) {
			changedValues.datumDo = datumDo;
		}

		if (pravniForma !== data.pravniForma?.kod) {
			changedValues.pravniForma = pravniForma;
		}
		const initialInterruption = data.pozastaveniPreruseni ?? false;
		if (interruptionToBoolean(pozastaveniPreruseni) !== initialInterruption) {
			changedValues.pozastaveniPreruseni = pozastaveniPreruseni;
		}

		if (adresaSidla !== data.sidloSpolecnosti?.adresaText) {
			changedValues.adresaSidla = adresaSidla;
		}

		changedValues._telefon = getPhoneContact(getValues());
		changedValues._email = getEmailContact(getValues());

		changedValues.popisReklamace = popisReklamace;

		if (Object.keys(changedValues).length > 0) {
			submitMutation.mutate({
				...changedValues,
			});
		} else {
			setControls({ processLoading: false });
		}
	}, [submitMutation, formDefinition.formMethods, setControls]);

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);

	const sortedOptions = getSortedLegalFormOptions(legalForm);
	const selectedLegalForm = formDefinition.formMethods.getValues('pravniForma');

	const filteredOptions = sortedOptions.filter(
		(option) => option.label !== selectedLegalForm
	);

	const { interruptionOptions } = useRosComplaintOptions();

	return (
		<ProfilROSReklamaceContext.Provider
			value={{
				controls,
				setControls,
				variant,
				formDefinition,
				data,
				legalForm,
				onSubmit,
				address,
				selectedLegalForm,
				filteredOptions,
				interruptionOptions,
				mainQuery,
				resetAddressSearchFields,
				disableSubmitButton:
					!formDefinition.formMethods.watch('kontaktTelefon') &&
					!formDefinition.formMethods.watch('kontaktEmail'),
			}}>
			{children}
		</ProfilROSReklamaceContext.Provider>
	);
}

export const useProfilROSReklamaceContextInstance =
	(): ProfileROSComplaintContext =>
		useContext(ProfilROSReklamaceContext) as ProfileROSComplaintContext;
