import {
	QueryKey,
	useQuery,
	useQueryClient,
	UseQueryOptions,
} from '@tanstack/react-query';
import { UseQueryResult } from '@tanstack/react-query/src/types';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useConnection } from '@gov-nx/core/hooks';
import { ResponseError } from '../errors/errors.types';
import { usePoResponseError } from '../errors/usePoResponseError';
import { PoQueryConfig } from './types';

export const usePoQuery = <
	TQueryFnData = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey
>(
	options: UseQueryOptions<
		TQueryFnData,
		AxiosError<ResponseError>,
		TData,
		TQueryKey
	>,
	config?: PoQueryConfig
): UseQueryResult<TData, AxiosError<ResponseError>> => {
	const { handleError } = usePoResponseError();
	const queryClient = useQueryClient();
	const { isOffline } = useConnection();

	useEffect(() => {
		const cached =
			options.queryKey && queryClient.getQueryData<TData>(options.queryKey);
		if (cached && options.onSuccess) {
			options.onSuccess(cached);
		}
	}, []);

	return useQuery({
		...options,
		onError: (error) => {
			// Note: Don't throw errors when no internet connection
			if (isOffline) return;

			if (config?.errorIgnoreFilter && config.errorIgnoreFilter(error)) {
				return;
			}

			handleError(error);

			options.onError && options.onError(error);
		},
	});
};
