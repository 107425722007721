import { FormSelectOption } from '@asseco/react-native-gov-components';
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
	CiselnikyCiselnikPolozkaDto,
	countriesQuery,
	OsobyFyzickaOsobaDto,
	sendComplaintQuery,
	usePoMutation,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	useAddressSearch,
	usePersonCommunicationData,
	useProcessControl,
} from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '../../definitions/codes';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import {
	FormData,
	ProfileROBComplaintContext,
	ProfileRobComplaintProcessControl,
} from './context.types';

const ProfilROBReklamaceContext =
	createContext<Nullable<ProfileROBComplaintContext>>(null);

interface ProfilROBComplaintContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
	data: Nullable<OsobyFyzickaOsobaDto>;
	onComplaintSubmit?: () => void;
}

export function ProfilROBReklamaceContextProvider({
	children,
	code,
	data,
	onComplaintSubmit,
}: ProfilROBComplaintContextProviderProps) {
	const { setControls, controls } =
		useProcessControl<ProfileRobComplaintProcessControl>({
			initialDataError: null,
			initialCountryError: null,
			displaySearchAddressLoading: false,
			displaySearchAddress: false,
			displaySearchDeliveryAddressLoading: false,
			displaySearchDeliveryAddress: false,
			displayBirthPlace: false,
		});

	const communicationData = usePersonCommunicationData();

	const [country, setCountry] = useState<
		Nullable<CiselnikyCiselnikPolozkaDto[]>
	>([]);
	const { toastMessage } = useMessageEvents();
	const { t } = useTranslation([code]);
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const POHLAVI_OPTIONS: FormSelectOption[] = [
		{
			label: tsn('formular.placeholders.pohlavi'),
			value: '',
		},
		{
			label: tsn('zaznam.pohlavi-moznosti.muz'),
			value: 'muz',
		},
		{
			label: tsn('zaznam.pohlavi-moznosti.zena'),
			value: 'zena',
		},
	];

	const OMEZENI_SVEPRAVNOSTI_OPTIONS: FormSelectOption[] = [
		{
			label: tsn('formular.placeholders.omezeni-svepravnosti'),
			value: '',
		},
		{
			label: tsn('zaznam.omezeni-svepravnosti-status.ano'),
			value: 'ano',
		},
		{
			label: tsn('zaznam.omezeni-svepravnosti-status.ne'),
			value: 'ne',
		},
	];

	const RODINNY_STAV_PARTNERSTVI_OPTIONS: FormSelectOption[] = [
		{
			label: tsn('formular.placeholders.rodinny-stav-partnerstvi'),
			value: '',
		},
		{
			label: tsn('zaznam.rodinny-stav-moznosti.SVOBODNY-SVOBODNA'),
			value: 'SVOBODNY/SVOBODNA',
		},
		{
			label: tsn('zaznam.rodinny-stav-moznosti.ZENATY-VDANA'),
			value: 'ZENATY/VDANA',
		},
		{
			label: tsn('zaznam.rodinny-stav-moznosti.ROZVEDENY-ROZVEDENA'),
			value: 'ROZVEDENY/ROZVEDENA',
		},
		{
			label: tsn('zaznam.rodinny-stav-moznosti.ZANIKLE-MANZELSTVI'),
			value: 'ZANIKLE MANZELSTVI',
		},
		{
			label: tsn('zaznam.rodinny-stav-moznosti.VDOVEC-VDOVA'),
			value: 'VDOVEC/VDOVA',
		},
		{
			label: tsn('zaznam.rodinny-stav-moznosti.PARTNERSTVI'),
			value: 'PARTNERSTVI',
		},
		{
			label: tsn(
				'zaznam.rodinny-stav-moznosti.ZANIKLE-PARTNERSTVI-ROZHODNUTIM'
			),
			value: 'ZANIKLE PARTNERSTVI ROZHODNUTIM',
		},
		{
			label: tsn('zaznam.rodinny-stav-moznosti.ZANIKLE-PARTNERSTVI'),
			value: 'ZANIKLE PARTNERSTVI',
		},
		{
			label: tsn('zaznam.rodinny-stav-moznosti.ZANIKLE-PARTNERSTVI-SMRTI'),
			value: 'ZANIKLE PARTNERSTVI SMRTI',
		},
		{
			label: tsn('zaznam.rodinny-stav-moznosti.ODLOUCENY-ODLOUCENA'),
			value: 'ODLOUCENY/ODLOUCENA',
		},
		{
			label: tsn('zaznam.rodinny-stav-moznosti.B'),
			value: 'B',
		},
		{
			label: tsn('zaznam.rodinny-stav-moznosti.C'),
			value: 'C',
		},
	];

	usePoQuery({
		queryKey: ['profile-rob-countries'],
		queryFn: countriesQuery,
		onSuccess: (robCountry) => {
			setCountry(robCountry.seznam ?? []);
			setControls({ initialLoading: false });
		},
		onError: async (error: Error) => {
			setControls({ initialCountryError: new GovError(error.message) });
		},
	});

	const formDefinition = FormInstance({
		code,
		data,
		communicationData,
		controls,
	});

	const { address, deliveryAddress, mainQuery, deliveryAddressQuery } =
		useAddressSearch({
			formMethods: formDefinition.formMethods,
			onSuccess: () =>
				setControls({
					displaySearchAddressLoading: false,
					displaySearchDeliveryAddressLoading: false,
				}),
			onError: () =>
				setControls({
					displaySearchAddressLoading: false,
					displaySearchDeliveryAddressLoading: false,
				}),
		});

	const newAddress = formDefinition.formMethods.watch('newAddress');
	const newDeliveryAddress =
		formDefinition.formMethods.watch('newDeliveryAddress');

	useEffect(() => {
		const selectedOption = address.find((option) => option.kod === newAddress);
		if (selectedOption && address.length > 0) {
			formDefinition.formMethods.setValue(
				'adresaPobytu',
				selectedOption.adresaText || ''
			);
		}
	}, [newAddress, address, formDefinition.formMethods]);

	useEffect(() => {
		const selectedOption = deliveryAddress.find(
			(option) => option.kod === newDeliveryAddress
		);
		if (selectedOption && deliveryAddress.length > 0) {
			formDefinition.formMethods.setValue(
				'adresaDorucovaci',
				selectedOption.adresaText || ''
			);
		}
		if (newDeliveryAddress === tsn('formular.adresa.neni-evidovana')) {
			formDefinition.formMethods.setValue(
				'adresaDorucovaci',
				tsn('formular.adresa.neni-evidovana') || ''
			);
		}
	}, [newDeliveryAddress, deliveryAddress, formDefinition.formMethods, tsn]);

	const newPlaceOfBirth = formDefinition.formMethods.watch('newPlaceOfBirth');

	const submitMutation = usePoMutation({
		mutationFn: async (data: FormData) => {
			const prepared = prepareSubmitData(
				newAddress,
				newDeliveryAddress,
				newPlaceOfBirth
			)(data);
			return sendComplaintQuery(prepared);
		},
		onError: (error) => {
			setControls({ processError: error, processLoading: false });
		},
		onSuccess: async () => {
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
				},
				content: t('formular.zprava.odeslano', { namespace: code }),
			});
			setControls({ processLoading: false });
			formDefinition.formMethods.reset();
			onComplaintSubmit && onComplaintSubmit();
		},
	});

	const [initialFormValues, setInitialFormValues] = useState<FormData | null>(
		null
	);

	useEffect(() => {
		setInitialFormValues(formDefinition.formMethods.getValues());
	}, [formDefinition.formMethods]);

	const resetBirthPlaceSearchFields = () => {
		formDefinition.formMethods.resetField('stat');
		formDefinition.formMethods.resetField('newPlaceOfBirth');
	};

	const resetAddressSearchFields = () => {
		formDefinition.formMethods.resetField('nazevObce');
		formDefinition.formMethods.resetField('nazevUlice');
		formDefinition.formMethods.resetField('cislo');
	};

	const resetDeliveryAddressSearchFields = () => {
		formDefinition.formMethods.resetField('nazevObceDorucovaciAdresa');
		formDefinition.formMethods.resetField('nazevUliceDorucovaciAdresa');
		formDefinition.formMethods.resetField('cisloDorucovaciAdresa');
	};

	const getCleanedChangedValues = useCallback(
		(changedValues: FormData): FormData => {
			let filteredChangedValues: FormData = {};
			Object.entries(changedValues).forEach(([ogKey, value]) => {
				const key = ogKey as keyof FormData;
				switch (key) {
					case 'poskytnoutEmail':
					case 'email':
					case 'poskytnoutTelefon':
					case 'telefonniCislo':
						filteredChangedValues = {
							...filteredChangedValues,
							[key]: value,
						};
						break;
					case 'omezeniSvepravnosti':
						if (
							(!data?.omezeniSvepravnosti && value === 'ne') ||
							(data?.omezeniSvepravnosti && value === 'ano')
						) {
							return undefined;
						}
						filteredChangedValues = {
							...filteredChangedValues,
							[key]: value,
						};
						break;

					case 'adresaDorucovaci':
					case 'adresaPobytu':
						if (value === data?.[key]?.adresaText) {
							return undefined;
						}
						filteredChangedValues = {
							...filteredChangedValues,
							[key]: value,
						};
						break;
					case undefined:
						if (data?.[key]) {
							filteredChangedValues = {
								...filteredChangedValues,
								[key]: value,
							};
						}
						break;
					default:
						if (
							value.toLowerCase() ===
							(
								data?.[key as keyof Nullable<OsobyFyzickaOsobaDto>] ?? ''
							).toLowerCase()
						) {
							return undefined;
						} else {
							filteredChangedValues = {
								...filteredChangedValues,
								[key as keyof FormData]: value,
							};
						}
						break;
				}
			});

			// Remove stat if mistoNarozeni is not present
			if (!filteredChangedValues.mistoNarozeni && filteredChangedValues.stat) {
				const { stat, ...rest } = filteredChangedValues;
				filteredChangedValues = rest;
			}

			return filteredChangedValues;
		},
		[data]
	);

	const handleSubmit = useCallback(async () => {
		setControls({ processError: null, processLoading: true });
		const changedValues: FormData = {};
		for (const fieldName in initialFormValues) {
			const initialValue = initialFormValues[fieldName as keyof FormData];
			const currentValue =
				formDefinition.formMethods.getValues()[
					fieldName === 'mistoNarozeni'
						? 'newPlaceOfBirth'
						: (fieldName as keyof FormData)
				];
			const trimmedCurrentValue =
				typeof currentValue === 'string' ? currentValue.trim() : currentValue;

			if (
				fieldName === 'email' ||
				fieldName === 'telefonniCislo' ||
				initialValue !== trimmedCurrentValue
			) {
				changedValues[fieldName as keyof FormData] = trimmedCurrentValue;
			}
		}
		if (Object.keys(changedValues).length > 0) {
			const cleanedChangedValues = getCleanedChangedValues(changedValues);
			submitMutation.mutate(cleanedChangedValues);
		} else {
			setControls({ processLoading: false });
		}
	}, [
		setControls,
		initialFormValues,
		formDefinition.formMethods,
		getCleanedChangedValues,
		submitMutation,
	]);

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);

	const emailError = formDefinition.formMethods.formState.errors.email;
	const phoneError = formDefinition.formMethods.formState.errors.telefonniCislo;

	return (
		<ProfilROBReklamaceContext.Provider
			value={{
				controls,
				setControls,
				formDefinition,
				data,
				country,
				address,
				mainQuery,
				deliveryAddress,
				deliveryAddressQuery,
				resetAddressSearchFields,
				resetDeliveryAddressSearchFields,
				resetBirthPlaceSearchFields,
				onSubmit,
				disableSubmitButton: submitMutation.isSuccess,
				pohlaviOptions: POHLAVI_OPTIONS,
				omezeniSvepravnostiOptions: OMEZENI_SVEPRAVNOSTI_OPTIONS,
				rodinnyStavPartnerstviOptions: RODINNY_STAV_PARTNERSTVI_OPTIONS,
				emailError,
				phoneError,
			}}>
			{children}
		</ProfilROBReklamaceContext.Provider>
	);
}

export const useProfilROBReklamaceContextInstance =
	(): ProfileROBComplaintContext =>
		useContext(ProfilROBReklamaceContext) as ProfileROBComplaintContext;
