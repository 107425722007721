import { create } from 'zustand';
import { Country, Language } from '@gov-nx/api/common';
import { prop } from '@gov-nx/core/types';
import { ListsState } from './store.types';

const initialState = {
	countries: [],
	languages: [],
};

const useListsStoreBase = create<ListsState>()((set, get) => ({
	...initialState,

	saveCountryList: (countries: Country[]) => set({ countries }),
	saveLanguageList: (languages: Language[]) => set({ languages }),

	getEuropeCountries: () => get().countries?.filter(({ eu }) => eu),
}));

export const useListsStore = <T extends keyof ListsState>(
	property: T
): ListsState[T] => useListsStoreBase(prop(property));
