import { GovIcon } from '@gov-design-system-ce/react';
import React, { PropsWithChildren } from 'react';
import { WebInputProps } from '@gov-nx/ui/types';
import {
	Button,
	FormInput,
	FormWidthElementLayout,
	HeaderLayout,
	HeaderLayoutProps,
} from '@gov-nx/ui/web';

type Props = PropsWithChildren<{
	filterParams?: HeaderLayoutProps['filterParams'];
	fields: {
		search: WebInputProps;
		button: {
			wcagLabel: string;
		};
	};
}>;

export const HeaderSearchBar = ({ children, filterParams, fields }: Props) => {
	return (
		<HeaderLayout filterParams={filterParams}>
			<FormWidthElementLayout
				type="l"
				className={'relative'}>
				<FormInput
					field={fields.search.field}
					label={{
						children: '',
					}}
					group={{ gap: 's' }}
				/>
				<label
					id={fields.search.field.wcagLabelledBy}
					className={'hidden'}>
					{fields.search.label.children}
				</label>
				<div className={'absolute top-1 right-1'}>
					<Button
						nativeType={'submit'}
						variant="primary"
						type="solid"
						size="s"
						wcagLabel={fields.button.wcagLabel}>
						<GovIcon
							name={'search'}
							type={'basic'}
							slot="left-icon"></GovIcon>
					</Button>
				</div>
			</FormWidthElementLayout>

			{children}
		</HeaderLayout>
	);
};
