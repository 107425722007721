import { MarkdownRender } from '@gov-nx/component/web';
import { ConfirmationPromptProps, PopConfirmProps } from '@gov-nx/ui/types';
import { useBoolean } from '@gov-nx/utils/common';
import { Button } from '../Button/Button';
import { Prompt } from './Prompt';

export const ConfirmationPrompt = ({
	children,
	isOpen,
	onCancel,
	onConfirm,
	strings,
	isDisabled,
	isLoading,
}: ConfirmationPromptProps) => {
	return (
		<Prompt
			label={strings.title}
			open={isOpen}
			onGov-close={onCancel}>
			{children}
			<Button
				nativeType="button"
				wcagLabel={strings.buttons.cancel.wcagLabel}
				variant="primary"
				type="base"
				slot="actions"
				disabled={isDisabled ?? false}
				onClick={onCancel}>
				{strings.buttons.cancel.label}
			</Button>
			<Button
				nativeType="button"
				wcagLabel={strings.buttons.confirm.wcagLabel}
				variant="error"
				type="solid"
				slot="actions"
				disabled={isDisabled ?? false}
				loading={isLoading}
				onClick={onConfirm}>
				{strings.buttons.confirm.label}
			</Button>
		</Prompt>
	);
};

export const PopConfirm = ({
	onConfirm,
	strings,
	children,
	isDisabled,
	isLoading,
}: PopConfirmProps) => {
	const isOpen = useBoolean();

	return (
		<div>
			<ConfirmationPrompt
				isOpen={isOpen.value}
				isDisabled={isDisabled}
				isLoading={isLoading}
				onConfirm={() => onConfirm(isOpen.setFalse)}
				onCancel={isOpen.setFalse}
				strings={strings}>
				<MarkdownRender>{strings.body}</MarkdownRender>
			</ConfirmationPrompt>
			{children({ open: isOpen.setTrue })}
		</div>
	);
};
