import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from 'react';
import {
	ovmQuery,
	ppdfConsentsQuery,
	ppdfConsentsSearchQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useProcessControl } from '@gov-nx/core/hooks';
import {
	FilterParam,
	FilterSortDirection,
	Nullable,
	Optional,
	Time,
} from '@gov-nx/core/types';
import { useFilter } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import { usePpdfSouhlasyLocale } from '../usePpdfSouhlasyLocale';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import {
	PpdfConsentsListControl,
	PpdfConsentsListFilter,
	PpdfConsentsListOrder,
	PpdfSouhlasySeznamContext,
} from './context.types';

const PageContext = createContext<Nullable<PpdfSouhlasySeznamContext>>(null);

interface Props {
	children: ReactNode;
}

const defaultFilter: PpdfConsentsListFilter = {
	pocet: 100,
	razeni: PpdfConsentsListOrder.datumTrvaniUdeleniSouhlasu,
	razeniSmer: FilterSortDirection.DESC,
	start: 0,
	odvolano: false,
};

export const PpdfSouhlasySeznamContextProvider = ({ children }: Props) => {
	const code = PageCode['ppdf-souhlasy'];
	const [ppdfConsentDetailId, setPpdfConsentDetailId] =
		useState<Optional<number>>(undefined);
	const { t } = usePpdfSouhlasyLocale();

	const { setControls, controls } = useProcessControl<PpdfConsentsListControl>({
		displayFilter: false,
	});

	const { filter, setPartialFilter } = useFilter<PpdfConsentsListFilter>({
		key: code,
		initialState: defaultFilter,
	});

	const formDefinition = FormInstance({
		code,
		defaultValues: defaultFilter,
	});

	useEffect(() => {
		filter.odvolano &&
			formDefinition.formMethods.setValue('odvolano', filter.odvolano);
		filter.razeni &&
			formDefinition.formMethods.setValue('razeni', filter.razeni);
		filter.razeniSmer &&
			formDefinition.formMethods.setValue('razeniSmer', filter.razeniSmer);
		filter.hledat &&
			formDefinition.formMethods.setValue('hledat', filter.hledat);
	}, [
		filter.hledat,
		filter.razeni,
		filter.razeniSmer,
		filter.odvolano,
		formDefinition.formMethods,
	]);

	const query = usePoQuery({
		queryKey: ['ppdf-consent', filter],
		queryFn: async () => {
			const { params } = prepareSubmitData(filter);

			if (filter.hledat) {
				return ppdfConsentsSearchQuery(filter.hledat, params);
			}

			return ppdfConsentsQuery(params);
		},
		staleTime: Time['10 seconds'],
	});

	const queryOvm = usePoQuery({
		queryKey: ['ovm'],
		queryFn: () => ovmQuery(true),
	});

	const filterParams: FilterParam[] = [
		{
			label: t('filtr.odvolano.label'),
			value: filter.odvolano ? filter.odvolano : undefined,
			isActive: !!filter.odvolano,
			onRemove: () => {
				setPartialFilter({ odvolano: undefined, start: 0 });
				formDefinition.formMethods.resetField('odvolano');
			},
		},
	];

	const resetFilter = async () => {
		setPartialFilter(defaultFilter);
		setControls({ displayFilter: false });
		formDefinition.formReset();
	};

	const onSubmit = formDefinition.formMethods.handleSubmit(async () => {
		setPartialFilter({ ...formDefinition.formMethods.getValues(), start: 0 });
		setControls({ displayFilter: false });
	});

	return (
		<PageContext.Provider
			value={{
				isLoading: query.isLoading || queryOvm.isLoading,
				query,
				data:
					query.data?.seznam?.map((item) => ({
						...item,
						nazevSpravceUdaju: queryOvm.data?.seznam?.find(
							(ovm) => ovm.kod === item.kodSpravceUdaju
						)?.nazev,
						nazevPrijemceUdaju: queryOvm.data?.seznam?.find(
							(ovm) => ovm.kod === item.kodPrijemceUdaju
						)?.nazev,
					})) ?? [],
				ppdfConsentDetailId,
				setPpdfConsentDetailId,
				controls,
				setControls,
				formDefinition,
				resetFilter,
				filter,
				onSubmit,
				filterParams,
			}}>
			{children}
		</PageContext.Provider>
	);
};

export const usePpdfSouhlasySeznamContext = () => {
	const context = useContext(PageContext);
	if (!context) {
		throw new Error('Context must be used within a ContextProvider');
	}
	return context;
};
