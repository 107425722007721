import {
	rosBusinessPersonExtendedQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { hasProperty, propEq } from '@gov-nx/core/types';
import { getMyInvolvement } from './utils';

export const useRosExtendedQuery = () => {
	const extendedQuery = usePoQuery(
		{
			queryKey: ['ros-extended-query'],
			queryFn: rosBusinessPersonExtendedQuery,
		},
		{ errorIgnoreFilter: () => true }
	);

	const extendedData =
		extendedQuery.data?.seznam?.filter(hasProperty('ico')) ?? [];

	const PFOData = extendedData.filter(propEq('typ', 'PFO')) ?? [];
	const POData = extendedData.filter(propEq('typ', 'PO')) ?? [];
	const personInvolvementData = getMyInvolvement(extendedData);

	const getDetailData = (ico: number) => extendedData.find(propEq('ico', ico));

	return {
		...extendedQuery,
		extendedData,
		PFOData,
		POData,
		personInvolvementData,
		getDetailData,
	};
};
