import { GovSkeleton } from '@gov-design-system-ce/react';
import { useGlobalLocale } from '@gov-nx/core/service';
import { ButtonGroupLayout, ContentLayout, LayoutGap } from '@gov-nx/ui/web';

export const MyAuthorizationDetailSkeleton = () => {
	const { t } = useGlobalLocale();
	return (
		<LayoutGap>
			<GovSkeleton
				width={'200px'}
				height={'20px'}
				variant={'secondary'}
				wcagLabel={t('loading.wcag.processing')}
				className={'skeleton-brighter'}
			/>
			<GovSkeleton
				width={'180px'}
				height={'20px'}
				variant={'secondary'}
				wcagLabel={t('loading.wcag.processing')}
				className={'skeleton-brighter'}
			/>
			<GovSkeleton
				width={'150px'}
				height={'40px'}
				variant={'secondary'}
				wcagLabel={t('loading.wcag.processing')}
				className={'skeleton-brighter'}
			/>
			<ContentLayout>
				<LayoutGap
					gap={4}
					className={'flex justify-between'}>
					<GovSkeleton
						width={'250px'}
						height={'30px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing')}
					/>
					<GovSkeleton
						width={'250px'}
						height={'50px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing')}
					/>
					<ButtonGroupLayout>
						<GovSkeleton
							width={'250px'}
							height={'50px'}
							variant={'secondary'}
							wcagLabel={t('loading.wcag.processing')}
						/>
						<GovSkeleton
							width={'250px'}
							height={'50px'}
							variant={'secondary'}
							wcagLabel={t('loading.wcag.processing')}
						/>
					</ButtonGroupLayout>
				</LayoutGap>
			</ContentLayout>
		</LayoutGap>
	);
};
