import { AxiosResponse } from 'axios';
import { axiosInstance } from '../axios';
import { RequestData } from '../types/queryTypes';

export const sendSubmissionQuery = async <Params, Body>({
	body,
	params,
}: RequestData<Params, Body>): Promise<AxiosResponse<number, void>> => {
	return axiosInstance.post('/api/v1/podaniinstance/odeslat', body, {
		params,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

export const sendComplaintQuery = async <Params, Body>({
	body,
	params,
}: RequestData<Params, Body>): Promise<AxiosResponse<number, void>> => {
	return axiosInstance.post('/api/v1/podaniinstance/reklamacerob', body, {
		params,
		headers: {
			'Content-Type': 'application/json',
		},
	});
};
export const sendComplaintQueryRos = async <Params, Body>({
	body,
	params,
}: RequestData<Params, Body>): Promise<AxiosResponse<number, void>> => {
	return axiosInstance.post('/api/v1/podaniinstance/reklamaceros', body, {
		params,
		headers: {
			'Content-Type': 'application/json',
		},
	});
};

export const downloadRequestQuery = async <Params, Body>({
	body,
	params,
}: {
	params: Params;
	body: Body;
}): Promise<AxiosResponse<Blob>> => {
	const response = await axiosInstance.post(
		'/api/v1/podaniinstance/stahnout',
		body,
		{
			params,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			responseType: 'blob',
		}
	);
	return response;
};

export const disconnectFromSubmissionQuery = async ({
	body,
	submissionId,
}: {
	submissionId: number;
	body: { datovaSchrankaId: string; datovaZpravaId: number };
}): Promise<AxiosResponse<void, void>> =>
	axiosInstance.put(`/api/v1/podaniinstance/${submissionId}/odpojitdz`, body);

export const connectToAnotherSubmissionQuery = async ({
	submissionId,
	body,
}: {
	submissionId: string;
	body: { datovaSchrankaId: string; datovaZpravaId: number };
}): Promise<AxiosResponse<void, void>> =>
	axiosInstance.put(`/api/v1/podaniinstance/${submissionId}/pripojitdz`, body);
