import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import {
	isUnauthorizedResponse,
	robCommunicationQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useAppContext } from '@gov-nx/core/app';
import { usePersonStore } from '@gov-nx/store/portal-obcana';

const queryKey = ['person-communication'];

export const usePersonCommunicationLoader = () => {
	const { setAppError } = useAppContext();
	const setPersonCommunicationData = usePersonStore(
		'setPersonCommunicationData'
	);

	const queryClient = useQueryClient();
	const initQuery = usePoQuery({
		queryKey,
		queryFn: robCommunicationQuery,
		onSuccess: (data) => {
			setPersonCommunicationData(data);
		},
		onError: (e) => {
			if (isUnauthorizedResponse(e) === false) {
				setAppError(e as Error);
			}
		},
		cacheTime: 0,
		enabled: false,
		retry: 1,
	});

	const initPersonCommunication = useCallback(async (): Promise<void> => {
		await initQuery.refetch();
	}, [initQuery]);

	const refetchPersonCommunication = useCallback(async (): Promise<void> => {
		await queryClient.invalidateQueries({ queryKey });

		await initQuery.refetch();
	}, [initQuery, queryClient]);

	return {
		initPersonCommunication,
		refetchPersonCommunication,
		isReloading: initQuery.isFetching,
	};
};
