import { GovModal } from '@gov-design-system-ce/react';
import { useLocale } from '@gov-nx/core/service';
import {
	formatPersonLabel,
	useProfilROSDetailContext,
} from '@gov-nx/module/page';
import { DataTable, LayoutGap } from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';

export const MembersListModal = () => {
	const { isMembersListModalOpen, members, code } = useProfilROSDetailContext();
	const { t } = useLocale(code);

	return (
		<GovModal
			open={isMembersListModalOpen.isTrue}
			onGov-close={isMembersListModalOpen.setFalse}
			label={t('modal.label.seznam-clenu')}>
			<LayoutGap>
				<p>{t('modal.popis.seznam-clenu')}</p>
				<ol>
					{members.map((member) => {
						return (
							<li
								key={[
									member.ico,
									member.sidloSpolecnosti?.adresaText,
									member.datumNarozeni,
								].join(',')}>
								<DataTable responsive>
									<tbody>
										<tr>
											<td>{t('modal.zaznam.jmeno')}</td>
											<td>
												<strong>{formatPersonLabel(member)}</strong>
											</td>
										</tr>
										<tr>
											<td>{t('modal.zaznam.adresa-pobytu')}</td>
											<td>
												<strong>
													{member?.sidloSpolecnosti?.adresaText ?? '-'}
												</strong>
											</td>
										</tr>
										<tr>
											<td>{t('modal.zaznam.datum-narozeni')}</td>
											<td>
												<strong>
													{member?.datumNarozeni
														? toDateReadable(member.datumNarozeni)
														: '-'}
												</strong>
											</td>
										</tr>
									</tbody>
								</DataTable>
							</li>
						);
					})}
				</ol>
			</LayoutGap>
		</GovModal>
	);
};
