import React from 'react';
import { getEntries } from '@gov-nx/core/types';
import {
	PageCode,
	useRezaDetailContext,
	useRezaLocale,
} from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	ContentLayout,
	CopyButton,
	DataTable,
} from '@gov-nx/ui/web';
import { CmsPageRender } from '../../../Cms/CmsPageRender';
import { ComplaintsModal } from '../ComplaintsModal';
import { InvalidationModal } from '../InvalidationModal';
import { RezaDetailSkeleton } from './RezaDetailSkeleton';

export const RezaDetailPage = () => {
	const { t } = useRezaLocale();
	const {
		isLoading,
		data,
		onDownload,
		complaintsForm,
		invalidationForm,
		keyValues,
	} = useRezaDetailContext();
	if (isLoading) {
		return <RezaDetailSkeleton />;
	}

	return (
		<CmsPageRender
			code={PageCode['reza']}
			override={{ name: data?.nazevSablony }}>
			<ContentLayout>
				{data?.typ === 'implicitni' ? (
					<h3 className={'text-xl'}>{t('detail.implicitni.title')}</h3>
				) : null}
				{data?.typ === 'explicitni' ? (
					<h3 className={'text-xl'}>{t('detail.explicitni.title')}</h3>
				) : null}

				<DataTable responsive={true}>
					<tbody>
						{getEntries(keyValues).map(([key, value]) => (
							<tr key={key}>
								<td>{key}</td>
								<td>
									<strong>{value.value ?? '-'}</strong>
									{value.canCopy && value.value ? (
										<CopyButton
											label={t('detail.buttons.zkopirovat-do-schranky')}
											value={value.value}></CopyButton>
									) : null}
								</td>
							</tr>
						))}
					</tbody>
				</DataTable>

				<ComplaintsModal complaintsForm={complaintsForm} />
				<InvalidationModal invalidationForm={invalidationForm} />

				<ButtonGroupLayout>
					<Button
						onClick={onDownload}
						variant={'primary'}
						type={'solid'}>
						{t('detail.buttons.pdf')}
					</Button>

					{data?.stav === 'platne' && data?.typ === 'implicitni' ? (
						<Button
							onClick={complaintsForm.isModalOpen.setTrue}
							variant={'primary'}
							type={'base'}>
							{t('detail.buttons.reklamace')}
						</Button>
					) : null}

					{data?.stav === 'platne' && data?.typ === 'explicitni' ? (
						<Button
							onClick={invalidationForm.isModalOpen.setTrue}
							variant={'error'}
							type={'base'}>
							{t('detail.buttons.ukoncit')}
						</Button>
					) : null}
				</ButtonGroupLayout>
			</ContentLayout>
		</CmsPageRender>
	);
};
