import { AxiosResponse } from 'axios';
import { axiosCmsInstance } from '@gov-nx/api/common';
import { LocalizationLanguage } from '@gov-nx/core/app';
import { axiosInstance } from '../axios';
import {
	RezaOpravneniDto,
	RezaOpravneniSeznamDto,
	RezaReklamaceOpravneniPozadavekDto,
	RezaSablonaDto,
	RezaSablonaSeznamDto,
	RezaUkonceniOpravneniPozadavekDto,
} from '../generated';

export interface EligibleListParams {
	text?: string;
	role?: 'zmocnenec' | 'zmocnitel';
	typ?: 'implicitni' | 'explicitni';
	kod?: string;
	stav?: 'platne' | 'archivni';
	platnostOdOd?: string;
	platnostOdDo?: string;
	platnostDoOd?: string;
	platnostDoDo?: string;
	kodSablony?: string[];
}

export const rezaEligibleList = async (
	params: EligibleListParams
): Promise<RezaOpravneniSeznamDto> => {
	const response = await axiosInstance.get<RezaOpravneniSeznamDto>(
		'/api/reza/v1/opravneni',
		{ params }
	);
	return response.data;
};

export const rezaEligibleDetail = async (
	code: string
): Promise<RezaOpravneniDto> => {
	const response = await axiosInstance.get<RezaOpravneniDto>(
		`/api/reza/v1/opravneni/${code}`
	);
	return response.data;
};

export const rezaDownloadQuery = async (
	code: string
): Promise<AxiosResponse<Blob>> => {
	return await axiosInstance.get(`/api/reza/v1/opravneni/${code}/stahnout`, {
		responseType: 'blob',
	});
};

interface TemplateParams {
	text?: string;
	kod?: string;
	platnostOd?: string;
	platnostDo?: string;
}

export const rezaTemplates = async (
	params: TemplateParams
): Promise<RezaSablonaSeznamDto> => {
	const response = await axiosInstance.get<RezaSablonaSeznamDto>(
		'/api/reza/v1/sablony',
		{ params }
	);
	return response.data;
};

export const rezaTemplate = async (kod: string): Promise<RezaSablonaDto> => {
	const response = await axiosInstance.get<RezaSablonaDto>(
		`/api/reza/v1/sablony/${kod}`
	);
	return response.data;
};

export const rezaSubmitComplaint = async (
	payload: RezaReklamaceOpravneniPozadavekDto
): Promise<void> => {
	const response = await axiosInstance.post(
		'/api/reza/v1/opravneni/reklamace',
		payload
	);
	return response.data;
};

export const rezaSubmitInvalidation = async (
	payload: RezaUkonceniOpravneniPozadavekDto
): Promise<void> => {
	const response = await axiosInstance.post(
		'/api/reza/v1/opravneni/zneplatneni',
		payload
	);
	return response.data;
};

export interface RezaPortalInfo {
	id: number;
	template: string; //'SOZ-1-2';
	portalName: string; //'Portál dopravy';
	portalURL: string; // 'https://portaldopravy.cz';
	portalDescription: string; // '';
}
export const rezaTemplatePortalInfoQuery = async (
	templateId: string,
	language: LocalizationLanguage
): Promise<RezaPortalInfo> => {
	const response = await axiosCmsInstance.get(
		`reza-portals/${templateId}-${language}.json`
	);

	return response.data.data;
};
