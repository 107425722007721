import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { FormDefinition2, useFormBuilder } from '@gov-nx/core/service';
import { useKorespondencniVolbyLocale } from '@gov-nx/module/service';
import { DataBoxFormData } from '@gov-nx/ui/types';
import { DataBoxFields, useDataboxDefinition } from '@gov-nx/utils/common';

export type KorespondencniHlasovaniZapisStep3 = DataBoxFormData;

type Fields = DataBoxFields;

export type KorespondencniHlasovaniZapisFormStep3 = FormDefinition2<
	KorespondencniHlasovaniZapisStep3,
	Fields
>;

export const useFormStep3 = ({
	isDisabled,
	databoxTypes,
}: {
	isDisabled: boolean;
	databoxTypes: DatovkaApiType['typSchranky'][];
}) => {
	const { t } = useKorespondencniVolbyLocale();

	const databox = useDataboxDefinition({
		name: 'datovaSchrankaId',
		label: t('zapis.formular.datova-schranka.label'),
		isDisabled,
		types: databoxTypes,
	});

	return useFormBuilder<KorespondencniHlasovaniZapisStep3, Fields>(databox);
};
