import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import { MarkdownRender } from '../../../Markdown/MarkdownRender';

interface EpetitionInfoBarProps {
	content: string;
}

export const EpetitionInfoBar = ({ content }: EpetitionInfoBarProps) => {
	return (
		<GovMessage variant="warning">
			<GovIcon
				type={'basic'}
				name={'info'}
				slot={'icon'}></GovIcon>

			<div
				className={
					'flex flex-col gap-4 [ md:flex-row md:justify-between md:items-center ]'
				}>
				<MarkdownRender>{content}</MarkdownRender>
			</div>
		</GovMessage>
	);
};
