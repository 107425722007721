import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { sendSubmissionQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	WizardFormStep,
	usePersonCommunicationData,
	useProcessControl,
	useWizard,
	useDataBoxes,
} from '@gov-nx/core/hooks';
import { getRequiredDataBoxes, useCountry } from '@gov-nx/core/service';
import { compose, getKeys } from '@gov-nx/core/types';
import { useBoolean } from '@gov-nx/utils/common';
import { ServiceCode } from '../definitions/codes';
import {
	FormInstanceStep1,
	FormInstanceStep2,
	FormInstanceStep3,
	FormInstanceStep4,
	FormInstanceStep5,
	FormInstanceStep6,
	prepareSubmitData,
} from './FormDefinitions';
import {
	ServiceContextTypes,
	TrestniOznameniFormData,
	TrestniOznameniFormDataStep1,
	TrestniOznameniGroupTarget,
} from './service.types';

export const ServiceContext = createContext<ServiceContextTypes | null>(null);

export interface ServiceContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
}

export function TrestniOznameniContextProvider({
	children,
	code,
}: ServiceContextProviderProps) {
	const { t } = useTranslation([code]);
	const { toastMessage } = useMessageEvents();
	const { requiredDataBoxes, isDataBoxConnected } = useDataBoxes(
		getRequiredDataBoxes(code)
	);
	const { controls, setControls } = useProcessControl();
	const isSubmited = useBoolean(false);
	const { allCountriesForSelect } = useCountry();

	const personCommunicationData = usePersonCommunicationData();

	const submitMutation = usePoMutation({
		mutationFn: compose(sendSubmissionQuery, prepareSubmitData),
		onError: (error) => {
			setControls({ processError: error, processLoading: false });
		},
		onSuccess: async () => {
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
					icon: {
						name: 'check-lg',
						type: 'basic',
					},
				},
				content: t('formular.zprava.odeslano', { namespace: code }),
			});
			setControls({ processLoading: false });
		},
		onSettled: () => {
			isSubmited.setTrue();
			wizard.resetForms();
		},
	});

	const onSubmit = useCallback(
		async (values: TrestniOznameniFormData) => {
			isSubmited.setFalse();
			setControls({ processError: null, processLoading: true });
			submitMutation.mutate(values);
		},
		[setControls, submitMutation]
	);

	const wizard = useWizard({
		steps: [
			FormInstanceStep1({ code }),
			FormInstanceStep2({ code }),
			FormInstanceStep3({ code }),
			FormInstanceStep4({ code }),
			FormInstanceStep5({ code, personCommunicationData }),
			FormInstanceStep6({ dataBoxTypes: requiredDataBoxes }),
		],
		onSubmit,
	});

	const groupTargetKeys = getKeys(TrestniOznameniGroupTarget);

	const step1 = wizard.step(
		0
	) as unknown as WizardFormStep<TrestniOznameniFormDataStep1>;
	useEffect(() => {
		const subscription = step1.formDefinition.formMethods.watch(
			(value, { name }) => {
				// reset "Popis" field when checkbox field above is unchecked
				if (
					name &&
					groupTargetKeys.includes(
						name as keyof typeof TrestniOznameniGroupTarget
					) &&
					value[name] === false
				) {
					step1.formDefinition.formMethods.resetField(
						`${name as keyof typeof TrestniOznameniGroupTarget}Popis`
					);
				}
			}
		);

		return () => subscription.unsubscribe();
	}, [step1.formDefinition.formMethods]);

	return (
		<ServiceContext.Provider
			value={{
				code,
				wizard,
				controls,
				setControls,
				requiredDataBoxes,
				isDataBoxConnected,
				personCommunicationData,
				groupTargetKeys,
				isSubmited,
				allCountriesForSelect,
			}}>
			{children}
		</ServiceContext.Provider>
	);
}

export const useTrestniOznameniContext = () =>
	useContext(ServiceContext) as ServiceContextTypes;
