import { AxiosResponse } from 'axios';
import React, { createContext, useCallback, useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { extractOfDataQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import { useProcessControl, useTreeFormCheckboxes } from '@gov-nx/core/hooks';
import {
	getRequiredDataBoxes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { compose, Nullable } from '@gov-nx/core/types';
import { ServiceCode } from '../definitions/codes';
import { FormInstance, prepareSubmitData, useTree } from './FormDefinitions';
import { FormData, ProcessControl, ServiceContextTypes } from './service.types';

export const ServiceContext =
	createContext<Nullable<ServiceContextTypes>>(null);

export interface ServiceContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
}

export const VypisUdajuZROBContextProvider = ({
	children,
	code,
}: ServiceContextProviderProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { toastMessage } = useMessageEvents({});
	const { controls, setControls } = useProcessControl<ProcessControl>({
		isSubmitted: false,
	});
	const requiredDataBoxes = getRequiredDataBoxes(code);
	const formDefinition = FormInstance({ dataBoxTypes: requiredDataBoxes });

	const treeCheckboxes = useTreeFormCheckboxes({
		tree: useTree(),
		formMethods: formDefinition.formMethods,
	});

	const submitMutation = usePoMutation<AxiosResponse<void>, FormData>({
		mutationFn: compose(extractOfDataQuery, prepareSubmitData),
		onSuccess: () => {
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
					icon: {
						name: 'check-lg',
						type: 'basic',
					},
				},
				content: tsn('form.messages.odeslano'),
			});
			setControls({ processLoading: false, isSubmitted: true });
			formDefinition.formReset();
		},
		onError: (error) => {
			setControls({
				processError: error,
				processLoading: false,
				isSubmitted: false,
			});
			formDefinition.formReset();
		},
	});

	const handleSubmit = useCallback(() => {
		if (treeCheckboxes.isAnyFieldChecked()) {
			setControls({
				processError: null,
				processLoading: true,
				isSubmitted: false,
			});
			submitMutation.mutate(formDefinition.formMethods.getValues());
		} else {
			toastMessage({
				options: {
					variant: 'warning',
					type: 'solid',
					icon: {
						name: 'lightbulb-fill',
						type: 'basic',
					},
				},
				content: tsn('form.messages.vyberte-moznost'),
			});
		}
	}, [
		formDefinition.formMethods,
		setControls,
		submitMutation,
		toastMessage,
		treeCheckboxes,
		tsn,
	]);

	return (
		<ServiceContext.Provider
			value={{
				code,
				controls,
				formDefinition,
				setControls,
				submitMutation,
				treeCheckboxes,
				onSubmit: formDefinition.formMethods.handleSubmit(handleSubmit),
				requiredDataBoxes: requiredDataBoxes,
			}}>
			<FormProvider {...formDefinition.formMethods}>{children}</FormProvider>
		</ServiceContext.Provider>
	);
};

export const useVypisUdajuZROBContext = (): ServiceContextTypes =>
	useContext(ServiceContext) as ServiceContextTypes;
