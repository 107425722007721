import {
	FileUploadItem,
	ProcessControlCore,
	useDocumentDownload,
} from '@gov-nx/core/hooks';
import { FormDefinition } from '@gov-nx/core/service';
import { useMyFilesDownload } from '@gov-nx/module/page';
import { IUseBoolean } from '@gov-nx/utils/common';

export interface MyOwnDocumentsContext {
	controls: ProcessControlCore;
	formDefinition: FormDefinition<OwnDocumentFormData>;

	documentId?: number;
	isEditAction: boolean;
	documentDownload: ReturnType<typeof useDocumentDownload>;
	onOwnDocumentDelete: ({
		id,
		permanent,
	}: {
		id: number;
		permanent: boolean;
	}) => void;
	isDeletePromptOpen: IUseBoolean;
	onFilesChanged: (files: FileUploadItem[]) => void;
	hasNotFinishedUploads: boolean;
	replaceFile: boolean;
	onSubmit: () => void;
	hasFile: boolean;
	imageBlob: Blob | null;
	hasPreview: boolean;
	myFilesDownload: ReturnType<typeof useMyFilesDownload>;
	downloadFile: () => void;
}

export interface OwnDokumentDataRequest {
	zdroj: 'UZIVATELEM_NAHRANY_DOKUMENT';
	kategorie: '#DOKLAD#';

	nazev: string;
	cisloDokumentu?: string;
	platnostOd?: string;
	platnostDo?: string;
	komentar?: string;
	nazevSouboru?: string; // nazev prijde v GET requestu
	soubor?: string; // uuid nahraneho souboru
}

export interface OwnDocumentFormData {
	_replaceFile: boolean;
	nazev: OwnDokumentDataRequest['nazev'];
	komentar: OwnDokumentDataRequest['komentar'];
	cisloDokumentu: OwnDokumentDataRequest['cisloDokumentu'];
	platnostOd?: Date;
	platnostDo?: Date;
	nazevSouboru: OwnDokumentDataRequest['nazevSouboru'];
	soubor?: FileUploadItem[];
}

export const OwnDokumentPreviewExtensions = ['.jpg', '.jpeg', '.png'];
