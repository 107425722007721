import { useQuery } from '@tanstack/react-query';
import { searchQuery } from '@gov-nx/api/portal-obcana';
import { SignpostRequestParams } from '@gov-nx/api/portal-obcana';
import { useConfigurationStore } from '@gov-nx/store/portal-obcana';
import { useAppLanguage } from '../Localization/useAppLanguage';

type SearchSignpostProps = Pick<SignpostRequestParams, 'externalServiceLink'>;

export const useSearchSignpost = ({
	externalServiceLink,
}: SearchSignpostProps) => {
	const environment = useConfigurationStore('environment');
	const application = useConfigurationStore('application');
	const { language } = useAppLanguage();

	// TODO: Pridat externalServiceLink do payloadu
	// jakmile bude api vracet podle partial linku a ne explicitne celeho
	const query = useQuery({
		queryKey: ['cms-search'],
		queryFn: async () => {
			if (!application || !environment) {
				return;
			}

			const response = await searchQuery({
				env: environment,
				app: application,
				locale: language,
			});

			return response.data;
		},
		enabled: !!externalServiceLink,
	});

	return {
		searchedSignpost: externalServiceLink
			? query.data?.find((item) =>
					item.externalService?.links?.some((link) =>
						link.link?.includes(externalServiceLink)
					)
			  )
			: undefined,
	};
};
