import React, { createContext, useContext } from 'react';
import {
	agendasCiselnikyQuery,
	agendasQuery,
	ovmQuery,
	ppdfConsentDetailQuery,
	PpdfSouhlasySouhlasDto,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useDownloadEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { toDateReadable } from '@gov-nx/utils/common';
import {
	PpdfConsentDetailContext,
	PpdfConsentDetailControl,
} from './context.types';

const PpdfSouhlasyDetailContext =
	createContext<Nullable<PpdfConsentDetailContext>>(null);

interface PpdfSouhlasyDetailContextProviderProps {
	consentId?: Nullable<number>;
	ppdfConsent?: Nullable<PpdfSouhlasySouhlasDto>;
	children: React.ReactNode;
}

export function PpdfSouhlasyDetailContextProvider({
	consentId,
	ppdfConsent,
	children,
}: PpdfSouhlasyDetailContextProviderProps) {
	const { downloadPpdfConsent } = useDownloadEvents();
	const { setControls, controls } = useProcessControl<PpdfConsentDetailControl>(
		{
			isDownloadProcessing: false,
		}
	);

	const query = usePoQuery({
		queryKey: ['ppdf-consent-detail', consentId],
		queryFn: () => (consentId ? ppdfConsentDetailQuery(consentId) : null),
	});

	const detail = query.data ?? ppdfConsent;

	const queryOvm = usePoQuery({
		queryKey: ['ovm'],
		queryFn: () => ovmQuery(true),
		enabled: !!detail,
	});

	const ovms = queryOvm.data?.seznam ?? [];

	const queryAgenda = usePoQuery({
		queryKey: ['agenda'],
		queryFn: agendasQuery,
	});

	const agendas = queryAgenda.data?.seznam ?? [];

	const querySharedAgendaData = usePoQuery({
		queryKey: [
			'agenda-ciselniky',
			detail?.kodSpravceUdaju,
			detail?.kodAgendySpravceUdaju,
		],
		queryFn: async () => {
			const kodAgendySpravceUdaju = agendas.find(
				(agenda) => agenda.kod === detail?.kodAgendySpravceUdaju
			)?.kod;
			return kodAgendySpravceUdaju
				? agendasCiselnikyQuery(kodAgendySpravceUdaju)
				: null;
		},
		enabled: !!agendas.length,
	});

	const sharedAgendaData = querySharedAgendaData.data?.seznam ?? [];

	const handlePpdfConsentDownload = () => {
		if (!consentId) return;
		setControls({
			isDownloadProcessing: true,
		});
		return downloadPpdfConsent({
			consentId,
			callback: () => {
				setControls({
					isDownloadProcessing: false,
				});
			},
		});
	};

	return (
		<PpdfSouhlasyDetailContext.Provider
			value={{
				consentId: consentId ?? ppdfConsent?.id,
				isLoading:
					query.isLoading ||
					queryOvm.isLoading ||
					queryAgenda.isLoading ||
					querySharedAgendaData.isLoading,
				fromOvmName: ovms.find((ovm) => ovm.kod === detail?.kodSpravceUdaju)
					?.nazev,
				fromAgendaName: agendas.find(
					(agenda) => agenda.kod === detail?.kodAgendySpravceUdaju
				)?.nazev,
				toOvmName: ovms.find((ovm) => ovm.kod === detail?.kodPrijemceUdaju)
					?.nazev,
				toAgendaName: agendas.find(
					(agenda) => agenda.kod === detail?.kodAgendyPrijemceUdaju
				)?.nazev,
				sharedAgendaData: sharedAgendaData
					.filter((item) => item.kod && detail?.udaje?.includes(item.kod))
					.map((item) => item.nazevCs)
					.filter((value): value is string => value !== undefined),
				fyzickaOsoba: detail?.osoba?.fyzickaOsoba,
				pravnickaOsoba: detail?.osoba?.pravnickaOsoba,
				datumTrvaniUdeleniSouhlasu: detail?.datumTrvaniUdeleniSouhlasu
					? toDateReadable(detail.datumTrvaniUdeleniSouhlasu)
					: undefined,
				datumUdeleniSouhlasu: detail?.datumUdeleniSouhlasu
					? toDateReadable(detail.datumUdeleniSouhlasu)
					: undefined,
				handlePpdfConsentDownload,
				controls,
			}}>
			{children}
		</PpdfSouhlasyDetailContext.Provider>
	);
}

export const usePpdfSouhlasyDetailContext = (): PpdfConsentDetailContext =>
	useContext(PpdfSouhlasyDetailContext) as PpdfConsentDetailContext;
