import { yupResolver } from '@hookform/resolvers/yup';
import React, { createContext, useContext } from 'react';
import {
	DefaultValues,
	FieldValues,
	Path,
	useForm,
	UseFormReturn,
} from 'react-hook-form';
import { UseFormProps } from 'react-hook-form/dist/types/form';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { RequiredObjectSchema, AnyObject } from 'yup/lib/object';
import { AnySchema, SchemaDescription } from 'yup/lib/schema';
import {
	isNumber,
	isString,
	Optional,
	propEqPartial,
} from '@gov-nx/core/types';
import {
	BadgeListDefinition,
	CheckboxDefinition,
	DateDefinition,
	InputDefinition,
	isDate,
	PhoneDefinition,
	RadioDefinition,
	SelectDefinition,
	toStringDate,
} from '@gov-nx/utils/common';

type ConditionalSchema<T> = T extends string
	? yup.StringSchema
	: T extends number
	? yup.NumberSchema
	: T extends boolean
	? yup.BooleanSchema
	: T extends Date
	? yup.DateSchema
	: // eslint-disable-next-line @typescript-eslint/no-explicit-any
	T extends Record<any, any>
	? yup.AnyObjectSchema
	: // eslint-disable-next-line @typescript-eslint/no-explicit-any
	T extends Array<any>
	? // eslint-disable-next-line @typescript-eslint/no-explicit-any
	  yup.ArraySchema<any, any>
	: yup.AnySchema;

export type FormSchemaShape<Fields> = {
	[Key in keyof Fields]: ConditionalSchema<Fields[Key]>;
};

export interface FormDefinition<T extends FieldValues> {
	formMethods: UseFormReturn<T>;
	formSchema: FormSchema<T>;
	formReset: () => void;
	resetField: (field: Path<T>) => void;
}

export interface FormDefinition2<T extends FieldValues, Fields>
	extends FormDefinition<T> {
	fields: Fields;
}

export type PropsFromSchema = {
	min?: string;
	max?: string;
	required?: boolean;
	arrayInvalid?: boolean;
	arrayError?: string;
};
export type PropsFromSchemaFn = <T extends object>(
	name: keyof T
) => PropsFromSchema;
export type FormSchema<T> = RequiredObjectSchema<
	FormSchemaShape<T>,
	AnyObject,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	any
>;

interface Props<T extends FieldValues> {
	formDefinition: FormDefinition<T>;
	children: React.ReactNode;
}

const getProp = (value: unknown): Optional<string> => {
	if (isString(value)) return value;

	if (isNumber(value)) return value.toString();

	if (isDate(value)) return toStringDate(value);

	return undefined;
};

const arrayValidations =
	<T extends FieldValues>(formDefinitions: FormDefinition<T>) =>
	(
		name: string,
		itemIndex: number
	): { isRequired: boolean; arrayInvalid?: boolean; arrayError?: string } => {
		const schema = formDefinitions.formSchema;
		const values = formDefinitions.formMethods.getValues();
		const field = schema.fields[name];

		if (field?.type !== 'array') {
			return { isRequired: false };
		}

		try {
			schema.validateSyncAt(name, { ...values, [name]: undefined });
			return { isRequired: false };
		} catch (error) {
			if ((error as ValidationError).type === 'at-least-one') {
				return {
					arrayError: (error as ValidationError).message,
					arrayInvalid: Array.isArray(values[name]) && values[name][0] === null, // mark form as invalid in case of submitted
					isRequired: itemIndex === 0, // used to mark the first item with an asterisk as mandatory
				};
			}
			return { isRequired: false };
		}
	};

const conditionalValidations =
	<T extends FieldValues>(formDefinitions: FormDefinition<T>) =>
	(name: string): { isRequired: boolean } => {
		const schema = formDefinitions.formSchema;
		const values = formDefinitions.formMethods.getValues();
		const field = schema.fields[name];
		if (!field?.deps.length) {
			return { isRequired: false };
		}

		try {
			schema.validateSyncAt(name, { ...values, [name]: undefined });
			return { isRequired: false };
		} catch (error) {
			if ((error as ValidationError).type === 'required') {
				return {
					isRequired: true,
				};
			}
			return { isRequired: false };
		}
	};

const propsFromSchema =
	<T extends FieldValues>(
		formDefinitions: FormDefinition<T>
	): PropsFromSchemaFn =>
	(name) => {
		/** We need to check if the field is of type array, if so, then the validation and definition are located in the
		 * root of the field.
		 * Transform name="myArrayField.0" => "myArrayField"
		 * */
		const fieldParts = name.toString().split('.');
		const isArrayField = fieldParts.length === 2; // limited to only one-level array for future
		const fieldName = fieldParts[0];

		const field: Optional<AnySchema> =
			formDefinitions.formSchema.fields[fieldName];
		const conditional = conditionalValidations(formDefinitions);

		const description = field?.describe();
		const nameProp = propEqPartial<SchemaDescription['tests'][number]>('name');
		const isRequired =
			!!description?.tests.find(nameProp('required')) ||
			conditional(fieldName as string).isRequired;
		const min = description?.tests.find(nameProp('min'))?.params?.min;
		const max = description?.tests.find(nameProp('max'))?.params?.max;

		if (isArrayField) {
			const arrayItemIndex = parseInt(fieldParts[1]);
			const arrayValidation = arrayValidations(formDefinitions)(
				fieldName as string,
				arrayItemIndex
			);

			return {
				required: arrayValidation.isRequired,
				min: getProp(min),
				max: getProp(max),
				arrayInvalid: arrayValidation.arrayInvalid,
				arrayError: arrayValidation.arrayError,
			};
		}

		return {
			required: isRequired,
			min: getProp(min),
			max: getProp(max),
		};
	};

const PoFormContext = createContext<{
	propsFromSchema: PropsFromSchemaFn;
}>({ propsFromSchema: () => ({}) });
export const PoForm = <T extends object>({
	formDefinition,
	children,
}: Props<T>) => {
	return (
		<PoFormContext.Provider
			value={{ propsFromSchema: propsFromSchema(formDefinition) }}>
			{children}
		</PoFormContext.Provider>
	);
};

export const usePoFormContext = () => useContext(PoFormContext);

interface UsePoForm<FormData extends FieldValues> {
	formSchema: FormSchema<FormData>;
	defaultValues: DefaultValues<FormData>;
	mode?: UseFormProps<FormData>['mode'];
	reValidateMode?: UseFormProps<FormData>['reValidateMode'];
}

export const usePoForm = <T extends FieldValues>({
	formSchema,
	defaultValues,
	mode = 'onSubmit',
	reValidateMode = 'onChange',
}: UsePoForm<T>) => {
	return useForm<T>({
		mode,
		reValidateMode,
		defaultValues,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		resolver: yupResolver(formSchema) as any,
	});
};

export const getFormDefinition = <T extends FieldValues>({
	formSchema,
	formMethods,
}: {
	formMethods: UseFormReturn<T>;
	formSchema: FormSchema<T>;
}): FormDefinition<T> => {
	return {
		formSchema,
		formMethods,
		formReset: () => {
			return formMethods.reset(
				formMethods.control._defaultValues as DefaultValues<T>
			);
		},
		resetField: (field: Path<T>) => {
			formMethods.setValue(
				field,
				(formMethods.control._defaultValues as DefaultValues<T>)[field]
			);
		},
	};
};

type Field =
	| InputDefinition
	| BadgeListDefinition
	| RadioDefinition
	| DateDefinition
	| CheckboxDefinition
	| SelectDefinition
	| PhoneDefinition;

export const useFormBuilder = <Form extends FieldValues, Fields>(
	definitions: Field[]
): FormDefinition2<Form, Fields> => {
	const schema = definitions.reduce((all, field) => {
		return { ...all, [field.field.field.name]: field.schema };
	}, {} as FormSchemaShape<Form>);

	const formSchema = yup.object(schema).required();

	const defaultValues = definitions.reduce((all, field) => {
		return { ...all, [field.field.field.name]: field.defaultValue };
	}, {} as DefaultValues<Form>);

	const formMethods = usePoForm<Form>({
		formSchema,
		defaultValues,
	});

	const formDefinition = getFormDefinition<Form>({ formMethods, formSchema });

	const fields = definitions.reduce((all, field) => {
		return { ...all, [field.field.field.name]: field.field };
	}, {} as Fields);

	return { ...formDefinition, fields };
};
