import * as yup from 'yup';
import {
	FormDefinition,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { FilterSortDirection } from '@gov-nx/core/types';
import {
	toStringDate,
	today,
	useDateShape,
	isValidDate,
} from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import {
	DataRequest,
	MyVerificationsListFilterForm,
	MyVerificationsListOrder,
	MyVerificationsListFilter,
} from './context.types';

export interface FormInstanceProps {
	code: PageCode;
	defaultValues: MyVerificationsListFilterForm;
}

export function FormInstance({
	code,
	defaultValues,
}: FormInstanceProps): FormDefinition<MyVerificationsListFilterForm> {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { getDatePickerShape } = useDateShape();

	const formSchema = yup
		.object({
			obdobiOd: getDatePickerShape()
				.max(today(), tsn('form.validations.datum-obdobi.max'))
				.nullable(),
			obdobiDo: getDatePickerShape()
				.when('obdobiOd', (from, schema) => {
					if (isValidDate(from)) {
						return schema.min(
							from,
							tsn('form.validations.datum-obdobi.starsi-nez-obdobi-od')
						);
					}
					return schema;
				})
				.max(today(), tsn('form.validations.datum-obdobi.max'))
				.nullable(),
			razeni: yup.string().optional(),
			razeniSmer: yup.string().optional(),
			hledat: yup.string().optional(),
		})
		.required();

	const formMethods = usePoForm<MyVerificationsListFilterForm>({
		formSchema,
		defaultValues,
	});
	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData = (
	formData: MyVerificationsListFilter
): DataRequest => {
	const order = (
		formData.razeniSmer === FilterSortDirection.DESC
			? '-' + formData.razeni
			: formData.razeni
	) as MyVerificationsListOrder;

	return {
		params: {
			razeni: order,
			obdobiOd: formData.obdobiOd ? toStringDate(formData.obdobiOd) : undefined,
			obdobiDo: formData.obdobiDo ? toStringDate(formData.obdobiDo) : undefined,
			start: formData.start,
			pocet: formData.pocet,
		},
	};
};
