import { CiselnikyCiselnikPolozkaDto } from '@gov-nx/api/portal-obcana';
import { Nullable, is } from '@gov-nx/core/types';
import { FormData } from './context.types';
import { EmailRadioOption, PhoneRadioOption } from './options';

export function getSortedLegalFormOptions(
	legalForm: Nullable<CiselnikyCiselnikPolozkaDto[]>
) {
	const sortedOptions = legalForm
		? legalForm
				.filter(
					(data) =>
						typeof data.kod !== 'undefined' &&
						typeof data.nazevCs !== 'undefined'
				)
				.sort((a, b) => (a.nazevCs || '').localeCompare(b.nazevCs || ''))
				.map((data) => ({
					value: (data.kod || '').toString(),
					label: data.nazevCs || '',
				}))
		: [];

	return sortedOptions;
}

export const getPhoneContact = (data: FormData) => {
	if (!data.kontaktTelefon) return undefined;

	return is(data._telefon) && data._telefon !== PhoneRadioOption.ANOTHER_PHONE
		? data._telefon
		: data.newPhone;
};

export const getEmailContact = (data: FormData) => {
	if (!data.kontaktEmail) return undefined;

	return is(data._email) && data._email !== EmailRadioOption.ANOTHER_EMAIL
		? data._email
		: data.newEmail;
};

export const interruptionToBoolean = (
	interruption: FormData['pozastaveniPreruseni']
) => interruption === 'ano';
