import { pubSubActionBridge } from '@gov-nx/core/app';
import {
	DownloadActions,
	DownloadBlobSubscription,
	DownloadDocumentSubscription,
	DownloadFileSubscription,
	DownloadDataBoxAttachmentSubscription,
	DownloadDataBoxMessageSubscription,
	DownloadSubscription,
	DownloadEPetitionAttachmentSubscription,
	DownloadEPetitionSubscription,
	DownloadVerificationSubscription,
	DownloadForm602AttachmentSubsription,
	DownloadPpdfConsentSubscription,
} from './types';

export enum DownloadSubscriptionTypes {
	download = 'DOWNLOAD_SUBSCRIPTION',
	downloadFile = 'DOWNLOAD_FILE_SUBSCRIPTION',
	downloadDocument = 'DOWNLOAD_DOCUMENT_SUBSCRIPTION',
	downloadBlob = 'DOWNLOAD_BLOB_SUBSCRIPTION',
	downloadDataBoxAttachment = 'DOWNLOAD_DATA_BOX_ATTACHMENT_SUBSCRIPTION',
	downloadEPetitionAttachment = 'DOWNLOAD_EPETITION_ATTACHMENT_SUBSCRIPTION',
	downloadEPetition = 'DOWLOAD_EPETITION_SUBSCRIPTION',
	downloadDataBoxMessage = 'DOWNLOAD_DATA_BOX_MESSAGE_SUBSCRIPTION',
	downloadVerification = 'DOWNLOAD_VERIFICATION_SUBSCRIPTION',
	downloadPpdfConsent = 'DOWNLOAD_PPDF_CONSENT_SUBSCRIPTION',
	downloadForm602Attachment = 'DOWNLOAD_FORM602_ATTACHMENT_SUBSCRIPTION',
}

export const downloadSubscription = (
	payload: DownloadSubscription['payload']
): DownloadSubscription => ({
	type: DownloadSubscriptionTypes.download,
	payload,
});

export const downloadDocumentSubscription = (
	payload: DownloadDocumentSubscription['payload']
): DownloadDocumentSubscription => ({
	type: DownloadSubscriptionTypes.downloadDocument,
	payload,
});

export const downloadFileSubscription = (
	payload: DownloadFileSubscription['payload']
): DownloadFileSubscription => ({
	type: DownloadSubscriptionTypes.downloadFile,
	payload,
});

export const downloadBlobSubscription = (
	payload: DownloadBlobSubscription['payload']
): DownloadBlobSubscription => ({
	type: DownloadSubscriptionTypes.downloadBlob,
	payload,
});

export const downloadDataBoxAttachmentSubscription = (
	payload: DownloadDataBoxAttachmentSubscription['payload']
): DownloadDataBoxAttachmentSubscription => ({
	type: DownloadSubscriptionTypes.downloadDataBoxAttachment,
	payload,
});

export const downloadEPetitionAttachmentSubscription = (
	payload: DownloadEPetitionAttachmentSubscription['payload']
): DownloadEPetitionAttachmentSubscription => ({
	type: DownloadSubscriptionTypes.downloadEPetitionAttachment,
	payload,
});

export const downloadEPetitionSubscription = (
	payload: DownloadEPetitionSubscription['payload']
): DownloadEPetitionSubscription => ({
	type: DownloadSubscriptionTypes.downloadEPetition,
	payload,
});

export const downloadDataBoxMessageSubscription = (
	payload: DownloadDataBoxMessageSubscription['payload']
): DownloadDataBoxMessageSubscription => ({
	type: DownloadSubscriptionTypes.downloadDataBoxMessage,
	payload,
});

export const downloadVerificationSubscription = (
	payload: DownloadVerificationSubscription['payload']
): DownloadVerificationSubscription => ({
	type: DownloadSubscriptionTypes.downloadVerification,
	payload,
});

export const downloadForm602AttachmentSubscription = (
	payload: DownloadForm602AttachmentSubsription['payload']
): DownloadForm602AttachmentSubsription => ({
	type: DownloadSubscriptionTypes.downloadForm602Attachment,
	payload,
});

export const downloadPpdfConsentSubscription = (
	payload: DownloadPpdfConsentSubscription['payload']
): DownloadPpdfConsentSubscription => ({
	type: DownloadSubscriptionTypes.downloadPpdfConsent,
	payload,
});

export const downloadEvents = pubSubActionBridge<DownloadActions>();
