import React, { createContext, useCallback, useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import {
	extractOfDataQuery,
	sendSubmissionQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useDownloadEvents, useMessageEvents } from '@gov-nx/core/events';
import {
	useLastDocument,
	usePersonCommunicationData,
	useProcessControl,
} from '@gov-nx/core/hooks';
import {
	getRequiredDataBoxes,
	useCountry,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { useDataBoxAccess } from '@gov-nx/module/data-box';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import {
	WarningRequestHeader,
	getApiWarningRequestHeader,
	useBoolean,
} from '@gov-nx/utils/common';
import { ServiceCode } from '../definitions/codes';
import {
	FormInstance,
	prepareSubmitData,
	prepareSubmitDataDS,
} from './FormDefinitions';
import {
	FormData,
	ServiceContextControls,
	ServiceContextTypes,
} from './service.types';

export const ServiceContext =
	createContext<Nullable<ServiceContextTypes>>(null);

export interface ServiceContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
}

export function VypisZRejstrikuTrestuPOContextProvider({
	children,
	code,
}: ServiceContextProviderProps) {
	const { toastMessage, toastMessageSuccess } = useMessageEvents();
	const changeEmail = useBoolean();
	const { emailVerified } = usePersonCommunicationData();
	const requiredDataBoxes = getRequiredDataBoxes(code);
	const getConnectedDataboxesListByTypes = useDataBoxStore(
		'getConnectedDataboxesListByTypes'
	);
	const dataBoxes = getConnectedDataboxesListByTypes(requiredDataBoxes);
	const { downloadDocument } = useDownloadEvents();
	const { controls, setControls } = useProcessControl<ServiceContextControls>({
		mustWaitForProcessing: null,

		sendToDSLoading: false,
		sendToDSError: null,
	});
	const { formDefinition, getFields } = FormInstance({
		code,
		isEmail: !!emailVerified,
		email: emailVerified,
		dataBoxTypes: requiredDataBoxes,
	});
	const registeredLocation = formDefinition.formMethods.watch(
		'_registeredLocation'
	);
	const enabled = dataBoxes.length > 0 && !!registeredLocation;
	const lastDocument = useLastDocument(code, enabled, {
		typOsoby: 'PO',
		poZahranicni: registeredLocation === 'foreign',
	});
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { filteredAllCountriesToSelect } = useCountry({ filterOut: ['cz'] });

	const { areAnyConnected } = useDataBoxAccess({
		requiredTypes: requiredDataBoxes,
	});

	const submitMutationDS = usePoMutation({
		mutationFn: async (data: FormData) => {
			const prepared = prepareSubmitDataDS(data);
			return extractOfDataQuery(prepared);
		},
		onError: (error: Error) => {
			setControls({ sendToDSError: error, sendToDSLoading: false });
		},
		onSuccess: () => {
			formDefinition.formReset();
			setControls({ sendToDSLoading: false });
			toastMessageSuccess(tsn('formular.zprava.odeslano'));
		},
	});

	const submitMutation = usePoMutation({
		mutationFn: async (data: FormData) => {
			const prepared = prepareSubmitData(data);
			return sendSubmissionQuery(prepared);
		},
		onError: (error: Error) => {
			setControls({ processError: error, processLoading: false });
		},
		onSuccess: async (response) => {
			const headerWarning = getApiWarningRequestHeader(response);
			if (headerWarning) {
				setControls({
					mustWaitForProcessing: headerWarning,
					processLoading: false,
				});
				await lastDocument.invalidateCache();
				formDefinition.formReset();
			} else if (response.data > 0) {
				downloadDocument({
					documentId: response.data,
					callback: async () => {
						setControls({ processLoading: false });
						await lastDocument.invalidateCache();
						formDefinition.formReset();
					},
				});
			} else if (response.data === 0) {
				await lastDocument.invalidateCache();
				setControls({
					mustWaitForProcessing: WarningRequestHeader.UNKNOW,
					processLoading: false,
				});
				formDefinition.formReset();
			} else {
				toastMessage({
					content: tsn('formular.zprava.chyba-pri-odesilani'),
					options: {
						variant: 'error',
						time: 0,
						icon: {
							name: 'exclamation-triangle-fill',
							type: 'basic',
						},
					},
				});
				await lastDocument.invalidateCache();
				setControls({
					processLoading: false,
					processError: new GovError(
						`[Service]: An unexpected error has occurred in (${code})`
					),
				});
			}
		},
	});

	const handleSubmit = useCallback(async () => {
		setControls({ processError: null, processLoading: true });
		submitMutation.mutate(formDefinition.formMethods.getValues());
	}, [formDefinition.formMethods, setControls, submitMutation]);

	const handleSubmitDS = useCallback(async () => {
		setControls({ sendToDSError: null, sendToDSLoading: true });
		submitMutationDS.mutate(formDefinition.formMethods.getValues());
	}, [formDefinition.formMethods, setControls, submitMutationDS]);

	const isProcessing =
		submitMutation.isLoading ||
		controls.processLoading ||
		controls.sendToDSLoading;

	return (
		<ServiceContext.Provider
			value={{
				code,
				controls,
				formDefinition,
				fields: getFields(isProcessing),
				onSubmit: formDefinition.formMethods.handleSubmit(handleSubmit),
				onSendToDS: formDefinition.formMethods.handleSubmit(handleSubmitDS),
				submitMutation,
				setControls,
				isEmail: !!emailVerified,
				email: emailVerified,
				changeEmail,
				lastDocument,
				areAnyDataBoxesConnected: areAnyConnected,
				isProcessing,
				registeredLocation,
				countriesToSelect: filteredAllCountriesToSelect,
			}}>
			<FormProvider {...formDefinition.formMethods}>{children}</FormProvider>
		</ServiceContext.Provider>
	);
}

export const useVypisRejstrikuTrestuPOContext = (): ServiceContextTypes =>
	useContext(ServiceContext) as ServiceContextTypes;
