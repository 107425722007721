import { GovSkeleton } from '@gov-design-system-ce/react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { ContentLayout, DataTable, LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const ConsentManagementDetailSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<LayoutGap gap={6}>
			<div
				className={
					'flex flex-col [ md:flex-row md:justify-between md:items-center ]'
				}>
				<GovSkeleton
					width={'175px'}
					height={'30px'}
					variant={'secondary'}
					wcagLabel={
						t('loading.wcag.processing', {
							namespace: LocalizeNameSpaceTypes.Global,
						}) as string
					}
					className={'mb-6 skeleton-brighter [ md:mb-0 ]'}
				/>
				<div className={'flex flex-col gap-4 [ md:flex-row ]'}>
					<div className={'w-full [ md:w-[190px] ]'}>
						<GovSkeleton
							width={'100%'}
							height={'48px'}
							variant={'secondary'}
							wcagLabel={
								t('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
							className={'skeleton-brighter'}
						/>
					</div>
					<div className={'w-full [ md:w-[210px] ]'}>
						<GovSkeleton
							width={'100%'}
							height={'48px'}
							variant={'secondary'}
							wcagLabel={
								t('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
							className={'skeleton-brighter'}
						/>
					</div>
				</div>
			</div>
			<ContentLayout>
				<div className={'flex gap-4 mb-8'}>
					<GovSkeleton
						width={'130px'}
						height={'40px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
						className={'mb-0 pb-0'}
					/>
					<GovSkeleton
						width={'80px'}
						height={'40px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
						className={'mb-0 pb-0'}
					/>
					<GovSkeleton
						width={'140px'}
						height={'40px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
						className={'mb-0 pb-0'}
					/>
				</div>
				<DataTable responsive>
					<tbody>
						{[0, 1, 2].map((_, i) => {
							return (
								// eslint-disable-next-line react/no-array-index-key
								<tr key={i}>
									<td className={'md:!w-1/2'}>
										<GovSkeleton
											width={generateRandomNumber(28, 50) + '%'}
											height={'19px'}
											wcagLabel={
												t('loading.wcag.processing', {
													namespace: LocalizeNameSpaceTypes.Global,
												}) as string
											}
										/>
									</td>
									<td>
										<GovSkeleton
											width={generateRandomNumber(20, 60) + '%'}
											height={'19px'}
											wcagLabel={
												t('loading.wcag.processing', {
													namespace: LocalizeNameSpaceTypes.Global,
												}) as string
											}
											className={'flex flex-grow '}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</DataTable>
			</ContentLayout>
			<ContentLayout>
				<div className={'flex gap-4 mb-8'}>
					<GovSkeleton
						width={'145px'}
						height={'40px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
						className={'mb-0 pb-0'}
					/>
					<GovSkeleton
						width={'165px'}
						height={'40px'}
						variant={'secondary'}
						wcagLabel={
							t('loading.wcag.processing', {
								namespace: LocalizeNameSpaceTypes.Global,
							}) as string
						}
						className={'mb-0 pb-0'}
					/>
				</div>
				<DataTable responsive>
					<tbody>
						{[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
							(_, i) => {
								return (
									// eslint-disable-next-line react/no-array-index-key
									<tr key={i}>
										<td className={'md:!w-1/2'}>
											<GovSkeleton
												width={generateRandomNumber(28, 50) + '%'}
												height={'19px'}
												wcagLabel={
													t('loading.wcag.processing', {
														namespace: LocalizeNameSpaceTypes.Global,
													}) as string
												}
											/>
										</td>
										<td>
											<GovSkeleton
												width={generateRandomNumber(20, 60) + '%'}
												height={'19px'}
												wcagLabel={
													t('loading.wcag.processing', {
														namespace: LocalizeNameSpaceTypes.Global,
													}) as string
												}
												className={'flex flex-grow '}
											/>
										</td>
									</tr>
								);
							}
						)}
					</tbody>
				</DataTable>
			</ContentLayout>
		</LayoutGap>
	);
};
