import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { FormProvider } from 'react-hook-form';
import {
	extractOfDataGetFileIdQuery,
	usePoMutation,
	VypisyudajuSouboryUlozkaDto,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useDownloadEvents } from '@gov-nx/core/events';
import {
	RegistrZPOperace,
	RegistrZPSubject,
	useProcessControl,
	useRegistrZPLoader,
	useSaveToDocuments,
} from '@gov-nx/core/hooks';
import { compose, propEq } from '@gov-nx/core/types';
import { ServiceCode } from '../definitions/codes';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import {
	FormData,
	ServiceContextControls,
	ServiceContextTypes,
} from './service.types';
import { getFileIdFromResponseData } from './utils';

export const ServiceContext = createContext<ServiceContextTypes | null>(null);

export interface ServiceContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
}

export function NeverejnyVypisZZRContextProvider({
	children,
	code,
}: ServiceContextProviderProps) {
	const { downloadFile } = useDownloadEvents();
	const { saveToDocuments } = useSaveToDocuments({
		onSuccess: () => {
			setControls({ processLoading: false });
			formDefinition.formReset();
		},
		onError: (error) =>
			setControls({ processError: error, processLoading: false }),
	});
	const { controls, setControls } = useProcessControl<ServiceContextControls>({
		dataError: null,
		downloadLoading: false,
		downloadError: null,
	});
	const [subjects, setSubjects] = useState<RegistrZPSubject[]>([]);

	const { load: registrZPLoad } = useRegistrZPLoader({
		onError: (error) =>
			setControls({ initialLoading: false, initialError: error }),
		onDataError: (error) =>
			setControls({ initialLoading: false, dataError: error }),
		onSuccess: (subjects) => {
			setControls({ initialLoading: false });
			setSubjects(subjects);
		},
	});

	useEffect(() => {
		setControls({ initialLoading: true });
		registrZPLoad().finally();
	}, []);

	const formDefinition = FormInstance({ code });

	const submitMutation = usePoMutation<VypisyudajuSouboryUlozkaDto, FormData>({
		mutationFn: compose(extractOfDataGetFileIdQuery, prepareSubmitData),
		onError: (error) =>
			setControls({ processError: error, processLoading: false }),
		onSuccess: async (data) => {
			const fileId = getFileIdFromResponseData(data);
			if (fileId) {
				saveToDocuments.mutate(fileId);
			} else {
				setControls({
					processError: new GovError('Error: no document id in response.'),
					processLoading: false,
				});
			}
		},
	});

	const downloadMutation = usePoMutation<VypisyudajuSouboryUlozkaDto, FormData>(
		{
			mutationFn: compose(extractOfDataGetFileIdQuery, prepareSubmitData),
			onError: (error) =>
				setControls({ downloadError: error, downloadLoading: false }),
			onSuccess: async (data) => {
				const fileId = getFileIdFromResponseData(data);
				if (fileId) {
					downloadFile({
						id: fileId,
						callback: () => {
							setControls({ downloadLoading: false });
							formDefinition.formReset();
						},
					});
				} else {
					setControls({
						processError: new GovError('Error: no document id in response.'),
						processLoading: false,
					});
				}
			},
		}
	);

	const handleSubmit = useCallback(async () => {
		setControls({ processError: null, processLoading: true });
		submitMutation.mutate({
			...formDefinition.formMethods.getValues(),
			operace: RegistrZPOperace.ULOZIT_DO_DOKUMENTU,
		});
	}, [formDefinition.formMethods, setControls, submitMutation]);

	const handleDownload = useCallback(async () => {
		setControls({ downloadError: null, downloadLoading: true });
		downloadMutation.mutate({
			...formDefinition.formMethods.getValues(),
			operace: RegistrZPOperace.STAHNOUT,
		});
	}, [downloadMutation, formDefinition.formMethods, setControls]);

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);
	const onDownload = formDefinition.formMethods.handleSubmit(handleDownload);

	return (
		<ServiceContext.Provider
			value={{
				code,
				formDefinition,
				onSubmit,
				onDownload,
				controls,
				setControls,
				subjects: subjects.filter(propEq('hasSomeActiveRole', true)),
			}}>
			<FormProvider {...formDefinition.formMethods}>{children}</FormProvider>
		</ServiceContext.Provider>
	);
}

export const useNeverejnyVypisZZRContext = (): ServiceContextTypes =>
	useContext(ServiceContext) as ServiceContextTypes;
