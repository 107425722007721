import { useLocale } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { Button, Prompt } from '@gov-nx/ui/web';

interface RecallConsentPromptProps {
	isOpen: boolean;
	isConsentRecalling: boolean;
	onConsentRecallConfirm: () => void;
	onClose: () => void;
}

export const RecallConsentPrompt = ({
	isOpen,
	isConsentRecalling,
	onConsentRecallConfirm,
	onClose,
}: RecallConsentPromptProps) => {
	const { t } = useLocale(PageCode['sprava-souhlasu']);
	return (
		<Prompt
			open={isOpen}
			label={t('modal.pole.odvolani-souhlasu')}
			onGov-close={onClose}>
			{t('modal.pole.potvrzeni-odvolani')}
			<Button
				nativeType="button"
				wcagLabel={t('tlacitko.akce.odvolat')}
				variant="primary"
				type="solid"
				slot="actions"
				loading={isConsentRecalling}
				disabled={isConsentRecalling}
				onClick={onConsentRecallConfirm}>
				{t('tlacitko.akce.odvolat')}
			</Button>
		</Prompt>
	);
};
