import { ServiceListMapper } from './ServiceMap';
import { ServiceCode } from './codes';

const services: ServiceListMapper[] = [
	{
		locales: {
			cs: {
				slug: 'vypis-z-rejstriku-trestu-fyzickych-osob',
			},
		},
		code: ServiceCode['vypis-z-rejstriku-trestu-FO'],
	},
	{
		locales: {
			cs: {
				slug: 'vypis-z-rejstriku-trestu-pravnickych-osob',
			},
		},
		code: ServiceCode['vypis-z-rejstriku-trestu-PO'],
	},
	{
		locales: {
			cs: {
				slug: 'vypis-z-zivnostenskeho-rejstriku',
			},
		},
		code: ServiceCode['vypis-z-zivnostenskeho-rejstriku'],
	},
	{
		locales: {
			cs: {
				slug: 'neverejny-vypis-z-zivnostenskeho-rejstriku',
			},
		},
		code: ServiceCode['neverejny-vypis-z-ZR'],
	},
	{
		locales: {
			cs: {
				slug: 'verejny-vypis-z-registru-osob',
			},
		},
		code: ServiceCode['verejny-vypis-z-ROS'],
	},
	{
		locales: {
			cs: {
				slug: 'neverejny-vypis-z-registru-osob',
			},
		},
		code: ServiceCode['neverejny-vypis-z-ROS'],
	},
	{
		locales: {
			cs: {
				slug: 'vypis-vyuziti-udaju-z-registru-obyvatel',
			},
		},
		code: ServiceCode['vypis-vyuziti-udaju-z-ROB'],
	},
	{
		locales: {
			cs: {
				slug: 'vypis-vyuziti-udaju-z-registru-osob',
			},
		},
		code: ServiceCode['vypis-vyuziti-udaju-z-ROS'],
	},
	{
		locales: {
			cs: {
				slug: 'vypis-ze-seznamu-kvalifikovanych-dodavatelu',
			},
		},
		code: ServiceCode['vypis-ze-seznamu-KD'],
	},
	{
		locales: {
			cs: {
				slug: 'vypis-z-insolvencniho-rejstriku',
			},
		},
		code: ServiceCode['vypis-z-IR'],
	},
	{
		locales: {
			cs: {
				slug: 'zadost-o-osvedceni-digitalniho-ukonu',
			},
		},
		code: ServiceCode['zadost-o-osvedceni-DIG-ukonu'],
	},
	{
		locales: {
			cs: {
				slug: 'vypis-bodoveho-hodnoceni-ridice',
			},
		},
		code: ServiceCode['vypis-bodoveho-hodnoceni-ridice'],
	},
	{
		locales: {
			cs: {
				slug: 'vypis-z-verejneho-rejstriku',
			},
		},
		code: ServiceCode['vypis-z-verejneho-rejstriku'],
	},
	{
		locales: {
			cs: {
				slug: 'podani-zadosti-o-informace',
			},
		},
		code: ServiceCode['podani-zadosti-o-informace'],
	},
	{
		locales: {
			cs: {
				slug: 'potvrzeni-o-studiu',
			},
		},
		code: ServiceCode['potvrzeni-o-studiu'],
	},
	{
		locales: {
			cs: {
				slug: 'vypis-udaju-z-registru-obyvatel',
			},
		},
		code: ServiceCode['vypis-udaju-z-ROB'],
	},
	{
		locales: {
			cs: {
				slug: 'zadost-o-poskytovani-udaju',
			},
		},
		code: ServiceCode['zadost-o-poskytovani-udaju'],
	},
	{
		locales: {
			cs: {
				slug: 'trestni-oznameni',
			},
		},
		code: ServiceCode['trestni-oznameni'],
	},
	{
		locales: {
			cs: {
				slug: 'volicsky-prukaz',
			},
		},
		code: ServiceCode['volicsky-prukaz'],
	},
	{
		locales: {
			cs: {
				slug: 'ppdf-souhlasy',
			},
		},
		code: ServiceCode['ppdf-souhlasy'],
	},
	{
		locales: {
			cs: {
				slug: 'zadost-o-korespondencni-hlasovani',
			},
		},
		code: ServiceCode['zadost-o-korespondencni-hlasovani'],
	},
	{
		locales: {
			cs: {
				slug: 'smazani-uzivatelskeho-uctu',
			},
		},
		code: ServiceCode['smazani-uzivatelskeho-uctu'],
	},
];

export default services;
