import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LocalizeNameSpaceTypes, useCmsService } from '@gov-nx/core/service';
import { ServiceCode } from '@gov-nx/module/service';
import {
	BackButton,
	CookbookList,
	LayoutGap,
	ToggleButton,
} from '@gov-nx/ui/web';
import { useTitle } from '@gov-nx/utils/web';
import { FavoriteToggle } from '../Favorite/FavoriteToggle';
import { MarkdownRender } from '../Markdown/MarkdownRender';
import { CmsFaq } from './CmsFaq';
import { CsmNavigationSignpost } from './CsmNavigationSignpost';

export interface CmsServiceRenderProps {
	code: ServiceCode;
	children: ReactNode;
	override?: {
		name?: string;
	};
}

export const CmsServiceRender = ({
	code,
	children,
	override,
}: CmsServiceRenderProps) => {
	const navigate = useNavigate();
	const cmsContent = useCmsService({ code });
	const localizeNameSpace = LocalizeNameSpaceTypes.Service;
	const { t } = useTranslation([localizeNameSpace]);
	const title = override?.name ? override?.name : cmsContent?.name;

	useTitle(title);

	if (cmsContent === null) {
		return null;
	}

	return (
		<div className={'gov-layout py-6'}>
			<section className={'gov-layout__section gov-layout__section-text-left'}>
				<div className={'gov-layout-column'}>
					<div className={'mr-4 mb-10 [ print:hidden ]'}>
						<BackButton onClick={() => navigate(-1)} />
					</div>
					<main className={'flex flex-col gap-4'}>
						<h1 className={'gov-text--4xl'}>
							{title}
							{cmsContent.favoriteAllowed ? (
								<FavoriteToggle {...{ code, name: title ?? cmsContent.name }} />
							) : null}
						</h1>
						<LayoutGap>
							{cmsContent.cookbook?.length ? (
								<CookbookList
									label={t('kucharka', { namespace: localizeNameSpace })}
									items={cmsContent.cookbook}
								/>
							) : null}
							{!!cmsContent.description &&
								(cmsContent.descriptionDisplayed ? (
									<MarkdownRender>cmsContent.description</MarkdownRender>
								) : (
									<ToggleButton>
										<MarkdownRender>{cmsContent.description}</MarkdownRender>
									</ToggleButton>
								))}
							{children}
						</LayoutGap>
					</main>
					<LayoutGap
						gap={20}
						className={'mt-20'}>
						{cmsContent.navigations.length ? (
							<CsmNavigationSignpost
								navigations={cmsContent.navigations}
								localizeNameSpace={localizeNameSpace}
							/>
						) : null}
						{cmsContent.faq.length ? (
							<CmsFaq
								faq={cmsContent.faq}
								localizeNameSpace={localizeNameSpace}
							/>
						) : null}
					</LayoutGap>
				</div>
			</section>
		</div>
	);
};
