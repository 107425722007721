import { QueryClient } from '@tanstack/react-query';
import { rezaEligibleList, usePoQuery } from '@gov-nx/api/portal-obcana';

export interface RezaFilter {
	text?: string;
	role?: 'zmocnenec' | 'zmocnitel';
	includeArchived?: boolean;
	platnostOdOd?: string;
	platnostOdDo?: string;
	platnostDoOd?: string;
	platnostDoDo?: string;
	kodSablony?: string[];
}

interface Props {
	filter: RezaFilter;
}
export const useRezaEligibleList = ({ filter }: Props) => {
	const query = usePoQuery({
		queryKey: ['reza', 'list', filter],
		queryFn: async () =>
			rezaEligibleList({
				text: filter.text,
				role: filter.role,
				stav: filter.includeArchived ? 'archivni' : 'platne',
				platnostOdOd: filter.platnostOdOd,
				platnostOdDo: filter.platnostOdDo,
				platnostDoOd: filter.platnostDoOd,
				platnostDoDo: filter.platnostDoDo,
				kodSablony: filter.kodSablony,
			}),
	});

	return {
		isLoading: query.isLoading,
		data: query.data,
	};
};

export const invalidateRezaEligibleListQuery = async (
	queryClient: QueryClient
) => queryClient.invalidateQueries({ queryKey: ['reza', 'list'] });
