import {
	createContext,
	ReactNode,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { useNotificationSettingsQuery } from '@gov-nx/api/portal-obcana';
import {
	usePersonCommunicationData,
	useProcessControl,
} from '@gov-nx/core/hooks';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import {
	DataBoxNotificationToggleInputName,
	FormInstance,
	setExtendedNotification,
	setNotificationCheckboxesValues,
} from './FormDefinitions';
import { DataboxNotificationSettingsContext } from './context.types';
import { useNotificationSettingsValues } from './utils';

export const DatovkaNastaveniUpozorneniContext =
	createContext<DataboxNotificationSettingsContext | null>(null);

interface DatovkaNastaveniUpozorneniContextProviderProps {
	databoxId: string;
	children: ReactNode;
}

export function DatovkaNastaveniUpozorneniContextProvider({
	databoxId,
	children,
}: DatovkaNastaveniUpozorneniContextProviderProps) {
	const { emailVerified, phoneVerified } = usePersonCommunicationData();
	const { controls, setControls } = useProcessControl();
	const [
		isExtendedMailConfirmationDialogOpen,
		setExtendedMailConfirmationDialogOpen,
	] = useState(false);

	const formInstance = FormInstance(databoxId);

	const getDataBoxById = useDataBoxStore('getDataBoxById');
	const databox = getDataBoxById(databoxId);

	const notificationSettingQuery = useNotificationSettingsQuery(databoxId);
	const settingsValues = useNotificationSettingsValues();

	useEffect(() => {
		if (notificationSettingQuery.isSuccess && notificationSettingQuery.data) {
			setControls({ initialLoading: false });

			const values = settingsValues.decompose(
				notificationSettingQuery.data.hodnota
			);

			setNotificationCheckboxesValues(formInstance, databoxId, values);
		}
	}, [
		databoxId,
		formInstance,
		notificationSettingQuery.data,
		notificationSettingQuery.isSuccess,
		setControls,
	]);

	const storeNotificationSettings = useCallback(() => {
		const newValue = settingsValues.compose(formInstance.getValues(databoxId));
		setControls({ processLoading: true });

		notificationSettingQuery.mutate(
			{ value: newValue },
			{
				onSuccess: (_, payload) => {
					setControls({ processLoading: false });
				},
				onError: async (error) => {
					setControls({ processError: error, processLoading: false });
					formInstance.reset(formInstance.control._defaultValues);
				},
			}
		);

		// Update checkboxes to reflect the new value (when email is disabled, email-attachment should be disabled too)
		setNotificationCheckboxesValues(
			formInstance,
			databoxId,
			settingsValues.decompose(newValue)
		);
	}, [databoxId, formInstance, notificationSettingQuery.mutate, setControls]);

	const handleExtendedMailNotificationChange = useCallback(() => {
		if (
			formInstance.getValues(databoxId)?.[
				DataBoxNotificationToggleInputName.EmailExtended
			]
		) {
			setExtendedMailConfirmationDialogOpen(true);
		} else {
			storeNotificationSettings();
		}
	}, [databoxId, formInstance, storeNotificationSettings]);

	const closeExtendedMailConfirmationDialog = useCallback(() => {
		setExtendedNotification(formInstance, databoxId, false);
		setExtendedMailConfirmationDialogOpen(false);
	}, [databoxId, formInstance]);

	const confirmExtendedMailNotification = useCallback(() => {
		setExtendedMailConfirmationDialogOpen(false);
		setExtendedNotification(formInstance, databoxId, true);
		storeNotificationSettings();
	}, [databoxId, formInstance, storeNotificationSettings]);

	const contextValue = {
		controls,
		formInstance,
		databox,
		storeNotificationSettings,
		handleExtendedMailNotificationChange,
		isExtendedMailConfirmationDialogOpen,
		closeExtendedMailConfirmationDialog,
		confirmExtendedMailNotification,
		noContactDetails: !emailVerified && !phoneVerified,
		disableEmailOptions: !emailVerified,
		disablePhoneOptions: !phoneVerified,
		isEmailExtentedChecked:
			formInstance.getValues(databoxId)?.[
				DataBoxNotificationToggleInputName.EmailExtended
			],
	};

	return (
		<DatovkaNastaveniUpozorneniContext.Provider value={contextValue}>
			{children}
		</DatovkaNastaveniUpozorneniContext.Provider>
	);
}
