import { FormProvider } from 'react-hook-form';
import { usePersonCommunicationData } from '@gov-nx/core/hooks';
import { useLocale } from '@gov-nx/core/service';
import {
	RosComplaintVariant,
	PageCode,
	useProfilROSReklamaceContextInstance,
	EmailRadioOption,
	PhoneRadioOption,
} from '@gov-nx/module/page';
import {
	AgendaSearchAutocomplete,
	Button,
	ButtonGroupLayout,
	FormCheckbox,
	FormDatePicker,
	FormInput,
	FormLabel,
	FormRadio,
	FormSelect,
	FormWidthElementLayout,
	LayoutGap,
	Space,
} from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';
import { PoFormWeb } from '../../Form/PoForm';
import { MarkdownRender } from '../../Markdown/MarkdownRender';
import { ProfilROSSkeleton } from '../profil-ROS/ProfilROSSkeleton';
import { AddressSearchForm } from './AddressSearchForm';

const ValueOrEmpty = ({
	label,
	value,
}: {
	label: string;
	value?: string | number;
}) => {
	const { t } = useLocale(PageCode['profil-ROS']);

	return (
		<>
			{label}
			<Space />
			<span className="font-bold">
				{value ? value : t('formular.pole.neuvedeno')}
			</span>
		</>
	);
};

export interface ComplaintProps {
	code: PageCode;
}

export const ProfileROSComplaintView = ({ code }: ComplaintProps) => {
	const {
		data,
		variant,
		controls,
		setControls,
		formDefinition,
		disableSubmitButton,
		onSubmit,
		filteredOptions,
		interruptionOptions,
		resetAddressSearchFields,
	} = useProfilROSReklamaceContextInstance();
	const { emailVerified, phoneVerified } = usePersonCommunicationData();
	const isProcessing = controls.processLoading;
	const { t } = useLocale(code);

	if (controls.initialLoading) {
		return <ProfilROSSkeleton />;
	}

	return (
		<FormProvider {...formDefinition.formMethods}>
			<PoFormWeb
				formDefinition={formDefinition}
				onSubmit={onSubmit}>
				<LayoutGap gap={6}>
					<MarkdownRender>{t('formular.popis')}</MarkdownRender>
					<FormWidthElementLayout type={'l'}>
						<LayoutGap gap={8}>
							{variant === RosComplaintVariant.BASIC_INFO ? (
								<>
									<FormInput
										field={{
											name: 'ico',
											placeholder: t('formular.placeholder.ico'),
										}}
										control={{ size: 'm' }}
										label={{
											children: (
												<ValueOrEmpty
													label={t('formular.pole.ico')}
													value={data?.ico}
												/>
											),
										}}
									/>
									<FormSelect
										field={{
											name: 'pravniForma',
											disabled: isProcessing,
											options: [
												{
													value: '',
													label: t('formular.placeholder.pravni-forma'),
												},
												...filteredOptions,
											],
										}}
										label={{
											children: (
												<ValueOrEmpty
													label={t('formular.pole.pravni-forma')}
													value={data?.pravniForma?.nazev}
												/>
											),
										}}
									/>

									<FormInput
										field={{
											name: 'stav',
											disabled: true,
											placeholder: t('formular.placeholder.stav'),
										}}
										control={{ size: 'm' }}
										label={{
											children: (
												<ValueOrEmpty
													label={t('formular.pole.stav')}
													value={data?.pravniStav?.nazev}
												/>
											),
										}}
									/>
								</>
							) : null}
							{variant === RosComplaintVariant.AGENDA ? (
								<>
									<FormInput
										field={{
											name: 'nazevSpolecnosti',
											disabled: isProcessing,
											placeholder: t('formular.placeholders.nazev-spolecnosti'),
										}}
										control={{ size: 'm' }}
										label={{
											children: (
												<ValueOrEmpty
													label={t('formular.pole.nazev-spolecnosti')}
													value={data.nazevSpolecnosti}
												/>
											),
										}}
									/>

									<AgendaSearchAutocomplete
										field={{
											name: 'agenda',
											disabled: isProcessing,
											placeholder: t('formular.placeholder.agenda'),
										}}
										label={{
											children: (
												<ValueOrEmpty
													label={t('formular.pole.agenda')}
													value={data.agenda?.nazev}
												/>
											),
										}}
									/>
									<FormDatePicker
										field={{
											name: 'datumOd',
											disabled: isProcessing,
											placeholder: t('formular.placeholder.datum-vzniku'),
										}}
										label={{
											children: (
												<ValueOrEmpty
													label={t('formular.pole.datum-vzniku')}
													value={
														data?.datumVzniku
															? toDateReadable(data.datumVzniku)
															: undefined
													}
												/>
											),
										}}
									/>
									<FormDatePicker
										field={{
											name: 'datumDo',
											disabled: isProcessing,
											placeholder: t('formular.placeholder.datum-zaniku'),
										}}
										label={{
											children: (
												<ValueOrEmpty
													label={t('formular.pole.datum-zaniku')}
													value={
														data?.datumZaniku
															? toDateReadable(data.datumZaniku)
															: undefined
													}
												/>
											),
										}}
									/>

									<div className="space-y-1">
										<FormLabel>
											<ValueOrEmpty
												label={t('formular.pole.adresa-sidla')}
												value={data?.sidloSpolecnosti?.adresaText}
											/>
										</FormLabel>
										{!controls.displaySearchAddress ? (
											<Button
												onClick={() =>
													setControls({ displaySearchAddress: true })
												}
												disabled={isProcessing}
												variant="primary"
												size="m"
												type="outlined">
												{t('akce.zmenit-adresu')}
											</Button>
										) : (
											<>
												<AddressSearchForm
													code={code}
													isProcessing={isProcessing}
												/>
												<Button
													onClick={() => {
														setControls({ displaySearchAddress: false });
														resetAddressSearchFields();
													}}
													disabled={isProcessing}
													variant="primary"
													size="m"
													type="outlined">
													{t('akce.zrusit-zmeny')}
												</Button>
											</>
										)}
									</div>
									<FormSelect
										field={{
											name: 'pozastaveniPreruseni',
											disabled: isProcessing,
											options: interruptionOptions,
										}}
										label={{
											children: (
												<ValueOrEmpty
													label={t('formular.pole.pozastaveni-preruseni')}
													value={
														data?.pozastaveniPreruseni
															? t('formular.pole.pozastaveni.preruseni.ano')
															: t('formular.pole.pozastaveni.preruseni.ne')
													}
												/>
											),
										}}
									/>
								</>
							) : null}
						</LayoutGap>
					</FormWidthElementLayout>

					<div>
						<FormLabel legend={true}>
							{t('formular.pole.kontaktni-udaje')}
						</FormLabel>
						<FormLabel required>
							{t('formular.pole.kontaktni-udaje-popis')}
						</FormLabel>
					</div>
					<FormWidthElementLayout
						type={'xl'}
						className={'flex flex-col gap-4'}>
						<FormCheckbox
							field={{
								name: 'kontaktTelefon',
								required: false,
								disabled: isProcessing,
								label: {
									children: t('formular.pole.poskytnuti-telefonu'),
								},
							}}
						/>
						{formDefinition.formMethods.watch('kontaktTelefon') ? (
							<LayoutGap className={'ml-10'}>
								{phoneVerified ? (
									<FormRadio
										field={{
											name: '_telefon',
											disabled: isProcessing,
											options: [
												{
													value: phoneVerified,
													label: {
														children: phoneVerified,
													},
												},
												{
													value: PhoneRadioOption.ANOTHER_PHONE,
													label: {
														children: t('formular.pole.jiny-telefon'),
													},
												},
											],
										}}
										label={{
											children: t('formular.pole.vyberte-ze-seznamu'),
										}}
										control={{ size: 'm' }}
										group={{ gap: 's' }}
									/>
								) : (
									<FormInput
										field={{
											name: 'newPhone',
											disabled: isProcessing,
											placeholder: t('formular.placeholders.novy-telefon'),
										}}
										label={{ children: t('formular.pole.novy-telefon') }}
										control={{ size: 'm' }}
									/>
								)}
							</LayoutGap>
						) : null}
						{formDefinition.formMethods.watch('_telefon') ===
							PhoneRadioOption.ANOTHER_PHONE &&
						formDefinition.formMethods.watch('kontaktTelefon') ? (
							<LayoutGap className="ml-10">
								<FormInput
									field={{
										name: 'newPhone',
										disabled: isProcessing,
										placeholder: t('formular.placeholders.novy-telefon'),
									}}
									label={{ children: t('formular.pole.novy-telefon') }}
									control={{ size: 'm' }}
								/>
							</LayoutGap>
						) : null}

						<FormCheckbox
							field={{
								name: 'kontaktEmail',
								required: false,
								disabled: isProcessing,
								label: {
									children: t('formular.pole.poskytnuti-emailu'),
								},
							}}
						/>
						{formDefinition.formMethods.watch('kontaktEmail') ? (
							<LayoutGap className={'ml-10'}>
								{emailVerified ? (
									<FormRadio
										field={{
											name: '_email',
											disabled: isProcessing,
											options: [
												{
													value: emailVerified,
													label: { children: emailVerified },
												},
												{
													value: EmailRadioOption.ANOTHER_EMAIL,
													label: {
														children: t('formular.pole.jiny-email'),
													},
												},
											],
										}}
										label={{
											children: t('formular.pole.vyberte-ze-seznamu'),
										}}
										control={{ size: 'm' }}
										group={{ gap: 's' }}
									/>
								) : (
									<FormInput
										field={{
											name: 'newEmail',
											disabled: isProcessing,
											placeholder: t('formular.placeholders.novy-email'),
										}}
										label={{ children: t('formular.pole.novy-email') }}
										control={{ size: 'm' }}
									/>
								)}
							</LayoutGap>
						) : null}
						{formDefinition.formMethods.watch('_email') ===
							EmailRadioOption.ANOTHER_EMAIL &&
						formDefinition.formMethods.watch('kontaktEmail') ? (
							<LayoutGap className={'ml-10'}>
								<FormInput
									field={{
										name: 'newEmail',
										disabled: isProcessing,
										placeholder: t('formular.placeholders.novy-email'),
									}}
									label={{ children: t('formular.pole.novy-email') }}
									control={{ size: 'm' }}
								/>
							</LayoutGap>
						) : null}
					</FormWidthElementLayout>
					<FormWidthElementLayout
						type={'l'}
						className={'flex flex-col gap-6'}>
						<FormInput
							field={{
								name: 'popisReklamace',
								multiline: true,
								disabled: isProcessing,
								placeholder: t('formular.placeholders.popis-reklamace'),
							}}
							label={{ children: t('formular.pole.popis-reklamace') }}
							control={{ size: 'm' }}
						/>

						<ButtonGroupLayout>
							<Button
								loading={isProcessing}
								disabled={isProcessing || disableSubmitButton}
								nativeType="submit"
								variant="primary"
								size="m"
								type="solid">
								{t('akce.odeslat-reklamaci')}
							</Button>
						</ButtonGroupLayout>
					</FormWidthElementLayout>
				</LayoutGap>
			</PoFormWeb>
		</FormProvider>
	);
};
