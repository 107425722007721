import { useAuthStore } from '@gov-nx/store/portal-obcana';

export const useUserLoginInfo = () => {
	const getUserLoginType = useAuthStore('getUserLoginType');
	const getUserLoa = useAuthStore('getUserLoa');
	const loa = getUserLoa();
	const loginType = getUserLoginType();
	const databoxLogin = loginType === 'DS';

	const isLowLoa = loa === 'LOW';
	const isSubstantialLoa = loa === 'SUBSTANTIAL';
	const isHighLoa = loa === 'HIGH';

	return {
		loa,
		loginType,
		isLowLoa,
		isSubstantialLoa,
		isHighLoa,
		databoxLogin,
	};
};
