import { CmsExternalServiceLink } from '@gov-nx/api/common';
import { useRezaEligibleList } from '@gov-nx/core/hooks';
import { useFormBuilder, useLocale } from '@gov-nx/core/service';
import { Nullable, hasProperties, is } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { CommonSelectProps, FormRadioProps } from '@gov-nx/ui/types';
import { getRadioDefinition, getSelectDefinition } from '@gov-nx/utils/common';

export interface Data {
	role: Nullable<'v_zastoupeni' | 'bez_zastoupeni'>;
	rezaCode: Nullable<string>;
}
export interface Fields {
	role: FormRadioProps;
	rezaCode: CommonSelectProps;
}

interface SmartFormRepresentationFormProps {
	link: CmsExternalServiceLink['link'];
}

export const useSmartFormRepresentationForm = ({
	link,
}: SmartFormRepresentationFormProps) => {
	const { t } = useLocale(PageCode['rozcestnik']);
	// zatim kody sablon doplneny do cms linku sluzby ve string formatu: &kodySablon=kod,kod,kod
	const params = Object.fromEntries(new URLSearchParams(link));
	const kodSablony = params?.kodySablon?.split(',').filter(is);

	const eligibleList = useRezaEligibleList({
		filter: {
			role: 'zmocnenec',
			kodSablony,
		},
	});

	const options =
		eligibleList.data?.seznam
			?.filter(hasProperties(['kod', 'nazevSablony']))
			.map((reza) => {
				return {
					label: `${reza.zastupujiciOsobaNazev}: ${reza.nazevSablony}`,
					value: reza.kod,
				};
			}) ?? [];

	const roleField = getRadioDefinition({
		name: 'role',
		label: t('smart-forms.role.label'),
		isRequired: true,
		isDisabled: eligibleList.isLoading,
		isRequiredMessage: t('smart-forms.role.povinny'),
		options: [
			{
				label: t('smart-forms.role.bez-zastupovani'),
				name: 'bez_zastoupeni',
			},
			{
				label: t('smart-forms.role.v-zastoupeni'),
				name: 'v_zastoupeni',
			},
		],
		defaultValue: 'bez_zastoupeni',
	});

	const rezaCode = getSelectDefinition({
		name: 'rezaCode',
		label: t('smart-forms.reza-kod.label'),
		isDisabled: eligibleList.isLoading,
		isRequiredWhen: {
			fieldName: roleField.field.field.name,
			is: 'v_zastoupeni',
			errorMessage: t('smart-forms.reza-kod.povinny'),
		},
		options: [
			{ label: t('smart-forms.reza-kod.vyberte'), value: '' },
			...options,
		],
	});

	const form = useFormBuilder<Data, Fields>([roleField, rezaCode]);
	const role = form.formMethods.watch('role');

	return {
		form,
		role,
		hasRepresentationOptions: !!options.length,
		eligibleList,
	};
};
