import { useCallback } from 'react';
import { languageQuery } from '@gov-nx/api/common';
import { usePoQuery } from '@gov-nx/api/portal-obcana';
import {
	useConfigurationStore,
	useListsStore,
} from '@gov-nx/store/portal-obcana';

export interface LanguageLoaderProps {
	onSuccess?: () => void;
	onError?: (error: Error) => void;
}

export const useLanguageLoader = (props?: LanguageLoaderProps) => {
	const languages = useListsStore('languages');
	const saveLanguageList = useListsStore('saveLanguageList');
	const getBaseCmsUrlPath = useConfigurationStore('getBaseCmsUrlPath');
	const cmsPath = getBaseCmsUrlPath();

	const query = usePoQuery({
		queryKey: ['languages'],
		queryFn: () => languageQuery(cmsPath as string),
		retry: 1,
		enabled: false,
		refetchOnWindowFocus: false,
		onError: (e) => {
			if (props && props.onError) {
				props.onError(e as Error);
			}
		},
		onSuccess: (data) => {
			saveLanguageList(data);
			if (props && props.onSuccess) {
				props.onSuccess();
			}
		},
	});

	const loadLanguages = useCallback(async (): Promise<void> => {
		if (Array.isArray(languages) && languages.length) {
			return Promise.resolve();
		}
		await query.refetch();
	}, [languages, query]);
	return { loadLanguages };
};
