import { useQueryClient } from '@tanstack/react-query';
import {
	disconnectFromSubmissionQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import { useLocale } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { invalidateMySubmissionsListQuery } from './queries';

export interface DisconnectFromSubmissionMutationVariables {
	submissionId: number;
	datovaSchrankaId: string;
	datovaZpravaId: number;
}

export const useDisconnectFromSubmissionMutation = (
	onDisconnected?: () => void
) => {
	const queryClient = useQueryClient();
	const { toastMessageSuccess } = useMessageEvents();
	const { t } = useLocale(PageCode['moje-podani']);

	return usePoMutation({
		mutationFn: async ({
			submissionId,
			datovaSchrankaId,
			datovaZpravaId,
		}: DisconnectFromSubmissionMutationVariables): Promise<void> => {
			await disconnectFromSubmissionQuery({
				submissionId,
				body: { datovaSchrankaId, datovaZpravaId },
			});
		},
		onSuccess: async () => {
			toastMessageSuccess(t('formular.zprava.odpojeno'));
			await invalidateMySubmissionsListQuery(queryClient);

			if (onDisconnected) {
				onDisconnected();
			}
		},
	});
};
