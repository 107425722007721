import * as yup from 'yup';
import { LocalizeNameSpaceTypes, useLocale } from '@gov-nx/core/service';
import {
	CommonCheckboxProps,
	CommonInputProps,
	FormCheckboxProps,
	FormInputProps,
	FormPhoneNumberProps,
	PhoneWithCountryCodeShape,
} from '@gov-nx/ui/types';
import {
	getCheckboxDefinition,
	useInputDefinition,
	usePhoneDefinition,
	usePhoneShapeWithCountryCodes,
} from '@gov-nx/utils/common';

export interface ContactDetailsFormData {
	_useEmail: boolean;
	_usePhone: boolean;
	email?: string;
	phone?: PhoneWithCountryCodeShape;
}

export interface ContactDetailsFormFields {
	_useEmail: FormCheckboxProps;
	email: FormInputProps;
	_usePhone: FormCheckboxProps;
	phone: FormPhoneNumberProps;
}
export const useContactDetailsDefinition = ({
	isDisabled,
	strings,
}: {
	isDisabled: boolean;
	strings: {
		_useEmail: { label: string };
		_usePhone: { label: string };
		email: { label: string; placeholder: string };
		phone: { label: string; placeholder: string };
	};
}) => {
	const { t } = useLocale(LocalizeNameSpaceTypes.Form);

	const useEmail = getCheckboxDefinition({
		name: '_useEmail',
		isDisabled,
		label: strings._useEmail.label,
	});
	const usePhone = getCheckboxDefinition({
		name: '_usePhone',
		isDisabled,
		label: strings._usePhone.label,
	});

	const email = useInputDefinition({
		name: 'email',
		placeholder: strings.email.placeholder,
		label: strings.email.label,
		isEmail: true,
		isDisabled,
		isRequiredWhen: {
			fieldName: useEmail.field.field.name,
			is: true,
			errorMessage: t('validations.zadejte-kontaktni-email'),
		},
	});

	const phone = usePhoneDefinition({
		name: 'phone',
		placeholder: strings.phone.placeholder,
		label: '', //strings.phone.label,
		countryCodeLabel: '',
		isDisabled,
		isRequiredWhen: {
			fieldName: usePhone.field.field.name,
			is: true,
		},
	});

	return [useEmail, email, usePhone, phone];
};

export interface ContactDetailsFields {
	_useEmail: CommonCheckboxProps;
	email: CommonInputProps;
	_usePhone: CommonCheckboxProps;
	phone: CommonInputProps;
}

export const useContactDetailsFormDefinition = () => {
	const { getPhoneShape, getDefaultValue } = usePhoneShapeWithCountryCodes();
	const { t } = useLocale(LocalizeNameSpaceTypes.Form);

	return {
		shape: {
			_useEmail: yup.boolean(),
			_usePhone: yup.boolean(),
			email: yup
				.string()
				.email(t('validations.email-neni-ve-spravnem-tvaru'))
				.when('_useEmail', {
					is: true,
					then: (schema) =>
						schema.required(t('validations.zadejte-kontaktni-email')),
					otherwise: (schema) => schema.optional(),
				}),
			phone: getPhoneShape().when('_usePhone', {
				is: true,
				then: getPhoneShape({ isRequired: true }),
				otherwise: (schema) => schema.optional(),
			}),
		},
		defaultValues: {
			_useEmail: false,
			email: undefined,
			_usePhone: false,
			phone: getDefaultValue(),
		},
		fields: (
			isProcessing: boolean,
			strings: {
				email: { label: string; placeholder: string };
				phone: { label: string; placeholder: string };
			}
		) => {
			const fields: ContactDetailsFields = {
				_useEmail: {
					name: '_useEmail',
					disabled: isProcessing,
					isDisabled: isProcessing,
					label: {
						children: strings.email.label,
					},
				},
				email: {
					name: 'email',
					disabled: isProcessing,
					isDisabled: isProcessing,
					placeholder: strings.email.placeholder,
				},
				_usePhone: {
					name: '_usePhone',
					disabled: isProcessing,
					isDisabled: isProcessing,
					label: {
						children: strings.phone.label,
					},
				},
				phone: {
					name: 'phone',
					disabled: isProcessing,
					isDisabled: isProcessing,
					placeholder: strings.phone.placeholder,
				},
			};

			return fields;
		},
	};
};
