import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Configuration } from '@gov-nx/api/common';
import { prop } from '@gov-nx/core/types';
import { currentPlatform, CurrentPlatformType } from '@gov-nx/utils/common';
import {
	storeGlobalConfig,
	PERSIST_ZUSTAND_STORE_KEY,
	StoreKeys,
} from '../constants';
import { ConfigurationState } from './store.types';

const initialState = {
	cmsUrlPath: 'https://obcan.portal.gov.cz/cms',
};

export const useConfigurationStoreBase = create<ConfigurationState>()(
	persist(
		(set, get) => ({
			...initialState,

			initMeta: (application: string, environment: string) =>
				set({
					...initialState,
					application: String(application).toString(),
					environment: String(environment).toString(),
				}),
			saveConfiguration: (configuration: Configuration) =>
				set({ configuration }),

			poServerUrl: () => {
				const configuration = get().configuration;
				// return 'https://poc.asseco-ce.com'
				// return 'http://localhost:3000'
				if (configuration?.poServerUrl) {
					const { poServerUrl } = configuration;
					if (poServerUrl && poServerUrl.indexOf('|') !== -1) {
						const urls = poServerUrl.split('|');
						if (currentPlatform === CurrentPlatformType.IOS) {
							return urls[0] as string;
						} else if (currentPlatform === CurrentPlatformType.ANDROID) {
							return urls[1] as string;
						}
					}
					return poServerUrl as string;
				}
				return null;
			},
			getBaseCmsUrlPath: () => {
				const cmsUrlPath = get().cmsUrlPath;
				const finalCmsUrlPath = String(cmsUrlPath).includes('https')
					? cmsUrlPath
					: 'https://obcan.portal.gov.cz/cms';

				return finalCmsUrlPath;
			},
			getCmsUrlPath: () => {
				const cmsUrlPath = get().cmsUrlPath;
				const finalCmsUrlPath = String(cmsUrlPath).includes('https')
					? cmsUrlPath
					: 'https://obcan.portal.gov.cz/cms';

				return `${finalCmsUrlPath}/${get().application}/${get().environment}`;
			},
		}),
		{
			...storeGlobalConfig,
			name: PERSIST_ZUSTAND_STORE_KEY + ':' + StoreKeys.configuration,
		}
	)
);

export const useConfigurationStore = <T extends keyof ConfigurationState>(
	property: T
): ConfigurationState[T] => useConfigurationStoreBase(prop(property));
