import { CiselnikyVolbyDto } from '@gov-nx/api/portal-obcana';
import { useElectionsQuery, useRobPersonQuery } from '@gov-nx/core/hooks';
import { useLocale } from '@gov-nx/core/service';
import { hasProperty } from '@gov-nx/core/types';
import { useDataBoxAccess } from '@gov-nx/module/data-box';
import { ServiceCode } from '@gov-nx/module/service';
import { toDateReadable } from '@gov-nx/utils/common';

export type AllowedElection = Omit<CiselnikyVolbyDto, 'druh'> & {
	druh: 'POSLANECKA_SNEMOVNA' | 'PREZIDENT' | 'EVROPSKY_PARLAMENT';
};

const isAllowedElection = (
	election: CiselnikyVolbyDto
): election is AllowedElection => {
	return (
		election.druh === 'POSLANECKA_SNEMOVNA' ||
		election.druh === 'PREZIDENT' ||
		election.druh === 'EVROPSKY_PARLAMENT'
	);
};

export const useElections = () => {
	const { t } = useLocale(ServiceCode['volicsky-prukaz']);

	const electionsQuery = useElectionsQuery();
	const personQuery = useRobPersonQuery();

	const elections = (electionsQuery.data?.seznam ?? [])
		.filter(isAllowedElection)
		.filter(hasProperty('volbyId'))
		.map((election) => {
			const dates = election.kola
				?.map((round) => {
					return `${round.terminOd ? toDateReadable(round.terminOd) : '-'} - ${
						round.terminDo ? toDateReadable(round.terminDo) : '-'
					}`;
				})
				.join(' a ');

			return {
				...election,
				title: t(`volby.druh.${election.druh}`) + ' (' + dates + ')',
			};
		});

	const requiredDataBoxes: ['FO', 'PFO'] = ['FO', 'PFO'];
	const { areAnyConnected } = useDataBoxAccess({
		requiredTypes: requiredDataBoxes,
	});

	return {
		elections,
		isLoading: electionsQuery.isLoading || personQuery.isLoading,
		isCzechCitizen: personQuery.isCzechCitizen,
		canVote:
			personQuery.isCzechCitizen && elections.length > 0 && areAnyConnected,
	};
};
