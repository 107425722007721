import { useCallback, useState } from 'react';
import {
	authNiaRegisterQuery,
	SecurityMobileTokenDto,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { ApiCallback, useApiCallback } from '@gov-nx/core/hooks';
import { useAuthStore } from '@gov-nx/store/portal-obcana';
import { delay } from '@gov-nx/utils/common';

export const useAuthNiaRegisterQuery = () => {
	const [niaAccessToken, setNiaAccessToken] = useState<string | undefined>(
		undefined
	);
	const { setCallback, onError, onSuccess } = useApiCallback();
	const setAccessToken = useAuthStore('setAccessToken');

	const registerQuery = usePoQuery<unknown, SecurityMobileTokenDto>({
		queryKey: ['po-auth-register', niaAccessToken],
		queryFn: async () => authNiaRegisterQuery(niaAccessToken as string),
		enabled: false,
		retry: 0,
		onSuccess: (data) => {
			setAccessToken(data.access_token as string);
			onSuccess();
		},
		onError: (e) => onError(e as Error),
	});

	const fetch = useCallback(
		async (
			accessToken: string,
			{ ...args }: ApiCallback<undefined, Error>
		): Promise<void> => {
			setNiaAccessToken(accessToken);
			await delay(200);
			setCallback({ ...args });
			await registerQuery.refetch();
		},
		[registerQuery, setCallback, setNiaAccessToken]
	);

	return { fetch };
};
