import { LocalizeNameSpaceTypes, useLocale } from '@gov-nx/core/service';
import {
	LocaleDefinition,
	LocaleNoParams,
	LocaleVariables,
} from '@gov-nx/core/types';

type GlobalLocaleObject = {
	TETES: LocaleNoParams;
	action: {
		confirm: {
			button: LocaleNoParams;
		};
		submit: {
			button: LocaleNoParams;
		};
		'submit-date': {
			button: LocaleNoParams;
		};
	};
	akce: {
		dalsi: LocaleNoParams;
		'registrovat-identitou': LocaleNoParams;
		souhlasim: LocaleNoParams;
		wcag: {
			'zrusit-filtr': LocaleNoParams;
			'zrusit-tento-filtr': LocaleNoParams;
		};
		zkopirovano: LocaleNoParams;
		'zkopirovat-emailovou-adresu': LocaleNoParams;
		zrusit: LocaleNoParams;
		'zrusit-filtr': LocaleNoParams;
		'registrovat-se': LocaleNoParams; // jen gov-app
	};
	alert: {
		wcag: {
			'toggle-label': LocaleNoParams;
		};
	};
	antivirus: {
		action: {
			stahnout: LocaleNoParams;
			zrusit: LocaleNoParams;
		};
		text: {
			'opravdu-chcete-stahnout': LocaleVariables<'fileName'>;
			'problem-s-antivirem': LocaleNoParams;
		};
	};
	'box-neprectene': {
		'nic-neuniklo': LocaleNoParams;
		'vsechny-prectene': LocaleNoParams;
	};
	chyba: {
		'stranka-nenalezena': {
			nadpis: LocaleNoParams;
			popis: LocaleNoParams;
			'prejit-na-hp': LocaleNoParams;
		};
	};
	'chyba-ulozeni': LocaleNoParams;
	copy: LocaleVariables<'year'>;
	'design-system': LocaleVariables<'version'>;
	documents: {
		choose: {
			title: LocaleNoParams;
		};
		document: {
			title: LocaleNoParams;
		};
		type: {
			title: LocaleNoParams;
		};
	};
	drozd: {
		test: LocaleNoParams;
	};
	empty: {
		'no-text': {
			filled: {
				title: LocaleNoParams;
			};
		};
		'no-value': {
			chosen: {
				title: LocaleNoParams;
			};
		};
		'not-provided': LocaleNoParams;
	};
	form: {
		fields: {
			'datova-schranka': LocaleNoParams;
		};
	};
	'gov-cz': {
		navigace: {
			'moje-podani': LocaleNoParams;
			'moje-soubory': LocaleNoParams;
			nastaveni: LocaleNoParams;
			profil: LocaleNoParams;
			upozorneni: LocaleNoParams;
		};
	};
	'jak-zalozit-identitu': LocaleNoParams;
	loading: {
		wcag: {
			processing: LocaleNoParams;
		};
	};
	login: LocaleNoParams;
	'login-has-failed': LocaleNoParams;
	'login-in-process': LocaleNoParams;
	logo: {
		'portal-obcana': LocaleNoParams;
	};
	logout: LocaleNoParams;
	'mobilni-aplikace': {
		'apple-store': LocaleNoParams;
		'google-play': LocaleNoParams;
		'mobilni-aplikace': LocaleNoParams;
		nadpis: LocaleNoParams;
	};
	'na-zacatek-stranky': LocaleNoParams;
	nacitani: LocaleNoParams;
	navigation: {
		back: LocaleNoParams;
	};
	notification: {
		error: {
			B_JEDEN_SOUBOR: LocaleNoParams;
			CHYBA_NIA_IDENTIFIKATOR: LocaleNoParams;
			CHYBA_VSTUPU: LocaleNoParams;
			DZ_NELZE_SMAZAT_ARCHIVACE_EXISTS: LocaleNoParams;
			GENERAL_NIA_ERROR_MSG: LocaleNoParams;
			ISZR_EGON_CHYBA: LocaleNoParams;
			ISZR_POSKYTNUTISOUHLASU_ZTOTOZNENI_DN: LocaleNoParams;
			NENALEZENO: LocaleNoParams;
			OBECNA_CHYBA: LocaleNoParams;
			default: LocaleNoParams;
		};
	};
	odkaz: {
		'chci-datovku': LocaleNoParams;
		'chci-identitu': LocaleNoParams;
		'gov-cz': LocaleNoParams;
		'otevrit-facebook': LocaleNoParams;
		'otevrit-stranky-dia': LocaleNoParams;
		'otevrit-stranky-nakit': LocaleNoParams;
		'otevrit-stranky-narodni-plan-obnovy': LocaleNoParams;
		'otevrit-stranky-next-generation-eu': LocaleNoParams;
		'otevrit-x-network': LocaleNoParams;
		'otevrit-youtube': LocaleNoParams;
		podpora: LocaleNoParams;
		'portal-identity-obcana': LocaleNoParams;
		'prihlaseni-do-ds': LocaleNoParams;
		'prohlaseni-o-pristupnosti': LocaleNoParams;
		'stahnout-na-google-play': LocaleNoParams;
		'stahnout-v-app-store': LocaleNoParams;
		'uzivatelska-prirucka': LocaleNoParams;
		'zpet-na-hp': LocaleNoParams;
		'zpracovani-osobnich-udaju': LocaleNoParams;
	};
	odregistrace: {
		akce: {
			'ano-zrusit': LocaleNoParams;
			nerusit: LocaleNoParams;
			odregistrovat: LocaleNoParams;
		};
		modal: {
			titul: LocaleNoParams;
		};
		'nebudete-se-moci-prihlasovat': LocaleNoParams;
		'opravdu-zrusit-registraci': LocaleNoParams;
	};
	'offline-data': {
		available: {
			modal: {
				button: LocaleNoParams;
				description: LocaleNoParams;
				'drozd-description': {
					'1': LocaleNoParams;
					'2': LocaleNoParams;
				};
				title: LocaleNoParams;
			};
		};
		downloaded: {
			title: LocaleNoParams;
		};
		downloading: {
			error: {
				title: LocaleNoParams;
			};
		};
	};
	opravneni: {
		action: {
			nastaveni: LocaleNoParams;
			udelit: LocaleNoParams;
		};
		description: {
			'chybi-opravneni': LocaleNoParams;
			'potreba-udelit': LocaleNoParams;
		};
		title: {
			'chybi-opravneni': LocaleNoParams;
		};
	};
	paticka: {
		sekce: {
			informace: LocaleNoParams;
			'mate-dotaz': LocaleNoParams;
			'mohlo-by-se-hodit': LocaleNoParams;
			'po-prinasi': LocaleNoParams;
			'sledujte-egov': LocaleNoParams;
		};
	};
	'portal-obcana': {
		navigace: {
			certifikaty: LocaleNoParams;
			cesky: LocaleNoParams;
			datovka: LocaleNoParams;
			'datovka-menu': {
				'pripojit-datovku': LocaleNoParams;
				'zalozit-datovku-fo': LocaleNoParams;
			};
			doklady: LocaleNoParams;
			domu: LocaleNoParams;
			edoklady: LocaleNoParams;
			english: LocaleNoParams;
			'moje-podani': LocaleNoParams;
			'moje-soubory': LocaleNoParams;
			nastaveni: LocaleNoParams;
			'odhlasit-se': LocaleNoParams;
			profil: LocaleNoParams;
			'registr-obyvatel': LocaleNoParams;
			'registr-osob': LocaleNoParams;
			upozorneni: LocaleNoParams;
			vice: LocaleNoParams;
			wcag: {
				cesky: LocaleNoParams;
				english: LocaleNoParams;
				'odhlasit-se': LocaleNoParams;
			};
		};
		nazev: LocaleNoParams;
	};
	'povolit-prihlasovani': LocaleNoParams;
	'prihlaseni-se-nepodarilo-dokoncit': LocaleNoParams;
	'registrace-odstranena': LocaleNoParams;
	'registration-has-failed': LocaleNoParams;
	'registration-in-process': LocaleNoParams;
	status: {
		databox: {
			messages: {
				connect: LocaleNoParams;
			};
		};
	};
	strankovani: LocaleNoParams;
	title: LocaleNoParams;
	'to-the-beginning': LocaleNoParams;
	ulozeno: LocaleNoParams;
	upozorneni: {
		neprectena: LocaleNoParams;
	};
	uzivatel: {
		oblibene: {
			sluzba: {
				odebrano: LocaleNoParams;
				odebrat: LocaleNoParams;
				pridano: LocaleNoParams;
				pridat: LocaleNoParams;
			};
			stranka: {
				odebrano: LocaleNoParams;
				odebrat: LocaleNoParams;
				pridano: LocaleNoParams;
				pridat: LocaleNoParams;
			};
			wcag: {
				sluzba: {
					odebrat: LocaleVariables<'name'>;
					pridat: LocaleVariables<'name'>;
				};
				stranka: {
					odebrat: LocaleVariables<'name'>;
					pridat: LocaleVariables<'name'>;
				};
			};
		};
	};
	verze: LocaleNoParams;
	'vice-informaci': {
		skryt: LocaleNoParams;
		wcag: {
			label: LocaleNoParams;
		};
		zobrazit: LocaleNoParams;
	};
	zprava: {
		'informace-casova-zona': LocaleNoParams;
	};
	zpravy: {
		'zpravu-nepodarilo-nacist': LocaleNoParams;
	};
	nastaveni: {
		'pripojene-datovky': LocaleNoParams;
		biometrie: LocaleNoParams;
	};
	prihlaseni: {
		nacitam: LocaleNoParams; // jen gov-app
		obcan: LocaleNoParams; // jen gov-app
		'pomoci-identity-obcana': LocaleNoParams; // jen gov-app
		'zadne-internety': LocaleNoParams; // jen gov-app
	};
};

export const useGlobalLocale = (): LocaleDefinition<GlobalLocaleObject> =>
	useLocale(LocalizeNameSpaceTypes.Global);
