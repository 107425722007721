import * as yup from 'yup';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import {
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
} from '@gov-nx/core/service';
import { useDataBoxShape } from '@gov-nx/utils/common';
import { FormDataStep4 } from './context.types';

interface Props {
	dataBoxTypes: DatovkaApiType['typSchranky'][];
}
export const FormStep4 = ({ dataBoxTypes }: Props) => {
	const { getDataBoxShape, getDataBoxDefaultValues } = useDataBoxShape({
		types: dataBoxTypes,
	});

	const formSchema = yup
		.object<FormSchemaShape<FormDataStep4>>(
			getDataBoxShape({ isRequired: true })
		)
		.required();

	const formMethods = usePoForm<FormDataStep4>({
		formSchema,
		defaultValues: getDataBoxDefaultValues(),
	});

	return getFormDefinition({ formMethods, formSchema });
};
