import { form602SubmissionQuery, usePoQuery } from '@gov-nx/api/portal-obcana';

interface Form602SubmissionQuery {
	formularPodaniId: string;
}

export const useForm602SubmissionQuery = ({
	formularPodaniId,
}: Form602SubmissionQuery) => {
	const query = usePoQuery(
		{
			queryKey: ['form602-submission', formularPodaniId],
			queryFn: () => form602SubmissionQuery(formularPodaniId),
		},
		{ errorIgnoreFilter: () => true }
	);

	return {
		data: query.data,
		isLoading: query.isLoading,
		error: query.error,
	};
};
