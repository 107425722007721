import { GovSkeleton } from '@gov-design-system-ce/react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const ConsentManagementSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<div className={'-mx-4 [ md:mx-0 ]'}>
			{[0, 1, 2, 3, 4].map((_, i) => {
				return (
					<div
						// eslint-disable-next-line react/no-array-index-key
						key={i}
						className={'px-4 py-6 border-b border-secondary-400'}>
						<div className={'flex justify-between mb-2'}>
							<div className={'w-full'}>
								<GovSkeleton
									width={generateRandomNumber(80, 95) + '%'}
									height={'24px'}
									variant={'secondary'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
									className={'skeleton-brighter [ md:hidden ]'}
								/>
								<GovSkeleton
									width={generateRandomNumber(20, 80) + '%'}
									height={'24px'}
									variant={'secondary'}
									wcagLabel={
										t('loading.wcag.processing', {
											namespace: LocalizeNameSpaceTypes.Global,
										}) as string
									}
									className={'skeleton-brighter'}
								/>
							</div>
							<GovSkeleton
								width={'130px'}
								height={'22px'}
								variant={'secondary'}
								wcagLabel={
									t('loading.wcag.processing', {
										namespace: LocalizeNameSpaceTypes.Global,
									}) as string
								}
								className={'hidden skeleton-brighter [ md:block ]'}
							/>
						</div>
						<GovSkeleton
							width={'140px'}
							height={'22px'}
							variant={'secondary'}
							wcagLabel={
								t('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
							className={'skeleton-brighter'}
						/>
						<GovSkeleton
							width={'130px'}
							height={'22px'}
							variant={'secondary'}
							wcagLabel={
								t('loading.wcag.processing', {
									namespace: LocalizeNameSpaceTypes.Global,
								}) as string
							}
							className={'block mt-2 ml-2 skeleton-brighter [ md:hidden ]'}
						/>
					</div>
				);
			})}
		</div>
	);
};
