import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { CmsExternalService, CmsPage, CmsService } from '@gov-nx/api/common';
import { Nullable, prop } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { ServiceCode } from '@gov-nx/module/service';
import {
	PERSIST_ZUSTAND_STORE_KEY,
	storeGlobalConfig,
	StoreKeys,
} from '../constants';
import { CmsState } from './store.types';

const initialState = {
	services: {},
	pages: {},
	externalServices: {},
};

export const useCmsStoreBase = create<CmsState>()(
	persist(
		(set, get) => ({
			...initialState,

			saveCmsService: (code: ServiceCode, data: CmsService) => {
				const prevServices = get().services;
				set({
					services: { ...prevServices, [code]: { ...data } },
				});
			},
			saveCmsExternalService: (id: number, data: CmsExternalService) => {
				const prevExternalServices = get().externalServices;
				set({
					externalServices: { ...prevExternalServices, [id]: { ...data } },
				});
			},
			saveCmsPage: (code: PageCode, data: CmsPage) => {
				const prevPages = get().pages;
				set({
					pages: { ...prevPages, [code]: { ...data } },
				});
			},
			resetCms: () => set({ ...initialState }),
		}),
		{
			...storeGlobalConfig,
			name: PERSIST_ZUSTAND_STORE_KEY + ':' + StoreKeys.cms,
		}
	)
);

export const useCmsStore = <T extends keyof CmsState>(
	property: T
): CmsState[T] => useCmsStoreBase(prop(property));

export const useCmsStorePage = (code: PageCode) =>
	useCmsStoreBase((state) => state.pages[code]);

export const useCmsStoreService = (code: ServiceCode) =>
	useCmsStoreBase((state) => state.services[code]);

export const useCmsStoreExternalService = (id: Nullable<number>) =>
	useCmsStoreBase((state) => (id ? state.externalServices[id] : null));
