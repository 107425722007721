import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDownloadEvents, useMessageEvents } from '@gov-nx/core/events';
import { useFileDownload } from '@gov-nx/core/hooks';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { DownloadCallback } from '../Download/types';

export const DownloadFileListener = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Service);
	const [fileId, setFileId] = useState<null | string>(null);
	const { toastMessage } = useMessageEvents();
	const { download } = useDownloadEvents();
	const [callback, setCallback] = useState<{
		[key: string]: Nullable<DownloadCallback<string>['callback']>;
	}>({});

	useDownloadEvents({
		onFileDownload: (_, { id, callback }) => {
			setFileId(id);
			if (callback) {
				setCallback({ ...callback, [id]: callback });
			}
		},
	});

	const onDownload = useCallback(
		(id: string, success: boolean | null, error: Error | null) => {
			if (typeof id !== 'string') {
				return;
			}
			const callbackFunction = callback[id];
			if (callbackFunction) {
				callbackFunction(id, success, error);
				setCallback({ ...callback, [id]: null });
			}
			setFileId(null);
		},
		[callback]
	);

	const { mutate } = useFileDownload({
		onSuccess: (data) => {
			download({
				...data,
				id: data.id,
				callback: (id, success, error) => {
					if (success) {
						successMessage(data.fileName);
						onDownload(data.id, true, null);
					}
					if (error) {
						errorMessage();
						onDownload(data.id, null, error);
					}
				},
			});
		},
		onError: (id, error) => {
			errorMessage();
			onDownload(id, null, error);
		},
	});

	const successMessage = (fileName: string) => {
		toastMessage({
			content: t('soubor.stazeni-souboru-uspech', {
				namespace: LocalizeNameSpaceTypes.Service,
				fileName,
			}),
			options: {
				variant: 'success',
				icon: {
					name: 'check-lg',
					type: 'basic',
				},
			},
		});
	};

	const errorMessage = () => {
		toastMessage({
			content: t('soubor.stazeni-souboru-chyba', {
				namespace: LocalizeNameSpaceTypes.Service,
			}),
			options: {
				variant: 'error',
				icon: {
					name: 'exclamation-triangle-fill',
					type: 'basic',
				},
			},
		});
	};

	useEffect(() => {
		if (fileId) {
			mutate(fileId);
		}
	}, [fileId]);

	return null;
};
