import { useUserLogout } from '@gov-nx/web';
import React, { useCallback, useEffect, useState } from 'react';
import { useIdentity } from '@gov-nx/auth/common';
import { useAuthEvents, useMessageEvents } from '@gov-nx/core/events';
import {
	LocalizeNameSpaceTypes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { useAuthStore } from '@gov-nx/store/portal-obcana';
import { delay } from '@gov-nx/utils/common';
import { authCookiesHelper } from '../helpers/cookies';

export interface AuthProviderProps {
	children: React.ReactNode;
}

export function AuthProvider(props: AuthProviderProps) {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(LocalizeNameSpaceTypes.Auth);
	const { toastMessage } = useMessageEvents();
	const { onUserLogout } = useUserLogout();

	useAuthEvents({
		onUserLogout: async (_, payload) => {
			if (payload?.enforce) {
				toastMessage({
					content: tn('zpravy.odhlaseni.byli-jste-automaticky-odhlaseni'),
					options: {
						variant: 'error',
						icon: {
							name: 'exclamation-triangle-fill',
							type: 'basic',
						},
					},
				});
			}
			await onUserLogout();
		},
		onUserInactive: () => {
			toastMessage({
				content: tn('zpravy.odhlaseni.budete-automaticky-odhlaseni'),
				options: {
					variant: 'warning',
					icon: {
						name: 'lightbulb-fill',
						type: 'basic',
					},
				},
			});
		},
	});

	return <AuthProviderAction {...props} />;
}

export function AuthProviderAction({ children }: AuthProviderProps) {
	const { userLogout } = useAuthEvents();
	const { fetchMe, accessToken } = useIdentity();
	const [processing, setProcessing] = useState<boolean>(true);
	const setAccessToken = useAuthStore('setAccessToken');

	const authCookie = authCookiesHelper();
	const initAuth = useCallback(async () => {
		await delay(500);
		const accessTokenFromCookie = authCookie.getAccessToken();
		if (accessTokenFromCookie) {
			setAccessToken(accessTokenFromCookie);
			authCookie.removeAccessToken();
			authCookie.removeAuthToken();
			await fetchMe();
		} else if (accessToken) {
			await fetchMe();
		}
	}, [authCookie, accessToken, setAccessToken, fetchMe]);

	useEffect(() => {
		setProcessing(true);
		initAuth()
			.catch(() => userLogout())
			.finally(() => setProcessing(false));
	}, []);

	if (processing) {
		return null;
	}

	return <>{children}</>;
}
