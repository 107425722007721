import React from 'react';
import { useLocale } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { Button, FormButtonProps, PopConfirm } from '@gov-nx/ui/web';

interface DisconnectProps {
	onDisconnect: (onConfirmed: () => void) => void;
	isDisconnecting?: boolean;
	buttonProps?: {
		type?: FormButtonProps['type'];
		size?: FormButtonProps['size'];
	};
}
export const DisconnectFromSubmission = ({
	onDisconnect,
	buttonProps,
	isDisconnecting,
}: DisconnectProps) => {
	const { t } = useLocale(PageCode['moje-podani']);

	return (
		<PopConfirm
			onConfirm={onDisconnect}
			isLoading={isDisconnecting}
			isDisabled={isDisconnecting}
			strings={{
				title: t('odpojit-od-podani.titulek'),
				body: t('odpojit-od-podani.text'),
				buttons: {
					cancel: {
						label: t('akce.odpojit-od-podani-zrusit'),
						wcagLabel: t('akce.odpojit-od-podani-zrusit'),
					},
					confirm: {
						label: t('akce.odpojit-od-podani-potvrdit'),
						wcagLabel: t('akce.odpojit-od-podani-potvrdit'),
					},
				},
			}}>
			{({ open }) => {
				return (
					<Button
						onClick={open}
						variant={'primary'}
						wcagLabel={t('akce.odpojit-od-podani')}
						{...buttonProps}>
						{t('akce.odpojit-od-podani')}
					</Button>
				);
			}}
		</PopConfirm>
	);
};
