import { LocalizationLanguage } from '@gov-nx/core/app';
import { useUserStore } from '@gov-nx/store/portal-obcana';

interface AppLanguageSelector {
	language: LocalizationLanguage;
}

export const useAppLanguageSelector = (): AppLanguageSelector => {
	const poUserLanguage = useUserStore('poUserLanguage');

	return { language: poUserLanguage() ?? LocalizationLanguage.Czech };
};
