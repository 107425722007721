import { hasLargeFile } from 'libs/core/hooks/src/File/fileUploadUtils';
import { UseFormReturn } from 'react-hook-form';
import { useConfiguration } from '@gov-nx/core/service';
import { Optional, is } from '@gov-nx/core/types';
import { DataBoxMessageFormInputs } from '../providers/MessageFormContext.types';
import { useMessagePriceFormat } from '../utils/message';
import { useDataBoxCredit } from './useDataBoxCredit';

export const useMessagePrice = (
	form: UseFormReturn<DataBoxMessageFormInputs>,
	senderId: Optional<string>
) => {
	const { datovaZpravaCena, velkokapacitniZpravaCena } = useConfiguration();
	const { credit } = useDataBoxCredit(senderId);
	const { formatPrice } = useMessagePriceFormat();

	const { files, documents, recipientAutocomplete } = form.watch();

	const isMessageFree =
		recipientAutocomplete?.selected?.typSchranky?.startsWith('OVM') ?? false;

	const showMessagePrice =
		!!files?.length ||
		!!documents?.length ||
		is(recipientAutocomplete?.selected);

	const getMessagePrice = () => {
		if (isMessageFree) return 0;
		if (hasLargeFile(files)) return velkokapacitniZpravaCena;
		return datovaZpravaCena;
	};

	const price = getMessagePrice();

	return {
		price,
		showMessagePrice,
		formattedPrice: formatPrice(price),
		isLowCredit: is(credit) && credit < price,
	};
};
