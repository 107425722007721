import { GovModal } from '@gov-design-system-ce/react';
import { useLocale } from '@gov-nx/core/service';
import {
	formatPersonLabel,
	useProfilROSDetailContext,
} from '@gov-nx/module/page';
import { DataTable, LayoutGap } from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';

export const PersonsListModal = () => {
	const { isPersonsListModalOpen, persons, code } = useProfilROSDetailContext();
	const { t } = useLocale(code);

	return (
		<GovModal
			open={isPersonsListModalOpen.isTrue}
			label={t('modal.label.osoby')}
			onGov-close={isPersonsListModalOpen.setFalse}>
			<LayoutGap>
				<p>{t('modal.popis.osoby')}</p>
				<ol>
					{persons.map((person) => {
						return (
							<li
								key={[
									person.ico,
									person.sidloSpolecnosti?.adresaText,
									person.datumNarozeni,
								].join(',')}>
								<DataTable responsive>
									<tbody>
										<tr>
											<td>{t('modal.zaznam.jmeno')}</td>
											<td>
												<strong>{formatPersonLabel(person)}</strong>
											</td>
										</tr>
										<tr>
											<td>{t('modal.zaznam.adresa-pobytu')}</td>
											<td>
												<strong>
													{person?.sidloSpolecnosti?.adresaText ?? '-'}
												</strong>
											</td>
										</tr>
										<tr>
											<td>{t('modal.zaznam.datum-narozeni')}</td>
											<td>
												<strong>
													{person?.datumNarozeni
														? toDateReadable(person.datumNarozeni)
														: '-'}
												</strong>
											</td>
										</tr>
									</tbody>
								</DataTable>
							</li>
						);
					})}
				</ol>
			</LayoutGap>
		</GovModal>
	);
};
