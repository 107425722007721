import {
	GovAccordion,
	GovAccordionItem,
	GovIcon,
} from '@gov-design-system-ce/react';
import { pageRouteResolver } from '@gov-nx/web';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	DataBoxFolderType,
	useDataBoxesContext,
} from '@gov-nx/module/data-box';
import { PageCode, Storage } from '@gov-nx/module/page';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import { StorageUsage } from './StorageUsage';

export enum DataBoxStorageUsageVariant {
	Accordeon = 'Accordeon',
}
interface DataBoxStorageUsageProps {
	variant?: DataBoxStorageUsageVariant;
}

export const DataBoxStorageUsage = ({ variant }: DataBoxStorageUsageProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const { t } = useTranslation([PageCode['datove-schranky']]);
	const { storage, dataBoxes } = useDataBoxesContext();
	const isStorageAccordionOpen = useDataBoxStore('isStorageAccordionOpen');
	const setIsStorageAccordionOpen = useDataBoxStore(
		'setIsStorageAccordionOpen'
	);

	const getStorageContent = (storage: Storage) => {
		return (
			<>
				<div className={'rounded overflow-hidden bg-secondary-500 mb-2'}>
					<div
						className={'rounded-l bg-error-400 h-4'}
						style={{
							width: `${
								storage.isStorageFull ? 100 : storage.usedInPercentInt
							}%`,
						}}
					/>
				</div>
				<dl className={'flex justify-between mb-4 text-xs text-secondary-700'}>
					<StorageUsage storage={storage} />
				</dl>
				<div className={'flex items-start gap-2 rounded bg-error-100 p-2'}>
					<GovIcon
						name="exclamation-triangle-fill"
						className={'grow-0 flex-shrink-0 w-3 text-error-400'}
					/>
					<p className={'text-xs'}>
						<Trans
							t={t}
							i18nKey="uloziste.procisteni"
							components={{
								'odkaz-archiv': (
									<Link
										to={
											dataBoxes[0]?.datovaSchrankaId
												? pageRouteResolver('datove-schranky.schranka', {
														dataBoxId: dataBoxes[0]?.datovaSchrankaId,
														folderType: DataBoxFolderType.Archived,
												  })
												: pageRouteResolver('datove-schranky')
										}
										className={'gov-link text-secondary-800'}
									/>
								),
								'odkaz-dokumenty': (
									<Link
										to={pageRouteResolver('moje-soubory')}
										className={'gov-link text-secondary-800'}
									/>
								),
							}}
						/>
					</p>
				</div>
			</>
		);
	};

	if (!storage.storageUsage) return null;
	if (storage.storageUsage.hasEnoughFreeSpace) return null;

	const storageContent = getStorageContent(storage.storageUsage);

	if (variant === DataBoxStorageUsageVariant.Accordeon) {
		return (
			<GovAccordion
				size={'xs'}
				className={'[ ds:hidden ]'}
				noBorder={true}
				wcagLabelledBy={'uloziste-portalu-obcana'}>
				<GovAccordionItem
					isExpanded={isStorageAccordionOpen}
					onGov-change={(e) => setIsStorageAccordionOpen(e.detail.open)}>
					<h3
						slot={'label'}
						id={'uloziste-portalu-obcana'}>
						{tn('uloziste.uloziste')}
					</h3>
					{storageContent}
				</GovAccordionItem>
			</GovAccordion>
		);
	}

	return (
		<div className={'hidden [ xl:block ] mb-4'}>
			<h3 className={'font-bold text-secondary-700 mb-4'}>
				{tn('uloziste.uloziste')}
			</h3>
			{storageContent}
		</div>
	);
};
