import { ContentLayout } from '@gov-nx/ui/web';
import { MyAuthorizationsListFilter } from './MyAuthorizationsListFilter';
import { MyAuthorizationListView } from './MyAuthorizationsListView';

export const MyAuthorizationsContentView = () => {
	return (
		<ContentLayout customClasses={'mt-6'}>
			<MyAuthorizationsListFilter />
			<MyAuthorizationListView />
		</ContentLayout>
	);
};
