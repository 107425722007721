import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useGlobalLocale } from '@gov-nx/core/service';
import { ContentLayout, LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const RegisterZPSkeleton = () => {
	const { t } = useGlobalLocale();
	return (
		<LayoutGap>
			<ContentLayout>
				<div className={'flex items-start gap-4 mb-4'}>
					<GovSkeleton
						height={'40px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing')}
						width={'70px'}
						className={'mb-0 pb-0'}
					/>
					<GovSkeleton
						height={'40px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing')}
						width={'132px'}
						className={'mb-0 pb-0'}
					/>
				</div>
				<div className={'p-5'}>
					<GovSkeleton
						height={'30px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing')}
						width={generateRandomNumber(20, 60) + '%'}
						className={'flex-1 mb-0 pb-0'}
					/>
					<div className={'my-2'}>
						<GovSkeleton
							height={'30px'}
							variant={'secondary'}
							wcagLabel={t('loading.wcag.processing')}
							width={generateRandomNumber(42, 120) + 'px'}
							className={'flex-1 mb-0 pb-0'}
						/>
					</div>
					<div
						className={
							'flex flex-col items-start gap-4 [ sm:flex-row sm:items-center ]'
						}>
						<div className={'flex'}>
							<GovSkeleton
								height={'20px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing')}
								width={'41px'}
								className={'flex-1 mb-0 pb-0 mr-1'}
							/>
							<GovSkeleton
								height={'20px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing')}
								width={'80px'}
								className={'flex mb-0 pb-0'}
							/>
						</div>
						<div className={'flex'}>
							<GovSkeleton
								height={'20px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing')}
								width={'150px'}
								className={'flex-1 mb-0 pb-0  mr-1'}
							/>
							<GovSkeleton
								height={'20px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing')}
								width={'80px'}
								className={'flex mb-0 pb-0'}
							/>
						</div>
					</div>
				</div>
				<div className={'p-5'}>
					<GovSkeleton
						height={'32px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing')}
						width={generateRandomNumber(20, 60) + '%'}
						className={'flex-1 mb-0 pb-0'}
					/>
					<div className={'my-2'}>
						<GovSkeleton
							height={'30px'}
							variant={'secondary'}
							wcagLabel={t('loading.wcag.processing')}
							width={generateRandomNumber(42, 120) + 'px'}
							className={'flex-1 mb-0 pb-0'}
						/>
					</div>
					<div
						className={
							'flex flex-col items-start gap-4 [ sm:flex-row sm:items-center ]'
						}>
						<div className={'flex'}>
							<GovSkeleton
								height={'20px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing')}
								width={'41px'}
								className={'flex-1 mb-0 pb-0 mr-1'}
							/>
							<GovSkeleton
								height={'20px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing')}
								width={'80px'}
								className={'flex mb-0 pb-0'}
							/>
						</div>
						<div className={'flex'}>
							<GovSkeleton
								height={'20px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing')}
								width={'88px'}
								className={'flex-1 mb-0 pb-0  mr-1'}
							/>
							<GovSkeleton
								height={'20px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing')}
								width={'80px'}
								className={'flex mb-0 pb-0'}
							/>
						</div>
					</div>
				</div>
			</ContentLayout>
		</LayoutGap>
	);
};
