import { UseQueryResult } from '@tanstack/react-query';
import { PodaniOsvedceniSeznamDto } from '@gov-nx/api/portal-obcana';
import { ProcessControlCore } from '@gov-nx/core/hooks';
import { FormDefinition } from '@gov-nx/core/service';
import { FilterParam, FilterSortDirection } from '@gov-nx/core/types';

export interface MyVerificationsListControl extends ProcessControlCore {
	displayFilter: boolean;
	isDownloadProcessing: boolean;
	isDownloadVerificationId: number;
}

interface MyVerifications {
	id: number;
	addressee: string;
	name: string;
	issueDate?: string;
}

export interface MyVerificationsListContext {
	controls: MyVerificationsListControl;
	verifications: MyVerifications[];
	setControls: (control: Partial<MyVerificationsListControl>) => void;
	query: UseQueryResult<PodaniOsvedceniSeznamDto>;
	numberOfVerifications: number;
	onSubmit: () => void;
	resetFilter: () => void;
	filter: MyVerificationsListFilter;
	setPartialFilter: (filter: Partial<MyVerificationsListFilter>) => void;
	currentPage: number;
	formDefinition: FormDefinition<MyVerificationsListFilterForm>;
	goToPage: (page: number) => void;
	filterParams: FilterParam[];
	handleVerificationDownload: (verificationId: number) => void;
}

export interface MyVerificationsListFilterForm {
	obdobiOd: Date | null;
	obdobiDo: Date | null;
	razeni?: MyVerificationsListOrder;
	razeniSmer?: FilterSortDirection;
	hledat?: string;
}

export enum MyVerificationsListOrder {
	datumVystaveni = 'datumVystaveni',
}

export interface MyVerificationsListFilter
	extends MyVerificationsListFilterForm {
	start: number;
	pocet: number;
}

export interface DataRequest {
	params: {
		obdobiOd?: string;
		obdobiDo?: string;
		razeni?: MyVerificationsListOrder;
		start?: number;
		pocet?: number;
	};
}
