import { useFormBuilder, useLocale } from '@gov-nx/core/service';
import { useDataBoxAccess } from '@gov-nx/module/data-box';
import { DataBoxFormData } from '@gov-nx/ui/types';
import { DataBoxFields, useDataboxDefinition } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';

type Fields = DataBoxFields;

export const useAuthorizationDetailForm = ({
	isDisabled,
}: {
	isDisabled: boolean;
}) => {
	const { areAnyConnected, connectedTypes } = useDataBoxAccess();
	const isDataboxSelectRequired = areAnyConnected;
	const { t } = useLocale(PageCode['moje-podani']);

	const databox = useDataboxDefinition({
		name: 'datovaSchrankaId',
		label: t('formular.datova-schranka.label'),
		isDisabled: isDisabled,
		isRequired: isDataboxSelectRequired,
		types: connectedTypes,
	});

	return {
		...useFormBuilder<DataBoxFormData, Fields>(databox),
		showDataboxSelect: isDataboxSelectRequired,
	};
};
