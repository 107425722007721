import { AxiosResponse } from 'axios';
import { createContext, ReactNode, useContext, useEffect } from 'react';
import {
	electionsQuery,
	sendSubmissionQuery,
	usePoMutation,
	usePoQuery,
	PodaniChybyDto,
} from '@gov-nx/api/portal-obcana';
import {
	useCountriesQuery,
	useCountryCodes,
	useDataBoxes,
	useDownloadBlob,
	useEmbassyOfficeQuery,
	usePersonCommunicationData,
	usePoIndividualDataLoad,
	useWizard,
} from '@gov-nx/core/hooks';
import { getRequiredDataBoxes, useLocale } from '@gov-nx/core/service';
import { Nullable, propEq } from '@gov-nx/core/types';
import {
	getDetailPageName,
	getSelectedRounds,
	ServiceCode,
} from '@gov-nx/module/service';
import { FormStep1 } from './FormStep1';
import { FormStep2 } from './FormStep2';
import { FormStep2a } from './FormStep2a';
import { FormStep2b } from './FormStep2b';
import { FormStep3 } from './FormStep3';
import { FormStep4 } from './FormStep4';
import { VotersCardFormData, VotersCardContext } from './context.types';
import {
	electionRoundDefaultValues,
	prepareCheckData,
	prepareSubmitData,
	prepareToDownloadPowerOfAttorney,
} from './formDefinitions';
import { useInitialCheck } from './useInitialCheck';
import {
	canVoteFromAbroad,
	isTwoRoundedElection,
	transformControl,
} from './utils';

const VolicskyPrukazDetailContext =
	createContext<Nullable<VotersCardContext>>(null);

interface VolicskyPrukazDetailContextProviderProps {
	children: ReactNode;
	code: ServiceCode;
	electionId: number;
	onSubmitted: () => void;
	platform: 'web' | 'native';
}

export const VolicskyPrukazDetailContextProvider = ({
	children,
	code,
	electionId,
	onSubmitted,
	platform,
}: VolicskyPrukazDetailContextProviderProps) => {
	const { t } = useLocale(code);
	const { handleDownloadBlob } = useDownloadBlob();
	const { isDataBoxConnected, requiredDataBoxes } = useDataBoxes(
		getRequiredDataBoxes(code)
	);
	const countryCodes = useCountryCodes();

	const query = usePoQuery({
		queryKey: ['announced-elections'],
		queryFn: electionsQuery,
	});

	const election = query.data?.seznam?.find((e) => e.volbyId === electionId);

	const individualPerson = usePoIndividualDataLoad();
	const personCommunicationData = usePersonCommunicationData();

	const embassyOfficesQuery = useEmbassyOfficeQuery();

	const countriesQuery = useCountriesQuery();

	const submitMutation = usePoMutation({
		mutationFn: (formData: VotersCardFormData) => {
			const prepared = prepareSubmitData(
				formData,
				electionId,
				election,
				embassy
			);
			return sendSubmissionQuery(prepared);
		},
	});

	const checkMutation = usePoMutation<
		AxiosResponse<PodaniChybyDto>,
		VotersCardFormData['electionRound']
	>({
		mutationFn: (rounds) => {
			const prepared = prepareCheckData(rounds, electionId, election);
			return sendSubmissionQuery(prepared) as unknown as Promise<
				AxiosResponse<PodaniChybyDto, VotersCardFormData['electionRound']>
			>;
		},
	});
	const checkMutationData = checkMutation.data?.data
		? transformControl(checkMutation.data.data, t)
		: undefined;

	const rounds = election?.kola ?? [];

	const initialCheck = useInitialCheck({
		election,
		onCheck: async () => {
			const defaultValues = electionRoundDefaultValues(rounds);
			const response = await checkMutation.mutateAsync(defaultValues);
			return transformControl(response.data, t);
		},
	});

	const formStep1 = FormStep1({
		code,
		rounds,
		isProcessing: checkMutation.isLoading,
		isDisabled: checkMutationData?.errors
			? checkMutationData?.errors?.length > 0
			: false,
	});

	const { embassyOfficesOptions } = embassyOfficesQuery;

	const formStep2 = FormStep2({
		code,
		embassyOfficesOptions,
		isProcessing: checkMutation.isLoading,
	});
	const formStep2a = FormStep2a({
		code,
		embassyOfficesOptions,
		isProcessing: checkMutation.isLoading,
	});
	const formStep2b = FormStep2b({
		code,
		isProcessing: checkMutation.isLoading,
	});
	const formStep3 = FormStep3({
		code,
		embassyOfficesOptions,
		checkMutationData,
		election,
	});
	const formStep4 = FormStep4({ dataBoxTypes: requiredDataBoxes });

	const isOneRoundedElection = election
		? !isTwoRoundedElection(election.druh)
		: false;

	const checkCall = async (): Promise<{ canContinue: boolean }> => {
		const electionRound =
			formStep1.formDefinition.formMethods.getValues('electionRound');

		const response = await checkMutation.mutateAsync(electionRound);
		const transformed = transformControl(response.data, t);
		if (transformed.errors.length > 0) {
			return { canContinue: false };
		}

		return { canContinue: true };
	};

	const wizard = useWizard<VotersCardFormData>({
		steps:
			platform === 'native'
				? [
						formStep1.formDefinition,
						formStep2a.formDefinition,
						formStep2b.formDefinition,
						formStep3.formDefinition,
						formStep4,
				  ]
				: [
						formStep1.formDefinition,
						formStep2.formDefinition,
						formStep3.formDefinition,
						formStep4,
				  ],
		canInitialize: !!election,
		initialIndexOpen: isOneRoundedElection ? [1] : undefined,
		onStepSubmitted: async (index) => {
			if (isOneRoundedElection && index === 1) {
				return checkCall();
			}
			if (index === 0) {
				return checkCall();
			}
			return { canContinue: true };
		},
		onSubmit: async (formData) =>
			submitMutation.mutate(formData, { onSuccess: onSubmitted }),
	});

	const embassyIdString =
		platform === 'native'
			? formStep2a.formDefinition.formMethods.watch('embassyId')
			: formStep2.formDefinition.formMethods.watch('embassyId');
	const embassyId = embassyIdString ? parseInt(embassyIdString, 10) : undefined;

	const embassy = embassyId
		? embassyOfficesQuery.data?.seznam?.find(propEq('id', embassyId))
		: undefined;

	const { emailVerified, phoneVerified } = personCommunicationData;
	useEffect(() => {
		if (emailVerified) {
			formStep2b.formDefinition.formMethods.setValue('email', emailVerified);
			formStep2.formDefinition.formMethods.setValue('email', emailVerified);
		}
	}, [emailVerified]);

	useEffect(() => {
		if (phoneVerified) {
			const phoneShape = countryCodes.stringToPhoneShape(phoneVerified);
			if (phoneShape) {
				formStep2.formDefinition.formMethods.setValue('phone', phoneShape);
				formStep2b.formDefinition.formMethods.setValue('phone', phoneShape);
			}
		}
	}, [phoneVerified]);

	useEffect(() => {
		if (
			countriesQuery.countryOptions &&
			!formStep3.formDefinition.formMethods.getValues('stat')
		) {
			formStep3.formDefinition.formMethods.setValue('stat', '203'); // czech code
		}
	}, [countriesQuery.countryOptions]);

	const selectedRounds = getSelectedRounds(
		formStep1.formDefinition.formMethods.getValues('electionRound'),
		formStep1.fields.electionRound
	);

	const livesAbroad =
		platform === 'native'
			? formStep2a.formDefinition.formMethods.watch('livesAbroad')
			: formStep2.formDefinition.formMethods.watch('livesAbroad');

	const onSpecialList =
		platform === 'native'
			? formStep2a.formDefinition.formMethods.watch('onSpecialList')
			: formStep2.formDefinition.formMethods.watch('onSpecialList');

	return (
		<VolicskyPrukazDetailContext.Provider
			value={{
				code,
				initialState: {
					isLoading:
						query.isLoading ||
						individualPerson.isLoading ||
						countriesQuery.isLoading ||
						embassyOfficesQuery.isLoading ||
						!countryCodes.isFetched ||
						initialCheck.isLoading,
					error: (query.error || embassyOfficesQuery.error) ?? undefined,
				},
				checkMutation: {
					isLoading: checkMutation.isLoading,
					error: checkMutation.error ?? undefined,
					data: checkMutationData,
				},
				submitMutation: {
					isSubmitting: submitMutation.isLoading,
					isSubmitted: submitMutation.isSuccess,
				},
				election,
				requiredDataBoxes,
				isDataBoxConnected,
				wizard,
				personCommunicationData,
				individualPerson: individualPerson.individualPerson,
				formStep1: formStep1,
				formStep2: formStep2,
				formStep2a: formStep2a,
				formStep2b: formStep2b,
				formStep3: formStep3,
				livesAbroad,
				onSpecialList,
				zpusobPrevzeti:
					formStep3.formDefinition.formMethods.watch('zpusobPrevzeti'),
				zpusobPrevzetiPosta: formStep3.formDefinition.formMethods.watch(
					'zpusobPrevzetiPosta'
				),
				zpusobPrevzetiUrad:
					formStep3.formDefinition.formMethods.watch('zpusobPrevzetiUrad'),
				embassy,
				volitNaOkrskuVZahraniciId: formStep3.formDefinition.formMethods.watch(
					'volitNaOkrskuVZahraniciId'
				),
				formData: wizard.formData,
				canVoteFromAbroad: election ? canVoteFromAbroad(election.druh) : false,
				detailName: getDetailPageName(t, election),
				countryOptions: countriesQuery.countryOptions,
				embassyOffices: embassyOfficesQuery.data?.seznam ?? [],
				powerOfAttorney: handleDownloadBlob(() =>
					prepareToDownloadPowerOfAttorney(electionId, wizard.formData)
				),
				selectedRounds,
				initialCheck,
			}}>
			{children}
		</VolicskyPrukazDetailContext.Provider>
	);
};

export const useVolicskyPrukazDetailContext = (): VotersCardContext =>
	useContext(VolicskyPrukazDetailContext) as VotersCardContext;
