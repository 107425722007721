import { GovIcon } from '@gov-design-system-ce/react';
import { useLocale } from '@gov-nx/core/service';
import {
	RosComplaintVariant,
	useProfilROSDetailContext,
} from '@gov-nx/module/page';
import { Button, ContentLayout, DataTable, LayoutGap } from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';

export const PODataView = () => {
	const {
		isMembersListModalOpen,
		isEstablishmentsListModalOpen,
		isPersonsListModalOpen,
		myInvolvement,
		members,
		establishments,
		persons,
		detailData,
		setControls,
		code,
	} = useProfilROSDetailContext();
	const { t } = useLocale(code);

	return (
		<LayoutGap gap={4}>
			{myInvolvement.map((person) => {
				return (
					<ContentLayout key={person.ico}>
						<LayoutGap>
							<DataTable responsive>
								<tbody>
									<tr>
										<td>{t('zaznam.angazovanost')}</td>
										<td>
											<strong>{t(`zaznam.role.hodnota.${person.role}`)}</strong>
										</td>
									</tr>
								</tbody>
							</DataTable>
							<p className="text-s">{t('zaznam.angazovanost-popis')}</p>
						</LayoutGap>
					</ContentLayout>
				);
			})}

			<ContentLayout>
				<LayoutGap gap={4}>
					<DataTable responsive>
						<tbody>
							<tr>
								<td>{t('zaznam.jmeno')}</td>
								<td>
									<strong>
										{detailData?.nazevSpolecnosti
											? detailData.nazevSpolecnosti
											: '-'}
									</strong>
								</td>
							</tr>
							<tr>
								<td>{t('zaznam.ico')}</td>
								<td>
									<strong>{detailData?.ico ? detailData.ico : '-'}</strong>
								</td>
							</tr>
							<tr>
								<td>{t('zaznam.pravni-forma')}</td>
								<td>
									<strong>
										{detailData?.pravniForma?.nazev
											? detailData.pravniForma.nazev
											: '-'}
									</strong>
								</td>
							</tr>
							<tr>
								<td>{t('zaznam.agenda')}</td>
								<td>
									<strong>
										{detailData?.agenda?.nazev ? detailData.agenda.nazev : '-'}
									</strong>
								</td>
							</tr>
							<tr>
								<td>{t('zaznam.sidlo')}</td>
								<td>
									<strong>
										{detailData?.sidloSpolecnosti?.adresaText
											? detailData.sidloSpolecnosti.adresaText
											: '-'}
									</strong>
								</td>
							</tr>
							<tr>
								<td>{t('zaznam.pocatek-registrace-vznik')}</td>
								<td>
									<strong>
										{detailData?.datumVzniku
											? toDateReadable(detailData.datumVzniku)
											: '-'}
									</strong>
								</td>
							</tr>
							<tr>
								<td>{t('zaznam.konec-registrace-zanik')}</td>
								<td>
									<strong>
										{detailData?.datumZaniku
											? toDateReadable(detailData.datumZaniku)
											: '-'}
									</strong>
								</td>
							</tr>
							<tr>
								<td>{t('zaznam.pozastaveni-preruseni')}</td>
								<td>
									<strong>
										{detailData?.pozastaveniPreruseni
											? t('zaznam.pozastaveni-preruseni-ano')
											: t('zaznam.pozastaveni-preruseni-ne')}
									</strong>
									<p className="text-xs">
										{t('zaznam.pozastaveni-preruseni-popis')}
									</p>
								</td>
							</tr>
							{members.length ? (
								<tr>
									<td>{t('zaznam.struktura-statutarniho-organu')}</td>
									<td>
										<strong>{t('zaznam.seznam-clenu')}</strong>
										<Button
											onClick={isMembersListModalOpen.setTrue}
											variant={'primary'}
											type={'base'}
											size={'s'}
											iconLeft={'eye'}>
											<GovIcon
												slot="left-icon"
												name="eye"></GovIcon>
										</Button>
									</td>
								</tr>
							) : null}
							{establishments.length ? (
								<tr>
									<td>{t('zaznam.provozovny')}</td>
									<td>
										<strong>{t('zaznam.seznam-provozoven')}</strong>
										<Button
											onClick={isEstablishmentsListModalOpen.setTrue}
											variant={'primary'}
											type={'base'}
											size={'s'}
											iconLeft={'eye'}>
											<GovIcon
												slot="left-icon"
												name="eye"></GovIcon>
										</Button>
									</td>
								</tr>
							) : null}

							{detailData?.dorucovaciAdresa?.adresaText ? (
								<tr>
									<td>{t('zaznam.dorucovaci-adresa-popis')}</td>
									<td>
										<strong>{detailData.dorucovaciAdresa.adresaText}</strong>
										<p className="text-xs mt-1 text-left">
											{t('zaznam.dorucovaci-adresa-vysvetleni')}
										</p>
									</td>
								</tr>
							) : null}

							<tr>
								<td>{t('zaznam.datova-schranka')}</td>
								<td>
									<strong>
										{detailData?.datovaSchrankaId
											? detailData.datovaSchrankaId
											: '-'}
									</strong>
								</td>
							</tr>
							{persons.length ? (
								<tr>
									<td>{t('zaznam.angazovane-osoby')}</td>
									<td>
										<strong>{t('zaznam.seznam-osob')}</strong>
										<Button
											onClick={isPersonsListModalOpen.setTrue}
											variant={'primary'}
											type={'base'}
											size={'s'}
											iconLeft={'eye'}>
											<GovIcon
												slot="left-icon"
												name="eye"></GovIcon>
										</Button>
									</td>
								</tr>
							) : null}
						</tbody>
					</DataTable>
					<Button
						onClick={() =>
							setControls({
								complaintModal: detailData,
								complaintVariant: RosComplaintVariant.AGENDA,
							})
						}
						variant={'primary'}
						type={'outlined'}
						size={'l'}>
						{t('akce.podnet-k-oprave-udaju')}
					</Button>
				</LayoutGap>
			</ContentLayout>
		</LayoutGap>
	);
};
