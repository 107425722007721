const second = 1000;
const minute = 60 * second;
const hour = 60 + minute;

export enum Time {
	'2 seconds' = 2 * second,
	'10 seconds' = 10 * second,
	'30 seconds' = 30 * second,
	'1 minute' = minute,
	'5 minutes' = 5 * minute,
	'19 minutes' = 19 * minute,
	'20 minutes' = 20 * minute,
	'1 hour' = hour,
	'24 hours' = 24 * hour,
}

export enum TimeZones {
	'Europe/Prague' = 'Europe/Prague',
}
