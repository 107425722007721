import { useQueryClient } from '@tanstack/react-query';
import { Buffer } from 'buffer';
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import {
	OsobyCertifikatDto,
	certificatesQuery,
	deleteCertificatesQuery,
	sendNewCertificatesQuery,
	usePoMutation,
	usePoQuery,
	shouldIgnoreError,
	isPoResponseError,
	getPoErrors,
	certificatesHistoryQuery,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	FileState,
	FileUploadItem,
	FileUploadProps,
	useProcessControl,
} from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useAuthStore } from '@gov-nx/store/portal-obcana';
import { useBoolean } from '@gov-nx/utils/common';
import { PageCode } from '../definitions/codes';
import { FormInstance } from './FormDefinitions';
import { CertificatesContext, ProfileRobProcessControl } from './context.types';
import { CertificateFormData } from './context.types';
import { getIgnoredError } from './utils';

const ProfilCertificatesContext = createContext<CertificatesContext | null>(
	null
);

interface ProfilCertificatesContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
}

export function ProfilCertificatesContextProvider({
	children,
	code,
}: ProfilCertificatesContextProviderProps) {
	const { setControls, controls } = useProcessControl<ProfileRobProcessControl>(
		{
			initialDataError: null,
			initialPhotoError: null,
			displayRemoveModal: false,
			displayCertificateAddingModal: false,
			selectedCertificate: undefined,
		}
	);
	const [certificates, setCertificates] = useState<OsobyCertifikatDto[]>([]);
	const hasUploadedFiles = useBoolean(false);
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { toastMessageSuccess, toastMessageError } = useMessageEvents();
	const queryClient = useQueryClient();

	const getUserLoa = useAuthStore('getUserLoa');
	const accessLoa = getUserLoa();
	const hasPermission = !!(
		accessLoa && ['HIGH', 'SUBSTANTIAL'].includes(accessLoa)
	);
	const formDefinition = FormInstance({ code });

	const historyQueryKey = ['profil-certificates', 'history'];
	const historyQuery = usePoQuery({
		queryKey: historyQueryKey,
		queryFn: certificatesHistoryQuery,
	});

	const query = usePoQuery({
		queryKey: ['profil-certificates'],
		queryFn: certificatesQuery,
		onError: async (err) => {
			setControls({
				initialLoading: false,
				initialError: new GovError(err.message),
			});

			toastMessageError(err.message);
		},
	});

	const reloadView = () => {
		setControls({ initialLoading: true });

		hasUploadedFiles.setFalse();

		query.refetch();
	};

	useEffect(() => {
		if (!query.isFetching) {
			setCertificates(query.data?.data?.seznam ?? []);
			setControls({ initialLoading: false });
		}
	}, [query.data, query.isFetching, setControls]);

	const handleFilesChanged = (files: FileUploadItem[]) => {
		hasUploadedFiles.setValue(
			files.some((file) => file.state === FileState.success)
		);
	};

	const submitMutation = usePoMutation(
		{
			mutationFn: async (formData: CertificateFormData) => {
				const fileUploadItem = formData.nazevSouboru?.[0];
				if (!fileUploadItem) return;
				return sendNewCertificatesQuery(fileUploadItem);
			},
			onSuccess: async () => {
				formDefinition.formReset();
				setControls({ displayCertificateAddingModal: false });
				toastMessageSuccess(tsn('certifikaty.pridano'));
				await queryClient.invalidateQueries({ queryKey: historyQueryKey });
				reloadView();
			},
			onError: (error) => {
				formDefinition.formReset();
				setControls({
					displayCertificateAddingModal: false,
					processError: error,
				});
				toastMessageError(error.message);
			},
		},
		{
			errorIgnoreFilter: shouldIgnoreError((error) => {
				const poErrors = isPoResponseError(error)
					? getPoErrors(error)
					: undefined;

				const ignoredError = getIgnoredError(poErrors);

				if (ignoredError) {
					toastMessageError(tsn('certifikaty.chyba.' + ignoredError.extKod));
					return true;
				}

				return false;
			}),
		}
	);

	const handleSubmit = useCallback(async () => {
		setControls({ processError: null });
		submitMutation.mutate(formDefinition.formMethods.getValues());
	}, [formDefinition, setControls, submitMutation]);

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);

	const deleteMutation = usePoMutation({
		mutationFn: async (certificate: OsobyCertifikatDto) => {
			if (certificate.vydavatel && certificate.serioveCislo) {
				const publisherBase = Buffer.from(
					encodeURIComponent(certificate.vydavatel),
					'utf-8'
				).toString('base64');

				await deleteCertificatesQuery(certificate.serioveCislo, {
					params: { vydavatel: publisherBase },
				});
			}
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: historyQueryKey });
			reloadView();
		},
	});

	const sortCertificates: CertificatesContext['sortCertificates'] = (
		field,
		sortOrder
	) => {
		const comparator = (a: OsobyCertifikatDto, b: OsobyCertifikatDto) => {
			if (field === 'vydavatel') {
				return sortOrder === 'asc'
					? (a?.vydavatel ?? '').localeCompare(b?.vydavatel ?? '')
					: (b?.vydavatel ?? '').localeCompare(a.vydavatel ?? '');
			} else if (field === 'serioveCislo') {
				const aValue = a.serioveCislo ? parseInt(a.serioveCislo, 10) : 0;
				const bValue = b.serioveCislo ? parseInt(b.serioveCislo, 10) : 0;
				return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
			} else if (field === 'platnostDo') {
				const dateA = a.platnostDo ? new Date(a.platnostDo) : null;
				const dateB = b.platnostDo ? new Date(b.platnostDo) : null;

				if (dateA && dateB) {
					return sortOrder === 'asc'
						? dateA.getTime() - dateB.getTime()
						: dateB.getTime() - dateA.getTime();
				} else if (dateA) {
					return -1;
				} else if (dateB) {
					return 1;
				}

				return 0;
			}
			return 0;
		};

		setCertificates([...certificates].sort(comparator));
	};

	const fileUploadOptions: FileUploadProps = {
		extensions: ['.cer', '.chain', '.crt', '.der', '.key', '.pem', '.txt'],
		maxFileSize: 10 * 1024 * 1024,
		maxAttachments: 1,
		maxSumFileSize: 10 * 1024 * 1024,
		multiple: true,
		onFilesChanged: handleFilesChanged,
		onFileRemoved: hasUploadedFiles.setFalse,
	};

	return (
		<ProfilCertificatesContext.Provider
			value={{
				controls,
				setControls,
				certificates,
				deleteCertificate: deleteMutation.mutate,
				hasPermission,
				formDefinition,

				isSubmitting: submitMutation.isLoading,
				isAddButtonDisabled:
					hasUploadedFiles.isFalse || submitMutation.isLoading,
				onSubmit,
				sortCertificates,
				handleFilesChanged,
				fileUploadOptions,
				history: historyQuery.data?.data.seznam ?? [],
			}}>
			{children}
		</ProfilCertificatesContext.Provider>
	);
}

export const useProfilCertificatesContextInstance = (): CertificatesContext =>
	useContext(ProfilCertificatesContext) as CertificatesContext;
