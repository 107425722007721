import { robPersonQuery, usePoQuery } from '@gov-nx/api/portal-obcana';
import { usePersonStore } from '@gov-nx/store/portal-obcana';

interface SupportDataLoader {
	isEnabled: boolean;
}

export const usePoSupportDataLoad = (props?: SupportDataLoader) => {
	const communication = usePersonStore('communication');
	const individualPerson = usePersonStore('individual');
	const setIndividualPersonData = usePersonStore('setIndividualPersonData');

	const query = usePoQuery({
		queryKey: ['osoby', 'fyzicke'],
		queryFn: robPersonQuery,
		onSuccess: (data) => setIndividualPersonData(data),
		refetchOnWindowFocus: false,
		retry: 0,
		enabled: props?.isEnabled,
	});

	return {
		individualPerson,
		communication,
		isLoading: query.isLoading,
		error: query.error,
	};
};
