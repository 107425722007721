import { createContext, PropsWithChildren, useContext } from 'react';
import { OsobyPodnikatelDto } from '@gov-nx/api/portal-obcana';
import {
	useCountryCodes,
	useEmailContactDetails,
	usePersonCommunicationData,
	usePhoneContactDetails,
	useUpdateContact,
} from '@gov-nx/core/hooks';
import { Nullable, RequiredProperty } from '@gov-nx/core/types';
import { useBoolean } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import { NastaveniKontaktniUdajeROSItemContextValue } from './context.types';
import { useROSContact } from './useROSContact';

export const NastaveniKontaktniUdajeROSItemContext =
	createContext<Nullable<NastaveniKontaktniUdajeROSItemContextValue>>(null);

type NastaveniKontaktniUdajeROSItemContextProviderProps = PropsWithChildren<{
	contact: RequiredProperty<OsobyPodnikatelDto, 'ico'>;
	code: PageCode;
}>;

export const NastaveniKontaktniUdajeROSItemContextProvider = ({
	contact,
	code,
	children,
}: NastaveniKontaktniUdajeROSItemContextProviderProps) => {
	const {
		reloadRosContact,
		getRosContact,
		isReloading: isRosContactReLoading,
		isLoading: isRosContactLoading,
	} = useROSContact();
	const communicationData = usePersonCommunicationData();
	const rosContact = getRosContact(contact.ico);
	const countryCodes = useCountryCodes();
	const isEditingEmail = useBoolean();
	const isEditingPhone = useBoolean();

	const updateContact = useUpdateContact({
		source: { source: 'ROS', ico: contact.ico },
		unverifiedEmail: rosContact?.emailOvereni,
		unverifiedPhone: rosContact?.telefonniCisloOvereni,
	});

	const emailContactDetails = useEmailContactDetails({
		updateContact: updateContact.email,
		refetch: reloadRosContact,
		isReloading: isRosContactReLoading,
		isEditing: isEditingEmail,
		unverifiedEmail: rosContact?.emailOvereni ?? undefined,
		emailVerified: rosContact?.email,
	});

	const phoneContactDetails = usePhoneContactDetails({
		updateContact: updateContact.phone,
		refetch: reloadRosContact,
		isReloading: isRosContactReLoading,
		countryCodes,
		isEditing: isEditingPhone,
		phoneVerified: rosContact?.telefonniCislo,
		unverifiedPhone: rosContact?.telefonniCisloOvereni ?? undefined,
	});

	const handleUsePersonalContacts = () => {
		if (communicationData.emailVerified) {
			emailContactDetails.onPersonalContactsUse(
				communicationData.emailVerified
			);
		}

		if (communicationData.phoneVerified) {
			phoneContactDetails.onPersonalContactsUse(
				communicationData.phoneVerified
			);
		}
	};

	const canUsePersonalContacts =
		!!communicationData.emailVerified && !!communicationData.phoneVerified;

	return (
		<NastaveniKontaktniUdajeROSItemContext.Provider
			value={{
				code,
				initialState: {
					isLoading: !countryCodes.isFetched || isRosContactLoading,
					error: countryCodes.error ?? undefined,
				},
				contact,
				handleUsePersonalContacts,
				isUsingPersonalContactsLoading:
					updateContact.email.isUpdating || updateContact.phone.isUpdating,
				canUsePersonalContacts,
				email: emailContactDetails,
				phone: phoneContactDetails,
			}}>
			{children}
		</NastaveniKontaktniUdajeROSItemContext.Provider>
	);
};

export const useNastaveniKontaktnichUdajuROSItemContext =
	(): NastaveniKontaktniUdajeROSItemContextValue =>
		useContext(
			NastaveniKontaktniUdajeROSItemContext
		) as NastaveniKontaktniUdajeROSItemContextValue;
