import { AxiosResponse } from 'axios';
import {
	Forms602FormSendSubmissionDto,
	Forms602FormSendSubmissionResultDto,
	Forms602FormStartSessionResultDto,
	Forms602FormSubmissionDto,
	Forms602FormSubmissionListDto,
	axiosInstance,
} from '..';

export interface Form602ListQueryParams {
	text?: string;
	start: number;
	pocet: number;
}

export const formSessionQuery = async (
	typ: string,
	formId: string,
	rezaCode?: string
): Promise<Forms602FormStartSessionResultDto> => {
	const { data } = await axiosInstance.get(
		'api/formulare/v1/formStartSession',
		{
			params: {
				typ,
				formId,
				zastoupeni: rezaCode,
			},
		}
	);

	return data;
};

export const form602ListQuery = async (
	params: Form602ListQueryParams
): Promise<Forms602FormSubmissionListDto> => {
	const { data } = await axiosInstance.get('api/formulare/v1/formSubmission', {
		params,
	});

	return data;
};

export const form602SubmissionQuery = async (
	formularPodaniId: string
): Promise<Forms602FormSubmissionDto> => {
	const response = await axiosInstance.get(
		`api/formulare/v1/formSubmission/${formularPodaniId}`
	);

	return response.data;
};

export const form602SubmissionDeleteQuery = async (
	formularPodaniId: string
) => {
	const { data } = await axiosInstance.delete(
		`api/formulare/v1/formSubmission/${formularPodaniId}/smazat`
	);

	return data;
};

export const sendForm602SubmissionQuery = async ({
	formularPodaniId,
	body,
}: {
	formularPodaniId: string;
	body: Forms602FormSendSubmissionDto;
}): Promise<Forms602FormSendSubmissionResultDto> => {
	const response = await axiosInstance.post(
		`api/formulare/v1/formSubmission/${formularPodaniId}/odeslat`,
		body
	);

	return response.data;
};

export const downloadForm602SubmissionQuery = async ({
	id,
}: {
	id: string;
}): Promise<AxiosResponse<Blob>> => {
	const response = await axiosInstance.get(
		`api/formulare/v1/formSubmission/${id}/stahnout`,
		{
			responseType: 'blob',
		}
	);

	return response;
};
