import { JSX } from '@gov-design-system-ce/components/dist/types/components';
import { ReactNode } from 'react';
import { WebLabelProps } from './FormLabel';
import { WebMessagesProps } from './FormMessage';

export enum RadioBooleanOption {
	no = 'no',
	yes = 'yes',
}

export interface WebFormRadioProps extends JSX.GovFormRadio {
	label: WebLabelProps;
}

export interface RadioOption extends Omit<WebFormRadioProps, 'name' | 'label'> {
	value: string;
	label?: ReactNode;
}

export interface WebRadioOption extends Omit<WebFormRadioProps, 'name'> {
	children?: ReactNode;
}

export interface WebRadioField {
	name: string;
	options: WebRadioOption[];
	disabled?: boolean;
}

export interface WebRadioProps {
	field: WebRadioField;
	label: WebLabelProps;
	control?: JSX.GovFormControl;
	group?: JSX.GovFormGroup;
	messages?: WebMessagesProps;
}

export interface FormRadioProps {
	field: {
		name: string;
		disabled?: boolean;
		options: { value: string; label: { children: string } }[];
	};
	label: {
		children?: string;
	};
}
