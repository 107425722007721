import { pageRouteResolver } from '@gov-nx/web';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	DATABOX_TIME_DIVISION_LABELS,
	DataBoxFolderType,
	DataBoxTimeDividedMessageList,
	isDataBoxTypePO,
	useDataBoxMessageListContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { DataBoxMessageItem } from './DataBoxMessageItem';

interface DataBoxMessageListProps {
	messageList?: DataBoxTimeDividedMessageList;
}

export const DataBoxMessageList = ({
	messageList,
}: DataBoxMessageListProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const { t } = useTranslation([PageCode['datove-schranky']]);

	const {
		dataBox,
		messagesFilter: { folderType },
		controls,
	} = useDataBoxMessageListContext();

	if (!messageList?.length && !controls.processLoading) {
		if (folderType === DataBoxFolderType.Archived) {
			return (
				<div
					className={
						'flex flex-col gap-6 h-full justify-center items-center text-center'
					}>
					<img
						src="\assets\images\empty.svg"
						width="143"
						height="88"
						alt=""
					/>
					<p className={'font-bold'}>{tn('zpravy.vypis.prazdna-slozka')}</p>
					{!isDataBoxTypePO(dataBox) && dataBox?.odpojeno === false ? (
						<p className={'text-secondary-700'}>
							<Trans
								t={t}
								i18nKey="zpravy.vypis.vytvoreni-kopie"
								components={{
									odkaz: (
										<Link
											to={pageRouteResolver(PageCode['nastaveni'])}
											className={'gov-link'}
										/>
									),
								}}
							/>
						</p>
					) : null}
				</div>
			);
		}

		if (folderType === DataBoxFolderType.Trash) {
			return (
				<div
					className={
						'flex flex-col gap-6 h-full justify-center items-center text-center'
					}>
					<img
						src="\assets\images\empty.svg"
						width="143"
						height="88"
						alt=""
					/>
					<p className={'font-bold'}>{tn('zpravy.vypis.prazdna-slozka')}</p>
					<p className={'text-secondary-700'}>
						<Trans
							t={t}
							i18nKey="zpravy.vypis.kos-kopie"
							components={{ 'novy-radek': <br /> }}
						/>
					</p>
					<p className={'text-secondary-700'}>
						<strong>
							<Trans
								t={t}
								i18nKey="zpravy.vypis.nemazat-ze-slozky"
								components={{ 'novy-radek': <br /> }}
							/>
						</strong>
						<br />
						{tn('zpravy.vypis.smazou-samy')}
					</p>
				</div>
			);
		}

		return (
			<div className={'flex flex-col gap-6 h-full justify-center items-center'}>
				<img
					src="\assets\images\empty.svg"
					width="143"
					height="88"
					alt=""
				/>
				<p className={'font-bold'}>{tn('zpravy.vypis.prazdna-slozka')}</p>
			</div>
		);
	}

	return (
		<ul className={'gov-list--plain'}>
			{messageList?.map((timePeriod) => (
				<li key={timePeriod.label}>
					<h2 className="px-4 py-2 text-s font-bold text-secondary-700 bg-secondary-200">
						{tn(DATABOX_TIME_DIVISION_LABELS[timePeriod.label])}
					</h2>
					<ul className={'gov-list--plain'}>
						{timePeriod?.messages.map((message) => {
							return (
								<DataBoxMessageItem
									key={message.datovaZpravaId}
									message={message}
									timePeriodLabel={timePeriod.label}
								/>
							);
						})}
					</ul>
				</li>
			))}
		</ul>
	);
};
