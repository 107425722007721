import { GovIcon } from '@gov-design-system-ce/react';
import { Fragment } from 'react';
import { MarkdownRender } from '@gov-nx/component/web';
import { useDataBoxConnectBanner } from '@gov-nx/core/hooks';
import { pipe, pushWhen } from '@gov-nx/core/types';
import { DataBoxConnectBannerProps } from '@gov-nx/ui/types';
import { Button } from '../Button/Button';
import { ButtonGroupLayout } from '../Layout/ButtonGroupLayout';

export const DataBoxConnectBanner = ({
	requiredTypes,
	translationPrefix,
}: DataBoxConnectBannerProps) => {
	const {
		events,
		strings,
		showCreateButton,
		showCreateFOButton,
		showConnectButton,
		possibleToConnectOrCreate,
	} = useDataBoxConnectBanner({
		requiredTypes,
		translationPrefix,
	});

	if (!possibleToConnectOrCreate) {
		return null;
	}

	const buttons = pipe(
		[],
		pushWhen(
			showConnectButton,
			<Button
				variant={'warning'}
				type={'solid'}
				onClick={() => events.connect({ requiredTypes })}
				wcagLabel={strings.buttons.connect.wcag}>
				{strings.buttons.connect.content}
			</Button>
		),
		pushWhen(
			showCreateButton,
			<Button
				variant={'warning'}
				type={'solid'}
				onClick={events.create}
				wcagLabel={strings.buttons.create.wcag}>
				{strings.buttons.create.content}
			</Button>
		),
		pushWhen(
			showCreateFOButton,
			<Button
				variant={'primary'}
				type={'outlined'}
				inverse
				onClick={events.create}
				wcagLabel={strings.buttons.createFO.wcag}>
				{strings.buttons.createFO.content}
			</Button>
		)
	);

	return (
		<div
			role="status"
			className={'bg-gradient-to-t to-primary-700 from-primary-600 rounded'}>
			<div
				className={
					'flex flex-col gap-4 items-start p-4 text-neutral-white [ md:flex-row md:gap-6 md:p-8 ]'
				}>
				<GovIcon
					className={'w-12 h-12 text-warning-400'}
					type={'basic'}
					name={'datovka'}></GovIcon>
				<div className={'flex flex-col gap-3 flex-1'}>
					<h3 className={'text-l font-bold'}>{strings.title}</h3>
					<MarkdownRender applyClasses={{ paragraph: 'text-s' }}>
						{strings.content}
					</MarkdownRender>
					{buttons.length > 1 && (
						<ButtonGroupLayout>
							{buttons.map((button, index) => (
								<Fragment key={index}>{button}</Fragment>
							))}
						</ButtonGroupLayout>
					)}
				</div>
				{buttons.length === 1 && buttons[0]}
			</div>
		</div>
	);
};
