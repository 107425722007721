import { JSX } from '@gov-design-system-ce/components/dist/types/components';
import { GovIcon, GovInfobar } from '@gov-design-system-ce/react';
import { renderIcon } from '@gov-nx/component/common';
import { useAppContext } from '@gov-nx/core/app';
import { useCmsMessageLoader, useGlobalLocale } from '@gov-nx/core/service';
import { filterCmsMessagesByCodes } from '@gov-nx/utils/common';
import { MarkdownRender } from '../Markdown/MarkdownRender';

const variantBackgrounds: Record<
	Exclude<JSX.GovInfobar['variant'], undefined>,
	string
> = {
	primary: 'gov-bg--primary-600',
	secondary: 'gov-bg--secondary-800',
	warning: 'gov-bg--warning-400',
	success: 'gov-bg--success-500',
	error: 'gov-bg--error-400',
};

export const CmsMessageContainer = () => {
	const { currentPageCode, currentServiceCode } = useAppContext();
	const { t } = useGlobalLocale();
	const { messages } = useCmsMessageLoader();

	return (
		<>
			{filterCmsMessagesByCodes(
				messages,
				currentPageCode,
				currentServiceCode
			).map((message) => {
				const icon = message.icon ? renderIcon(message.icon) : null;
				return (
					<div
						className={
							message.properties.variant &&
							variantBackgrounds[message.properties.variant]
						}
						key={message.id}>
						<div className={'gov-container'}>
							<GovInfobar
								key={message.id}
								headline={message.headline || undefined}
								variant={message.properties.variant}
								wcagToggleLabel={t('alert.wcag.toggle-label')}
								className={'pl-4'}>
								{icon ? (
									<GovIcon
										name={icon.name}
										type={icon.type}
										slot={'icon'}
									/>
								) : null}
								<MarkdownRender>{message.message}</MarkdownRender>
							</GovInfobar>
						</div>
					</div>
				);
			})}
		</>
	);
};
