import { countriesQuery, usePoQuery } from '@gov-nx/api/portal-obcana';
import { has } from '@gov-nx/core/types';
import { AlphabeticalComparator } from '@gov-nx/utils/common';
import { useAppLanguage } from '../Localization/useAppLanguage';

export const useCountriesQuery = () => {
	const { language } = useAppLanguage();

	const query = usePoQuery({
		queryKey: ['profile-rob-countries'],
		queryFn: countriesQuery,
	});

	const countryOptions =
		query.data?.seznam
			?.filter(has('kod'))
			.map((country) => {
				return {
					value: country.kod,
					label: language === 'cs' ? country.nazevCs : country.nazevEn,
				};
			})
			.sort(AlphabeticalComparator) ?? [];

	return { countryOptions, isLoading: query.isLoading };
};
