import * as yup from 'yup';
import { BooleanSchema } from 'yup';
import { OptionalObjectSchema, TypeOfShape } from 'yup/lib/object';
import { AnyObject } from 'yup/lib/types';
import { FormBadgeListProps } from '@gov-nx/ui/types';

interface FieldProps<Value extends Record<string, boolean>> {
	name: string;
	options: { name: keyof Value; label: string }[];
	defaultValues: Value;
}

type Schema<Value> = OptionalObjectSchema<
	Record<keyof Value, BooleanSchema>,
	AnyObject,
	TypeOfShape<Record<keyof Value, BooleanSchema>> | null
>;

export type BadgeListDefinition<
	Value extends Record<string, boolean> = Record<string, boolean>
> = {
	schema: Schema<Value>;
	defaultValue: Value;
	field: FormBadgeListProps<keyof Value>;
};
export const getBadgeListDefinition = <Value extends Record<string, boolean>>(
	props: FieldProps<Value>
): BadgeListDefinition<Value> => {
	const shape = props.options.reduce((all, current) => {
		return { ...all, [current.name]: yup.boolean() };
	}, {} as Record<keyof Value, BooleanSchema>);

	const schema = yup.object(shape).nullable();

	return {
		schema,
		defaultValue: props.defaultValues,
		field: {
			field: {
				name: props.name,
			},
			options: props.options,
		},
	};
};
