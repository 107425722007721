import { FormDefinition2, useFormBuilder } from '@gov-nx/core/service';
import { useSmazaniUctuLocale } from '@gov-nx/module/service';
import { FormCheckboxProps, FormInputProps } from '@gov-nx/ui/types';
import {
	getCheckboxDefinition,
	useInputDefinition,
} from '@gov-nx/utils/common';

interface Step1Fields {
	agreeWithDeletion: FormCheckboxProps;
}

interface FormStep1 {
	agreeWithDeletion: boolean;
}

export type SmazaniUctuFormStep1 = FormDefinition2<FormStep1, Step1Fields>;

export const useFormStep1 = ({
	isDisabled,
}: {
	isDisabled: boolean;
}): SmazaniUctuFormStep1 => {
	const { t } = useSmazaniUctuLocale();

	const agreeWithDeletion = getCheckboxDefinition({
		name: 'agreeWithDeletion',
		isDisabled,
		oneOf: {
			value: [true],
			errorMessage: t('formular.souhlas-se-smazanim.validace.povinny'),
		},
		label: t('formular.souhlas-se-smazanim.label'),
	});

	return useFormBuilder<FormStep1, Step1Fields>([agreeWithDeletion]);
};

interface Step2Fields {
	agreeWithKeepingSomeInfo: FormCheckboxProps;
}

interface FormStep2 {
	agreeWithKeepingSomeInfo: boolean;
}
export type SmazaniUctuFormStep2 = FormDefinition2<FormStep2, Step2Fields>;

export const useFormStep2 = ({
	isDisabled,
}: {
	isDisabled: boolean;
}): SmazaniUctuFormStep2 => {
	const { t } = useSmazaniUctuLocale();

	const agreeWithKeepingSomeInfo = getCheckboxDefinition({
		name: 'agreeWithKeepingSomeInfo',
		isDisabled,
		oneOf: {
			value: [true],
			errorMessage: t(
				'formular.souhlas-s-ponechanim-informaci.validace.povinny'
			),
		},
		label: t('formular.souhlas-s-ponechanim-informaci.label'),
	});

	return useFormBuilder<FormStep2, Step2Fields>([agreeWithKeepingSomeInfo]);
};

interface Step3Fields {
	message: FormInputProps;
}

interface FormStep3 {
	message: string;
}
export type SmazaniUctuFormStep3 = FormDefinition2<FormStep3, Step3Fields>;

export const useFormStep3 = ({
	isDisabled,
}: {
	isDisabled: boolean;
}): SmazaniUctuFormStep3 => {
	const { t } = useSmazaniUctuLocale();

	const message = useInputDefinition({
		name: 'message',
		placeholder: t('formular.zprava.placeholder'),
		isDisabled,
		label: t('formular.zprava.label'),
		isMultiline: true,
		rows: 4,
	});

	return useFormBuilder<FormStep3, Step3Fields>([message]);
};

export type SmazaniUctuFormData = FormStep1 & FormStep2 & FormStep3;
