import * as yup from 'yup';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
} from '@gov-nx/core/service';
import { dataBoxParams, useDataBoxShape } from '@gov-nx/utils/common';
import { DataRequest, FormData } from './service.types';

export interface FormInstanceProps {
	dataBoxTypes: DatovkaApiType['typSchranky'][];
}

export function FormInstance({
	dataBoxTypes,
}: FormInstanceProps): FormDefinition<FormData> {
	const { getDataBoxShape, getDataBoxDefaultValues } = useDataBoxShape({
		types: dataBoxTypes,
	});

	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			...getDataBoxShape({ isRequired: true }),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: getDataBoxDefaultValues(),
	});

	return getFormDefinition({ formMethods, formSchema });
}

export function prepareSubmitData(licenceNumber?: string) {
	return (formData: FormData): DataRequest => {
		return {
			params: dataBoxParams(formData),
			body: {
				typVypisu: 'BODOVE_HODNOCENI_RIDICE',
				data: {
					cisloRP: licenceNumber,
				},
			},
		};
	};
}
