import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PoFormWeb } from '@gov-nx/component/web';
import { SubmissionConnection } from '@gov-nx/core/hooks';
import { LocalizeNameSpaceTypes, useLocale } from '@gov-nx/core/service';
import { DataMessage, PageCode } from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	FormSelect,
	LayoutGap,
	Modal,
} from '@gov-nx/ui/web';

const Skeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);

	return (
		<LayoutGap>
			<div className={'flex flex-col mt-6 [ md:flex-row ]'}>
				<div className={'w-2/5'}>
					<GovSkeleton
						height={'22px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing')}
						className={'[ md:ml-3 ]'}
					/>
				</div>
			</div>
		</LayoutGap>
	);
};

type Props = SubmissionConnection['connect'] & {
	message: DataMessage;
};

export const ConnectToAnotherSubmission = ({
	message,
	form,
	fields,
	isConnectModalOpen,
	isLoading,
	isConnecting,
	onConnectSubmit,
	onConnectCancel,
	connect,
}: Props) => {
	const { t } = useLocale(PageCode['moje-podani']);
	const { t: td } = useLocale(PageCode['datove-schranky']);

	return (
		<div>
			<Modal
				label={td('formular.pripojit-k-moje-podani.titulek')}
				open={isConnectModalOpen}
				onGov-close={onConnectCancel}>
				<div>
					{isLoading ? (
						<Skeleton />
					) : (
						<FormProvider {...form.formMethods}>
							<PoFormWeb
								formDefinition={form}
								onSubmit={onConnectSubmit}>
								<LayoutGap gap={4}>
									<FormSelect {...fields.submissionId} />

									<ButtonGroupLayout>
										<Button
											loading={isConnecting}
											nativeType={'submit'}
											wcagLabel={t('akce.pripojit-k-jinemu-podani-ulozit')}
											disabled={isConnecting}
											variant={'primary'}
											type={'solid'}>
											{t('akce.pripojit-k-jinemu-podani-ulozit')}
										</Button>
									</ButtonGroupLayout>
								</LayoutGap>
							</PoFormWeb>
						</FormProvider>
					)}
				</div>
			</Modal>
			<Button
				onClick={() => connect(message)}
				variant="primary"
				type="outlined"
				size="s"
				wcagLabel={t('akce.pripojit-k-jinemu-podani')}>
				{td('akce.pripojit-k-moje-podani')}
			</Button>
		</div>
	);
};
