import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { CommonSettingKey, UserSetting } from '@gov-nx/api/portal-obcana';
import { prop } from '@gov-nx/core/types';
import {
	PERSIST_ZUSTAND_STORE_KEY,
	storeGlobalConfig,
	StoreKeys,
} from '../constants';
import { UserState } from './store.types';
import { isFavoritesSetting, isLanguageSetting } from './utils';

export const useUserStoreBase = create<UserState>()(
	persist(
		(set, get) => {
			const setUserSetting = (setting: UserSetting) =>
				set((state: UserState) => ({
					settings: [
						...state.settings.filter((i) => i.key !== setting.key),
						setting,
					],
				}));

			return {
				settings: [],
				biometricAllowed: false,

				setUserSettingFavorites: (favorites) =>
					setUserSetting({ key: CommonSettingKey.Favorites, value: favorites }),
				setUserSettingLanguage: (language) =>
					setUserSetting({
						key: CommonSettingKey.Language,
						value: language,
					}),
				setBiometricStatus: (biometricAllowed: boolean) =>
					set({ biometricAllowed }),
				poUserFavorites: () =>
					get().settings?.find(isFavoritesSetting)?.value || [],
				poUserLanguage: () => get().settings?.find(isLanguageSetting)?.value,
			};
		},
		{
			...storeGlobalConfig,
			name: PERSIST_ZUSTAND_STORE_KEY + ':' + StoreKeys.user,
		}
	)
);

export const useUserStore = <T extends keyof UserState>(
	property: T
): UserState[T] => useUserStoreBase(prop(property));
