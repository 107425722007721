import React, { createContext, useContext } from 'react';
import { OsobyPodnikatelRozsireneDto } from '@gov-nx/api/portal-obcana';
import { useProcessControl } from '@gov-nx/core/hooks';
import { getRequiredDataBoxes } from '@gov-nx/core/service';
import {
	Nullable,
	hasProperty,
	hasSomeProperties,
	propEq,
} from '@gov-nx/core/types';
import { useDataBoxAccess } from '@gov-nx/module/data-box';
import { ServiceCode } from '@gov-nx/module/service';
import { useBoolean } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import { RosComplaintVariant } from '../reklamace/options';
import { useRosExtendedQuery } from '../useRosExtendedQuery';
import {
	ProfileROSDetailProcessControl,
	ROSDetailContextType,
} from './context.types';
import { getName } from './utils';

const ProfilROSDetailContext =
	createContext<Nullable<ROSDetailContextType>>(null);

interface SettingsROSContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
	ico: number;
	onComplaint?: (
		data: OsobyPodnikatelRozsireneDto,
		variant: RosComplaintVariant
	) => void;
	onService: (code: ServiceCode) => void;
}

export function RegistrOsobDetailContextProvider({
	children,
	code,
	ico,
	onComplaint,
	onService,
}: SettingsROSContextProviderProps) {
	const { controls, setControls } =
		useProcessControl<ProfileROSDetailProcessControl>({
			complaintModal: null,
			complaintVariant: RosComplaintVariant.AGENDA,
		});
	const isMembersListModalOpen = useBoolean(false);
	const isEstablishmentsListModalOpen = useBoolean(false);
	const isPersonsListModalOpen = useBoolean(false);

	const requiredDataBoxes = getRequiredDataBoxes(
		ServiceCode['vypis-z-zivnostenskeho-rejstriku']
	);
	const { areAnyConnected } = useDataBoxAccess({
		requiredTypes: requiredDataBoxes,
	});

	const { isLoading, error, getDetailData } = useRosExtendedQuery();

	const detailData = getDetailData(ico);

	return (
		<ProfilROSDetailContext.Provider
			value={{
				code,
				controls: {
					...controls,
					initialLoading: isLoading,
					initialError: error,
				},
				detailData,
				setControls,
				onComplaint,
				onService,

				isMembersListModalOpen,
				isEstablishmentsListModalOpen,
				isPersonsListModalOpen,

				areAnyConnected,

				myInvolvement:
					detailData?.angazovaneOsoby
						?.filter(propEq('mojeAngazovanost', true))
						.filter(hasProperty('role')) ?? [],
				members:
					detailData?.statutarniOrgany?.filter(
						hasSomeProperties([
							'jmeno',
							'prijmeni',
							'ico',
							'nazevSpolecnosti',
							'sidloSpolecnosti',
							'datumNarozeni',
						])
					) ?? [],
				establishments:
					detailData?.provozovny?.filter(
						hasSomeProperties([
							'adresa',
							'dorucovaciAdresa',
							'datumZahajeniCinnosti',
						])
					) ?? [],
				persons:
					detailData?.angazovaneOsoby?.filter(
						hasSomeProperties([
							'jmeno',
							'prijmeni',
							'ico',
							'nazevSpolecnosti',
							'sidloSpolecnosti',
							'datumNarozeni',
						])
					) ?? [],

				name: getName(detailData),
			}}>
			{children}
		</ProfilROSDetailContext.Provider>
	);
}

export const useProfilROSDetailContext = (): ROSDetailContextType =>
	useContext(ProfilROSDetailContext) as ROSDetailContextType;
