import { useTranslation } from 'react-i18next';
import { getI18n } from './i18n';

/**
 * @deprecated use `useLocale` instead
 */
export const useTranslationWithNamespace = () => {
	const { t } = useTranslation();
	const getLocalize = (
		namespace: string,
		key: string,
		values?: Record<string, string | number>
	): string => {
		const isLoaded = getI18n().hasLoadedNamespace(namespace);
		if (!isLoaded) return key;

		return t(key, { ns: namespace, ...values });
	};

	/**
	 * @deprecated tohle bych nepouzival, kdysi to bylo docasny reseni ale lepsi je pouzivat `useLocale`
	 */
	const getLocalizeCurried =
		(namespace: string) =>
		(key: string, values?: Record<string, string | number>): string =>
			getLocalize(namespace, key, values);

	return { getLocalize, ts: getLocalize, getLocalizeCurried, t };
};
