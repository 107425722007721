import {
	EmbassyOfficeFields,
	EmbassyOfficeValues,
	useEmbassyOfficeFields,
	useEmbassyOfficeSelects,
} from '@gov-nx/core/hooks';
import { FormDefinition2, useFormBuilder } from '@gov-nx/core/service';
import { useKorespondencniVolbyLocale } from '@gov-nx/module/service';
import { FormInputProps, FormRadioProps } from '@gov-nx/ui/types';
import { getRadioDefinition, useInputDefinition } from '@gov-nx/utils/common';

export interface KorespondencniHlasovaniVymazStep2 extends EmbassyOfficeValues {
	feedBackMethod?: 'POSTA' | 'OSOBNE' | 'DATOVA_SCHRANKA';
	street?: string;
	city?: string;
	zip?: string;
	country?: string;
}

interface Fields extends EmbassyOfficeFields {
	feedBackMethod: FormRadioProps;
	street: FormInputProps;
	city: FormInputProps;
	zip: FormInputProps;
	country: FormInputProps;
}

export type KorespondencniHlasovaniVymazFormStep2 = FormDefinition2<
	KorespondencniHlasovaniVymazStep2,
	Fields
>;

export const useFormStep2 = ({ isDisabled }: { isDisabled: boolean }) => {
	const { t } = useKorespondencniVolbyLocale();

	const feedBackMethod = getRadioDefinition({
		name: 'feedBackMethod',
		label: t('vymaz.formular.zpusob-potvrzeni.label'),
		isRequired: true,
		isRequiredMessage: t('vymaz.formular.zpusob-potvrzeni.validace.povinny'),
		options: [
			{ label: t('vymaz.formular.zpusob-potvrzeni.osobne'), name: 'OSOBNE' },
			{
				label: t('vymaz.formular.zpusob-potvrzeni.datovka'),
				name: 'DATOVA_SCHRANKA',
			},
			{ label: t('vymaz.formular.zpusob-potvrzeni.posta'), name: 'POSTA' },
		],
		defaultValue: null,
	});

	const street = useInputDefinition({
		name: 'street',
		label: t('formular.ulice.label'),
		placeholder: t('formular.ulice.placeholder'),
		isDisabled,
		isRequiredWhen: {
			fieldName: 'feedBackMethod',
			is: 'POSTA',
			errorMessage: t('formular.ulice.validace.povinny'),
		},
	});
	const city = useInputDefinition({
		name: 'city',
		label: t('formular.mesto.label'),
		placeholder: t('formular.mesto.placeholder'),
		isDisabled,
		isRequiredWhen: {
			fieldName: 'feedBackMethod',
			is: 'POSTA',
			errorMessage: t('formular.mesto.validace.povinny'),
		},
	});
	const zip = useInputDefinition({
		name: 'zip',
		label: t('formular.psc.label'),
		placeholder: t('formular.psc.placeholder'),
		isDisabled,
		isRequiredWhen: {
			fieldName: 'feedBackMethod',
			is: 'POSTA',
			errorMessage: t('formular.psc.validace.povinny'),
		},
	});
	const country = useInputDefinition({
		name: 'country',
		label: t('formular.stat.label'),
		placeholder: t('formular.stat.placeholder'),
		isDisabled,
		isRequiredWhen: {
			fieldName: 'feedBackMethod',
			is: 'POSTA',
			errorMessage: t('formular.stat.validace.povinny'),
		},
	});

	const { isLoading, fields } = useEmbassyOfficeFields({ isDisabled });

	const form = useFormBuilder<KorespondencniHlasovaniVymazStep2, Fields>([
		...fields,
		feedBackMethod,
		street,
		city,
		zip,
		country,
	]);

	const updatedForm = useEmbassyOfficeSelects(form, isDisabled);

	return {
		isLoading: isLoading,
		hideAddressInputs:
			updatedForm.form.formMethods.watch('feedBackMethod') !== 'POSTA',
		...updatedForm,
	};
};
