import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { prop } from '@gov-nx/core/types';
import {
	PERSIST_ZUSTAND_STORE_KEY,
	storeGlobalConfig,
	StoreKeys,
} from '../constants';
import { DataBoxSearchState } from './store.types';

const isInHistory = (array: string[], string: string) =>
	array.some((str) => str.toLowerCase() === string.toLowerCase());

export const useDataBoxSearchStoreBase = create<DataBoxSearchState>()(
	persist(
		(set, get) => ({
			searchHistory: [],

			setDataBoxSearchHistory: (databoxSearch: string) =>
				set((state: DataBoxSearchState) => ({
					searchHistory: isInHistory(state.searchHistory, databoxSearch)
						? state.searchHistory
						: [databoxSearch, ...state.searchHistory],
				})),
			deleteDataboxSearchHistory: (databoxSearch: string) =>
				set((state: DataBoxSearchState) => ({
					searchHistory: state.searchHistory.filter(
						(item) => item !== databoxSearch
					),
				})),
		}),
		{
			...storeGlobalConfig,
			name: PERSIST_ZUSTAND_STORE_KEY + ':' + StoreKeys.dataBoxSearch,
		}
	)
);

export const useDataBoxSearchStore = <T extends keyof DataBoxSearchState>(
	property: T
): DataBoxSearchState[T] => useDataBoxSearchStoreBase(prop(property));
