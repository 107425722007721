import { GovIcon, GovTag, GovTooltip } from '@gov-design-system-ce/react';
import { useLocale } from '@gov-nx/core/service';
import { filter, hasProperty, pipe } from '@gov-nx/core/types';
import { useDataBoxLabel } from '@gov-nx/module/data-box';
import {
	RosComplaintVariant,
	useProfilROSDetailContext,
} from '@gov-nx/module/page';
import { ServiceCode } from '@gov-nx/module/service';
import {
	Button,
	ContentLayout,
	DataBoxConnectBanner,
	DataTable,
	LayoutGap,
} from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';

export const PFODataView = () => {
	const {
		detailData,
		setControls,
		code,
		name,
		areAnyConnected,
		onService,
		members,
		persons,
		establishments,
		isMembersListModalOpen,
		isPersonsListModalOpen,
		isEstablishmentsListModalOpen,
	} = useProfilROSDetailContext();
	const { t } = useLocale(code);

	const { label } = useDataBoxLabel();

	return (
		<>
			<ContentLayout>
				<LayoutGap gap={4}>
					<h3 className="text-secondary-700 text-l">
						{t('nadpis.zakladni-informace')}
					</h3>
					<DataTable responsive>
						<tbody>
							<tr>
								<td>{t('zaznam.ico')}</td>
								<td>
									<strong>{detailData?.ico ? detailData.ico : '-'}</strong>
								</td>
							</tr>
							<tr>
								<td>{t('zaznam.pravni-forma')}</td>
								<td>
									<strong>
										{detailData?.nazevPravniFormy
											? detailData.nazevPravniFormy
											: '-'}
									</strong>
								</td>
							</tr>
							<tr>
								<td>{t('zaznam.stav')}</td>
								<td>
									<strong>
										{detailData?.pravniStav?.nazev
											? detailData.pravniStav.nazev
											: '-'}
									</strong>
								</td>
							</tr>
						</tbody>
					</DataTable>
					<Button
						onClick={() =>
							setControls({
								complaintModal: detailData,
								complaintVariant: RosComplaintVariant.BASIC_INFO,
							})
						}
						variant={'primary'}
						type={'outlined'}
						size={'l'}>
						{t('akce.podnet-k-oprave-udaju')}
					</Button>
				</LayoutGap>
			</ContentLayout>
			<ContentLayout>
				<LayoutGap gap={4}>
					<div className="flex items-center gap-4">
						<h3 className="text-secondary-700 text-l">
							{t('nadpis.agendova-cast')}
						</h3>
						{detailData?.pozastaveniPreruseni ? (
							<GovTag
								variant={'secondary'}
								size={'xs'}>
								{t('tag.pozastaveni-preruseni')}
							</GovTag>
						) : null}
					</div>

					<DataTable responsive>
						<tbody>
							<tr>
								<td>{t('zaznam.nazev')}</td>
								<td>
									<strong>{name ? name : '-'}</strong>
								</td>
							</tr>
							<tr>
								<td>{t('zaznam.agenda')}</td>
								<td>
									<strong>
										{detailData?.agenda?.nazev ? detailData.agenda.nazev : '-'}
									</strong>
								</td>
							</tr>
							<tr>
								<td>{t('zaznam.agenda-kod')}</td>
								<td>
									<strong>
										{detailData?.agenda?.kod ? detailData.agenda.kod : '-'}
									</strong>
								</td>
							</tr>
							<tr>
								<td>{t('zaznam.zapisujici-organ')}</td>
								<td>
									<strong>
										{detailData?.pravniStav?.nazev
											? detailData.pravniStav.nazev
											: '-'}
									</strong>
								</td>
							</tr>
							<tr>
								<td>{t('zaznam.sidlo')}</td>
								<td>
									<strong>
										{detailData?.sidloSpolecnosti?.adresaText
											? detailData?.sidloSpolecnosti?.adresaText
											: '-'}
									</strong>
								</td>
							</tr>
							<tr>
								<td>{t('zaznam.datum-vzniku')}</td>
								<td>
									<strong>
										{detailData?.datumVzniku
											? toDateReadable(detailData.datumVzniku)
											: '-'}
									</strong>
								</td>
							</tr>
							<tr>
								<td>{t('zaznam.pozastaveni-preruseni')}</td>
								<td>
									<strong>
										{detailData?.pozastaveniPreruseni
											? t('zaznam.pozastaveni-preruseni-ano')
											: t('zaznam.pozastaveni-preruseni-ne')}
									</strong>
								</td>
							</tr>
							{members.length ? (
								<tr>
									<td>{t('zaznam.struktura-statutarniho-organu')}</td>
									<td>
										<strong>{t('zaznam.seznam-clenu')}</strong>
										<Button
											onClick={isMembersListModalOpen.setTrue}
											variant={'primary'}
											type={'base'}
											size={'s'}
											iconLeft={'eye'}>
											<GovIcon
												slot="left-icon"
												name="eye"></GovIcon>
										</Button>
									</td>
								</tr>
							) : null}
							{establishments.length ? (
								<tr>
									<td>{t('zaznam.provozovny')}</td>
									<td>
										<strong>{t('zaznam.seznam-provozoven')}</strong>
										<Button
											onClick={isEstablishmentsListModalOpen.setTrue}
											variant={'primary'}
											type={'base'}
											size={'s'}
											iconLeft={'eye'}>
											<GovIcon
												slot="left-icon"
												name="eye"></GovIcon>
										</Button>
									</td>
								</tr>
							) : null}

							{detailData?.dorucovaciAdresa?.adresaText ? (
								<tr>
									<td>{t('zaznam.dorucovaci-adresa-popis')}</td>
									<td>
										<strong>{detailData.dorucovaciAdresa.adresaText}</strong>
										<p className="text-xs mt-1 text-left">
											{t('zaznam.dorucovaci-adresa-vysvetleni')}
										</p>
									</td>
								</tr>
							) : null}
							{persons.length ? (
								<tr>
									<td>{t('zaznam.angazovane-osoby')}</td>
									<td>
										<strong>{t('zaznam.seznam-osob')}</strong>
										<Button
											onClick={isPersonsListModalOpen.setTrue}
											variant={'primary'}
											type={'base'}
											size={'s'}
											iconLeft={'eye'}>
											<GovIcon
												slot="left-icon"
												name="eye"></GovIcon>
										</Button>
									</td>
								</tr>
							) : null}
						</tbody>
					</DataTable>
					<Button
						onClick={() =>
							setControls({
								complaintModal: detailData,
								complaintVariant: RosComplaintVariant.AGENDA,
							})
						}
						variant={'primary'}
						type={'outlined'}
						size={'l'}>
						{t('akce.podnet-k-oprave-udaju')}
					</Button>
				</LayoutGap>
			</ContentLayout>
			<ContentLayout>
				<LayoutGap gap={4}>
					<h3 className="text-secondary-700 text-l">
						{t('nadpis.datove-schranky')}
					</h3>

					{pipe(
						detailData?.datoveSchranky,
						filter(hasProperty('id')),
						(databoxes) =>
							databoxes.length ? (
								<DataTable responsive>
									<tbody>
										{databoxes.map((databox) => (
											<tr key={databox.id}>
												<td>
													{databox.typ
														? label(databox.typ)
														: t('zaznam.datova-schranka')}
												</td>
												<td>
													<strong>{databox.id}</strong>
												</td>
											</tr>
										))}
									</tbody>
								</DataTable>
							) : (
								<DataBoxConnectBanner
									requiredTypes={[]}
									translationPrefix="banner"
								/>
							)
					)}
				</LayoutGap>
			</ContentLayout>
			<ContentLayout>
				<LayoutGap gap={4}>
					<h3 className="text-secondary-700 text-l">
						{t('nadpis.vypis.zivnostenskeho.rejstriku')}
					</h3>
					<p className="text-secondary-700 text-s">
						{t('nadpis.vypis.zivnostenskeho.rejstriku-popis')}
					</p>
				</LayoutGap>

				<div className="pt-4">
					{areAnyConnected ? (
						<Button
							onClick={() =>
								onService(ServiceCode['vypis-z-zivnostenskeho-rejstriku'])
							}
							variant={'primary'}
							size="m"
							type="solid">
							{t('akce.pozadat-o-vypis')}
						</Button>
					) : (
						<GovTooltip
							className={'border-b-0'}
							message={t('akce.nejprve-pripojte')}>
							<Button
								disabled
								variant={'primary'}
								size="m"
								type="solid">
								{t('akce.pozadat-o-vypis')}
							</Button>
						</GovTooltip>
					)}
				</div>
			</ContentLayout>
		</>
	);
};
