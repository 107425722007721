import { useLocale } from '@gov-nx/core/service';
import { PageCode } from '../definitions/codes';

export const useZpusobPrihlaseniOptions = () => {
	const { t } = useLocale(PageCode['podpora']);

	const source = [
		{ value: 'ID', label: t('formular.pole.e-obcanka') },
		{ value: 'JMENO_HESLO', label: t('formular.pole.jmeno-heslo-sms') },
		{ value: 'DS', label: t('formular.pole.datova-schranka') },
		{
			value: 'NEREGISTROVANY',
			label: t('formular.pole.neregistrovany-uzivatel'),
		},
		{ value: 'STARCOS', label: t('formular.pole.karta-starcos') },
		{ value: 'MOBILNI_KLIC', label: t('formular.pole.mobilni-klic') },
		{ value: 'MOJE_ID', label: t('formular.pole.mojeID') },
		{ value: 'CSOB_ID', label: t('formular.pole.csob-identita') },
		{ value: 'CESKA_SPORITELNA', label: t('formular.pole.ceska-sporitelna') },
		{ value: 'KOMERCNI_BANKA', label: t('formular.pole.komercni-banka') },
		{ value: 'Air_Bank', label: t('formular.pole.air-bank') },
		{ value: 'MONETA_Money_Bank', label: t('formular.pole.moneta-money-bank') },
		{ value: 'Raiffeisenbank', label: t('formular.pole.raiffeisenbank') },
		{ value: 'FIO_banka', label: t('formular.pole.fio-banka') },
		{ value: 'UniCredit_Bank', label: t('formular.pole.unicredit-bank') },
		{ value: 'mBank', label: t('formular.pole.mbank') },
		{ value: 'CREDITAS', label: t('formular.pole.creditas-bank') },
		{ value: 'IIG', label: t('formular.pole.iig') },
	];

	return { options: source };
};
