import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { ContentLayout, DataTable, LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';
import { CmsPageSkeleton } from '../../../../Cms/CmsPageSkeleton';

export const RezaTemplateDetailSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);

	return (
		<CmsPageSkeleton>
			<LayoutGap>
				<ContentLayout>
					<LayoutGap gap={3}>
						<DataTable responsive>
							<tbody>
								{Array.from(Array(15))
									.fill(null)
									.map((_, index) => {
										return (
											// eslint-disable-next-line react/no-array-index-key
											<tr key={index}>
												<td className={'md:!w-1/2'}>
													<GovSkeleton
														height={'19px'}
														width={generateRandomNumber(20, 70) + '%'}
														wcagLabel={t('loading.wcag.processing')}
													/>
												</td>
												<td>
													<GovSkeleton
														height={'19px'}
														width={generateRandomNumber(20, 70) + '%'}
														wcagLabel={t('loading.wcag.processing')}
														className={'flex flex-grow '}
													/>
												</td>
											</tr>
										);
									})}
							</tbody>
						</DataTable>
					</LayoutGap>
				</ContentLayout>
			</LayoutGap>
		</CmsPageSkeleton>
	);
};
