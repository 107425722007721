import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { DrozdRegistrationDto } from '@gov-nx/api/portal-obcana';
import { prop } from '@gov-nx/core/types';
import {
	PERSIST_ZUSTAND_STORE_KEY,
	StoreKeys,
	storeGlobalConfig,
} from '../../constants';
import { updateByPath } from '../../filters/store';
import { update, updateRegistrations } from '../utils';
import { DrozdRegistrationsState } from './store.types';

const initialState = {
	registrations: [],
	registrationsDetails: {},
};

const useDrozdRegistrationsStoreBase = create<DrozdRegistrationsState>()(
	persist(
		(set, get) => ({
			...initialState,

			saveRegistrations: (registrations) => set({ registrations }),
			updateRegistrations: (registrations) =>
				set(update('registrations', updateRegistrations(registrations))),

			updateRegistrationDetails: (
				registrationsDetails: DrozdRegistrationDto,
				key: string
			) => {
				set(updateByPath(['registrationsDetails', key], registrationsDetails));
			},

			getRegistrationDetail: (token) => {
				return get().registrations.find(
					(registration) => registration.traveller?.token === token
				);
			},
		}),
		{
			...storeGlobalConfig,
			name: PERSIST_ZUSTAND_STORE_KEY + ':' + StoreKeys.drozdRegistrations,
		}
	)
);

export const useDrozdRegistrationsStore = <
	T extends keyof DrozdRegistrationsState
>(
	property: T
): DrozdRegistrationsState[T] => useDrozdRegistrationsStoreBase(prop(property));
