import { createContext, ReactNode, useContext } from 'react';
import { useElectionsQuery } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { ServiceCode } from '@gov-nx/module/service';
import { VotersCardContext } from './context.types';

const ServiceContext = createContext<Nullable<VotersCardContext>>(null);

interface VolicskyPrukazContextProviderProps {
	children: ReactNode;
	code: ServiceCode;
}

export const VolicskyPrukazSeznamContextProvider = ({
	children,
}: VolicskyPrukazContextProviderProps) => {
	const elections = useElectionsQuery();
	return (
		<ServiceContext.Provider
			value={{
				initialState: {
					isLoading: elections.isLoading,
					error: elections.error ?? undefined,
				},
				elections: elections.data?.seznam ?? [],
			}}>
			{children}
		</ServiceContext.Provider>
	);
};

export const useVolicskyPrukazSeznamContext = (): VotersCardContext =>
	useContext(ServiceContext) as VotersCardContext;
