export const stripHTMLTagsAndGetParagraphs = (html: string): string[] => {
	const newLinesAdded = html.replace(/(<\/(?:p|h[1-6]|li|ul|ol)>)/gm, '$1\n');
	const stripped = plainText(newLinesAdded);
	return stripped.split(/(?:\r\n|\r|\n)+/g);
};

export const stripHTMLTags = (html: string): string => {
	return html.replace(/(<([^>]+)>)/gi, '');
};

function plainText(html: string): string {
	const doc: Document = new DOMParser().parseFromString(html, 'text/html');
	return doc.body.textContent || '';
}
