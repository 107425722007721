import { pageRouteResolver } from '@gov-nx/web';
import React from 'react';
import { useLocale } from '@gov-nx/core/service';
import { filter, hasProperty, pipe } from '@gov-nx/core/types';
import { useProfilROSContextInstance } from '@gov-nx/module/page';
import { CopyButton, Tile, Tiles } from '@gov-nx/ui/web';

export const PersonInvolmentListView = () => {
	const { personInvolvementData, code } = useProfilROSContextInstance();
	const { t } = useLocale(code);

	if (!personInvolvementData.length) return null;

	return (
		<section>
			<h1 className="text-2xl text-secondary-700">
				{t('nadpis.angazovanost')}
			</h1>
			<Tiles>
				{personInvolvementData.map((person) => {
					return (
						<Tile
							key={person.ico}
							name={person.nazevSpolecnosti ?? ''}
							headlineLink={true}
							to={pageRouteResolver('profil.ros.detail', {
								ico: person.ico,
							})}
							icon={{ name: 'chevron-right', type: 'basic' }}>
							<div className={'flex flex-col gap-2'}>
								<div className={'flex flex-wrap items-center gap-1'}>
									<span className={'inline-block mr-1 text-secondary-700'}>
										{t('zaznam.role.popis')}
									</span>
									{pipe(
										person.angazovaneOsoby,
										filter(hasProperty('role')),
										(involvementPerson) => (
											<strong className={'gov-color--secondary-900'}>
												{involvementPerson.length
													? involvementPerson
															.map((person) =>
																t(`zaznam.role.hodnota.${person.role}`)
															)
															.join(', ')
													: '-'}
											</strong>
										)
									)}
								</div>

								{person.ico ? (
									<div className={'flex items-center gap-1'}>
										<span className={'inline-block mr-1 text-secondary-700'}>
											{t('zaznam.dic')}
										</span>
										<strong className={'gov-color--secondary-900'}>
											{'CZ' + person.ico}
										</strong>
										<CopyButton
											label={'akce.kopirovat-ico' + person.ico}
											value={'CZ' + person.ico}
										/>
									</div>
								) : null}

								{person.sidloSpolecnosti?.adresaText ? (
									<div className={'flex items-center'}>
										<span className={'inline-block mr-1 text-secondary-700'}>
											{t('zaznam.sidlo')}
										</span>
										<strong className={'gov-color--secondary-900'}>
											{person.sidloSpolecnosti?.adresaText}
										</strong>
									</div>
								) : null}
							</div>
						</Tile>
					);
				})}
			</Tiles>
		</section>
	);
};
