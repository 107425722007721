import {
	axiosCmsBaseInstance,
	axiosCmsInstance,
	CmsSignpost,
	CmsSignpostGroup,
	CmsSignpostGroupResponse,
	CmsSignpostResponse,
} from '@gov-nx/api/common';
import { LocalizationLanguage } from '@gov-nx/core/app';

export interface SignpostRequestParams {
	app: string;
	env: string;
	search?: string;
	locale: LocalizationLanguage;
	serviceGroups?: number;
	externalServiceLink?: string;
}

export interface SignpostGroupRequestParams {
	locale: LocalizationLanguage;
}

export const cmsSignpostQuery = async (
	params: SignpostRequestParams
): Promise<CmsSignpost[]> => {
	const response = await axiosCmsBaseInstance.get<CmsSignpostResponse>(
		`/search/`,
		{
			params,
		}
	);
	return response.data.data;
};

export const cmsSignpostGroupsQuery = async (
	params: SignpostGroupRequestParams
): Promise<CmsSignpostGroup[]> => {
	const response = await axiosCmsInstance.get<CmsSignpostGroupResponse>(
		`/service-groups/${params.locale}.json`
	);
	return response.data.data;
};
