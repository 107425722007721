import { useQueryClient } from '@tanstack/react-query';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
	fetchDataBoxesMessageQuery,
	searchDataBoxesQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useDataBoxEvents } from '@gov-nx/core/events';
import { useMySubmissionConnection } from '@gov-nx/core/hooks';
import { useLanguageNamespaceLoader } from '@gov-nx/core/service';
import { Nullable, propEq } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import { useDataBoxUnreadMessages } from '../hooks/useDataBoxUnreadMessagesCounts';
import { useMessageOperations } from '../hooks/useMessageOperations';
import { DataBoxConfirmModalType, DataBoxFolderType } from './DataBoxes.types';
import {
	DataBoxMessageDetailContextType,
	DataBoxMessageDetailLoadingItemType,
} from './MessageDetailContext.types';

export const DataBoxMessageDetailContext =
	createContext<Nullable<DataBoxMessageDetailContextType>>(null);

interface DataBoxMessageDetailProviderProps {
	dataBoxId: string;
	folderType: DataBoxFolderType;
	messageId: number;
	children: React.ReactNode;
}

export function DataBoxMessageDetailProvider({
	dataBoxId,
	folderType,
	messageId,
	children,
}: DataBoxMessageDetailProviderProps) {
	const queryClient = useQueryClient();
	const mySubmissionLanguage = useLanguageNamespaceLoader(
		PageCode['moje-podani']
	);

	const {
		data: message,
		isLoading,
		refetch,
	} = usePoQuery({
		queryKey: ['data-box-message', dataBoxId, messageId],
		queryFn: async () =>
			fetchDataBoxesMessageQuery({ dataBoxId, folderType, messageId }),
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const oppositeDataBoxQuery = usePoQuery({
		queryKey: ['data-box-message', message],
		queryFn: async () => {
			const oppositeDataBoxId =
				dataBoxId === message?.prijemceId
					? message.odesilatelId
					: message?.prijemceId;

			return searchDataBoxesQuery(oppositeDataBoxId as string, 'GENERAL');
		},
		enabled: !!message,
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const { messageRead, hideMessageList, resetActionInfo } = useDataBoxEvents();

	const dataBoxes = useDataBoxStore('list');
	const currentDataBox = dataBoxes.find(propEq('datovaSchrankaId', dataBoxId));
	const oppositeDataBox = oppositeDataBoxQuery.data?.seznam?.[0];

	const isCurrentDataboxRecipient = dataBoxId === message?.prijemceId;
	const recipientDataBox = isCurrentDataboxRecipient
		? currentDataBox
		: oppositeDataBox;
	const isCurrentDataboxSender = dataBoxId === message?.odesilatelId;
	const senderDataBox = isCurrentDataboxSender
		? currentDataBox
		: oppositeDataBox;

	const [loadingItems, setLoadingItems] = useState<
		Partial<Record<DataBoxMessageDetailLoadingItemType, boolean>>
	>({});

	const updateLoadingItems = (
		loadingItem: DataBoxMessageDetailLoadingItemType,
		isLoading: boolean
	) => {
		if (isLoading) {
			setLoadingItems((loadingItems) => ({
				...loadingItems,
				[loadingItem]: true,
			}));
		} else {
			setLoadingItems((loadingItems) => ({
				...loadingItems,
				[loadingItem]: false,
			}));
		}
	};

	const { refetchDataBoxUnreadMessages } = useDataBoxUnreadMessages();

	useEffect(() => {
		setTimeout(() => {
			hideMessageList({ hidden: true });
		});

		return () => {
			setTimeout(() => {
				hideMessageList({ hidden: false });
				resetActionInfo();
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are correct
	}, []);

	useEffect(() => {
		setLoadingItems({});

		if (message) {
			queryClient.invalidateQueries({
				queryKey: ['data-box-unread-messages-list'],
			});
			messageRead({ messageId });
			refetchDataBoxUnreadMessages();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are correct
	}, [message]);

	useDataBoxEvents({
		onMessagesArchive: () => {
			refetch();
		},
	});

	const [showAllAttachments, setShowAllAttachments] = useState(false);
	const [confirmModal, setConfirmModal] =
		useState<Nullable<DataBoxConfirmModalType>>(null);

	const messageOperations = useMessageOperations({
		message,
		dataBoxId,
		folderType,
		messageId,
		updateLoadingItems,
		setConfirmModal,
	});

	const getConnectedDataBoxesList = useDataBoxStore(
		'getConnectedDataBoxesList'
	);
	const connectedDataBoxes = getConnectedDataBoxesList();

	const disableNewMessageButton = !connectedDataBoxes.some(
		(dataBox) => dataBox.datovaSchrankaId === dataBoxId
	);

	const mySubmissionConnection = useMySubmissionConnection({ messageId });

	return (
		<DataBoxMessageDetailContext.Provider
			value={{
				dataBoxId,
				dataBox: currentDataBox,
				recipientDataBox,
				senderDataBox,
				folderType,
				messageId,
				message,
				isLoading:
					isLoading ||
					messageOperations.isLoading ||
					mySubmissionLanguage.isLoading,
				loadingItems,
				messageOperations,
				confirmModal,
				setConfirmModal,
				showAllAttachments,
				setShowAllAttachments,
				disableNewMessageButton,
				mySubmissionConnection,
			}}>
			{children}
		</DataBoxMessageDetailContext.Provider>
	);
}

export const useDataBoxMessageDetailContext =
	(): DataBoxMessageDetailContextType =>
		useContext(DataBoxMessageDetailContext) as DataBoxMessageDetailContextType;
