import {
	OsobyOsobaDto,
	OsobyPodnikatelRozsireneDto,
} from '@gov-nx/api/portal-obcana';
import { is } from '@gov-nx/core/types';
import { nameFormatting } from '@gov-nx/utils/common';

export const getName = (data?: OsobyPodnikatelRozsireneDto) => {
	switch (data?.typ) {
		case 'PFO':
			return data?.jmeno && data.prijmeni
				? nameFormatting(data.jmeno, data.prijmeni)
				: undefined;
		case 'PO':
			return data.nazevSpolecnosti;
	}
};

export const formatPersonLabel = (person: OsobyOsobaDto) => {
	if (person?.jmeno && person?.prijmeni) {
		return nameFormatting(person.jmeno, person.prijmeni);
	}

	if (person?.ico || person?.nazevSpolecnosti) {
		return [person.ico, person.nazevSpolecnosti].filter(is).join(', ');
	}

	if (person?.prijmeni) {
		return person.prijmeni;
	}

	return '-';
};
