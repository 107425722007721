import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { propEq } from '@gov-nx/core/types';
import { useDataBoxStore, usePersonStore } from '@gov-nx/store/portal-obcana';

const hasDisconnectedDataBox =
	(disconnectedDataBoxes: DatovkaApiType[]) =>
	(type: DatovkaApiType['typSchranky']) =>
		disconnectedDataBoxes.filter(propEq('typSchranky', type)).length > 0;

export const useApplicantSelector = () => {
	const requiredDataBoxes: DatovkaApiType['typSchranky'][] = [
		'FO',
		'PFO',
		'PO',
	];
	const getConnectedDataboxesListByTypes = useDataBoxStore(
		'getConnectedDataboxesListByTypes'
	);
	const getDataBoxByType = useDataBoxStore('getDataBoxByType');
	const dataBoxes = getConnectedDataboxesListByTypes(requiredDataBoxes);
	const dataBoxFO = getDataBoxByType('FO');
	const individualPerson = usePersonStore('individual');
	const poBusinessDataPO = usePersonStore('poBusinessDataPO');
	const poBusinessDataPFO = usePersonStore('poBusinessDataPFO');
	const dataBoxesPO = poBusinessDataPO();
	const dataBoxPFO = poBusinessDataPFO();
	const getDisconnectedDataBoxes = useDataBoxStore('getDisconnectedDataBoxes');
	const hasDisconnectedDS = hasDisconnectedDataBox(getDisconnectedDataBoxes());

	return {
		dataBoxes,
		dataBoxesPO,
		dataBoxPFO,
		dataBoxFO,
		individualPerson,
		hasDisconnectedDS,
	};
};
