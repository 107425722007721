import { useDownloadEvents } from '@gov-nx/core/events';
import { useLocale } from '@gov-nx/core/service';
import { useMojeAutorizaceDetailContext } from '@gov-nx/module/page';
import { Attachment, DocumentDownloadContainer } from '@gov-nx/ui/web';

export const Form602Attachments = () => {
	const { code, attachments } = useMojeAutorizaceDetailContext();
	const { t } = useLocale(code);
	const { downloadForm602Attachment } = useDownloadEvents();

	if (!attachments?.length) return null;

	return (
		<DocumentDownloadContainer label={t('prilohy.akce.stahnout')}>
			{attachments.map((attachment) => {
				return (
					<Attachment
						key={attachment.dokumentId}
						onDownload={(onSuccess) => {
							downloadForm602Attachment({
								formularPodaniId: attachment.dokumentId,
								callback: onSuccess,
							});
						}}
						downloadLabel={t('prilohy.akce.stahnout')}
						name={attachment.nazev}
						size={attachment.velikost}
					/>
				);
			})}
		</DocumentDownloadContainer>
	);
};
