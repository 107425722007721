import {
	GovChip,
	GovEmpty,
	GovIcon,
	GovPagination,
} from '@gov-design-system-ce/react';
import { useScroll } from '@gov-nx/web';
import React from 'react';
import { CmsSignpost } from '@gov-nx/api/common';
import { parseIcon } from '@gov-nx/component/common';
import { useCmsEvents } from '@gov-nx/core/events';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { isNumber, Optional } from '@gov-nx/core/types';
import {
	getPageUrlByCode,
	PageCode,
	useRozcestnikContext,
} from '@gov-nx/module/page';
import { getServiceUrlByCode } from '@gov-nx/module/service';
import { useConfigurationStore } from '@gov-nx/store/portal-obcana';
import { BadgeNav, ContentLayout, HeaderLayout } from '@gov-nx/ui/web';
import { FavoriteToggle } from '../../Favorite/FavoriteToggle';
import { MarkdownRender } from '../../Markdown/MarkdownRender';
import { Tile, TileButtonProps, TileLinkProps, Tiles } from '../../Tile/Tile';
import { SignpostSearch } from './SignpostSearch';
import { SignpostSkeleton } from './SignpostSkeleton';

export interface SignpostViewProps {
	code: PageCode;
}

export const SignpostView = ({ code }: SignpostViewProps) => {
	const { scrollToTop } = useScroll();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(PageCode['rozcestnik']);
	const getBaseCmsUrlPath = useConfigurationStore('getBaseCmsUrlPath');
	const urlCmsPath = getBaseCmsUrlPath();
	const {
		data,
		isSignpostDataFetching,
		groups,
		filter,
		numberOfSignposts,
		favorites,
		goToPage,
		setFilterFavourites,
		setFilterAll,
		setFilterActiveGroup,
	} = useRozcestnikContext();
	const { cmsExternalServiceModal } = useCmsEvents();
	const linkResolver = (signpost: CmsSignpost): string => {
		if (signpost.pageCode) {
			return getPageUrlByCode(signpost.pageCode) ?? '#';
		} else if (signpost.serviceCode) {
			return getServiceUrlByCode(signpost.serviceCode) ?? '#';
		}
		return '#';
	};

	const actionResolver = (
		signpost: CmsSignpost
	): Optional<TileLinkProps | TileButtonProps> => {
		if (signpost.pageCode || signpost.serviceCode) {
			return { to: linkResolver(signpost) };
		} else if (isNumber(signpost.externalServiceId)) {
			return {
				onClick: () =>
					cmsExternalServiceModal({ id: signpost.externalServiceId as number }),
			};
		}
		return;
	};
	return (
		<ContentLayout customClasses={'px-0 mx-0'}>
			<HeaderLayout className={'!block'}>
				<SignpostSearch code={code} />
			</HeaderLayout>
			<div className={'my-4'}>
				<BadgeNav
					moreBadges={true}
					wcagLabel={tsn('filtr.kategorie')}
					customClasses={'pt-1 pl-1'}>
					<li>
						<GovChip
							variant={'primary'}
							onGov-click={() => setFilterAll()}
							tag={'button'}
							size="m"
							inverse={
								!(filter.favourites === false && filter.serviceGroups === null)
							}
							wcagLabel={tsn('filtr.wcag.vse')}>
							<span>{tsn('filtr.vse')}</span>
						</GovChip>
					</li>
					<li>
						<GovChip
							variant={'primary'}
							onGov-click={() => setFilterFavourites()}
							tag={'button'}
							size="m"
							identifier={'favorites-toggle'}
							iconLeft={favorites.length ? 'basic/star-fill' : 'basic/star'}
							inverse={!filter.favourites}
							wcagLabel={tsn('filtr.wcag.oblibene')}>
							<span>{tsn('filtr.oblibene')}</span>
						</GovChip>
					</li>
					{groups.map((group) => {
						return (
							<li key={group.label}>
								<GovChip
									variant={'primary'}
									onGov-click={() => setFilterActiveGroup(group.id)}
									tag={'button'}
									size="m"
									inverse={filter.serviceGroups !== group.id}
									wcagLabel={group.label}>
									{group.label}
								</GovChip>
							</li>
						);
					})}
				</BadgeNav>
			</div>
			<section>
				{isSignpostDataFetching ? (
					<SignpostSkeleton />
				) : data.length ? (
					<Tiles>
						{data.map((signpost) => {
							const code =
								signpost.pageCode ||
								signpost.serviceCode ||
								signpost.externalServiceCode ||
								null;
							if (!signpost.name) return null;

							const action = actionResolver(signpost);
							if (!action) return null;

							return (
								<Tile
									key={signpost.id}
									{...action}
									title={signpost.name}
									icon={
										signpost.icon?.file ? (
											<img
												src={urlCmsPath + signpost.icon?.file}
												alt=""
												className={'[ md:w-10 ]'}
											/>
										) : (
											<GovIcon
												{...(signpost.icon?.icon
													? parseIcon(signpost.icon.icon)
													: { name: undefined, type: undefined })}
												slot="icon"
												className={'!text-primary-600 [ md:w-10 ]'}></GovIcon>
										)
									}
									favoriteToggle={
										code ? (
											<FavoriteToggle
												size={'s'}
												code={code}
												name={signpost.name}
											/>
										) : undefined
									}>
									{signpost.description ? (
										<div className={'text-secondary-700'}>
											<MarkdownRender className={'signpost-description'}>
												{signpost.description}
											</MarkdownRender>
										</div>
									) : null}
								</Tile>
							);
						})}
					</Tiles>
				) : (
					<GovEmpty className={'text-center'}>
						<p className={'gov-text--2xl'}>{tsn('chyba.nic-jsme-nenasli')}</p>
						<p className={'gov-text--m gov-color--secondary-700'}>
							{tsn('chyba.nepodarilo-se-najit-zadnou-sluzbu')}
						</p>
						<GovIcon
							type="complex"
							name="cactus"
							slot="icon"></GovIcon>
					</GovEmpty>
				)}
			</section>
			{numberOfSignposts > filter.perPage ? (
				<footer className={'mt-6'}>
					<GovPagination
						pageSize={filter.perPage}
						current={filter.page}
						onGov-page={(event) => {
							scrollToTop();
							goToPage(event.detail.pagination.currentPage);
						}}
						wcag-label={tsn('wcag.strankovani')}
						total={numberOfSignposts}></GovPagination>
				</footer>
			) : null}
		</ContentLayout>
	);
};
