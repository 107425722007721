import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { CmsSignpost } from '@gov-nx/api/common';
import { prop } from '@gov-nx/core/types';
import {
	PERSIST_ZUSTAND_STORE_KEY,
	StoreKeys,
	storeGlobalConfig,
} from '../constants';
import { SignpostState } from './store.types';

const initialState = {
	signposts: [],
};

const useSignpostStoreBase = create<SignpostState>()(
	persist(
		(set) => ({
			...initialState,

			saveSignposts: (signposts: CmsSignpost[]) => set({ signposts }),
		}),
		{
			...storeGlobalConfig,
			name: PERSIST_ZUSTAND_STORE_KEY + ':' + StoreKeys.signpost,
		}
	)
);

export const useSignpostStore = <T extends keyof SignpostState>(
	property: T
): SignpostState[T] => useSignpostStoreBase(prop(property));
