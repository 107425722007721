import { useQueryClient, UseQueryResult } from '@tanstack/react-query';
import React, { createContext, useContext, useEffect } from 'react';
import {
	ePetitionCreateQuery,
	ePetitionDeleteQuery,
	ePetitionDetailQuery,
	ePetitionMembersQuery,
	ePetitionUpdateQuery,
	PeticeClenPeticnihoVyboruSeznamDto,
	PeticePeticeDto,
	usePoMutation,
	usePoQueries,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	useApplicantLoader,
	useCountryCodes,
	usePersonCommunicationData,
	useProcessControl,
	useWizard,
} from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { has, Nullable } from '@gov-nx/core/types';
import { useBoolean } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import { useROSCommunicationQuery } from '../../nastaveni/kontaktni-udaje-ros/useROSCommunicationQuery';
import {
	FormInstanceStep1,
	FormInstancePOStep2,
	prepareSubmitData,
	FormInstanceFOStep2,
} from '../FormDefinitions';
import { isPetitionEditable } from '../detail/utils';
import { EPetitionNewControls } from '../nova/context.types';
import { useAddressLookup } from '../useAddressLookup';
import { usePetitionAttachments } from '../usePetitionAttachments';
import { usePetitionMemberAddressLookup } from '../usePetitionMemberAddressLookup';
import { EPeticeFormData } from '../wizzard.types';
import { EPetitionEditContext } from './context.types';
import { setContactDetailsValues, setPetitionDetailsValues } from './formUtils';

const EPeticeEditContext = createContext<Nullable<EPetitionEditContext>>(null);

interface EPeticeEditContextProviderProps {
	petitionId: number;
	children: React.ReactNode;
	code: PageCode;
}

export const EPeticeEditContextProvider = ({
	petitionId,
	code,
	children,
}: EPeticeEditContextProviderProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { toastMessageSuccess } = useMessageEvents();

	const { setControls, controls } = useProcessControl<EPetitionNewControls>({
		initialLoading: true,
		createLoading: false,
		saveLoading: false,
		deleteLoading: false,
	});
	const queryClient = useQueryClient();
	const personCommunicationData = usePersonCommunicationData();
	const petitionAttachments = usePetitionAttachments(petitionId);

	const formStep1 = FormInstanceStep1({ code });
	const formStep2PO = FormInstancePOStep2({
		code,
		petitionAttachmentsOptions: petitionAttachments.options,
	});
	const formStep2FO = FormInstanceFOStep2({
		code,
		petitionAttachmentsOptions: petitionAttachments.options,
	});

	const addressLookup = useAddressLookup({
		code,
		onSubmit: ({ address }) => {
			formStep2FO.formDefinition.formMethods.setValue('adresa', address);
			personCommunicationData.control.isEditingAddress.setFalse();
		},
	});

	const [petitionQuery, petitionMembersQuery] = usePoQueries<
		[
			UseQueryResult<PeticePeticeDto>,
			UseQueryResult<PeticeClenPeticnihoVyboruSeznamDto>
		]
	>({
		queries: [
			{
				queryKey: ['epetice-detail', petitionId],
				queryFn: () => ePetitionDetailQuery(petitionId),
				refetchOnWindowFocus: false,
				retry: 0,
				onError: (initialError: unknown) => {
					setControls({
						initialLoading: false,
						initialError: initialError as GovError,
					});
				},
			},
			{
				queryKey: ['epetice-members', petitionId],
				queryFn: () => ePetitionMembersQuery(petitionId),
				refetchOnWindowFocus: false,
				retry: 0,
				onError: (initialError: unknown) => {
					setControls({
						initialLoading: false,
						initialError: initialError as GovError,
					});
				},
			},
		],
	});
	const rosCommunicationQuery = useROSCommunicationQuery();

	const { isFetched, stringToPhoneShape } = useCountryCodes();

	const zakladatelTyp = petitionQuery.data?.zakladatel
		? petitionQuery.data.zakladatel.pravnickaOsoba
			? 'PO'
			: 'FO'
		: undefined;

	const applicant = useApplicantLoader({
		onError: (initialError) => {
			setControls({ initialLoading: false, initialError });
		},
		onLoaded: () => null,
	});

	const setFormValues = ({
		zakladatel,
		nazev,
		perex,
		text,
		adresat,
		kontaktniUdaje,
	}: PeticePeticeDto) => {
		nazev && formStep1.formMethods.setValue('nazev', nazev);
		zakladatelTyp &&
			formStep1.formMethods.setValue('zakladatelTyp', zakladatelTyp);

		if (formStep2FO && zakladatelTyp === 'FO') {
			setPetitionDetailsValues(formStep2FO.formDefinition, {
				perex,
				text,
				adresat,
			});

			const phone =
				kontaktniUdaje?.telefon ?? personCommunicationData.phoneVerified;

			setContactDetailsValues(formStep2FO.formDefinition, {
				_useEmail: !!kontaktniUdaje?.email,
				email: kontaktniUdaje?.email ?? personCommunicationData.emailVerified,
				_usePhone: !!kontaktniUdaje?.telefon,
				phone: phone ? stringToPhoneShape(phone) : undefined,
			});

			formStep2FO.formDefinition.formMethods.setValue(
				'_useAddress',
				!!kontaktniUdaje?.adresa?.kod
			);
			kontaktniUdaje?.adresa?.kod &&
				formStep2FO.formDefinition.formMethods.setValue(
					'adresa',
					kontaktniUdaje?.adresa
				);
			kontaktniUdaje?.adresa?.kod &&
				formStep2FO.formDefinition.formMethods.setValue(
					'adresa.kod',
					kontaktniUdaje?.adresa.kod
				);
			kontaktniUdaje?.adresa?.kod &&
				formStep2FO.formDefinition.formMethods.setValue(
					'adresa.adresaText',
					kontaktniUdaje?.adresa.adresaText
				);
		}

		if (zakladatelTyp === 'PO') {
			setPetitionDetailsValues(formStep2PO.formDefinition, {
				perex,
				text,
				adresat,
			});

			const ico =
				zakladatel?.pravnickaOsoba?.ico ??
				applicant.businessPerson?.seznam?.[0].ico;

			ico &&
				formStep2PO.formDefinition.formMethods.setValue('zakladatelPoIco', ico);

			if (ico) {
				const contact = rosCommunicationQuery.getRosContact(ico);
				const phone = kontaktniUdaje?.telefon ?? contact?.telefonniCislo;

				setContactDetailsValues(formStep2PO.formDefinition, {
					_useEmail: !!kontaktniUdaje?.email,
					_usePhone: !!kontaktniUdaje?.telefon,
					email: kontaktniUdaje?.email ?? contact?.email,
					phone: phone ? stringToPhoneShape(phone) : undefined,
				});
			}
		}
	};

	const setPetitionMembers = ({
		seznam,
	}: PeticeClenPeticnihoVyboruSeznamDto) => {
		formStep2FO.formDefinition.formMethods.setValue(
			'clenovePeticnihoVyboru',
			seznam ?? []
		);
	};

	useEffect(() => {
		if (
			petitionQuery.data &&
			zakladatelTyp &&
			!controls.initialLoading &&
			rosCommunicationQuery.isFetched
		) {
			setFormValues(petitionQuery.data);
		}
	}, [
		zakladatelTyp,
		petitionQuery.data,
		controls.initialLoading,
		rosCommunicationQuery.isFetched,
		applicant.businessPerson?.seznam,
	]);

	useEffect(() => {
		if (petitionMembersQuery.data) {
			setPetitionMembers(petitionMembersQuery.data);
		}
	}, [petitionMembersQuery.data]);

	useEffect(() => {
		if (
			petitionQuery.isFetched &&
			petitionMembersQuery.isFetched &&
			isFetched &&
			applicant.individualPerson &&
			applicant.businessPerson &&
			controls.initialLoading
		) {
			setControls({ initialLoading: false });
		}
	}, [
		petitionQuery.isFetched,
		petitionMembersQuery.isFetched,
		isFetched,
		controls.initialLoading,
		applicant.individualPerson,
		applicant.businessPerson,
	]);

	const deleteMutation = usePoMutation({
		mutationFn: async () => {
			setControls({ processError: null, deleteLoading: true });
			return ePetitionDeleteQuery(petitionId);
		},
		onSuccess: async () => {
			toastMessageSuccess(tsn('formular.zprava.smazano'));

			await queryClient.invalidateQueries({ queryKey: ['epetice-seznam'] });
			wizard.resetForms();
			setControls({ deleteLoading: false });
		},
		onError: (error) => {
			setControls({ processError: error, deleteLoading: false });
		},
	});

	const saveMutation = usePoMutation({
		mutationFn: async () => {
			setControls({ processError: null, saveLoading: true });

			return ePetitionUpdateQuery({
				petitionId,
				body: prepareSubmitData(
					formStep1.formMethods.getValues(),
					formStep2?.formDefinition.formMethods.getValues()
				),
			});
		},
		onSuccess: async () => {
			toastMessageSuccess(tsn('formular.zprava.ulozeno'));

			await queryClient.invalidateQueries({ queryKey: ['epetice-seznam'] });
			await queryClient.invalidateQueries({
				queryKey: ['epetice-detail', petitionId],
			});
			await queryClient.invalidateQueries({
				queryKey: ['epetice-members', petitionId],
			});
			await petitionAttachments.invalidate();

			setControls({ saveLoading: false });
		},
		onError: (error) => {
			setControls({ processError: error, saveLoading: false });
		},
	});

	const createMutation = usePoMutation({
		mutationFn: async () => {
			setControls({ processError: null, createLoading: true });
			await ePetitionUpdateQuery({
				petitionId,
				body: prepareSubmitData(
					formStep1.formMethods.getValues(),
					formStep2?.formDefinition.formMethods.getValues()
				),
			});

			return ePetitionCreateQuery(petitionId);
		},
		onSuccess: async () => {
			toastMessageSuccess(tsn('formular.zprava.zalozeno'));
			setControls({ createLoading: false });

			await queryClient.invalidateQueries({ queryKey: ['epetice-seznam'] });
			await queryClient.invalidateQueries({
				queryKey: ['epetice-detail', petitionId],
			});
			await queryClient.invalidateQueries({
				queryKey: ['epetice-members', petitionId],
			});
			await petitionAttachments.invalidate();

			wizard.resetForms();
		},
		onError: (error) => {
			setControls({ processError: error, createLoading: false });
		},
	});

	const petitionMemberAddressLookup = usePetitionMemberAddressLookup({
		code,
		onSubmit: ({ formData, address }) => {
			isPetitionMemberAddressLookupModalOpen.setFalse();

			const existing =
				formStep2FO.formDefinition.formMethods.getValues(
					'clenovePeticnihoVyboru'
				) ?? [];

			formStep2FO.formDefinition.formMethods.setValue(
				'clenovePeticnihoVyboru',
				[
					...existing,
					{
						jmeno: formData.jmeno,
						prijmeni: formData.prijmeni,
						adresa: {
							kod: address?.kod,
							adresaText: address?.adresaText,
						},
					},
				]
			);
		},
	});

	const formStep2 = zakladatelTyp
		? zakladatelTyp === 'PO'
			? formStep2PO
			: formStep2FO
		: undefined;

	const wizard = useWizard<EPeticeFormData>({
		initialIndexOpen: [1],
		steps: [formStep1, formStep2?.formDefinition ?? {}, {}, {}],
		onSubmit: async (_, onSuccess) =>
			createMutation.mutate(undefined, {
				onSuccess,
			}),
		onStepInvalid: (index, errors) => {
			if (index === 1) {
				personCommunicationData.onFormValidated(errors);
			}
		},
	});

	const selectedIcNumber =
		zakladatelTyp === 'PO'
			? formStep2PO?.formDefinition.formMethods.watch('zakladatelPoIco')
			: undefined;

	const selectIcNumberOptions =
		applicant.businessPerson?.seznam?.filter(has('ico')).map((subject) => ({
			value: subject.ico,
			label: `${subject.nazevSpolecnosti}, IČ: ${subject.ico}`,
		})) ?? [];

	const isPetitionMemberAddressLookupModalOpen = useBoolean(false);
	const isSubmitConfirmModalOpen = useBoolean(false);
	const isDeleteConfirmModalOpen = useBoolean(false);

	useEffect(() => {
		if (!isPetitionMemberAddressLookupModalOpen.value) {
			petitionMemberAddressLookup.formDefinition.formReset();
		}
	}, [
		isPetitionMemberAddressLookupModalOpen.value,
		petitionMemberAddressLookup.formDefinition.formMethods,
	]);

	useEffect(() => {
		if (
			selectedIcNumber &&
			formStep2PO?.formDefinition.formMethods.getFieldState('zakladatelPoIco')
				.isDirty
		) {
			const contact = rosCommunicationQuery.getRosContact(selectedIcNumber);

			setContactDetailsValues(formStep2PO.formDefinition, {
				_useEmail: !!contact?.email,
				_usePhone: !!contact?.telefonniCislo,
				email: contact?.email,
				phone: contact?.telefonniCislo
					? stringToPhoneShape(contact.telefonniCislo)
					: undefined,
			});
		}
	}, [selectedIcNumber]);

	const email =
		zakladatelTyp === 'FO'
			? formStep2FO?.formDefinition.formMethods.watch('email')
			: formStep2PO?.formDefinition.formMethods.watch('email');

	const phone =
		zakladatelTyp === 'FO'
			? formStep2FO?.formDefinition.formMethods.watch('phone')
			: formStep2PO?.formDefinition.formMethods.watch('phone');

	const resetEmailField = () => {
		formStep2FO.formDefinition.formMethods.resetField('email');
		formStep2PO.formDefinition.formMethods.resetField('email');
	};
	const resetPhoneField = () => {
		formStep2FO.formDefinition.formMethods.resetField('phone');
		formStep2PO.formDefinition.formMethods.resetField('phone');
	};

	return (
		<EPeticeEditContext.Provider
			value={{
				...(zakladatelTyp === 'PO'
					? {
							zakladatelTyp: 'PO',
							formStep2: formStep2PO,
							selectedIcNumber,
							selectIcNumberOptions,
					  }
					: { zakladatelTyp: 'FO', formStep2: formStep2FO }),
				code,
				isProcessing:
					controls.processLoading ||
					controls.saveLoading ||
					controls.createLoading ||
					controls.deleteLoading,
				wizard,
				controls,
				petitionId,
				setControls,
				applicant,
				personCommunicationData,
				isEditable: petitionQuery.data
					? isPetitionEditable(petitionQuery.data)
					: false,

				onSave: (onSuccess: () => void) => {
					saveMutation.mutate(undefined, {
						onSuccess,
					});
				},
				onDelete: (onSuccess: () => void) =>
					deleteMutation.mutate(undefined, { onSuccess }),
				onRemoveMember: (index: number) => {
					const existing =
						formStep2FO.formDefinition.formMethods.getValues(
							'clenovePeticnihoVyboru'
						) ?? [];

					formStep2FO.formDefinition.formMethods.setValue(
						'clenovePeticnihoVyboru',
						existing.filter((_, memberIndex) => memberIndex !== index)
					);
				},
				petitionAttachments,

				formStep1,

				addressLookup,
				petitionMemberAddressLookup,
				isPetitionMemberAddressLookupModalOpen,
				isSubmitConfirmModalOpen,
				isDeleteConfirmModalOpen,
				email,
				phone,
				resetEmailField,
				resetPhoneField,
			}}>
			{children}
		</EPeticeEditContext.Provider>
	);
};

export const useEPeticeEditContext = (): EPetitionEditContext =>
	useContext(EPeticeEditContext) as EPetitionEditContext;
