import { GovIcon } from '@gov-design-system-ce/react';
import React from 'react';
import { PersonCommunicationData } from '@gov-nx/core/hooks';
import { LocalizeNameSpaceTypes, useLocale } from '@gov-nx/core/service';
import { CheckboxProps, WebFormInputProps } from '@gov-nx/ui/types';
import {
	Button,
	FormCheckbox,
	FormInput,
	FormWidthElementLayout,
} from '@gov-nx/ui/web';

interface UseCommunicationDataAddressProps {
	fields: {
		_useAddress: CheckboxProps;
		address: WebFormInputProps;
	};
	wcagLabels: {
		edit: string;
		cancel: string;
	};
	personCommunicationData: PersonCommunicationData;
	onReset: () => void;
	address?: string;
}

export const UseCommunicationDataAddress = ({
	personCommunicationData,
	onReset,
	wcagLabels,
	address,
	fields,
}: UseCommunicationDataAddressProps) => {
	const { t } = useLocale(LocalizeNameSpaceTypes.Form);

	return (
		<div className={'flex flex-col gap-1 [ md:flex-row md:items-center ]'}>
			<FormCheckbox field={fields._useAddress} />
			{personCommunicationData.control.isEditingAddress.value ? (
				<div className={'flex flex-row items-center gap-2 [ md:w-1/2 ]'}>
					<FormWidthElementLayout type={'m'}>
						<FormInput
							field={fields.address}
							label={{}}
						/>
					</FormWidthElementLayout>
					{personCommunicationData.address && (
						<Button
							variant={'primary'}
							type={'base'}
							onClick={() => {
								onReset();
							}}
							wcagLabel={wcagLabels.cancel}>
							<GovIcon
								name="x-lg"
								slot="right-icon"
							/>
						</Button>
					)}
				</div>
			) : (
				<div className={'inline-flex items-center gap-2 mb-1'}>
					<strong>{address ? address : <i>{t('empty')}</i>}</strong>
					<Button
						onClick={personCommunicationData.control.isEditingAddress.setTrue}
						wcagLabel={wcagLabels.edit}
						variant={'primary'}
						size={'s'}
						type={'base'}>
						<GovIcon
							name="pencil-square"
							slot="right-icon"
						/>
					</Button>
				</div>
			)}
		</div>
	);
};
