import React, { createContext, useContext } from 'react';
import '@gov-nx/api/portal-obcana';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '../definitions/codes';
import { ROSContext } from './context.types';
import { useRosExtendedQuery } from './useRosExtendedQuery';

const ProfilROSContext = createContext<Nullable<ROSContext>>(null);

interface SettingsROSContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
}

export function RegistrOsobContextProvider({
	children,
	code,
}: SettingsROSContextProviderProps) {
	const {
		isLoading,
		error,
		extendedData,
		PFOData,
		POData,
		personInvolvementData,
	} = useRosExtendedQuery();

	return (
		<ProfilROSContext.Provider
			value={{
				code,
				controls: {
					initialLoading: isLoading,
					initialError: error,
				},
				extendedData,
				PFOData,
				POData,
				personInvolvementData,
			}}>
			{children}
		</ProfilROSContext.Provider>
	);
}

export const useProfilROSContextInstance = (): ROSContext =>
	useContext(ProfilROSContext) as ROSContext;
