import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes, useGlobalLocale } from '@gov-nx/core/service';
import { LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

const numberOfColumn = 3;
const faqItemsCount = generateRandomNumber(3, 10);

export interface CmsPageSkeletonProps {
	children: React.ReactNode;
}

export const CmsPageSkeleton = ({ children }: CmsPageSkeletonProps) => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Page);
	const { t: tg } = useGlobalLocale();

	return (
		<div className={'gov-layout py-6'}>
			<div className={'gov-layout__section gov-layout__section-text-left'}>
				<div className={'gov-layout-column'}>
					<main className={'flex flex-col gap-4'}>
						{/* Page heading */}
						<GovSkeleton
							height={'37px'}
							wcagLabel={tg('loading.wcag.processing')}
							width={generateRandomNumber(20, 60) + '%'}
							className={'mt-6 skeleton-brighter'}
						/>
						<div>
							{/* CMS Description heading */}
							<GovSkeleton
								height={'24px'}
								wcagLabel={tg('loading.wcag.processing')}
								width={generateRandomNumber(15, 30) + '%'}
								className={'mt-6 mb-4 skeleton-brighter'}
							/>
						</div>
						<LayoutGap>{children}</LayoutGap>
					</main>
					<LayoutGap gap={10}>
						<section className={'mt-20'}>
							<h2 className={'mb-4 text-4xl text-secondary-600'}>
								{t('mozna-hledate', { namespace: LocalizeNameSpaceTypes.Page })}
							</h2>
							<div className={'grid gap-10 [ md:grid-cols-3 ]'}>
								{/* CMS Related Items */}
								{Array(numberOfColumn)
									.fill({})
									.map((_, index) => (
										// eslint-disable-next-line react/no-array-index-key
										<div key={index}>
											<GovSkeleton
												height={'48px'}
												shape={'circle'}
												wcagLabel={tg('loading.wcag.processing')}
												width={'48px'}
												className={'mb-2 skeleton-brighter'}
											/>
											<div className={'flex flex-col'}>
												<GovSkeleton
													height={'30px'}
													shape={'text'}
													wcagLabel={tg('loading.wcag.processing')}
													width={'100%'}
													className={'skeleton-brighter'}
												/>
												<GovSkeleton
													height={'30px'}
													shape={'text'}
													wcagLabel={tg('loading.wcag.processing')}
													width={generateRandomNumber(25, 65) + '%'}
													className={'mb-4 skeleton-brighter'}
												/>
											</div>
										</div>
									))}
							</div>
						</section>
						<section>
							<h2 className={'mb-4 text-4xl text-secondary-600'}>
								{t('casto-se-ptate', {
									namespace: LocalizeNameSpaceTypes.Page,
								})}
							</h2>
							{/* CMS - FAQ items	*/}
							{Array(faqItemsCount)
								.fill({})
								.map((_, index) => (
									<div
										// eslint-disable-next-line react/no-array-index-key
										key={index}
										className={'pt-4 pb-2.5'}>
										<GovSkeleton
											height={'36px'}
											wcagLabel={tg('loading.wcag.processing')}
											width={generateRandomNumber(25, 85) + '%'}
											className={'mb-0 skeleton-brighter'}
										/>
									</div>
								))}
						</section>
					</LayoutGap>
				</div>
			</div>
		</div>
	);
};
