import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { countryQuery } from '@gov-nx/api/common';
import {
	useConfigurationStore,
	useListsStore,
} from '@gov-nx/store/portal-obcana';

export interface CountryLoaderProps {
	onSuccess?: () => void;
	onError?: (e: Error) => void;
}

export const useCountryLoader = (props?: CountryLoaderProps) => {
	const saveCountryList = useListsStore('saveCountryList');
	const countriesState = useListsStore('countries');
	const countries = countriesState || [];
	const getBaseCmsUrlPath = useConfigurationStore('getBaseCmsUrlPath');
	const cmsPath = getBaseCmsUrlPath();

	const query = useQuery({
		queryKey: ['countries'],
		queryFn: () => countryQuery(cmsPath as string),
		retry: 1,
		enabled: false,
		refetchOnWindowFocus: false,
		onError: (e) => {
			if (props && props.onError) {
				props.onError(e as Error);
			}
		},
		onSuccess: (data) => {
			saveCountryList(data);
			if (props && props.onSuccess) {
				props.onSuccess();
			}
		},
	});

	const loadCountries = useCallback(async (): Promise<void> => {
		if (Array.isArray(countries) && countries.length) {
			return Promise.resolve();
		}
		await query.refetch();
	}, [countries, query]);

	return { loadCountries };
};
