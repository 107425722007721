import { AxiosResponse } from 'axios';
import { Optional } from '@gov-nx/core/types';
import {
	axiosInstance,
	PeticeClenPeticnihoVyboruSeznamDto,
	PeticeOvmSeznamDto,
	PeticePeticeDto,
	PeticePeticeSeznamDto,
	PeticePrilohaSeznamDto,
} from '..';

export interface EPetitionListFilter {
	text: Optional<string>;
	mnouPodepsane: boolean;
	mnouZalozene: boolean;
	start: number;
	pocet: number;
	platnostOd: Optional<string> | null;
	platnostDo: Optional<string> | null;
}

export const ePetitionQuery = async (
	filter: EPetitionListFilter
): Promise<PeticePeticeSeznamDto> => {
	const { data } = await axiosInstance.get('/api/kompozitni/v1/petice', {
		params: filter,
	});
	return data;
};
export const ePetitionDetailQuery = async (
	petitionId: number
): Promise<PeticePeticeDto> => {
	const { data } = await axiosInstance.get(
		'/api/kompozitni/v1/petice/' + petitionId
	);
	return data;
};
export const ePetitionMembersQuery = async (
	petitionId: number
): Promise<PeticeClenPeticnihoVyboruSeznamDto> => {
	const { data } = await axiosInstance.get(
		'/api/kompozitni/v1/petice/' + petitionId + '/clenove-peticniho-vyboru'
	);
	return data;
};
export const ePetitionAttachmentsQuery = async (
	petitionId: number
): Promise<PeticePrilohaSeznamDto> => {
	const { data } = await axiosInstance.get(
		'/api/kompozitni/v1/petice/' + petitionId + '/prilohy'
	);
	return data;
};

export const ePetitionAttachmentsUpdateQuery = async ({
	petitionId,
	files,
}: {
	petitionId: number;
	files: [string];
}): Promise<number> => {
	const { data } = await axiosInstance.post(
		'/api/kompozitni/v1/petice/' + petitionId + '/prilohy',
		{
			soubory: files,
		},
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}
	);
	return data;
};
export const ePetitionSignQuery = async (petitionId: number): Promise<void> => {
	return axiosInstance.put(
		'/api/kompozitni/v1/petice/' + petitionId + '/podepsat'
	);
};
export const ePetitionRevokeQuery = async (
	petitionId: number
): Promise<void> => {
	return axiosInstance.put(
		'/api/kompozitni/v1/petice/' + petitionId + '/odvolat-podpis'
	);
};
export const ePetitionOvmDataBoxQuery = async (params: {
	text: string;
	pocet: number;
}): Promise<PeticeOvmSeznamDto> => {
	const { data } = await axiosInstance.get('/api/kompozitni/v1/ovm', {
		params,
	});
	return data;
};

export const ePetitionSetupQuery = async (body: {
	zakladatelTyp: 'FO' | 'PO';
	nazev: string;
}): Promise<number> => {
	const { data } = await axiosInstance.post('/api/kompozitni/v1/petice', body);
	return data;
};

export const ePetitionUpdateQuery = async <Body,>({
	petitionId,
	body,
}: {
	petitionId: number;
	body: Body;
}): Promise<number> => {
	const { data } = await axiosInstance.put(
		`/api/kompozitni/v1/petice/${petitionId}`,
		body
	);
	return data;
};

export const ePetitionDeleteQuery = async (
	petitionId: number
): Promise<number> => {
	const { data } = await axiosInstance.delete(
		`/api/kompozitni/v1/petice/${petitionId}`
	);
	return data;
};

export const ePetitionDeleteAttachmentQuery = async ({
	attachmentId,
	petitionId,
}: {
	petitionId: number;
	attachmentId: number;
}): Promise<number> => {
	const { data } = await axiosInstance.delete(
		`/api/kompozitni/v1/petice/${petitionId}/prilohy/${attachmentId}`
	);
	return data;
};

export const ePetitionCreateQuery = async (
	petitionId: number
): Promise<number> => {
	const { data } = await axiosInstance.put(
		`/api/kompozitni/v1/petice/${petitionId}/zalozit`
	);
	return data;
};

export const ePetitionExtendQuery = async (
	petitionId: number
): Promise<number> => {
	const { data } = await axiosInstance.put(
		`/api/kompozitni/v1/petice/${petitionId}/prodlouzit`
	);
	return data;
};

export const ePetitionCloseQuery = async (
	petitionId: number
): Promise<number> => {
	const { data } = await axiosInstance.put(
		`/api/kompozitni/v1/petice/${petitionId}/uzavrit`
	);
	return data;
};

export const ePetitionSubmitQuery = async (
	petitionId: number
): Promise<number> => {
	const { data } = await axiosInstance.put(
		`/api/kompozitni/v1/petice/${petitionId}/podat`
	);
	return data;
};

export const ePetitionCloseAndSubmitQuery = async (
	petitionId: number
): Promise<number> => {
	const { data } = await axiosInstance.put(
		`/api/kompozitni/v1/petice/${petitionId}/uzavrit-a-podat`
	);
	return data;
};

export const ePetitionDownloadQuery = async (petitionId: number) => {
	const data = await axiosInstance.get(
		`/api/kompozitni/v1/petice/${petitionId}/stahnout-vypis`,
		{
			responseType: 'blob',
		}
	);
	return data;
};

export const ePetitionAttachmentDownloadQuery = async ({
	petitionId,
	attachmentId,
}: {
	petitionId: number;
	attachmentId: number;
}): Promise<AxiosResponse<Blob>> => {
	const data = await axiosInstance.get(
		`api/kompozitni/v1/petice/${petitionId}/prilohy/${attachmentId}/stahnout`,
		{
			responseType: 'blob',
		}
	);
	return data;
};
