import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { LocalizeNameSpaceTypes, useLocale } from '@gov-nx/core/service';
import { compare, hasProperties } from '@gov-nx/core/types';
import { dataBoxLabel } from '@gov-nx/module/data-box';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import {
	DataBoxFormData,
	DataBoxOption,
	DataBoxSelectProps,
	FormInputProps,
} from '@gov-nx/ui/types';
import { getSelectDefinition, SelectDefinition } from './select';
import { InputDefinition, useInputDefinition } from './string';

export const dataBoxParams = <T extends DataBoxFormData>(
	formData: T
): DataBoxFormData => {
	return {
		datovaSchrankaId: formData.datovaSchrankaId,
		naseCisloJednaci: formData.naseCisloJednaci,
		naseSpisovaZnacka: formData.naseSpisovaZnacka,
	};
};

interface DataBoxShape {
	isRequired?: boolean;
	isRequiredMessage?: string;
	fileNumberField?: {
		maxLengthValidationCopy?: string;
	};
	referenceNumberField?: {
		maxLengthValidationCopy?: string;
	};
}

interface Props {
	types: DatovkaApiType['typSchranky'][];
}

export const useDataBoxShape = ({ types }: Props) => {
	const { t } = useTranslation([LocalizeNameSpaceTypes.Form]);

	const dataBoxes = useDataBoxStore('getConnectedDataboxesListByTypes')(types);
	const dataBoxesOptions = prepareDataBoxOptions(dataBoxes);

	return {
		dataBoxesOptions,
		getDataBoxDefaultValues: () => {
			const firstOption =
				dataBoxesOptions.length > 0
					? dataBoxesOptions[0].value.toString()
					: undefined;

			return {
				datovaSchrankaId: firstOption ? firstOption : undefined,
				naseCisloJednaci: undefined,
				naseSpisovaZnacka: undefined,
			};
		},
		getDataBoxShape: ({
			isRequired,
			isRequiredMessage,
			fileNumberField,
			referenceNumberField,
		}: DataBoxShape) => {
			let datovaSchrankaId = yup.string();
			if (isRequired) {
				datovaSchrankaId = datovaSchrankaId.required(
					isRequiredMessage ??
						t('data-box.validations.vyberte-datovou-schranku') ??
						undefined
				);
			}

			return {
				datovaSchrankaId,
				naseCisloJednaci: yup
					.string()
					.max(
						50,
						fileNumberField?.maxLengthValidationCopy ??
							t('data-box.messages.cislo-jednaci') ??
							undefined
					),
				naseSpisovaZnacka: yup
					.string()
					.max(
						50,
						referenceNumberField?.maxLengthValidationCopy ??
							t('data-box.messages.spisova-znacka') ??
							undefined
					),
			};
		},
	};
};

export const prepareDataBoxOptions = (
	dataBoxes: DatovkaApiType[]
): DataBoxOption[] => {
	return dataBoxes
		.filter(hasProperties(['datovaSchrankaId', 'typSchranky']))
		.map((box) => {
			return {
				type: box.typSchranky,
				value: box.datovaSchrankaId,
				label: dataBoxLabel(box),
			};
		})
		.sort(compare(['type', 'label']));
};

interface FieldProps extends DataBoxShape {
	name?: string;
	label: string;
	isDisabled?: boolean;
	fixedDataBoxId?: string;
	types: DatovkaApiType['typSchranky'][];
}

export interface DataBoxFields {
	datovaSchrankaId: DataBoxSelectProps;
	naseCisloJednaci: FormInputProps;
	naseSpisovaZnacka: FormInputProps;
}

export const useDataboxDefinition = (
	props: FieldProps
): [SelectDefinition, InputDefinition, InputDefinition] => {
	const { t } = useLocale(LocalizeNameSpaceTypes.Form);

	const { getDataBoxDefaultValues, dataBoxesOptions } = useDataBoxShape({
		types: props.types,
	});

	const defaultValues = getDataBoxDefaultValues();

	const filteredDataBoxesOptions = props.fixedDataBoxId
		? dataBoxesOptions.filter(
				(dataBox) => dataBox.value === props.fixedDataBoxId
		  )
		: dataBoxesOptions;

	const datovaSchrankaId = getSelectDefinition({
		name: props.name ?? 'datovaSchrankaId',
		label: props.label,
		isDisabled: props.isDisabled,
		options: filteredDataBoxesOptions,
		isRequired: props.isRequired,
		defaultValue: defaultValues.datovaSchrankaId,
		isRequiredMessage:
			props.isRequiredMessage ??
			t('data-box.validations.vyberte-datovou-schranku'),
	});

	const naseCisloJednaci = useInputDefinition({
		name: 'naseCisloJednaci',
		label: t('data-box.fields.cislo-jednaci'),
		placeholder: t('data-box.placeholders.cislo-jednaci'),
		isRequired: false,
		isDisabled: props.isDisabled,
		max: {
			max: 50,
			errorMessage:
				props.fileNumberField?.maxLengthValidationCopy ??
				t('data-box.messages.cislo-jednaci'),
		},
	});
	const naseSpisovaZnacka = useInputDefinition({
		name: 'naseSpisovaZnacka',
		label: t('data-box.fields.spisova-znacka'),
		placeholder: t('data-box.placeholders.spisova-znacka'),
		isRequired: false,
		isDisabled: props.isDisabled,
		max: {
			max: 50,
			errorMessage:
				props.referenceNumberField?.maxLengthValidationCopy ??
				t('data-box.messages.spisova-znacka'),
		},
	});

	return [datovaSchrankaId, naseCisloJednaci, naseSpisovaZnacka];
};
