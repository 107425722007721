import { useLocale } from '@gov-nx/core/service';
import {
	LocaleDefinition,
	LocaleNoParams,
	LocaleVariables,
} from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';

// TODO: Je mozny recyklovat uz nepouzivany preklad: 'seznam.validace.platnost.max'

type RezaLocaleObject = {
	akce: {
		filtrovat: {
			label: LocaleNoParams;
			wcag: LocaleNoParams;
		};
		'zrusit-filtr': {
			label: LocaleNoParams;
			wcag: LocaleNoParams;
		};
		'zobrazit-filtr': {
			wcag: LocaleNoParams;
		};
	};
	seznam: {
		item: {
			'platnost-od': {
				label: LocaleNoParams;
			};
			'platnost-do': {
				label: LocaleNoParams;
				empty: LocaleNoParams;
			};
			zmocnitel: LocaleNoParams;
			zmocnenec: LocaleNoParams;
			identifikator: LocaleNoParams;
		};
		search: {
			label: LocaleNoParams;
			placeholder: LocaleNoParams;
			button: {
				placeholder: LocaleNoParams;
			};
		};
		empty: LocaleNoParams;
		footer: LocaleVariables<'link'>;
		button: {
			reklamace: { label: LocaleNoParams };
		};
		filters: {
			'zahrnout-archivovane': LocaleNoParams;
			'zahrnout-archivovane-popis': LocaleNoParams;
			role: {
				zmocnitel: LocaleNoParams;
				zmocnenec: LocaleNoParams;
			};
			'platnost-od-od': LocaleNoParams;
			'platnost-od-do': LocaleNoParams;
			'platnost-do-od': LocaleNoParams;
			'platnost-do-do': LocaleNoParams;
			text: LocaleNoParams;
		};
		validace: {
			platnost: {
				min: LocaleVariables<'datum'>;
				// max: LocaleNoParams;
			};
			'platnost-do': {
				'starsi-nez-datum-od': LocaleNoParams;
			};
		};
		modal: {
			title: LocaleNoParams;
			'platnost-od': { label: LocaleNoParams };
			'platnost-do': { label: LocaleNoParams };
			'platnost-od-od': LocaleNoParams;
			'platnost-od-do': LocaleNoParams;
			'platnost-do-od': LocaleNoParams;
			'platnost-do-do': LocaleNoParams;
		};
	};
	detail: {
		implicitni: {
			title: LocaleNoParams;
		};
		explicitni: {
			title: LocaleNoParams;
		};
		buttons: {
			pdf: LocaleNoParams;
			reklamace: LocaleNoParams;
			ukoncit: LocaleNoParams;
			'zkopirovat-do-schranky': LocaleNoParams;
		};
		table: {
			identifikator: LocaleNoParams;
			zmocnitel: LocaleNoParams;
			zmocnenec: LocaleNoParams;
			'platnost-od': LocaleNoParams;
			'platnost-do': LocaleNoParams;
			'kod-sablony': LocaleNoParams;
			postupitelny: {
				klic: LocaleNoParams;
				ano: LocaleNoParams;
				ne: LocaleNoParams;
			};
		};
	};
	complaints: {
		title: LocaleNoParams;
		description: LocaleNoParams;
		form: {
			pole: {
				complaint: {
					label: LocaleNoParams;
					placeholder: LocaleNoParams;
					validace: { 'povinne-pole': LocaleNoParams };
				};
			};
			buttons: {
				submit: {
					label: LocaleNoParams;
					wcag: LocaleNoParams;
				};
			};
			odeslano: LocaleNoParams;
		};
	};
	invalidation: {
		title: LocaleNoParams;
		description: LocaleNoParams;
		form: {
			pole: {
				'termination-date': {
					label: LocaleNoParams;
					now: {
						label: LocaleNoParams;
					};
					'on-date': {
						label: LocaleNoParams;
					};
					validace: { 'povinne-pole': LocaleNoParams };
				};
				date: {
					label: LocaleNoParams;
				};
			};
			buttons: {
				submit: {
					label: LocaleNoParams;
					wcag: LocaleNoParams;
				};
			};
		};
	};
	sablony: {
		detail: {
			table: {
				identifikator: LocaleNoParams;
				verze: LocaleNoParams;
				'platnost-od': LocaleNoParams;
				'platnost-do': LocaleNoParams;
				'platnost-do-neomezeno': LocaleNoParams;
				popis: LocaleNoParams;
				postupitelny: {
					klic: LocaleNoParams;
					ano: LocaleNoParams;
					ne: LocaleNoParams;
				};
			};
		};
		seznam: {
			title: LocaleNoParams;
			item: {
				'platnost-od': {
					label: LocaleNoParams;
				};
				'platnost-do': {
					label: LocaleNoParams;
					empty: LocaleNoParams;
				};
				verze: LocaleNoParams;
				identifikator: LocaleNoParams;
			};

			empty: LocaleNoParams;
		};
		pole: {
			hledat: {
				label: LocaleNoParams;
				placeholder: LocaleNoParams;
			};
		};
		buttons: {
			submit: {
				wcag: LocaleNoParams;
			};
		};
	};
	toasts: {
		download: {
			success: LocaleVariables<'fileName'>;
			chyba: LocaleNoParams;
		};
	};
};

export const useRezaLocale = (): LocaleDefinition<RezaLocaleObject> =>
	useLocale(PageCode['reza']);
