import { GovPagination } from '@gov-design-system-ce/react';
import { useScroll } from '@gov-nx/web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	PageCode,
	useMojeOsvedceniSeznamContextInstance,
} from '@gov-nx/module/page';
import { ContentLayout, InfoBanner, Tile, TileIconSize } from '@gov-nx/ui/web';
import { MySubmissionsListSkeleton } from './MySubmissionsListSkeleton';
import { MyVerificationsListFilter } from './MyVerificationsListFilter';

export interface MyVerificationsViewProps {
	code: PageCode;
}

export const MyVerificationsList = ({ code }: MyVerificationsViewProps) => {
	const { scrollIntoView } = useScroll();
	const { t } = useTranslation([code]);
	const {
		verifications,
		filter,
		currentPage,
		goToPage,
		numberOfVerifications,
		query,
		handleVerificationDownload,
		controls,
	} = useMojeOsvedceniSeznamContextInstance();
	return (
		<ContentLayout customClasses={'mt-6'}>
			<MyVerificationsListFilter code={code} />
			{query.isFetching ? (
				<MySubmissionsListSkeleton />
			) : (
				<section>
					{numberOfVerifications ? (
						<>
							<ul className={'gov-list--plain mt-2 [ md:mt-8 ]'}>
								{verifications.map((verification) => {
									return (
										<Tile
											key={verification.id}
											icon={{ name: 'download', type: 'basic' }}
											iconSize={TileIconSize.large}
											onClick={() => {
												if (!controls.isDownloadProcessing) {
													handleVerificationDownload(verification.id);
												}
											}}
											loading={
												controls.isDownloadVerificationId === verification.id ??
												false
											}
											date={verification.issueDate}
											name={verification.addressee}>
											<p className={'text-secondary-700'}>
												{verification.name}, {t('osvedceni.id.nazev')}:{' '}
												{verification.id}
											</p>
										</Tile>
									);
								})}
							</ul>
							{numberOfVerifications > filter.pocet ? (
								<footer className={'mt-6'}>
									<GovPagination
										onGov-page={(event) => {
											scrollIntoView();
											goToPage(event.detail.pagination.currentPage);
										}}
										current={currentPage}
										page-size={filter.pocet}
										total={numberOfVerifications}
										wcag-label={t('strankovani')}></GovPagination>
								</footer>
							) : null}
						</>
					) : (
						<InfoBanner
							icon={{ name: 'cactus', type: 'colored' }}
							type="secondary"
							className={'!px-0 !py-4'}>
							<p className={'text-2xl [ md:text-3xl ]'}>
								{t('osvedceni.stav.chyba.zadna-osvedceni') as string}
							</p>
							<p>
								{
									t(
										'osvedceni.stav.chyba.zrejme-jste-nic-nevytvorili'
									) as string
								}
							</p>
						</InfoBanner>
					)}
				</section>
			)}
		</ContentLayout>
	);
};
