import * as yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import { Maybe } from '@gov-nx/core/types';
import { CommonSelectProps } from '@gov-nx/ui/types';

interface FieldProps<Value> {
	name: string;
	label: string;
	isDisabled?: boolean;
	isRequired?: boolean;
	isRequiredMessage?: string;
	options: { value: Value; label: string; isDisabled?: boolean }[];
	defaultValue?: Value;
	isRequiredWhen?: {
		fieldName: string;
		is: boolean | string;
		errorMessage: string;
	};
}

type SelectStringDefinition = {
	schema: yup.StringSchema<Maybe<string>, AnyObject, Maybe<string>>;
	defaultValue: string | null;
	field: CommonSelectProps<string>;
};

type SelectNumberDefinition = {
	schema: yup.NumberSchema<Maybe<number>, AnyObject, Maybe<number>>;
	defaultValue: number | null;
	field: CommonSelectProps<number>;
};

export type SelectDefinition = SelectStringDefinition | SelectNumberDefinition;

const isNumberSelectDefinition = (
	props: FieldProps<string | number>
): props is FieldProps<number> => typeof props.options[0]?.value === 'number';

export function getSelectDefinition<T extends number | string>(
	props: FieldProps<T>
) {
	if (isNumberSelectDefinition(props)) {
		return getSelectNumberDefinition(props);
	}

	return getSelectStringDefinition(props as FieldProps<string>);
}

const getSelectNumberDefinition = (
	props: FieldProps<number>
): SelectNumberDefinition => {
	let schema = yup.number().nullable();
	if (props.isRequired) {
		schema = schema.required(props.isRequiredMessage);
	}
	if (props.isRequiredWhen) {
		schema = schema.when(props.isRequiredWhen.fieldName, {
			is: props.isRequiredWhen.is,
			then: (schema) => schema.required(props.isRequiredWhen?.errorMessage),
			otherwise: (schema) => schema.optional(),
		});
	}
	return {
		schema,
		defaultValue: props.defaultValue ?? null,
		field: {
			field: {
				name: props.name,
				isDisabled: props.isDisabled,
				disabled: props.isDisabled,
				options: props.options,
			},
			label: {
				children: props.label,
			},
		},
	};
};

const getSelectStringDefinition = (
	props: FieldProps<string>
): SelectStringDefinition => {
	let schema = yup.string().nullable();
	if (props.isRequired) {
		schema = schema.required(props.isRequiredMessage);
	}
	if (props.isRequiredWhen) {
		schema = schema.when(props.isRequiredWhen.fieldName, {
			is: props.isRequiredWhen.is,
			then: (schema) => schema.required(props.isRequiredWhen?.errorMessage),
			otherwise: (schema) => schema.optional(),
		});
	}
	return {
		schema,
		defaultValue: props.defaultValue ?? null,
		field: {
			field: {
				name: props.name,
				isDisabled: props.isDisabled,
				disabled: props.isDisabled,
				options: props.options,
			},
			label: {
				children: props.label,
			},
		},
	};
};
