import { createContext, ReactNode, useContext } from 'react';
import {
	DatovkaApiType,
	OsobyFyzickaOsobaDto,
	sendSubmissionQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useIdentity } from '@gov-nx/auth/common';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	FormWizardHook,
	PersonCommunicationData,
	useCountryCodes,
	useDownloadBlob,
	usePersonCommunicationData,
	usePersonCommunicationForm,
	useRobPersonQuery,
	useWizard,
} from '@gov-nx/core/hooks';
import { Nullable, Optional } from '@gov-nx/core/types';
import { useDataBoxAccess } from '@gov-nx/module/data-box';
import { dataBoxParams, phoneShapeToString } from '@gov-nx/utils/common';
import { useKorespondencniVolbyLocale } from '../useKorespondencniVolbyLocale';
import {
	KorespondencniHlasovaniDokumentyStep1,
	KorespondencniHlasovaniDolumentyFormStep1,
	useFormStep1,
} from './useFormStep1';
import {
	KorespondencniHlasovaniDokumentyFormStep2,
	KorespondencniHlasovaniDokumentyStep2,
	useFormStep2,
} from './useFormStep2';
import {
	KorespondencniHlasovaniDokumentyFormStep3,
	KorespondencniHlasovaniDokumentyStep3,
	useFormStep3,
} from './useFormStep3';

interface KorespondencniHlasovaniDokumentyContext {
	wizard: FormWizardHook<DokumentyForm>;
	step1: KorespondencniHlasovaniDolumentyFormStep1;
	step2: KorespondencniHlasovaniDokumentyFormStep2;
	step3: KorespondencniHlasovaniDokumentyFormStep3;
	personCommunicationData: PersonCommunicationData;
	isLoading: boolean;
	isSubmitting: boolean;
	person?: OsobyFyzickaOsobaDto;
	selectedOffice?: string;
	selectedDistrict?: string;
	selectedCountry?: string;
	isOnOtherListValue: Nullable<'YES' | 'NO'>;
	pickupMethodSelected: Optional<
		KorespondencniHlasovaniDokumentyStep2['pickUpMethod']
	>;
	powerOfAttorney: {
		download: () => void;
		isDownloading: boolean;
	};
	showConnectDataboxBanner: boolean;
	databoxTypes: DatovkaApiType['typSchranky'][];
}

type DokumentyForm = KorespondencniHlasovaniDokumentyStep1 &
	KorespondencniHlasovaniDokumentyStep2 &
	KorespondencniHlasovaniDokumentyStep3;

const ServiceContext =
	createContext<Nullable<KorespondencniHlasovaniDokumentyContext>>(null);

interface Props {
	children: ReactNode;
	electionId: number;
	onSubmitSuccess: () => void;
}

export const KorespondencniHlasovaniDokumentyContextProvider = ({
	children,
	electionId,
	onSubmitSuccess,
}: Props) => {
	const databoxTypes: DatovkaApiType['typSchranky'][] = ['FO', 'PFO'];

	const personQuery = useRobPersonQuery();
	const countryCodes = useCountryCodes();
	const { t } = useKorespondencniVolbyLocale();
	const { toastMessageSuccess } = useMessageEvents();
	const { handleDownloadBlob } = useDownloadBlob();
	const { isDataBoxLoginType } = useIdentity();
	const { areAnyConnected } = useDataBoxAccess({
		requiredTypes: databoxTypes,
	});

	const submitMutation = usePoMutation({
		mutationFn: async (variables: DokumentyForm) => {
			const { datovaSchrankaId, ...params } = dataBoxParams(variables);

			return sendSubmissionQuery({
				params: {
					odesilatelDSId: datovaSchrankaId,
					...params,
				},
				body: {
					kodPodani: 'ZADOST_KORESPONDENCNI_HLASOVANI',
					zpusobPrevzeti: variables.pickUpMethod,
					zuKod: variables.officeId,
					adresaUlice: variables.street,
					adresaMesto: variables.city,
					adresaPsc: variables.zip,
					adresaStat: variables.country,
					email: variables._useEmail ? variables.email : undefined,
					volbyId: electionId,
					telefon:
						variables._usePhone && variables.phone
							? phoneShapeToString(variables.phone)
							: undefined,
				},
			});
		},
		onSuccess: () => {
			toastMessageSuccess(t('dokumenty.zpravy.odeslano'));
			wizard.resetForms();
			onSubmitSuccess();
		},
	});

	const isSubmitting = submitMutation.isLoading;

	const personCommunicationData = usePersonCommunicationData();

	const step1 = useFormStep1({ isDisabled: isSubmitting });
	const step2 = useFormStep2({ isDisabled: isSubmitting });
	const step3 = useFormStep3({
		isDisabled: isSubmitting,
		databoxTypes,
	});

	usePersonCommunicationForm(step1.form, {
		countryCodes,
		email: personCommunicationData.emailVerified,
		phone: personCommunicationData.phoneVerified,
	});

	const wizard = useWizard<DokumentyForm>({
		steps: [step1.form, step2.form, step3],
		onSubmit: async (values) => submitMutation.mutate(values),
		onStepInvalid: (index, errors) => {
			if (index === 0) {
				personCommunicationData.onFormValidated(errors);
			}
		},
	});

	return (
		<ServiceContext.Provider
			value={{
				isLoading:
					personQuery.isLoading || step2.isLoading || countryCodes.isLoading,
				wizard,
				personCommunicationData,
				step1: step1.form,
				step2: step2.form,
				step3,
				isSubmitting,
				isOnOtherListValue: step1.isOnOtherListValue,
				pickupMethodSelected: step2.pickUpMethod,
				person: personQuery.data,
				selectedOffice: step2.selectedOffice,
				selectedDistrict: step2.selectedDistrict,
				selectedCountry: step2.selectedCountry,
				powerOfAttorney: handleDownloadBlob(() =>
					prepareToDownloadPowerOfAttorney(
						step2.selectedOffice,
						step2.selectedCountry
					)
				),
				showConnectDataboxBanner: isDataBoxLoginType && !areAnyConnected,
				databoxTypes,
			}}>
			{children}
		</ServiceContext.Provider>
	);
};

const prepareToDownloadPowerOfAttorney = (
	selectedOffice?: string,
	selectedCountry?: string
) => {
	return {
		body: {
			kodPodani: 'ZADOST_KORESPONDENCNI_HLASOVANI_PLNA_MOC',
			zuNazev: selectedOffice,
			zuStat: selectedCountry,
		},
	};
};

export const useKorespondencniHlasovaniDokumentyContext = () => {
	const context = useContext(ServiceContext);
	if (!context) {
		throw new Error('Context must be used within a ContextProvider');
	}
	return context;
};
