import { GovModal } from '@gov-design-system-ce/react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { PoFormWeb, MarkdownRender } from '@gov-nx/component/web';
import { InvalidationForm, useRezaLocale } from '@gov-nx/module/page';
import {
	Button,
	FormDatePicker,
	FormRadio,
	FormSideOffsetLayout,
	FormWidthElementLayout,
	LayoutGap,
} from '@gov-nx/ui/web';

interface Props {
	invalidationForm: InvalidationForm;
}

export const InvalidationModal = ({ invalidationForm }: Props) => {
	const { t } = useRezaLocale();
	const { isModalOpen, onSubmit, fields, formDefinition } = invalidationForm;

	return (
		<FormProvider {...formDefinition.formMethods}>
			<PoFormWeb
				formDefinition={formDefinition}
				onSubmit={onSubmit}>
				<GovModal
					label={t('invalidation.title')}
					open={isModalOpen.isTrue}
					onGov-close={isModalOpen.setFalse}>
					<LayoutGap>
						<MarkdownRender>{t('invalidation.description')}</MarkdownRender>
						<FormRadio
							{...fields.terminationDate}
							field={{
								...fields.terminationDate.field,
								options: fields.terminationDate.field.options.map((option) => {
									if (option.value === 'onDate') {
										return {
											...option,
											children: (
												<FormSideOffsetLayout gap={4}>
													<FormWidthElementLayout type="m">
														<FormDatePicker {...fields.date} />
													</FormWidthElementLayout>
												</FormSideOffsetLayout>
											),
										};
									}
									return option;
								}),
							}}
						/>

						<Button
							variant={'error'}
							type={'solid'}
							nativeType={'submit'}
							{...fields.submit}>
							{fields.submit.children}
						</Button>
					</LayoutGap>
				</GovModal>
			</PoFormWeb>
		</FormProvider>
	);
};
