import { pageRouteResolver } from '@gov-nx/web';
import { useLocale } from '@gov-nx/core/service';
import {
	DataBoxFolderType,
	isDataBoxTypePO,
	useDataBoxActionInfoContext,
	useDataBoxesContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';

export const ListStateList = () => {
	const { t } = useLocale(PageCode['datove-schranky']);
	const { automaticArchiveEnabled, notificationsEnabled } =
		useDataBoxesContext();
	const { folderType, emptyFolder, dataBox } = useDataBoxActionInfoContext();

	if (emptyFolder) {
		return (
			<div className={'px-6 py-4 h-full'}>
				<div className={'flex flex-col h-full justify-center items-center'}>
					<img
						src="\assets\images\postcard-placeholder.svg"
						width="160"
						height="100"
						alt=""
					/>
				</div>
			</div>
		);
	}

	switch (folderType) {
		case DataBoxFolderType.Received:
		case DataBoxFolderType.Sent:
			return (
				<div className={'px-6 py-4 h-full'}>
					<div className={'flex flex-col h-full justify-center items-center'}>
						<div
							className={
								'flex flex-col gap-6 h-full justify-center items-center text-center'
							}>
							<img
								src="\assets\images\postcard.svg"
								width="160"
								height="100"
								alt=""
							/>
							<div className={'flex flex-col gap-2'}>
								<h4 className={'text-l font-bold'}>
									{t('info-stranka.nevybrali-jste-zpravu')}
								</h4>
								<p className={'text-secondary-700'}>
									{t('info-stranka.vyberte-zpravu')}
								</p>
							</div>
						</div>

						{!isDataBoxTypePO(dataBox) && !automaticArchiveEnabled ? (
							<>
								<div className={'my-14 w-full h-[1px] bg-secondary-400'}></div>
								<div
									className={
										'flex flex-col gap-6 h-full justify-center items-center text-center'
									}>
									<img
										src="\assets\images\archiv.svg"
										width="102"
										height="64"
										alt=""
									/>
									<div className={'flex flex-col gap-2'}>
										<h4 className={'text-l font-bold'}>
											{t('info-stranka.automaticke-mazani')}
										</h4>
										<p className={'text-secondary-700'}>
											{t('info-stranka.automaticka-archivace')}
										</p>
									</div>
									<div>
										<Button
											key="zapnout-archivaci-button"
											href={pageRouteResolver(PageCode['nastaveni'])}
											variant="primary"
											type="solid">
											{t('info-stranka.zapnout-archivaci')}
										</Button>
									</div>
								</div>
							</>
						) : !notificationsEnabled ? (
							<>
								<div className={'my-14 w-full h-[1px] bg-secondary-400'}></div>
								<div
									className={
										'flex flex-col gap-6 h-full justify-center items-center text-center'
									}>
									<img
										src="\assets\images\notification.svg"
										width="102"
										height="64"
										alt=""
									/>
									<div className={'flex flex-col gap-2'}>
										<h4 className={'text-l font-bold'}>
											{t('info-stranka.dulezita-zprava')}
										</h4>
										<p className={'text-secondary-700'}>
											{t('info-stranka.zapnout-notifikace')}
										</p>
									</div>
									<div>
										<Button
											key="nastavit-notifikace-button"
											href={pageRouteResolver(PageCode['nastaveni'])}
											variant="primary"
											type="solid"
											wcagLabel={t('info-stranka.wcag.nastavit-notifikace')}>
											{t('info-stranka.nastavit-notifikace')}
										</Button>
									</div>
								</div>
							</>
						) : null}
					</div>
				</div>
			);
		case DataBoxFolderType.Archived:
			return (
				<div className={'px-6 py-4 h-full'}>
					<div className={'flex flex-col h-full justify-center items-center'}>
						<div
							className={
								'flex flex-col gap-6 h-full justify-center items-center text-center'
							}>
							<img
								src="\assets\images\postcard.svg"
								width="160"
								height="100"
								alt=""
							/>
							<div className={'flex flex-col gap-2'}>
								<h4 className={'text-l font-bold'}>
									{t('info-stranka.nevybrali-jste-zpravu')}
								</h4>
								<p className={'text-secondary-700'}>
									{t('info-stranka.vyberte-zpravu')}
								</p>
							</div>
						</div>
						{!isDataBoxTypePO(dataBox) && !automaticArchiveEnabled && (
							<>
								<div className={'my-14 w-full h-[1px] bg-secondary-400'}></div>
								<div
									className={
										'flex flex-col gap-6 h-full justify-center items-center text-center'
									}>
									<img
										src="\assets\images\archiv.svg"
										width="102"
										height="64"
										alt=""
									/>
									<div className={'flex flex-col gap-2'}>
										<h4 className={'text-l font-bold'}>
											{t('info-stranka.automaticke-mazani')}
										</h4>
										<p className={'text-secondary-700'}>
											{t('info-stranka.automaticka-archivace')}
										</p>
									</div>
									<div>
										<Button
											href={pageRouteResolver(PageCode['nastaveni'])}
											variant="primary"
											type="solid">
											{t('info-stranka.zapnout-archivaci')}
										</Button>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			);
		case DataBoxFolderType.Trash:
			return (
				<div className={'px-6 py-4 h-full'}>
					<div className={'flex flex-col h-full justify-center items-center'}>
						<div
							className={
								'flex flex-col gap-6 h-full justify-center items-center text-center'
							}>
							<img
								src="\assets\images\postcard.svg"
								width="160"
								height="100"
								alt=""
							/>
							<div className={'flex flex-col gap-2'}>
								<h4 className={'text-l font-bold'}>
									{t('info-stranka.nevybrali-jste-zpravu')}
								</h4>
								<p className={'text-secondary-700'}>
									{t('info-stranka.vyberte-zpravu')}
								</p>
							</div>
						</div>
					</div>
				</div>
			);
		default:
			return null;
	}
};
