import React from 'react';
import { useTranslation } from 'react-i18next';
import { CmsNavigation, isCmsNavigationService } from '@gov-nx/api/common';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { is } from '@gov-nx/core/types';
import { getPageUrlByCode } from '@gov-nx/module/page';
import { getServiceUrlByCode } from '@gov-nx/module/service';
import { TileLinkList } from '@gov-nx/ui/web';

export interface CsmNavigationSignpostProps {
	navigations: CmsNavigation[];
	localizeNameSpace: LocalizeNameSpaceTypes;
}

export const CsmNavigationSignpost = ({
	navigations,
	localizeNameSpace,
}: CsmNavigationSignpostProps) => {
	const { t } = useTranslation([localizeNameSpace]);
	return (
		<section>
			<h2 className={'mb-4 text-4xl'}>
				{t('mozna-hledate', { namespace: localizeNameSpace })}
			</h2>
			<TileLinkList
				items={navigations
					.map((navigation) => {
						const link = isCmsNavigationService(navigation)
							? navigation.service
							: navigation.page;
						if (!link) {
							return null;
						}
						const route = isCmsNavigationService(navigation)
							? getServiceUrlByCode(navigation.service.code)
							: getPageUrlByCode(navigation.page.code);

						if (route?.indexOf('#') === -1) {
							return {
								label: link.name,
								link: route,
								icon: link.icon?.icon,
							};
						}
						return null;
					})
					.filter(is)}
			/>
		</section>
	);
};
