import { usePoMutation } from '@gov-nx/api/portal-obcana';
import { verificationDownloadQuery } from '@gov-nx/api/portal-obcana';
import { OnVerificationDownloadParams } from '@gov-nx/component/common';
import { GovError } from '@gov-nx/core/app';
import {
	delay,
	getAntiVirusWarningHeader,
	getDispositionResponseHeader,
	getFileNameFromDispositionHeader,
} from '@gov-nx/utils/common';

export interface VerificationDownloadProps {
	onError?: (verificationId: number, error: Error) => void;
	onSuccess?: (data: OnVerificationDownloadParams) => void;
}

export const useVerificationDownload = (props?: VerificationDownloadProps) => {
	const mutation = usePoMutation({
		mutationFn: verificationDownloadQuery,
		onError: (error, verificationId) => {
			props?.onError && props.onError(verificationId, error as GovError);
		},
		onSuccess: async (response, verificationId) => {
			if (response.status === 202) {
				await delay(2000);
				mutation.mutate(verificationId);
			} else {
				try {
					const disposition = getDispositionResponseHeader(response);
					const antivirus = getAntiVirusWarningHeader(response);
					const fileName = getFileNameFromDispositionHeader(disposition);
					const blob = response.data;
					props?.onSuccess &&
						props.onSuccess({
							antivirus,
							fileName,
							blob,
							verificationId: verificationId,
						});
				} catch (e) {
					props?.onError && props.onError(verificationId, e as Error);
				}
			}
		},
	});
	return mutation;
};
