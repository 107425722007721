import { GovSkeleton } from '@gov-design-system-ce/react';
import { useTranslation } from 'react-i18next';
import {
	LocalizeNameSpaceTypes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { dataBoxLabel, getDataBoxTypeLabel } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import { generateRandomNumber } from '@gov-nx/utils/common';

interface DataboxSettingsHeadingProps {
	databoxId: string;
}

export const DataboxSettingsHeading = ({
	databoxId,
}: DataboxSettingsHeadingProps) => {
	const getDataBoxById = useDataBoxStore('getDataBoxById');
	const databox = getDataBoxById(databoxId);

	const { getLocalizeCurried } = useTranslationWithNamespace();
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	const tds = getLocalizeCurried(PageCode['datove-schranky']);

	if (!databox) {
		return (
			<GovSkeleton
				height={'28px'}
				width={generateRandomNumber(60, 80) + '%'}
				className={'py-2'}
				wcagLabel={t('loading.wcag.processing')}
			/>
		);
	}

	return (
		<h3 id={`databox-notification-settings-label_${databoxId}`}>
			<strong className="texl-l md:text-xl">
				{dataBoxLabel(databox, true)}&nbsp;
			</strong>
			&ndash; {getDataBoxTypeLabel(databox.typSchranky, tds)}
			{` (${databoxId})`}
		</h3>
	);
};
