import React, { createContext, useCallback, useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import {
	driversRegistryResults,
	extractOfDataQuery,
	filterErrorStatus,
	usePoMutation,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import {
	getRequiredDataBoxes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { compose, Nullable } from '@gov-nx/core/types';
import { useDataBoxAccess } from '@gov-nx/module/data-box';
import { ServiceCode } from '../definitions/codes';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import { ServiceContextTypes } from './service.types';
import { getDriversLicenceInfo } from './utils';

export const ServiceContext =
	createContext<Nullable<ServiceContextTypes>>(null);

export interface ServiceContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
}

export function VypisBodovehoHodnoceniRidiceContextProvider({
	children,
	code,
}: ServiceContextProviderProps) {
	const requiredDataBoxes = getRequiredDataBoxes(code);
	const databoxAccess = useDataBoxAccess({
		requiredTypes: requiredDataBoxes,
	});
	const { toastMessage } = useMessageEvents({});
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const formDefinition = FormInstance({ dataBoxTypes: requiredDataBoxes });
	const { controls, setControls } = useProcessControl({ initialLoading: true });
	const source = 'REGISTR_RIDICU_V2';

	const driversRegistryQuery = usePoQuery(
		{
			queryKey: ['service', source],
			queryFn: () => driversRegistryResults(source),
			refetchOnWindowFocus: false,
			onSuccess: () => {
				setControls({ initialLoading: false });
			},
			onError: (error) => {
				setControls({ initialError: error, initialLoading: false });
			},
		},
		{
			errorIgnoreFilter: filterErrorStatus(500, (initialError) =>
				setControls({ initialError })
			),
		}
	);

	const driversInfo = getDriversLicenceInfo(driversRegistryQuery.data);

	const submitMutation = usePoMutation({
		mutationFn: compose(
			extractOfDataQuery,
			prepareSubmitData(driversInfo.licenceNumber)
		),
		onSuccess: () => {
			setControls({ processLoading: false });
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
					icon: {
						name: 'check-lg',
						type: 'basic',
					},
				},
				content: tsn('form.messages.odeslano'),
			});
			formDefinition.formReset();
		},
		onError: (error) => {
			setControls({ processError: error, processLoading: false });
			formDefinition.formReset();
		},
	});

	const handleSubmit = useCallback(async () => {
		setControls({ processError: null, processLoading: true });

		return submitMutation.mutate(formDefinition.formMethods.getValues());
	}, [formDefinition.formMethods, setControls, submitMutation]);

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);

	const infoBox =
		driversInfo.points && driversInfo.points >= 5
			? tsn('form.messages.velky-pocet-bodu-varovani')
			: undefined;

	return (
		<ServiceContext.Provider
			value={{
				code,
				formDefinition,
				onSubmit,
				submitMutation,
				driversInfo,
				infoBox,
				maxDriversPoints: 12,
				controls,
				setControls,
				requiredDataBoxes,
				databoxAccess,
			}}>
			<FormProvider {...formDefinition.formMethods}>{children}</FormProvider>
		</ServiceContext.Provider>
	);
}

export const useVypisBodovehoHodnoceniRidiceContext = (): ServiceContextTypes =>
	useContext(ServiceContext) as ServiceContextTypes;
