import { pageRouteResolver } from '@gov-nx/web';
import { useQueryClient } from '@tanstack/react-query';
import { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMessageEvents } from '@gov-nx/core/events';
import { useLocale } from '@gov-nx/core/service';
import { Nullable, hasProperty } from '@gov-nx/core/types';
import { PageCode } from '../../definitions/codes';
import { useAuthorizationDetailForm } from './FormDefinitions';
import { MyAuthorizationDetailContextType } from './context.types';
import { useForm602SubmissionDeleteQuery } from './hooks/useForm602SubmissionDeleteQuery';
import { useForm602SubmissionQuery } from './hooks/useForm602SubmissionQuery';
import { useForm602SubmissionSendQuery } from './hooks/useForm602SubmissionSendQuery';

const MojeAutorizaceDetailContext =
	createContext<Nullable<MyAuthorizationDetailContextType>>(null);

interface MojeAutorizaceDetailContextProviderProps {
	code: PageCode;
	formularPodaniId: string;
	children: React.ReactNode;
}

export function MojeAutorizaceDetailContextProvider({
	code,
	formularPodaniId,
	children,
}: MojeAutorizaceDetailContextProviderProps) {
	const queryClient = useQueryClient();
	const { toastMessageSuccess } = useMessageEvents();
	const { t } = useLocale(code);
	const navigate = useNavigate();

	const form602Submission = useForm602SubmissionQuery({ formularPodaniId });

	const sendQuery = useForm602SubmissionSendQuery({
		onSuccess: async () => {
			await queryClient.invalidateQueries([
				'form602-submissions',
				'my-submissions-filter',
			]);
			toastMessageSuccess(t('autorizace.formular.odeslan'));
			navigate(pageRouteResolver('moje-podani'));
		},
	});
	const deleteQuery = useForm602SubmissionDeleteQuery({
		formularPodaniId,
		onSuccess: async () => {
			await queryClient.invalidateQueries(['form602-submissions']);
			toastMessageSuccess(t('autorizace.formular.smazan'));
			navigate(pageRouteResolver('moje-podani.autorizace'));
		},
	});

	const form = useAuthorizationDetailForm({
		isDisabled: sendQuery.isLoading || deleteQuery.isLoading,
	});

	const onSubmit = form.formMethods.handleSubmit(() => {
		const formData = form.formMethods.getValues();
		sendQuery.mutate({
			formularPodaniId,
			body: {
				akce: 'PODAT',
				prijemceDSId: form602Submission.data?.prijemce?.idDS,
				odesilatelDSId: formData.datovaSchrankaId,
				naseCisloJednaci: formData.naseCisloJednaci,
				naseSpisovaZnacka: formData.naseSpisovaZnacka,
			},
		});
	});

	return (
		<MojeAutorizaceDetailContext.Provider
			value={{
				code,
				form602Submission,
				attachments: form602Submission.data?.prilohy?.filter(
					hasProperty('dokumentId')
				),
				form,
				isProcessing: sendQuery.isLoading || deleteQuery.isLoading,
				sendQuery,
				deleteQuery,
				onSubmit,
			}}>
			{children}
		</MojeAutorizaceDetailContext.Provider>
	);
}

export const useMojeAutorizaceDetailContext =
	(): MyAuthorizationDetailContextType =>
		useContext(MojeAutorizaceDetailContext) as MyAuthorizationDetailContextType;
