import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { FormProvider, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	newDataBoxesMessageQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useDataBoxEvents, useMessageEvents } from '@gov-nx/core/events';
import { FileUploadItem, useProcessControl } from '@gov-nx/core/hooks';
import {
	LocalizeNameSpaceTypes,
	PoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { Nullable, compose } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import { formatBytes, useBoolean } from '@gov-nx/utils/common';
import { useDocumentsModal } from '../hooks/useDocumentsModal';
import { useLowCreditPrompt } from '../hooks/useLowCreditPrompt';
import { useMessagePrice } from '../hooks/useMessagePrice';
import {
	prepareNewMessageSubmitData,
	useNewMessageForm,
} from '../hooks/useNewMessageForm';
import { MAX_ATTACHMENTS, MAX_SUM_FILE_SIZE } from '../options/options';
import { DataBoxFolderType } from './DataBoxes.types';
import {
	AdditionalDataInputs,
	DataBoxMessageFormContextType,
	DataBoxUploadRef,
} from './MessageFormContext.types';
import { useSender } from './useSender';

export const DataBoxMessageFormContext =
	createContext<Nullable<DataBoxMessageFormContextType>>(null);

type DataBoxMessageFormProviderProps = {
	dataBoxId?: string;
	folderType?: DataBoxFolderType;
	messageId?: number;
	onMessageSent?: () => void;
	onMessageCancelled?: () => void;
	children: React.ReactNode;
};

export function DataBoxMessageFormProvider({
	dataBoxId,
	folderType,
	messageId,
	children,
	onMessageSent,
	onMessageCancelled,
}: DataBoxMessageFormProviderProps) {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const { t } = useTranslation(LocalizeNameSpaceTypes.Form);
	const { creditsRefetch } = useDataBoxEvents();
	const { controls, setControls } = useProcessControl();
	const { toastMessage } = useMessageEvents();
	const { form, formSchema, formDefinition, dataLoading } = useNewMessageForm({
		isAnswerForm: !!messageId,
	});
	const { documents, showDocumentsModal, setShowDocumentsModal } =
		useDocumentsModal();
	const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);
	const getConnectedDataBoxesList = useDataBoxStore(
		'getConnectedDataBoxesList'
	);
	const dataBoxes = getConnectedDataBoxesList();
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [showAdditionalDataModal, setShowAdditionalDataModal] = useState(false);
	const showCancelConfirmModal = useBoolean(false);

	const submitMutation = usePoMutation({
		mutationFn: compose(newDataBoxesMessageQuery, prepareNewMessageSubmitData),
		onError: (error: Error) => {
			setControls({ processError: error, processLoading: false });
		},
		onSuccess: async () => {
			setControls({ processLoading: false });
			creditsRefetch();
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
					time: 3000,
					icon: {
						name: 'check-lg',
						type: 'basic',
					},
				},
				content: 'Zpráva odeslána',
			});

			onMessageSent && onMessageSent();

			form.reset();
		},
	});
	const localData = useWatch({
		control: form.control,
	});
	const uploadRef = useRef<DataBoxUploadRef>(null);
	const blockUploadModalClose = !!(
		uploadRef.current?.filesUploading() || uploadRef.current?.filesValidating()
	);

	const canSendForm = Boolean(
		localData.senderId &&
			localData.recipientAutocomplete?.selected &&
			localData.subject &&
			(localData.message ||
				localData.documents?.length ||
				localData.files?.length)
	);
	const formIsTouched = form.formState.isDirty;

	const hasAdditionalInfo = Boolean(
		localData.intoTheirOwnHands ||
			localData.senderIdentification ||
			localData.toTheHandsOf ||
			localData.naseSpisovaZnacka ||
			localData.naseCisloJednaci ||
			localData.vaseSpisovaZnacka ||
			localData.vaseCisloJednaci
	);
	const [pager, setPager] = useState({
		page: 1,
		perPage: 10,
	});
	const pageDocuments = documents.slice(
		(pager.page - 1) * pager.perPage,
		pager.page * pager.perPage
	);

	const loading = dataLoading;

	const goToPage = (page: number) => {
		setPager({ ...pager, page });
	};

	const onCancelClick = () =>
		formIsTouched ? showCancelConfirmModal.setTrue() : onMessageCancelled?.();

	const resetDocuments = useCallback(() => {
		selectedDocuments.forEach((documentId) => {
			form.setValue(`document${documentId}`, false);
		});
		localData.documents?.forEach((documentId) => {
			form.setValue(`document${documentId}`, true);
		});
		setSelectedDocuments([...(localData.documents || [])]);
	}, [localData.documents, selectedDocuments, form]);

	const onDocumentsModalClose = useCallback(() => {
		resetDocuments();
		setShowDocumentsModal(false);
		setPager({
			...pager,
			page: 1,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are correct
	}, [
		setShowDocumentsModal,
		localData.documents,
		selectedDocuments,
		form,
		pager,
	]);

	const onUploadModalClose = useCallback(() => {
		setShowUploadModal(false);
	}, [setShowUploadModal]);

	const resetAdditionalData = useCallback(() => {
		form.setValue(
			'temporary.additionalData.toTheHandsOf',
			localData.toTheHandsOf
		);
		form.setValue(
			'temporary.additionalData.intoTheirOwnHands',
			localData.intoTheirOwnHands
		);
		form.setValue(
			'temporary.additionalData.senderIdentification',
			localData.senderIdentification
		);
		form.setValue(
			'temporary.additionalData.naseCisloJednaci',
			localData.naseCisloJednaci
		);
		form.setValue(
			'temporary.additionalData.naseSpisovaZnacka',
			localData.naseSpisovaZnacka
		);
		form.setValue(
			'temporary.additionalData.vaseCisloJednaci',
			localData.vaseCisloJednaci
		);
		form.setValue(
			'temporary.additionalData.vaseSpisovaZnacka',
			localData.vaseSpisovaZnacka
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are correct
	}, [localData]);

	const onAdditionalDataModalConfirm = useCallback(() => {
		setShowAdditionalDataModal(false);
		form.setValue(
			'toTheHandsOf',
			form.getValues('temporary.additionalData').toTheHandsOf
		);
		form.setValue(
			'intoTheirOwnHands',
			form.getValues('temporary.additionalData').intoTheirOwnHands
		);
		form.setValue(
			'senderIdentification',
			form.getValues('temporary.additionalData').senderIdentification
		);
		form.setValue(
			'naseCisloJednaci',
			form.getValues('temporary.additionalData').naseCisloJednaci
		);
		form.setValue(
			'naseSpisovaZnacka',
			form.getValues('temporary.additionalData').naseSpisovaZnacka
		);
		form.setValue(
			'vaseCisloJednaci',
			form.getValues('temporary.additionalData').vaseCisloJednaci
		);
		form.setValue(
			'vaseSpisovaZnacka',
			form.getValues('temporary.additionalData').vaseSpisovaZnacka
		);

		toastMessage({
			options: {
				variant: 'success',
				type: 'solid',
				icon: {
					name: 'check-lg',
					type: 'basic',
				},
			},
			content: tn('nova-zprava.doplnkove-udaje.pridany-doplnkove-udaje'),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are correct
	}, [setShowAdditionalDataModal]);

	const onAdditionalDataModalClose = useCallback(() => {
		resetAdditionalData();
		setShowAdditionalDataModal(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are correct
	}, [setShowAdditionalDataModal, localData]);

	const removeAdditionalData = useCallback(
		(key: keyof AdditionalDataInputs) => {
			if (key === 'intoTheirOwnHands' || key === 'senderIdentification') {
				form.setValue(key, false);
				form.setValue(`temporary.additionalData.${key}`, false);
			} else {
				form.setValue(key, '');
				form.setValue(`temporary.additionalData.${key}`, '');
			}
		},
		[form]
	);

	const openDocumentsModal = useCallback(() => {
		setShowDocumentsModal(true);
	}, [setShowDocumentsModal]);

	const removeFile = useCallback(
		(fileId: string) => {
			form.setValue('files', [
				...((localData.files?.filter((file) => file.fileId !== fileId) ||
					[]) as FileUploadItem[]),
			]);
		},
		[form, localData.files]
	);

	const openUploadModal = useCallback(() => {
		setShowUploadModal(true);
	}, [setShowUploadModal]);

	const openAdditionalDataModal = useCallback(() => {
		resetAdditionalData();
		setShowAdditionalDataModal(true);
	}, [resetAdditionalData]);

	const onRecipientRemove = useCallback(() => {
		form.setValue('recipientAutocomplete', undefined);
		// eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are correct
	}, [form]);

	const addDocument = useCallback(
		(documentId: number) => {
			setSelectedDocuments([...selectedDocuments, documentId]);
		},
		[selectedDocuments]
	);

	const removeDocument = useCallback(
		(documentId: number, isTemporary = false) => {
			const newValue = [
				...selectedDocuments.filter((item) => item !== documentId),
			];

			setSelectedDocuments([...newValue]);

			if (!isTemporary) {
				form.setValue(`document${documentId}`, false);
				form.setValue('documents', [...newValue]);
			}
		},
		[form, selectedDocuments]
	);

	const onFilesConfirm = useCallback(() => {
		const currentFiles = form.getValues('files');
		let filesCount = currentFiles?.length ?? 0;
		let totalFilesSize =
			currentFiles?.reduce((acc, file) => {
				return acc + (file.size ?? 0);
			}, 0) ?? 0;

		const tempFiles = localData.temporary?.files;
		const addedFiles = [];
		let filesOverflow = false;

		if (tempFiles && tempFiles.length > 0) {
			for (const file of tempFiles) {
				totalFilesSize += file.size ?? 0;
				filesCount++;
				if (
					filesCount > MAX_ATTACHMENTS ||
					totalFilesSize > MAX_SUM_FILE_SIZE
				) {
					filesOverflow = true;
					break;
				} else {
					addedFiles.push(file);
				}
			}

			form.setValue('files', [
				...((currentFiles || []) as FileUploadItem[]),
				...(addedFiles as FileUploadItem[]),
			]);

			if (filesOverflow) {
				toastMessage({
					options: {
						variant: 'error',
						type: 'solid',
						icon: {
							name: 'attachment',
							type: 'basic',
						},
					},
					content: t('nahrani-souboru.pocet-velikost-souboru', {
						namespace: LocalizeNameSpaceTypes.Form,
						count: MAX_ATTACHMENTS,
						sumSize: formatBytes(MAX_SUM_FILE_SIZE),
					}),
				});
			} else {
				toastMessage({
					options: {
						variant: 'success',
						type: 'solid',
						time: 3000,
						icon: {
							name: 'attachment',
							type: 'basic',
						},
					},
					content: tn('nova-zprava.prilohy.pridany-prilohy', {
						count: localData.temporary?.files?.length ?? 0,
					}),
				});
			}
		}

		onUploadModalClose();
	}, [
		localData.temporary?.files,
		onUploadModalClose,
		t,
		tn,
		toastMessage,
		form,
	]);
	const onDocumentsConfirm = useCallback(() => {
		form.setValue('documents', selectedDocuments.filter(Boolean));
		toastMessage({
			options: {
				variant: 'success',
				type: 'solid',
				time: 3000,
				icon: {
					name: 'check-lg',
					type: 'basic',
				},
			},
			content: tn('nova-zprava.prilohy.pridany-prilohy', {
				count: selectedDocuments.length,
			}),
		});
		setShowDocumentsModal(false);
		setPager({
			...pager,
			page: 1,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are correct
	}, [toastMessage, tn, selectedDocuments.length, pager]);

	const { hideMessageList, resetActionInfo } = useDataBoxEvents();

	useEffect(() => {
		setTimeout(() => {
			hideMessageList({ hidden: true });
		});

		return () => {
			setTimeout(() => {
				hideMessageList({ hidden: false });
				resetActionInfo();
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps -- These dependencies are correct
	}, []);

	useEffect(() => {
		if (Object.keys(form.formState.touchedFields).length > 0) {
			form.trigger('message');
		}
	}, [localData.documents, localData.files, form]);

	const recipient = form.watch('recipientAutocomplete')?.selected;

	const sender = useSender(dataBoxes, form);

	const messagePrice = useMessagePrice(form, localData.senderId);

	const lowCreditPrompt = useLowCreditPrompt({
		senderId: localData.senderId,
		recipient: recipient,
		messagePrice: messagePrice.price,
		onPromptClose: onRecipientRemove,
	});

	const handleSubmit = useCallback(async () => {
		if (messagePrice.isLowCredit) {
			lowCreditPrompt.handleOpen();
			return;
		}
		setControls({ processError: null, processLoading: true });
		submitMutation.mutate(form.getValues());
	}, [
		form,
		setControls,
		submitMutation,
		lowCreditPrompt,
		messagePrice.isLowCredit,
	]);

	const onSubmit = form.handleSubmit(handleSubmit);

	return (
		<DataBoxMessageFormContext.Provider
			value={{
				dataBoxId,
				folderType,
				messageId,
				form,
				formSchema,
				controls,
				submitMutation,
				dataBoxes,
				recipient,
				sender,
				showDocumentsModal,
				showUploadModal,
				showAdditionalDataModal,
				documents,
				selectedDocuments,
				uploadRef,
				blockUploadModalClose,
				hasAdditionalInfo,
				loading,
				pageDocuments,
				pager,
				showCancelConfirmModal,
				canSendForm,
				localData,
				messagePrice,
				lowCreditPrompt,
				onCancelClick,
				goToPage,
				onDocumentsModalClose,
				onSubmit,
				setControls,
				onUploadModalClose,
				onAdditionalDataModalClose,
				onAdditionalDataModalConfirm,
				removeAdditionalData,
				openDocumentsModal,
				openUploadModal,
				openAdditionalDataModal,
				onRecipientRemove,
				addDocument,
				removeDocument,
				onFilesConfirm,
				onDocumentsConfirm,
				onMessageCancelled,
				removeFile,
			}}>
			<FormProvider {...form}>
				<PoForm formDefinition={formDefinition}>{children}</PoForm>
			</FormProvider>
		</DataBoxMessageFormContext.Provider>
	);
}

export const useDataBoxMessageFormContext = (): DataBoxMessageFormContextType =>
	useContext(DataBoxMessageFormContext) as DataBoxMessageFormContextType;
