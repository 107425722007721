import { UseQueryResult } from '@tanstack/react-query';
import { PpdfSouhlasySouhlasDto } from '@gov-nx/api/portal-obcana';
import { ProcessControlCore } from '@gov-nx/core/hooks';
import { FormDefinition } from '@gov-nx/core/service';
import { FilterParam, FilterSortDirection, Optional } from '@gov-nx/core/types';

export interface PpdfConsentsListControl extends ProcessControlCore {
	displayFilter: boolean;
}

export enum PpdfConsentsListOrder {
	datumTrvaniUdeleniSouhlasu = 'datumTrvaniUdeleniSouhlasu',
}

export interface PpdfConsentsListFilterForm {
	odvolano: boolean | null;
	razeni?: PpdfConsentsListOrder;
	razeniSmer?: FilterSortDirection;
	hledat?: string;
}

export interface PpdfConsentsListFilter extends PpdfConsentsListFilterForm {
	start: number;
	pocet: number;
}

export interface PpdfSouhlasySeznamContext {
	controls: PpdfConsentsListControl;
	isLoading: boolean;
	query: UseQueryResult;
	data: ExtendedPpdfSouhlasySouhlasDto[];
	ppdfConsentDetailId: Optional<number>;
	setPpdfConsentDetailId: (id: Optional<number>) => void;
	resetFilter: () => void;
	filterParams: FilterParam[];
	setControls: (control: Partial<PpdfConsentsListControl>) => void;
	filter: PpdfConsentsListFilter;
	formDefinition: FormDefinition<PpdfConsentsListFilterForm>;
	onSubmit: () => void;
}

interface ExtendedPpdfSouhlasySouhlasDto extends PpdfSouhlasySouhlasDto {
	nazevSpravceUdaju: string | undefined;
	nazevPrijemceUdaju: string | undefined;
}

export interface DataRequest {
	params: {
		odvolano?: boolean;
		razeni?: PpdfConsentsListOrder;
		start?: number;
		pocet?: number;
	};
}
