import * as yup from 'yup';
import {
	FormDefinition,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { FilterSortDirection } from '@gov-nx/core/types';
import { toStringDate, today, useDateShape } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import {
	DataRequest,
	MySubmissionListFilterForm,
	MySubmissionListOrder,
	MySubmissionsListFilter,
} from './context.types';

export interface FormInstanceProps {
	code: PageCode;
	defaultValues: MySubmissionListFilterForm;
}

export function FormInstance({
	code,
	defaultValues,
}: FormInstanceProps): FormDefinition<MySubmissionListFilterForm> {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { getDatePickerShape } = useDateShape();

	const formSchema = yup
		.object({
			razeni: yup.string().optional(),
			datumVyrizeni: getDatePickerShape()
				.max(today(), tsn('form.validations.datum-ukonu-maximum'))
				.nullable(),
			lhutaVyrizeni: getDatePickerShape().nullable(),
			vyrizeno: getDatePickerShape()
				.max(today(), tsn('form.validations.datum-ukonu-maximum'))
				.nullable(),
			razeniSmer: yup.string().optional(),
			hledat: yup.string().optional(),
		})
		.required();

	const formMethods = usePoForm<MySubmissionListFilterForm>({
		formSchema,
		defaultValues,
	});
	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData = (
	formData: MySubmissionsListFilter
): DataRequest => {
	const order = (
		formData.razeniSmer === FilterSortDirection.DESC
			? '-' + formData.razeni
			: formData.razeni
	) as MySubmissionListOrder;

	return {
		params: {
			razeni: order,
			datumVyrizeni: formData.datumVyrizeni
				? toStringDate(formData.datumVyrizeni)
				: undefined,
			lhutaVyrizeni: formData.lhutaVyrizeni
				? toStringDate(formData.lhutaVyrizeni)
				: undefined,
			vyrizeno: formData.vyrizeno ? toStringDate(formData.vyrizeno) : undefined,
			start: formData.start,
			pocet: formData.pocet,
		},
	};
};
