import React, { createContext, useContext, useEffect } from 'react';
import {
	sendLoggedSupportQuery,
	sendNotLoggedSupportQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	FileUploadItem,
	FileUploadProps,
	WizardFormStep,
	hasNoSuccessFile,
	usePoSupportDataLoad,
	useWizard,
} from '@gov-nx/core/hooks';
import { useLocale } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { useAuthStore } from '@gov-nx/store/portal-obcana';
import { useBoolean } from '@gov-nx/utils/common';
import { PageCode } from '../definitions/codes';
import {
	FormPersonInstance,
	FormProblemInstance,
	FormRecapInstance,
	prepareSubmitData,
} from './FormDefinitions';
import {
	FormData,
	FormPersonData,
	FormProblemData,
	SupportContextTypes,
} from './context.types';
import { useZpusobPrihlaseniOptions } from './options';

const PodporaContext = createContext<Nullable<SupportContextTypes>>(null);

interface PodporaContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
	onSuccess?: () => void;
}

export function PodporaContextProvider({
	children,
	code,
	onSuccess,
}: PodporaContextProviderProps) {
	const { t } = useLocale(code);
	const { toastMessageSuccess } = useMessageEvents();
	const isSubmitted = useBoolean();
	const isAttachmentsLoading = useBoolean();
	const isLoggedIn = useAuthStore('isLoggedIn');
	const isLogged = isLoggedIn();

	const { individualPerson, communication, ...supportData } =
		usePoSupportDataLoad({
			isEnabled: isLogged,
		});

	const submitMutation = usePoMutation({
		mutationFn: async (formData: FormData) => {
			const prepared = prepareSubmitData(formData);
			if (!isLogged) {
				return sendNotLoggedSupportQuery(prepared);
			}
			return sendLoggedSupportQuery(prepared);
		},
		onSuccess: async () => {
			toastMessageSuccess(t('formular.zprava.odeslana'));
			wizard.resetForms();
			isSubmitted.setTrue();
			onSuccess && onSuccess();
		},
	});

	const wizard = useWizard<FormData>({
		steps: [
			FormPersonInstance({ code, communication, individualPerson }),
			FormProblemInstance({ code, isLogged }),
			FormRecapInstance(),
		],
		onSubmit: async (values, onSubmitted) =>
			submitMutation.mutate(values, { onSuccess: onSubmitted }),
		initialIndexOpen: [0],
	});

	const personForm = wizard.step(
		0
	) as unknown as WizardFormStep<FormPersonData>;

	const problemForm = wizard.step(
		1
	) as unknown as WizardFormStep<FormProblemData>;

	useEffect(() => {
		if (individualPerson && communication) {
			if (individualPerson.jmeno) {
				personForm.formDefinition.formMethods.setValue(
					'jmeno',
					individualPerson.jmeno
				);
			}
			if (individualPerson.prijmeni) {
				personForm.formDefinition.formMethods.setValue(
					'prijmeni',
					individualPerson.prijmeni
				);
			}
		}
	}, [
		individualPerson,
		communication,
		wizard.resetForms,
		personForm.formDefinition.formMethods,
	]);

	const { options } = useZpusobPrihlaseniOptions();

	const PodporaFileUploadParams: FileUploadProps = {
		extensions: [
			'.xml',
			'.pdf',
			'.doc',
			'.docx',
			'.odt',
			'.jpg',
			'.jpeg',
			'.png',
		],
		maxFileSize: 15 * 1024 * 1024,
		maxSumFileSize: 15 * 1024 * 1024,
		maxAttachments: 10,
		multiple: true,
		onFilesChanged: (files: FileUploadItem[]) =>
			isAttachmentsLoading.setValue(hasNoSuccessFile(files)),
	};

	return (
		<PodporaContext.Provider
			value={{
				code,
				controls: {
					initialLoading: isLogged ? supportData.isLoading : false,
					initialError: supportData.error,

					processLoading: submitMutation.isLoading,
					processError: submitMutation.error,
				},
				individualPerson,
				isLogged,
				wizard,
				isSubmitted: isSubmitted.isTrue,
				isAttachmentsLoading: isAttachmentsLoading.value,
				PodporaFileUploadParams,
				zpusobPrihlaseni:
					problemForm.formDefinition.formMethods.watch('zpusobPrihlaseni'),
				zpusobPrihlaseniOptions: options,
			}}>
			{children}
		</PodporaContext.Provider>
	);
}

export const usePodporaContext = (): SupportContextTypes =>
	useContext(PodporaContext) as SupportContextTypes;
