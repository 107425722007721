import { AxiosResponse } from 'axios';
import { axiosInstance } from '@gov-nx/api/portal-obcana';

export const removeAccountQuery = ({
	reason,
}: {
	reason: string;
}): Promise<AxiosResponse<void, void>> =>
	axiosInstance.post(
		'/api/v1/remove-account',
		{ duvod: reason },
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}
	);
