import { GovIcon } from '@gov-design-system-ce/react';
import cx from 'classnames';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	PageCode,
	useProfilROBReklamaceContextInstance,
} from '@gov-nx/module/page';
import {
	Button,
	FormCheckbox,
	FormInput,
	FormLabel,
	FormSelect,
	FormWidthElementLayout,
	LayoutGap,
} from '@gov-nx/ui/web';
import { nameFormatting } from '@gov-nx/utils/common';
import { PoFormWeb } from '../../Form/PoForm';
import { MarkdownRender } from '../../Markdown/MarkdownRender';
import { AddressSearchForm } from './AddressSearchForm';
import { BirthPlaceChangeForm } from './BirthplaceChangeForm';
import { DeliveryAddressSearchForm } from './DeliveryAddressSearchForm';

export interface ComplaintProps {
	code: PageCode;
}

export const ProfileROBComplaintView = ({ code }: ComplaintProps) => {
	const {
		data,
		controls,
		setControls,
		formDefinition,
		onSubmit,
		resetAddressSearchFields,
		resetDeliveryAddressSearchFields,
		resetBirthPlaceSearchFields,
		pohlaviOptions,
		omezeniSvepravnostiOptions,
		rodinnyStavPartnerstviOptions,
		emailError,
		phoneError,
	} = useProfilROBReklamaceContextInstance();

	const [isEditingEmail, setIsEditingEmail] = useState(false);
	const [isEditingPhoneNumber, setIsEditingPhoneNumber] = useState(false);

	const isProcessing = controls.processLoading;

	const { getLocalizeCurried } = useTranslationWithNamespace();
	const t = getLocalizeCurried(code);

	const email = formDefinition.formMethods.watch('email');
	const telefonniCislo = formDefinition.formMethods.watch('telefonniCislo');

	const isContactProvided =
		formDefinition.formMethods.watch('poskytnoutEmail') ||
		formDefinition.formMethods.watch('poskytnoutTelefon');

	return (
		<FormProvider {...formDefinition.formMethods}>
			<PoFormWeb
				formDefinition={formDefinition}
				onSubmit={onSubmit}>
				<LayoutGap gap={6}>
					<MarkdownRender>{t('formular.popis')}</MarkdownRender>
					<FormWidthElementLayout
						type="l"
						className={'flex flex-col gap-6'}>
						<LayoutGap gap={8}>
							<FormInput
								field={{
									name: 'jmeno',
									disabled: isProcessing,
									placeholder: t('formular.placeholders.jmeno'),
								}}
								label={{
									children: (
										<>
											{t('formular.pole.jmeno')}:{' '}
											<span className="font-bold">
												{nameFormatting(data?.jmeno) ??
													t('formular.pole.neuvedeno')}
											</span>
										</>
									),
								}}
								control={{ size: 'm' }}
							/>
							<FormInput
								field={{
									name: 'prijmeni',
									disabled: isProcessing,
									placeholder: t('formular.placeholders.prijmeni'),
								}}
								label={{
									children: (
										<>
											{t('formular.pole.prijmeni')}:{' '}
											<span className="font-bold">
												{nameFormatting(data?.prijmeni) ??
													t('formular.pole.neuvedeno')}
											</span>
										</>
									),
								}}
								control={{ size: 'm' }}
							/>
							<FormInput
								field={{
									name: 'rodnePrijmeni',
									disabled: isProcessing,
									placeholder: t('formular.placeholders.rodne-prijmeni'),
								}}
								label={{
									children: (
										<>
											{t('formular.pole.rodne-prijmeni')}:{' '}
											<span className="font-bold">
												{nameFormatting(data?.rodnePrijmeni) ??
													t('formular.pole.neuvedeno')}
											</span>
										</>
									),
								}}
								control={{ size: 'm' }}
							/>
							<FormSelect
								field={{
									name: 'rodinnyStavPartnerstvi',
									disabled: isProcessing,
									options: rodinnyStavPartnerstviOptions,
								}}
								label={{
									children: (
										<>
											{t('formular.pole.rodinny-stav-partnerstvi')}:{' '}
											<span className="font-bold">
												{data?.rodinnyStavPartnerstvi
													? t(
															'zaznam.rodinny-stav-moznosti.' +
																data?.rodinnyStavPartnerstvi?.replace(
																	/\/|\s/g,
																	'-'
																)
													  )
													: t('formular.pole.neuvedeno')}
											</span>
										</>
									),
								}}
								control={{ size: 'm' }}
							/>
							<FormSelect
								field={{
									name: 'pohlavi',
									disabled: isProcessing,
									options: pohlaviOptions,
								}}
								label={{
									children: (
										<>
											{t('formular.pole.pohlavi')}:{' '}
											<span className="font-bold">
												{data?.pohlavi
													? t('zaznam.pohlavi-moznosti.' + data?.pohlavi)
													: t('formular.pole.neuvedeno')}
											</span>
										</>
									),
								}}
								control={{ size: 'm' }}
							/>
							<FormInput
								field={{
									name: 'datumNarozeni',
									disabled: true,
									placeholder: t('formular.placeholders.datum-narozeni'),
								}}
								label={{
									children: t('formular.pole.datum-narozeni'),
								}}
								control={{ size: 'm' }}
							/>

							<div className="space-y-1">
								<FormLabel>
									{t('formular.pole.misto-narozeni')}:{' '}
									<span className="font-bold">
										{data?.mistoNarozeni ?? t('formular.pole.neuvedeno')}
									</span>
								</FormLabel>

								{!controls.displayBirthPlace ? (
									<Button
										onClick={() => setControls({ displayBirthPlace: true })}
										disabled={isProcessing}
										variant="primary"
										size="m"
										type="outlined">
										{t('akce.zmenit-misto')}
									</Button>
								) : (
									<>
										<BirthPlaceChangeForm
											code={code}
											isProcessing={isProcessing}
										/>
										<Button
											onClick={() => {
												setControls({ displayBirthPlace: false });
												resetBirthPlaceSearchFields();
											}}
											disabled={isProcessing}
											variant="primary"
											size="m"
											type="outlined">
											{t('akce.zrusit-zmeny')}
										</Button>
									</>
								)}
							</div>

							<div className="space-y-1">
								<FormLabel>
									{t('formular.pole.adresa-pobytu')}:{' '}
									<span className="font-bold">
										{data?.adresaPobytu?.adresaText ??
											t('formular.pole.neuvedeno')}
									</span>
								</FormLabel>

								{!controls.displaySearchAddress ? (
									<Button
										onClick={() => setControls({ displaySearchAddress: true })}
										disabled={isProcessing}
										variant="primary"
										size="m"
										type="outlined">
										{t('akce.zmenit-adresu')}
									</Button>
								) : (
									<>
										<AddressSearchForm
											code={code}
											isProcessing={isProcessing}
										/>
										<Button
											onClick={() => {
												setControls({ displaySearchAddress: false });
												resetAddressSearchFields();
											}}
											disabled={isProcessing}
											variant="primary"
											size="m"
											type="outlined">
											{t('akce.zrusit-zmeny')}
										</Button>
									</>
								)}
							</div>

							<div className="space-y-1">
								<FormLabel>
									{t('formular.pole.dorucovaci-adresa')}:{' '}
									<span className="font-bold">
										{data?.adresaDorucovaci?.adresaText ??
											t('formular.pole.neuvedeno')}
									</span>
								</FormLabel>
								{!controls.displaySearchDeliveryAddress ? (
									<Button
										onClick={() =>
											setControls({ displaySearchDeliveryAddress: true })
										}
										disabled={isProcessing}
										variant="primary"
										size="m"
										type="outlined">
										{t('akce.zmenit-adresu')}
									</Button>
								) : (
									<>
										<DeliveryAddressSearchForm
											code={code}
											isProcessing={isProcessing}
										/>
										<Button
											onClick={() => {
												setControls({ displaySearchDeliveryAddress: false });
												resetDeliveryAddressSearchFields();
											}}
											disabled={isProcessing}
											variant="primary"
											size="m"
											type="outlined">
											{t('akce.zrusit-zmeny')}
										</Button>
									</>
								)}
							</div>
							<FormSelect
								field={{
									name: 'omezeniSvepravnosti',
									disabled: isProcessing,
									options: omezeniSvepravnostiOptions,
								}}
								label={{
									children: (
										<>
											{t('formular.pole.omezeni-svepravnosti')}:{' '}
											<span className="font-bold">
												{data?.omezeniSvepravnosti !== undefined
													? data?.omezeniSvepravnosti
														? t('zaznam.omezeni-svepravnosti-status.ano')
														: t('zaznam.omezeni-svepravnosti-status.ne')
													: t('formular.pole.neuvedeno')}
											</span>
										</>
									),
								}}
								control={{ size: 'm' }}
							/>
							<FormInput
								field={{
									name: 'popisReklamace',
									multiline: true,
									disabled: isProcessing,
									placeholder: t('formular.placeholders.popis-reklamace'),
								}}
								label={{ children: t('formular.pole.popis-reklamace') }}
								control={{ size: 'm' }}
							/>
						</LayoutGap>
					</FormWidthElementLayout>
					<FormWidthElementLayout
						type="max"
						className="pt-4">
						<LayoutGap gap={4}>
							<FormLabel required>
								{t('formular.kontakty.disclaimer')}
							</FormLabel>

							<FormCheckbox
								field={{
									required: false, // Pro schovani hvezdicky, ktera se spatne renderuje, pro konzistentnost s POM, nema vliv na validaci fieldu
									name: 'poskytnoutEmail',
									size: 's',
									label: {
										children: t('formular.pole.poskytuji-email'),
									},
								}}
							/>

							<div className="flex flex-wrap">
								<div className={cx('flex', { 'text-error-400': emailError })}>
									<div className="mr-2 text-s">
										{t('formular.pole.email')}:{' '}
									</div>
									{isEditingEmail ? (
										<div className="flex ml-1">
											<div className="min-w-52 -mt-1">
												<FormInput
													field={{ name: 'email', size: 's' }}
													label={{}}
												/>
											</div>
											<Button
												onClick={() => {
													setIsEditingEmail(false);
												}}
												variant={'primary'}
												size={'s'}
												type={'base'}
												className="-mt-1">
												<GovIcon
													name="save"
													slot="right-icon"
												/>
											</Button>
										</div>
									) : (
										<div className="flex">
											<span className="font-bold text-s mt-1/2">
												{email && email !== ''
													? email
													: t('formular.pole.nenastaveno')}
											</span>{' '}
											<Button
												onClick={() => setIsEditingEmail(true)}
												variant={'primary'}
												size={'s'}
												type={'base'}
												className="-mt-1">
												<GovIcon
													name="pencil-square"
													slot="right-icon"
												/>
											</Button>
										</div>
									)}
								</div>
							</div>

							<FormCheckbox
								field={{
									required: false, // Pro schovani hvezdicky, ktera se spatne renderuje, pro konzistentnost s POM, nema vliv na validaci fieldu
									name: 'poskytnoutTelefon',
									size: 's',
									label: {
										children: t('formular.pole.poskytuji-telefon'),
									},
								}}
							/>

							<div className="flex flex-wrap">
								<div className={cx('flex', { 'text-error-400': phoneError })}>
									<div className="mr-2 text-s">
										{t('formular.pole.phone')}:{' '}
									</div>
									{isEditingPhoneNumber ? (
										<div className="flex ml-1">
											<div className="min-w-52 -mt-1">
												<FormInput
													field={{ name: 'telefonniCislo', size: 's' }}
													label={{}}
												/>
											</div>
											<Button
												onClick={() => {
													setIsEditingPhoneNumber(false);
												}}
												variant={'primary'}
												size={'s'}
												type={'base'}
												className="-mt-1">
												<GovIcon
													name="save"
													slot="right-icon"
												/>
											</Button>
										</div>
									) : (
										<div className="flex">
											<span className="font-bold text-s mt-1/2">
												{telefonniCislo && telefonniCislo !== ''
													? telefonniCislo
													: t('formular.pole.nenastaveno')}
											</span>{' '}
											<Button
												onClick={() => setIsEditingPhoneNumber(true)}
												variant={'primary'}
												size={'s'}
												type={'base'}
												className="-mt-1">
												<GovIcon
													name="pencil-square"
													slot="right-icon"
												/>
											</Button>
										</div>
									)}
								</div>
							</div>

							<Button
								loading={isProcessing}
								disabled={isProcessing || !isContactProvided}
								nativeType="submit"
								variant="primary"
								size="m"
								type="solid">
								{t('akce.odeslat-reklamaci')}
							</Button>
						</LayoutGap>
					</FormWidthElementLayout>
				</LayoutGap>
			</PoFormWeb>
		</FormProvider>
	);
};
