import * as yup from 'yup';
import { rezaSubmitComplaint, usePoMutation } from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
} from '@gov-nx/core/service';
import { useRezaLocale } from '@gov-nx/module/page';
import { WebInputProps } from '@gov-nx/ui/types';
import { FormButtonProps } from '@gov-nx/ui/web';
import { IUseBoolean, useBoolean } from '@gov-nx/utils/common';

export interface RezaComplaintsForm {
	complaint: string;
}

export interface ComplaintsForm {
	isModalOpen: IUseBoolean;
	formDefinition: FormDefinition<RezaComplaintsForm>;
	onSubmit: () => void;
	fields: {
		complaint: WebInputProps;
		submit: FormButtonProps;
	};
}

export const useComplaintsForm = (id?: string): ComplaintsForm => {
	const { t } = useRezaLocale();
	const { toastMessageSuccess } = useMessageEvents();

	const formSchema = yup
		.object<FormSchemaShape<RezaComplaintsForm>>({
			complaint: yup
				.string()
				.required(t('complaints.form.pole.complaint.validace.povinne-pole')),
		})
		.required();
	const formMethods = usePoForm({
		formSchema,
		defaultValues: {
			complaint: undefined,
		},
	});

	const formDefinition = getFormDefinition({ formMethods, formSchema });

	const mutation = usePoMutation({
		mutationFn: rezaSubmitComplaint,
		onSuccess: () => {
			formDefinition.formReset();
			toastMessageSuccess(t('complaints.form.odeslano'));
		},
	});

	const onSubmit = formDefinition.formMethods.handleSubmit((data) =>
		mutation.mutate(
			{
				popis: data.complaint,
				opravneniKod: id,
				typ: id ? 'NEMA_EXISTOVAT' : 'NEEXISTENCE',
			},
			{ onSuccess: isModalOpen.setFalse }
		)
	);

	const fields: ComplaintsForm['fields'] = {
		complaint: {
			field: {
				name: 'complaint',
				multiline: true,
				rows: 4,
				disabled: mutation.isLoading,
				placeholder: t('complaints.form.pole.complaint.placeholder'),
			},
			label: {
				children: t('complaints.form.pole.complaint.label'),
			},
		},
		submit: {
			children: t('complaints.form.buttons.submit.label'),
			disabled: mutation.isLoading,
			loading: mutation.isLoading,
			wcagLabel: t('complaints.form.buttons.submit.wcag'),
		},
	};
	const isModalOpen = useBoolean();

	return { formDefinition, onSubmit, fields, isModalOpen };
};
