import { useQueryClient } from '@tanstack/react-query';
import React, {
	createContext,
	PropsWithChildren,
	useContext,
	useEffect,
} from 'react';
import { CmsSignpost } from '@gov-nx/api/common';
import {
	cmsSignpostGroupsQuery,
	cmsSignpostQuery,
	FavoriteCode,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useAppLanguage, useConnection } from '@gov-nx/core/hooks';
import { Nullable, Optional } from '@gov-nx/core/types';
import {
	useConfigurationStore,
	useSignpostStore,
} from '@gov-nx/store/portal-obcana';
import { useUserStore } from '@gov-nx/store/portal-obcana';
import { useDebounce, useFilter } from '@gov-nx/utils/common';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import { SignpostContext, SignpostListFilter } from './context.types';

const RozcestikContext = createContext<Nullable<SignpostContext>>(null);

const OFFLINE_SERVICES = ['DROZD'];

export function RozcestnikContextProvider({ children }: PropsWithChildren) {
	const poUserFavorites = useUserStore('poUserFavorites');
	const favorites = poUserFavorites();
	const application = useConfigurationStore('application');
	const environment = useConfigurationStore('environment');
	const { language } = useAppLanguage();

	const { isOnline, isOffline } = useConnection();
	const localSignposts = useSignpostStore('signposts');
	const saveSignposts = useSignpostStore('saveSignposts');
	const queryClient = useQueryClient();
	const searchTerm = useDebounce<string>(200);

	const { filter, setPartialFilter } = useFilter<SignpostListFilter>({
		key: 'rozcestnik',
		initialState: {
			language: language,
			serviceGroups: null,
			favourites: false,
			page: 1,
			perPage: 10,
		},
	});

	const { data, isFetching } = usePoQuery<Optional<CmsSignpost[]>>({
		queryKey: ['signpost', filter, searchTerm.value],
		queryFn: async (): Promise<CmsSignpost[]> => {
			if (isOffline) {
				return localSignposts;
			}

			if (!application || !environment) {
				return [];
			}

			const params = prepareSubmitData(
				filter,
				searchTerm.value,
				application,
				environment
			);
			const data = await cmsSignpostQuery(params);
			saveSignposts(
				data.filter((signpost) => OFFLINE_SERVICES.includes(signpost.name))
			);
			return data;
		},
	});

	const signposts = (data ?? [])
		.sort((a, b) => a.order - b.order)
		.filter((signpost) => {
			if (filter.favourites) {
				return (
					favorites.includes(signpost.pageCode as FavoriteCode) ||
					favorites.includes(signpost.serviceCode as FavoriteCode) ||
					favorites.includes(signpost.externalServiceCode as FavoriteCode)
				);
			}
			return true;
		})
		.slice((filter.page - 1) * filter.perPage, filter.page * filter.perPage);

	const signpostGroupQuery = usePoQuery({
		queryKey: ['signpost-group', language],
		queryFn: () => cmsSignpostGroupsQuery({ locale: language }),
		refetchOnWindowFocus: false,
		retry: 0,
	});

	const groups = (signpostGroupQuery.data ?? []).sort((a, b) => {
		return a.order - b.order;
	});

	const goToPage = (page: number) => {
		setPartialFilter({ page: page });
	};

	const formDefinition = FormInstance();
	const onSubmit = formDefinition.formMethods.handleSubmit(() => null);

	const setFilterActiveGroup = (groupId: number) => {
		setPartialFilter({ serviceGroups: groupId, favourites: false, page: 1 });
		searchTerm.reset();
		formDefinition.formReset();
	};
	const setFilterFavourites = () => {
		setPartialFilter({ serviceGroups: null, favourites: true, page: 1 });
		searchTerm.reset();
		formDefinition.formReset();
	};
	const setFilterAll = () => {
		setPartialFilter({ serviceGroups: null, favourites: false, page: 1 });
		searchTerm.reset();
		formDefinition.formReset();
	};

	useEffect(() => {
		if (language !== filter.language) {
			setPartialFilter({ serviceGroups: null, language });
		}
	}, [language]);

	useEffect(() => {
		const subscription = formDefinition.formMethods.watch((value, { name }) => {
			const searchValue = value.search;
			if (searchValue && searchValue.length > 2) {
				setPartialFilter({ serviceGroups: null, favourites: false, page: 1 });
				searchTerm.update(searchValue);
			} else {
				searchTerm.reset();
			}
		});
		return () => subscription.unsubscribe();
	}, [formDefinition.formMethods]);

	useEffect(() => {
		if (filter.favourites && signposts.length === 0) {
			goToPage(1);
		}
	}, [signposts.length, filter.favourites]);

	useEffect(() => {
		queryClient.invalidateQueries({ queryKey: ['signpost'] });
	}, [isOnline]);

	return (
		<RozcestikContext.Provider
			value={{
				setFilterActiveGroup,
				setFilterFavourites,
				setFilterAll,
				groups,
				numberOfSignposts: filter.favourites
					? favorites.length
					: data?.length ?? 0,
				filter,
				data: signposts,
				isSignpostDataFetching: isFetching,
				formDefinition,
				onSubmit,
				goToPage,
				favorites,
			}}>
			{children}
		</RozcestikContext.Provider>
	);
}

export const useRozcestnikContext = (): SignpostContext =>
	useContext(RozcestikContext) as SignpostContext;
