import { CmsMessage } from '@gov-nx/api/common';
import { PageCode } from '@gov-nx/module/page';
import { ServiceCode } from '@gov-nx/module/service';

export const filterCmsMessagesByCodes = (
	messages: CmsMessage[],
	pageCode?: PageCode | PageCode[] | null,
	serviceCode?: ServiceCode | ServiceCode[] | null
): CmsMessage[] => {
	const pageCodes = pageCode
		? Array.isArray(pageCode)
			? pageCode
			: [pageCode]
		: null;

	const serviceCodes = serviceCode
		? Array.isArray(serviceCode)
			? serviceCode
			: [serviceCode]
		: null;

	return messages.filter((message) => {
		const pages = Array.isArray(message.pageCodes) ? message.pageCodes : [];
		const services = Array.isArray(message.serviceCodes)
			? message.serviceCodes
			: [];

		if (pages.length || services.length) {
			if (pages.length && pageCodes) {
				return message.pageCodes.some((pageCode) =>
					pageCodes.includes(pageCode)
				);
			} else if (services.length && serviceCodes) {
				return message.serviceCodes.some((serviceCode) =>
					serviceCodes.includes(serviceCode)
				);
			} else {
				return false;
			}
		} else {
			return true;
		}
	});
};
