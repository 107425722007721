import { createContext, PropsWithChildren, useContext } from 'react';
import {
	RezaOpravneniDto,
	usePoQuery,
	rezaEligibleDetail,
	usePoMutation,
	rezaDownloadQuery,
} from '@gov-nx/api/portal-obcana';
import { useDownloadEvents, useMessageEvents } from '@gov-nx/core/events';
import {
	delay,
	getAntiVirusWarningHeader,
	getDispositionResponseHeader,
	getFileNameFromDispositionHeader,
	toDateReadable,
} from '@gov-nx/utils/common';
import { ComplaintsForm, useComplaintsForm } from '../useComplaintsForm';
import { InvalidationForm, useInvalidationForm } from '../useInvalidateForm';
import { useRezaLocale } from '../useRezaLocale';

interface RezaDetailContexts {
	isLoading: boolean;
	data?: RezaOpravneniDto;
	onDownload: () => void;
	complaintsForm: ComplaintsForm;
	invalidationForm: InvalidationForm;
	keyValues: Record<string, { value?: string; canCopy?: boolean }>;
}

const Context = createContext<RezaDetailContexts | null>(null);

type Props = PropsWithChildren<{ id: string; onInvalidated: () => void }>;

export const RezaDetailContextProvider = ({
	children,
	id,
	onInvalidated,
}: Props) => {
	const { t } = useRezaLocale();
	const { toastMessageSuccess, toastMessageError } = useMessageEvents();

	const query = usePoQuery({
		queryKey: ['reza', 'detail', id],
		queryFn: async () => rezaEligibleDetail(id),
	});
	const { download } = useDownloadEvents();

	const mutation = usePoMutation({
		mutationFn: async (variables: { id: string }) => {
			const response = await rezaDownloadQuery(id);
			if (response.status === 202) {
				await delay(2000);
				mutation.mutate(variables);
			} else {
				const disposition = getDispositionResponseHeader(response);
				const antivirus = getAntiVirusWarningHeader(response);
				const fileName = getFileNameFromDispositionHeader(disposition);

				return download({
					blob: response.data,
					id: id,
					fileName,
					antivirus,
					callback: (id, success, error) => {
						if (success) {
							toastMessageSuccess(t('toasts.download.success', { fileName }));
						}
						if (error) {
							toastMessageError(t('toasts.download.chyba'));
						}
					},
				});
			}
		},
	});

	const onDownload = async () => mutation.mutateAsync({ id });

	const keyValues: Record<string, { value?: string; canCopy?: boolean }> = {
		[t('detail.table.identifikator')]: {
			value: query.data?.kod,
			canCopy: true,
		},
		[query.data?.role === 'zmocnenec'
			? t('detail.table.zmocnenec')
			: t('detail.table.zmocnitel')]: {
			value: query.data?.zastupujiciOsobaNazev,
		},
		[t('detail.table.platnost-od')]: {
			value: query.data?.platnostOd
				? toDateReadable(query.data?.platnostOd)
				: undefined,
		},
		[t('detail.table.platnost-do')]: {
			value: query.data?.platnostDo
				? toDateReadable(query.data?.platnostDo)
				: undefined,
		},
		[t('detail.table.kod-sablony')]: {
			value: query.data?.kodSablony ?? '-',
		},
		[t('detail.table.postupitelny.klic')]: {
			value: query.data?.postupitelny
				? t('detail.table.postupitelny.ano')
				: t('detail.table.postupitelny.ne'),
		},
	};

	return (
		<Context.Provider
			value={{
				isLoading: query.isLoading,
				data: query.data,
				onDownload,
				complaintsForm: useComplaintsForm(id),
				invalidationForm: useInvalidationForm(id, onInvalidated),
				keyValues,
			}}>
			{children}
		</Context.Provider>
	);
};

export const useRezaDetailContext = () => {
	const context = useContext(Context);
	if (!context) {
		throw new Error(
			'useRezaDetailContext must be used within a DrozdRegistrationDetailContextProvider'
		);
	}
	return context;
};
