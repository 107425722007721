import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { ServiceCode } from '@gov-nx/module/service';

export const getRequiredDataBoxes = (
	code: ServiceCode
): DatovkaApiType['typSchranky'][] => {
	switch (code) {
		case ServiceCode['vypis-bodoveho-hodnoceni-ridice']:
		case ServiceCode['vypis-vyuziti-udaju-z-ROB']:
		case ServiceCode['vypis-udaju-z-ROB']:
		case ServiceCode['vypis-z-rejstriku-trestu-FO']: // nema tlactko pro odeslani datovkou
		case ServiceCode['zadost-o-poskytovani-udaju']:
		case ServiceCode['volicsky-prukaz']:
			return ['FO'];
		case ServiceCode['neverejny-vypis-z-ROS']:
		case ServiceCode['neverejny-vypis-z-ZR']: // nema tlactko pro odeslani datovkou
		case ServiceCode['vypis-vyuziti-udaju-z-ROS']:
			return ['PFO', 'PO'];
		case ServiceCode['podani-zadosti-o-informace']:
		case ServiceCode['potvrzeni-o-studiu']:
		case ServiceCode['trestni-oznameni']:
		case ServiceCode['verejny-vypis-z-ROS']:
		case ServiceCode['vypis-z-IR']:
		case ServiceCode['vypis-z-rejstriku-trestu-PO']:
		case ServiceCode['vypis-z-verejneho-rejstriku']:
		case ServiceCode['vypis-z-zivnostenskeho-rejstriku']:
		case ServiceCode['vypis-ze-seznamu-KD']:
			return [
				'PFO',
				'FO',
				'PFO_ADVOK',
				'PFO_DANPOR',
				'PFO_INSSPR',
				'PFO_AUDITOR',
				'PFO_ZNALEC',
				'PFO_TLUMOCNIK',
				'PFO_ARCH',
				'PFO_AIAT',
				'PFO_AZI',
				'PFO_REQ',
			];
		case ServiceCode['zadost-o-osvedceni-DIG-ukonu']:
			return [
				'FO',
				'PFO',
				'PO',
				'PO_REQ',
				'PFO_ADVOK',
				'PFO_DANPOR',
				'PFO_INSSPR',
				'PFO_AUDITOR',
				'PFO_ZNALEC',
				'PFO_TLUMOCNIK',
				'PFO_ARCH',
				'PFO_AIAT',
				'PFO_AZI',
				'PFO_REQ',
			];
		default:
			return [];
	}
};
