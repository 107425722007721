import { useCallback } from 'react';
import {
	useAuthStore,
	useCmsStore,
	useFiltersStore,
} from '@gov-nx/store/portal-obcana';
import { useDataBoxStore, usePersonStore } from '@gov-nx/store/portal-obcana';

export const useAppLogout = () => {
	const dataBoxReset = useDataBoxStore('dataBoxReset');
	const personReset = usePersonStore('personReset');
	const resetCms = useCmsStore('resetCms');
	const filtersResetAction = useFiltersStore('filtersResetAction');
	const logoutAction = useAuthStore('logout');

	const logout = useCallback(() => {
		logoutAction();

		dataBoxReset();
		personReset();
		resetCms();
		filtersResetAction();
	}, [logoutAction, dataBoxReset, personReset, resetCms, filtersResetAction]);

	return { logout };
};
