import { useEffect, useRef, useState } from 'react';
import { Optional } from '@gov-nx/core/types';

export const delay = (time: number): Promise<void> => {
	return new Promise((resolve) => {
		setTimeout(resolve, time);
	});
};

export const useInterval = (callback: () => void, delay?: number): void => {
	const savedCallback = useRef<() => void>();

	useEffect(() => {
		savedCallback.current = callback;
	});

	useEffect(() => {
		const tick = () => {
			savedCallback.current?.();
		};

		if (!delay) return;

		const id = setInterval(tick, delay);
		return () => clearInterval(id);
	}, [delay]);
};

export const throttle = (fn: (value: string) => void, delay: number) => {
	let timer: ReturnType<typeof setTimeout> = 0 as unknown as ReturnType<
		typeof setTimeout
	>;
	return (value: Optional<string>) => {
		if (!value) return;
		clearTimeout(timer);
		timer = setTimeout(() => fn(value), delay);
	};
};

export const debounce = <T>(callback: (value: T) => void, wait: number) => {
	let timerId: ReturnType<typeof setTimeout> = 0 as unknown as ReturnType<
		typeof setTimeout
	>;
	return (value: T): void => {
		clearTimeout(timerId);
		timerId = setTimeout(() => {
			callback(value);
		}, wait);
	};
};

interface DebouncedValue<T> {
	value: Optional<T>;
	update: (value: Optional<T>) => void;
	reset: () => void;
}

export const useDebounce = <T>(ms: number): DebouncedValue<T> => {
	const [value, setValue] = useState<Optional<T>>(undefined);

	return {
		value,
		update: debounce(setValue, ms),
		reset: () => setValue(undefined),
	};
};
