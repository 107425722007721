import * as yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import { Maybe, Nullable } from '@gov-nx/core/types';
import { FormRadioProps } from '@gov-nx/ui/types';

interface FieldProps<Value extends string> {
	name: string;
	label?: string;
	isRequired?: boolean;
	isDisabled?: boolean;
	isRequiredMessage?: string;
	oneOf?: { value: Value[]; errorMessage: string };
	options: { name: Value; label: string }[];
	defaultValue: Nullable<Value>;
}

export type RadioDefinition = {
	schema: yup.StringSchema<Maybe<string>, AnyObject, Maybe<string>>;
	defaultValue: Nullable<string>;
	field: FormRadioProps;
};
export const getRadioDefinition = <Value extends string>(
	props: FieldProps<Value>
): RadioDefinition => {
	let schema = yup.string().nullable();
	if (props.isRequired) {
		schema = schema.required(props.isRequiredMessage);
	} else {
		schema = schema.optional();
	}

	if (props.oneOf) {
		schema = schema.oneOf(props.oneOf.value, props.oneOf.errorMessage);
	}

	return {
		schema,
		defaultValue: props.defaultValue,
		field: {
			field: {
				name: props.name,
				disabled: props.isDisabled,
				options: props.options.map((option) => ({
					value: option.name,
					label: { children: option.label },
				})),
			},
			label: {
				children: props.label,
			},
		},
	};
};
