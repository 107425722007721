import { FormSelectOption } from '@asseco/react-native-gov-components';
import { useLocale } from '@gov-nx/core/service';
import { PageCode } from '../../definitions/codes';

export enum EmailRadioOption {
	ANOTHER_EMAIL = 'ANOTHER_EMAIL',
}

export enum PhoneRadioOption {
	ANOTHER_PHONE = 'ANOTHER_PHONE',
}

export type InterruptionOption = FormSelectOption<'ano' | 'ne' | ''>;

export const useRosComplaintOptions = () => {
	const { t } = useLocale(PageCode['profil-ROS']);

	const interruptionOptions: InterruptionOption[] = [
		{
			value: '',
			label: t('formular.placeholder.pozastaveni-preruseni'),
		},
		{ label: t('formular.pole.pozastaveni.preruseni.ano'), value: 'ano' },
		{ label: t('formular.pole.pozastaveni.preruseni.ne'), value: 'ne' },
	];

	return {
		interruptionOptions,
	};
};

export enum RosComplaintVariant {
	BASIC_INFO = 'BASIC_INFO',
	AGENDA = 'AGENDA',
}
