import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import { hasCommonValue } from '@gov-nx/utils/common';

export interface DataBoxAccessProps {
	requiredTypes?: DatovkaApiType['typSchranky'][];
}

export const useDataBoxAccess = (
	{ requiredTypes }: DataBoxAccessProps = { requiredTypes: [] }
) => {
	const areRequiredTypes = !!(
		Array.isArray(requiredTypes) && requiredTypes.length
	);

	const list = useDataBoxStore('list');
	const getConnectedDataBoxesList = useDataBoxStore(
		'getConnectedDataBoxesList'
	);
	const getDisconnectedDataBoxes = useDataBoxStore('getDisconnectedDataBoxes');
	const getConnectedDataBoxesIds = useDataBoxStore('getConnectedDataBoxesIds');
	const getDataBoxesToConnect = useDataBoxStore('getDataBoxesToConnect');
	const getDataBoxesToCreate = useDataBoxStore('getDataBoxesToCreate');
	const connectedList = getConnectedDataBoxesList();
	const disconnectedList = getDisconnectedDataBoxes();
	const listToConnect = getDataBoxesToConnect();
	const listToCreate = getDataBoxesToCreate();
	const connectedIds = getConnectedDataBoxesIds();

	const connectedTypes = connectedList.map((dataBox) => dataBox.typSchranky);
	const disconnectedTypes = disconnectedList.map(
		(dataBox) => dataBox.typSchranky
	);
	const listToConnectTypes = listToConnect.map(
		(dataBox) => dataBox.typSchranky
	);
	const listToCreateTypes = listToCreate.map((dataBox) => dataBox.typSchranky);

	const possibleToConnect = areRequiredTypes
		? hasCommonValue(requiredTypes, listToConnectTypes)
		: !!listToConnectTypes.length;
	const possibleToCreate = areRequiredTypes
		? hasCommonValue(requiredTypes, listToCreateTypes)
		: !!listToCreateTypes.length;
	const possibleToConnectOrCreate = possibleToConnect || possibleToCreate;
	const areAnyConnected = areRequiredTypes
		? hasCommonValue(requiredTypes, connectedTypes)
		: !!connectedTypes.length;

	return {
		list,
		connectedList,
		connectedIds,
		disconnectedList,
		listToConnect,
		connectedTypes,
		disconnectedTypes,
		listToConnectTypes,
		listToCreateTypes,
		possibleToConnect,
		possibleToCreate,
		possibleToConnectOrCreate,
		areAnyConnected,
	};
};
