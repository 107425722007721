import { useEffect } from 'react';
import * as yup from 'yup';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { TypeOfSchool } from '@gov-nx/core/types';
import {
	getAutocompleteDefaultValues,
	getAutocompleteShape,
	useDataBoxShape,
	yearOptions,
} from '@gov-nx/utils/common';
import { ServiceCode } from '../definitions/codes';
import { DataRequest, FormData } from './service.types';

export interface FormInstanceProps {
	code: ServiceCode;
	dataBoxTypes: DatovkaApiType['typSchranky'][];
}

export function FormInstance({
	code,
	dataBoxTypes,
}: FormInstanceProps): FormDefinition<FormData> {
	const { getDataBoxShape, getDataBoxDefaultValues } = useDataBoxShape({
		types: dataBoxTypes,
	});

	const { getLocalize } = useTranslationWithNamespace();
	const { datovaSchrankaId, ...dataBoxAdditional } = getDataBoxShape({
		isRequired: false,
	});
	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			...dataBoxAdditional,
			odesilatelDSId: datovaSchrankaId,
			skola: getAutocompleteShape({
				requiredMessage: getLocalize(
					code,
					'formular.validace.skola-nazev.povinny'
				),
			}),
			adresatDSId: yup.string().optional(),

			fakulta: yup.string().when('skolaTyp', {
				is: TypeOfSchool.VS,
				then: yup
					.string()
					.required(getLocalize(code, 'formular.validace.fakulta.povinny')),
				otherwise: yup.string().optional(),
			}),
			skolniRok: yup
				.string()
				.required(getLocalize(code, 'formular.validace.skolni-rok.vyberte')),
			studijniProgram: yup
				.string()
				.required(
					getLocalize(code, 'formular.validace.studijni-program.povinny')
				),
			formaStudia: yup.string().optional(),
			skolaTyp: yup
				.string()
				.required(getLocalize(code, 'formular.validace.skola.vyberte')),
			druhStudia: yup.string().optional(),
		})
		.required();

	const dataBoxDefaultValues = getDataBoxDefaultValues();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			odesilatelDSId: dataBoxDefaultValues.datovaSchrankaId,
			naseCisloJednaci: dataBoxDefaultValues.naseCisloJednaci,
			naseSpisovaZnacka: dataBoxDefaultValues.naseSpisovaZnacka,
			adresatDSId: undefined,

			skola: getAutocompleteDefaultValues(),
			fakulta: undefined,
			skolniRok: yearOptions()[0].value,
			studijniProgram: undefined,
			formaStudia: undefined,
			skolaTyp: undefined,
			druhStudia: undefined,
		},
	});

	const type = formMethods.watch('skolaTyp');

	useEffect(() => {
		formMethods.setValue('skola', undefined);
	}, [type]);

	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData = (data: FormData): DataRequest => {
	return {
		params: {
			adresatDSId: data.skola?.selected?.datovaSchrankaId,
			naseCisloJednaci: data.naseCisloJednaci,
			naseSpisovaZnacka: data.naseSpisovaZnacka,
			odesilatelDSId: data.odesilatelDSId,
		},
		body: {
			kodPodani: 'POTVRZENI_O_STUDIU',
			fakulta: data.fakulta,
			druhStudia: data.druhStudia,
			formaStudia: data.formaStudia,
			skolaNazev: `${data.skola?.selected?.nazev} (${data.skola?.selected?.datovaSchrankaId})`,
			skolaDatovaSchrankaId: data.skola?.selected?.datovaSchrankaId ?? '',
			skolniRok: data.skolniRok,
			skolaTyp: data.skolaTyp,
			studijniProgram: data.studijniProgram,
		},
	};
};
