import {
	GovButton,
	GovControlGroup,
	GovDropdown,
	GovIcon,
	GovTooltip,
	GovTooltipContent,
} from '@gov-design-system-ce/react';
import { pageRouteResolver } from '@gov-nx/web';
import cx from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import {
	DATABOX_FOLDER_LABELS,
	DataBoxConfirmModalType,
	DataBoxFolderType,
	dataBoxLabel,
	DataBoxListStatesType,
	getDataBoxIcon,
	getDataBoxInitials,
	isDataBoxTypePO,
	useDataBoxMessageListContext,
	useDataBoxSearchFormContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button, Checkbox, Space } from '@gov-nx/ui/web';
import { DataBoxSearch } from './DataBoxSearch';

export const DataBoxMessageListHeader = () => {
	const { t } = useTranslation([PageCode['datove-schranky']]);

	const {
		dataBox,
		storage,
		messagesFilter: { dataBoxId, folderType, query, hasSearch },
		messageList,
		messageListCount,
		massOperations: { handleArchiveAllMessages },
		listState,
		setListState,
		messagesSelect: {
			showCheckboxes,
			updateAllMessagesSelection,
			isAllSelected,
		},
		handleOpenMobileMenu,
		setConfirmModal,
		controls,
		unreadMessagesCount,
		showUnreadMessagesCount,
	} = useDataBoxMessageListContext();
	const { form } = useDataBoxSearchFormContext();

	const navigate = useNavigate();

	return (
		<header
			className={
				hasSearch
					? 'p-4 bg-primary-200 [ ds:rounded-t-2xl ]'
					: cx(
							'flex justify-between items-center gap-1 p-4 bg-primary-100 [ ds:rounded-t-2xl ] [ xl:bg-neutral-white ]',
							showCheckboxes && '!bg-primary-200'
					  )
			}>
			{!hasSearch && (
				<div className="flex gap-2">
					<Button
						variant="primary"
						type="link"
						onClick={handleOpenMobileMenu}
						className={'block relative ds-no-underline [ xl:hidden ]'}
						wcagLabel={t('wcag.otevrit-postranni-panel')}
						wcagExpanded={true}
						wcagControls="ds-menu">
						<div
							className={
								'flex flex-col justify-center items-center w-10 h-10 rounded-full bg-neutral-white text-secondary-800'
							}>
							<GovIcon
								name={
									getDataBoxIcon(
										dataBox?.typSchranky as DatovkaApiType['typSchranky']
									) as string
								}
								className={'!w-3 !h-3 grow-0 shrink-0'}
							/>
							<h2
								className={'text-s font-bold'}
								aria-label={dataBox ? dataBoxLabel(dataBox, true) : undefined}>
								{dataBox && getDataBoxInitials(dataBox)}
							</h2>
						</div>
					</Button>
					<div className={'flex items-center'}>
						{showCheckboxes && (
							<Checkbox
								name="all-messages"
								checked={isAllSelected}
								onChange={() => {
									updateAllMessagesSelection(!isAllSelected);
								}}
								noLabel={true}
							/>
						)}
						<div>
							<h1 className={'text-xl font-bold'}>
								{t(DATABOX_FOLDER_LABELS[folderType])}
								{showUnreadMessagesCount && (
									<span
										className={
											'inline-flex items-center relative -top-0.5 px-1 ml-2 h-4 text-xs font-bold bg-warning-400 text-primary-600 rounded-full [ xl:hidden ]'
										}
										aria-label={t('zpravy.neprectene-zpravy')}>
										{unreadMessagesCount}
									</span>
								)}
							</h1>
							{folderType === DataBoxFolderType.Archived &&
								listState === DataBoxListStatesType.List &&
								storage.storageUsage?.isBeingUsed && (
									<span className={'block text-xs'}>
										{t('uloziste.vyuzito')}
										<Space />
										{storage.storageUsage?.usedFormattedShort}
										<Space />
										<GovTooltip position="bottom">
											{t('uloziste.z')}
											<Space />
											{storage.storageUsage?.maximumFormattedShort}
											<GovTooltipContent>
												{t('uloziste.celkove-uloziste', {
													'maximalni-kapacita':
														storage.storageUsage?.maximumFormattedShort,
												})}
											</GovTooltipContent>
										</GovTooltip>
									</span>
								)}
						</div>
					</div>
				</div>
			)}

			<div className="flex items-center gap-2">
				{!isDataBoxTypePO(dataBox) &&
					[DataBoxFolderType.Received, DataBoxFolderType.Sent].includes(
						folderType
					) &&
					listState === DataBoxListStatesType.List && (
						<GovControlGroup
							className={cx(
								'w-auto !border-hidden [ xl:!border-solid ]',
								!messageList.length && '!border-primary-300'
							)}>
							<Button
								variant="primary"
								type="base"
								size="s"
								disabled={!messageList.length}
								onClick={handleArchiveAllMessages}
								className={cx(
									!messageList.length && '!border-r-primary-300',
									'!hidden [ xl:!inline-block ]'
								)}
								wcagLabel={t('zpravy.vypis.archivovat-vse')}>
								<GovIcon name="archive-outline" />
								{t('zpravy.vypis.archivovat-vse')}
							</Button>
							<GovDropdown position="right">
								<GovButton
									variant="primary"
									type="base"
									size="s"
									disabled={!messageList.length}>
									<GovIcon
										name="chevron-down"
										slot="left-icon"
										className={'!hidden [ xl:!inline-block ]'}
									/>
									<GovIcon
										name="archive-outline"
										slot="left-icon"
										className={'[ xl:!hidden ]'}
									/>
								</GovButton>
								<ul slot="list">
									<li>
										<Button
											variant="secondary"
											type="base"
											size="s"
											disabled={!messageList.length}
											onClick={handleArchiveAllMessages}
											wcagLabel={t('zpravy.vypis.archivovat-vse')}>
											{t('zpravy.vypis.archivovat-vse')}
											<GovIcon
												slot="right-icon"
												name="archive-outline"
											/>
										</Button>
									</li>
									<li>
										<Button
											variant="secondary"
											type="base"
											size="s"
											disabled={!messageList.length}
											onClick={() => {
												setListState(DataBoxListStatesType.MassArchive);
												navigate(
													pageRouteResolver('datove-schranky.schranka', {
														dataBoxId,
														folderType,
													})
												);
											}}
											wcagLabel={t('zpravy.vypis.archivovat-vybrane')}>
											{t('zpravy.vypis.archivovat-nektere')}
											<GovIcon
												slot="right-icon"
												name="archive-outline"
											/>
										</Button>
									</li>
								</ul>
							</GovDropdown>
						</GovControlGroup>
					)}

				{folderType === DataBoxFolderType.Archived &&
					listState === DataBoxListStatesType.List && (
						<Button
							variant="primary"
							type="base"
							size="s"
							disabled={!messageList.length}
							onClick={() => {
								setListState(DataBoxListStatesType.MassRemove);
								navigate(
									pageRouteResolver('datove-schranky.schranka', {
										dataBoxId,
										folderType,
									})
								);
							}}
							wcagLabel={t('zpravy.vypis.hromadne-mazani')}>
							<GovIcon
								slot="left-icon"
								name="trash"
							/>
							<span className={'!hidden [ xl:!inline-block ]'}>
								{t('zpravy.vypis.hromadne-mazani')}
							</span>
						</Button>
					)}

				{folderType === DataBoxFolderType.Trash &&
					listState === DataBoxListStatesType.List && (
						<>
							<Button
								variant="primary"
								type="base"
								size="s"
								disabled={!messageList.length}
								className={'[ xl:!hidden ]'}
								onClick={() => {
									setListState(DataBoxListStatesType.MassRestore);
									navigate(
										pageRouteResolver('datove-schranky.schranka', {
											dataBoxId,
											folderType,
										})
									);
								}}
								wcagLabel={t('zpravy.vypis.hromadne-obnoveni')}>
								<GovIcon
									slot="left-icon"
									name="arrow-counterclockwise"
								/>
							</Button>
							<GovControlGroup
								className={cx(
									'w-auto !border-hidden [ xl:!border-solid ]',
									!messageList.length && '!border-primary-300'
								)}>
								<Button
									variant="primary"
									type="base"
									size="s"
									disabled={!messageList.length}
									onClick={() => {
										setConfirmModal(DataBoxConfirmModalType.emptyTrash);
									}}
									className={cx(
										!messageList.length && '!border-r-primary-300',
										'!hidden [ xl:!inline-block ]'
									)}
									wcagLabel={t('zpravy.vypis.trvale-smazat-zobrazene')}>
									<GovIcon name="trash" />
									{t('zpravy.vypis.trvale-smazat-zobrazene')}
								</Button>
								<GovDropdown position="right">
									<GovButton
										variant="primary"
										type="base"
										size="s"
										disabled={!messageList.length}>
										<GovIcon
											name="chevron-down"
											slot="left-icon"
											className={'!hidden [ xl:!inline-block ]'}
										/>
										<GovIcon
											name="trash"
											slot="left-icon"
											className={'[ xl:!hidden ]'}
										/>
									</GovButton>
									<ul slot="list">
										<li>
											<Button
												variant="secondary"
												type="base"
												size="s"
												disabled={!messageList.length}
												onClick={() => {
													setConfirmModal(DataBoxConfirmModalType.emptyTrash);
												}}
												wcagLabel={t('zpravy.vypis.trvale-smazat-zobrazene')}>
												{t('zpravy.vypis.trvale-smazat-zobrazene')}
												<GovIcon
													slot="right-icon"
													name="trash"
												/>
											</Button>
										</li>
										<li>
											<Button
												variant="secondary"
												type="base"
												size="s"
												disabled={!messageList.length}
												onClick={() => {
													setListState(
														DataBoxListStatesType.MassPermanentRemove
													);
													navigate(
														pageRouteResolver('datove-schranky.schranka', {
															dataBoxId,
															folderType,
														})
													);
												}}
												wcagLabel={t('zpravy.vypis.trvale-smazat-nektere')}>
												{t('zpravy.vypis.trvale-smazat-nektere')}
												<GovIcon
													slot="right-icon"
													name="trash"
												/>
											</Button>
										</li>
										<li className={'!hidden [ xl:!block ]'}>
											<Button
												variant="secondary"
												type="base"
												size="s"
												disabled={!messageList.length}
												onClick={() => {
													setListState(DataBoxListStatesType.MassRestore);
													navigate(
														pageRouteResolver('datove-schranky.schranka', {
															dataBoxId,
															folderType,
														})
													);
												}}
												wcagLabel={t('zpravy.vypis.hromadne-obnoveni')}>
												{t('zpravy.vypis.hromadne-obnoveni')}
												<GovIcon
													slot="right-icon"
													name="arrow-counterclockwise"
												/>
											</Button>
										</li>
									</ul>
								</GovDropdown>
							</GovControlGroup>
						</>
					)}

				{listState === DataBoxListStatesType.List &&
					folderType !== DataBoxFolderType.Trash && (
						<Button
							variant="primary"
							type="outlined"
							size="s"
							disabled={!messageList.length}
							onClick={() => {
								setListState(DataBoxListStatesType.Search);
								navigate(
									pageRouteResolver('datove-schranky.schranka', {
										dataBoxId,
										folderType,
									})
								);
							}}
							wcagLabel={t('zpravy.vypis.wcag.hledat')}>
							<GovIcon
								slot="left-icon"
								name="search"
							/>
						</Button>
					)}
			</div>

			{hasSearch && (
				<>
					<div className={'flex items-center gap-4 mb-2'}>
						<DataBoxSearch />
						{listState !== DataBoxListStatesType.List && (
							<Button
								variant="primary"
								type="base"
								size="s"
								onClick={() => {
									setListState(DataBoxListStatesType.List);
									form.resetField('query');
								}}
								wcagLabel={t('zpravy.zrusit')}>
								<GovIcon
									slot="left-icon"
									name="x-lg"
								/>
							</Button>
						)}
					</div>

					<div className={'flex items-center justify-between gap-4'}>
						{listState === DataBoxListStatesType.Search && (
							<p className={'text-s text-secondary-700'}>
								<Trans
									t={t}
									i18nKey={
										folderType === DataBoxFolderType.Received
											? 'slozky.v-dorucenych'
											: folderType === DataBoxFolderType.Sent
											? 'slozky.v-odeslanych'
											: folderType === DataBoxFolderType.Archived
											? 'slozky.v-archivu'
											: 'slozky.v-kosi'
									}
									components={{ strong: <strong className="capitalize" /> }}
								/>
							</p>
						)}

						{listState === DataBoxListStatesType.Search &&
							query &&
							query.length >= 3 &&
							!controls.processLoading && (
								<span className={'text-s text-secondary-700 mr-auto'}>
									{t('vyhledavani.vysledku')}:{' '}
									<strong>{messageListCount}</strong>
								</span>
							)}

						{!!messageListCount && query && (
							<>
								{[
									DataBoxListStatesType.SearchMassArchive,
									DataBoxListStatesType.SearchMassPermanentRemove,
									DataBoxListStatesType.SearchMassRemove,
									DataBoxListStatesType.SearchMassRestore,
								].includes(listState) && (
									<Checkbox
										name="all-messages"
										size="s"
										onChange={(e) => {
											updateAllMessagesSelection(e.target.checked);
										}}
										label={{
											children: t('vyhledavani.vsechny-vyhledane'),
										}}
									/>
								)}
								<span>
									{listState === DataBoxListStatesType.Search && (
										<Button
											variant="primary"
											type="base"
											size="s"
											onClick={() => {
												if (folderType === DataBoxFolderType.Archived) {
													setListState(DataBoxListStatesType.SearchMassRemove);
												} else if (folderType === DataBoxFolderType.Trash) {
													setListState(
														DataBoxListStatesType.SearchMassPermanentRemove
													);
												} else {
													setListState(DataBoxListStatesType.SearchMassArchive);
												}
											}}
											wcagLabel={t('zpravy.vypis.hromadne-obnoveni')}>
											<GovIcon
												slot="left-icon"
												name="check-multiple"
											/>
											{t('zpravy.vypis.hromadne-obnoveni')}
										</Button>
									)}
									{[
										DataBoxListStatesType.SearchMassArchive,
										DataBoxListStatesType.SearchMassPermanentRemove,
										DataBoxListStatesType.SearchMassRemove,
										DataBoxListStatesType.SearchMassRestore,
									].includes(listState) && (
										<Button
											variant="primary"
											type="base"
											size="s"
											onClick={() => {
												setListState(DataBoxListStatesType.Search);
											}}
											wcagLabel={t('zpravy.vypis.ukoncit-hromadne-akce')}>
											{t('zpravy.vypis.ukoncit-hromadne-akce')}
										</Button>
									)}
								</span>
							</>
						)}
					</div>
				</>
			)}

			{listState !== DataBoxListStatesType.List && !hasSearch && (
				<Button
					variant="primary"
					type="base"
					size="s"
					onClick={() => {
						setListState(DataBoxListStatesType.List);
					}}
					wcagLabel={t('zpravy.zrusit')}>
					<GovIcon
						slot="left-icon"
						name="x-lg"
					/>
				</Button>
			)}
		</header>
	);
};
