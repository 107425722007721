import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import { pageRouteResolver } from '@gov-nx/web';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import {
	PageCode,
	useRezaLocale,
	useRezaTemplateListContext,
} from '@gov-nx/module/page';
import {
	ContentLayout,
	HeaderSearchBar,
	KeyValues,
	Tile,
	Tiles,
} from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';
import { CmsPageRender } from '../../../../Cms/CmsPageRender';
import { PoFormWeb } from '../../../../Form/PoForm';
import { RezaTemplateListSkeleton } from './RezaTemplateListSkeleton';

export const RezaTemplateList = () => {
	const { t } = useRezaLocale();

	const { isLoading, templates, formDefinition, fields, onSubmit } =
		useRezaTemplateListContext();

	return (
		<CmsPageRender
			code={PageCode['reza']}
			override={{ name: t('sablony.seznam.title') }}>
			<ContentLayout>
				<FormProvider {...formDefinition.formMethods}>
					<PoFormWeb
						formDefinition={formDefinition}
						onSubmit={onSubmit}>
						<HeaderSearchBar fields={fields}></HeaderSearchBar>
					</PoFormWeb>
				</FormProvider>

				{isLoading ? (
					<RezaTemplateListSkeleton />
				) : templates.length === 0 ? (
					<GovMessage variant={'primary'}>
						<GovIcon
							slot={'icon'}
							type={'basic'}
							name={'info'}
						/>
						{t('sablony.seznam.empty')}
					</GovMessage>
				) : (
					<Tiles>
						{templates.map((template) => {
							return (
								<Tile
									key={template.kod}
									name={template.nazev ?? '-'}
									to={pageRouteResolver('reza.templates.detail', {
										id: template.kod,
									})}
									icon={{ name: 'chevron-right', type: 'basic' }}>
									<div className={'!text-secondary-700'}>
										<KeyValues
											options={[
												{
													label: t('sablony.seznam.item.platnost-od.label'),
													value: template.platnostOd
														? toDateReadable(template.platnostOd)
														: '-',
												},
												{
													label: t('sablony.seznam.item.platnost-do.label'),
													value: template.platnostDo
														? toDateReadable(template.platnostDo)
														: t('sablony.seznam.item.platnost-do.empty'),
												},
												{
													label: t('sablony.seznam.item.verze'),
													value: template.verze,
												},
												{
													label: t('sablony.seznam.item.identifikator'),
													value: template.kod,
												},
											]}
										/>
									</div>
								</Tile>
							);
						})}
					</Tiles>
				)}
			</ContentLayout>
		</CmsPageRender>
	);
};
