import { GovIcon } from '@gov-design-system-ce/react';
import cx from 'classnames';
import { useState } from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';
import { MarkdownRender } from '../../Markdown/MarkdownRender';

interface DataBoxOnboardingProps {
	show: boolean;
	onFinish: () => void;
}

export const DataBoxOnboarding = ({
	show,
	onFinish,
}: DataBoxOnboardingProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const [step, setStep] = useState(0);
	const [visible, setVisible] = useState(show);

	if (!visible) return null;

	const tooltips = [
		{
			arrowPosition: 'top',
			icon: 'exclamation-circle',
			title: tn('onboarding.krok1.nadpis'),
			desc1: tn('onboarding.krok1.popis1'),
			desc2: tn('onboarding.krok1.popis2'),
		},
		{
			arrowPosition: 'left',
			icon: 'archive-outline',
			title: tn('onboarding.krok2.nadpis'),
			desc1: tn('onboarding.krok2.popis1'),
		},
		{
			arrowPosition: 'left',
			icon: 'trash',
			title: tn('onboarding.krok3.nadpis'),
			desc1: tn('onboarding.krok3.popis1'),
			desc2: tn('onboarding.krok3.popis2'),
		},
	];

	const lastStep = step === tooltips.length - 1;

	const nextStep = () => {
		setStep((step) => step + 1);
	};

	const resetSteps = () => {
		setStep(0);
	};

	const cancel = () => {
		setVisible(false);
		onFinish();
	};

	return (
		<div
			className={cx(
				'absolute top-[100px] left-[100px] w-[400px] rounded-xl shadow p-4 bg-gradient-to-b to-primary-700 from-primary-600 animate-slide-up before:absolute before:block before:w-0 before:h-0 before:border-[16px]  before:border-transparent [ md:p-6 ]',
				tooltips[step].arrowPosition === 'top'
					? 'before:border-t-0 before:border-b-[#2463a3] before:left-1/2 before:bottom-full before:-translate-x-1/2'
					: 'before:border-l-0 before:border-r-[#255991] before:top-1/2 before:right-full before:-translate-y-1/2'
			)}>
			<div>
				<GovIcon
					name={tooltips[step].icon}
					className="text-neutral-white mb-3"
				/>
				<p className="font-bold text-neutral-white mb-3 [ md:mb-4 ]">
					{tooltips[step].title}
				</p>
				<MarkdownRender applyClasses={{ paragraph: 'text-primary-200 text-s' }}>
					{tooltips[step].desc1 as string}
				</MarkdownRender>
				{!!tooltips[step].desc2 && (
					<MarkdownRender
						applyClasses={{ paragraph: 'text-primary-200 text-s mt-2' }}>
						{tooltips[step].desc2 as string}
					</MarkdownRender>
				)}
				<div className="flex justify-between items-center mt-4 [ md:mt-6 ]">
					<div className="font-bold text-primary-300 text-xs">
						{step + 1}/{tooltips.length}
					</div>
					<div className="flex gap-4 items-center">
						<Button
							variant={'primary'}
							size={'s'}
							inverse
							type={'outlined'}
							onClick={lastStep ? resetSteps : cancel}>
							<span>
								{lastStep
									? tn('onboarding.projit-tipy-znovu')
									: tn('onboarding.preskocit-tipy')}
							</span>
						</Button>

						<Button
							variant={'primary'}
							size={'s'}
							inverse
							type={'solid'}
							onClick={lastStep ? cancel : nextStep}>
							<span>
								{lastStep ? tn('onboarding.ukoncit') : tn('onboarding.dalsi')}
							</span>
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
