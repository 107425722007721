import { GovSkeleton } from '@gov-design-system-ce/react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { ContentLayout, DataTable, LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';
import { ContactDetailSkeleton } from '../../nastaveni/kontaktni-udaje/Skeletons';

export const ProfileROSDetailSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);

	return (
		<LayoutGap gap={4}>
			<ContentLayout>
				<DataTable
					noStripped
					responsive>
					<tbody>
						{Array.from({ length: 10 }).map((_, i) => (
							// eslint-disable-next-line react/no-array-index-key
							<tr key={i}>
								<td className="w-1/2">
									<GovSkeleton
										height={'18px'}
										variant={'secondary'}
										wcagLabel={
											t('loading.wcag.processing', {
												namespace: LocalizeNameSpaceTypes.Global,
											}) as string
										}
										width={generateRandomNumber(20, 60) + '%'}
										className={'flex-1 mb-0 pb-0'}
									/>
								</td>
								<td className="w-1/2">
									<GovSkeleton
										height={'18px'}
										variant={'secondary'}
										wcagLabel={
											t('loading.wcag.processing', {
												namespace: LocalizeNameSpaceTypes.Global,
											}) as string
										}
										width={generateRandomNumber(20, 60) + '%'}
										className={'flex-1 mb-0 pb-0'}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</DataTable>
			</ContentLayout>
			<ContentLayout>
				<ContactDetailSkeleton />
				<ContactDetailSkeleton />
			</ContentLayout>
		</LayoutGap>
	);
};
