import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useConnection } from '@gov-nx/core/hooks';
import { ResponseError } from '../errors/errors.types';
import { usePoResponseError } from '../errors/usePoResponseError';
import { PoQueryConfig } from './types';

export const usePoMutation = <
	TData = unknown,
	TVariables = void,
	TContext = unknown
>(
	options: UseMutationOptions<
		TData,
		AxiosError<ResponseError>,
		TVariables,
		TContext
	>,
	config?: PoQueryConfig
) => {
	const { handleError } = usePoResponseError();
	const { isOffline } = useConnection();

	return useMutation({
		...options,
		onError: (error, variables, context) => {
			// Note: Don't throw errors when no internet connection
			if (isOffline) return;

			if (config?.errorIgnoreFilter && config.errorIgnoreFilter(error)) {
				return;
			}
			handleError(error);

			options.onError && options.onError(error, variables, context);
		},
	});
};
