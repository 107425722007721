import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { compose, prop } from '@gov-nx/core/types';
import {
	FormPhoneNumberProps,
	PhoneWithCountryCodeShape,
} from '@gov-nx/ui/types';
import {
	digitsOnly,
	isCzechFormat,
	phoneShapeValidator,
	phoneValidator,
} from './Validator/phone';

interface PhoneShape {
	isRequired?: boolean;
	isRequiredMessage?: string;
	isInvalidMessage?: string;
}

export const usePhoneShape = () => {
	const { t } = useTranslation([LocalizeNameSpaceTypes.Form]);

	return {
		getPhoneShape: (props?: PhoneShape): yup.StringSchema => {
			const schema = yup
				.string()
				.test(
					'length',
					props?.isInvalidMessage ??
						t('telefon.validace.telefon-neni-ve-spravnem-tvaru'),
					phoneValidator
				);

			if (props?.isRequired) {
				return schema.required(
					props?.isRequiredMessage ?? t('telefon.validace.zadejte-telefon')
				);
			}

			return schema;
		},
	};
};

export const getDefaultPhoneValue = (): PhoneWithCountryCodeShape => ({
	countryCode: '420',
	number: '',
});

interface PhoneShapeOptions {
	isRequired?: boolean;
	isRequiredWhen?: { fieldName: string; is: boolean };
}
export const usePhoneShapeWithCountryCodes = () => {
	const { t } = useTranslation([LocalizeNameSpaceTypes.Form]);
	return {
		getPhoneShape: (options?: PhoneShapeOptions) => {
			let shape = yup
				.object()
				.shape({
					countryCode: yup.string(),
					number: yup.string(),
				})
				.test(
					'format',
					t('telefon.validace.telefon-neni-ve-spravnem-tvaru'),
					phoneShapeValidator
				);

			if (options?.isRequiredWhen) {
				shape = shape.when(options.isRequiredWhen.fieldName, {
					is: options.isRequiredWhen.is,
					then: (schema) =>
						schema.test(
							'required-when',
							t('telefon.validace.zadejte-telefon'),
							compose((value) => !!value, prop('number'))
						),
				});
			}

			if (options?.isRequired) {
				shape = shape.test(
					'required',
					t('telefon.validace.zadejte-telefon'),
					compose((value) => !!value, prop('number'))
				);
			}
			return shape;
		},
		getDefaultValue: getDefaultPhoneValue,
	};
};

export const formatPhoneNumber = (phoneNumber: string): string => {
	if (isCzechFormat(phoneNumber)) {
		const cleanedNumber = digitsOnly(phoneNumber);
		if (phoneNumber.startsWith('+420')) {
			return cleanedNumber.replace(
				/(\d{3})(\d{3})(\d{3})(\d{3})/,
				'+$1 $2 $3 $4'
			);
		}
		if (phoneNumber.startsWith('00420')) {
			return cleanedNumber.replace(
				/(\d{5})(\d{3})(\d{3})(\d{3})/,
				'$1 $2 $3 $4'
			);
		}
		return cleanedNumber.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
	}

	return phoneNumber;
};

export const phoneShapeToString = (phone: PhoneWithCountryCodeShape): string =>
	['+', phone.countryCode, phone.number].join('');

export const phoneShapeReadable: (phone: PhoneWithCountryCodeShape) => string =
	compose(formatPhoneNumber, phoneShapeToString);

export interface PhoneDefinition {
	defaultValue: PhoneWithCountryCodeShape;
	field: FormPhoneNumberProps;
	schema: yup.ObjectSchema<{
		number: yup.StringSchema;
		countryCode: yup.StringSchema;
	}>;
}

interface FieldProps {
	name: string;
	label: string;
	countryCodeLabel: string;
	placeholder?: string;
	isDisabled?: boolean;
	isRequired?: boolean;
	isRequiredWhen?: { fieldName: string; is: boolean };
}
export const usePhoneDefinition = (props: FieldProps): PhoneDefinition => {
	const { getPhoneShape, getDefaultValue } = usePhoneShapeWithCountryCodes();

	return {
		defaultValue: getDefaultValue(),
		schema: getPhoneShape(props),
		field: {
			field: {
				name: props.name,
				placeholder: props.placeholder,
				isDisabled: props.isDisabled,
			},
			label: { children: props.label },
			countryCodeLabel: { children: props.countryCodeLabel },
		},
	};
};
