import { FunctionComponent, lazy } from 'react';
import { ServiceCode } from '@gov-nx/module/service';
import type { ServiceContainerProps } from './Service/types';

export * from './Page/kde-o-mne-stat-vede-udaje/AgendasSkeleton';
export * from './Page/kde-o-mne-stat-vede-udaje/AgendasView';

export * from './Applicant/ApplicantDetails';
export * from './Applicant/ApplicantFO';

export * from './Cms/CmsFaq';
export * from './Cms/CmsMessageContainer';
export * from './Cms/CmsPageProvider';
export * from './Cms/CmsPageRender';
export * from './Cms/CmsServiceRender';
export * from './Cms/CmsServiceSkeleton';
export * from './Cms/CmsExternalServiceListener';
export * from './CommunicationDetails/UseCommunicationDataEmail';
export * from './CommunicationDetails/UseCommunicationDataPhone';
export * from './CommunicationDetails/UseCommunicationDataAddress';
export * from './Markdown/MarkdownRender';

export * from './DataBox/Connect/DataBoxConnectListener';

export * from './DataBox/ActionPage/DataBoxActionInfoPage';
export * from './DataBox/DataBoxes/DataBoxItemNavigation';
export * from './DataBox/DataBoxes/DataBoxItemNavigationItem';
export * from './DataBox/DataBoxes/DataBoxAddCreditModal';
export * from './DataBox/DataBoxes/DataBoxSupportModal';
export * from './DataBox/DataBoxes/DataBoxesItem';
export * from './DataBox/DataBoxes/DataBoxesList';
export * from './DataBox/DataBoxes/DataBoxesNavigation';
export * from './DataBox/DataBoxes/DataBoxesPage';
export * from './DataBox/MessageDetail/DataBoxAttachmentItem';
export * from './DataBox/MessageDetail/DataBoxAttachmentList';
export * from './DataBox/MessageDetail/DataBoxMessageConfirmModal';
export * from './DataBox/MessageDetail/DataBoxMessageDetailPage';
export * from './DataBox/MessageDetail/DataBoxMessageOverview';
export * from './DataBox/MessageDetail/DataBoxMessageDataBoxDetail';
export * from './DataBox/MessageForm/DataBoxMessageAnswerPage';
export * from './DataBox/MessageForm/DataBoxNewMessagePage';
export * from './DataBox/MessageList/DataBoxMessageItem';
export * from './DataBox/MessageList/DataBoxMessageList';
export * from './DataBox/MessageList/DataBoxMessageListConfirmModal';
export * from './DataBox/MessageList/DataBoxMessageListFooter';
export * from './DataBox/MessageList/DataBoxMessageListHeader';
export * from './DataBox/MessageList/DataBoxMessageListMain';
export * from './DataBox/MessageList/DataBoxMessageListPage';
export * from './DataBox/MessageList/DataBoxSearch';
export * from './DataBox/Onboarding/DataBoxOnboarding';

export * from './Download/DownloadListener';
export * from './Download/DownloadListenerCore';

export * from './Page/registr-ridicu/DriversRegistrySkeleton';
export * from './Page/registr-ridicu/DriversRegistryView';

export * from './Message/ToastMessageListener';

export * from './Page/nastaveni/kontaktni-udaje/rob/ContactView';
export * from './Page/nastaveni/kontaktni-udaje/ros/ContactListView';

export * from './Page/nastaveni/upozorneni/SettingsNotificationForm';
export * from './Page/nastaveni/upozorneni/SettingsNotificationFormSkeleton';
export * from './Page/nastaveni/datovky/DataboxesSettings';
export * from './Page/nastaveni/datovky/DataboxConnectedSettingsModal';

export * from './Page/doklady/DocumentsView';
export * from './Page/doklady/IdentityDocumentList';
export * from './Page/doklady/IdentityDocumentListSkeleton';
export * from './Page/doklady/IdentityDocumentSkeleton';
export * from './Page/doklady/IdentityDocumentView';

export * from './Page/doklady/vlastni-doklady/OwnDocumentForm';
export * from './Page/doklady/vlastni-doklady/OwnDocumentFormSkeleton';

export * from './Favorite/FavoriteToggle';

export * from './Page/registr-ZP/RegisterZPSkeleton';
export * from './Page/registr-ZP/RegisterZPView';

export * from './Page/muj-katastr-nemovitosti/CadastreOfRealEstateSkeleton';
export * from './Page/muj-katastr-nemovitosti/CadastreOfRealEstateView';

export * from './Page/registr-SV/detail/RegisterSVDetailView';
export * from './Page/registr-SV/seznam/RegisterSVListSkeleton';
export * from './Page/registr-SV/seznam/RegisterSVListView';

export * from './Page/epetice/detail/EPetitionDetailSkeleton';
export * from './Page/epetice/detail/EPetitionDetailView';
export * from './Page/epetice/form/EPetitionFormSkeleton';
export * from './Page/epetice/nova/EPetitionNewView';
export * from './Page/epetice/seznam/EPetitionSkeleton';
export * from './Page/epetice/seznam/EPetitionView';
export * from './Page/epetice/editace/EPetitionEditView';

export * from './Page/moje-soubory/nahrat/MyFilesUploadForm';
export * from './Page/moje-soubory/seznam/MyFilesListView';
export * from './Page/moje-soubory/seznam/MyFilesSkeleton';

export * from './Page/dashboard/DashboardUnreadMessagesWidget';
export * from './Page/dashboard/DashboardDataBoxConnectBanner';
export * from './Page/dashboard/DashboardUnreadNotificationsWidget';

export * from './State/ErrorPage';
export * from './State/ErrorBoundary';

export * from './Page/profil-ROB/ProfilROBSkeleton';
export * from './Page/profil-ROB/ProfileROBView';
export * from './Page/profil-ROS/ProfilROSSkeleton';
export * from './Page/profil-ROS/ProfileROSView';
export * from './Page/profil-ROS/detail/ProfileROSDetailView';
export * from './Page/profil-certifikaty/ProfilCertificatesView';
export * from './Page/profil-certifikaty/ProfilCertificatesSkeleton';
export * from './Page/profil-edoklady/ProfileEDocumentsSkeleton';
export * from './Page/profil-edoklady/ProfileEDocumentsView';

export * from './Page/moje-podani/detail/MySubmissionDetail';
export * from './Page/moje-podani/detail/MySubmissionsDetailSkeleton';
export * from './Page/moje-podani/seznam/MySubmissionsList';
export * from './Page/moje-podani/seznam/MySubmissionsListSkeleton';
export * from './Page/moje-podani/tabs/MySubmissionsTabsView';

export * from './Page/moje-autorizace/seznam/MyAuthorizationContentView';
export * from './Page/moje-autorizace/detail/MyAuthorizationDetailView';
export * from './Page/moje-autorizace/detail/MyAuthorizationDetailSkeleton';

export * from './Page/moje-osvedceni/seznam/MyVerificationsList';

export * from './Page/upozorneni/NotificationsSkeleton';
export * from './Page/upozorneni/NotificationsView';

export * from './Page/zdrav-dokumentace/HealthDocumentationSkeleton';
export * from './Page/zdrav-dokumentace/HealthDocumentationView';

export * from './Page/rozcestnik/SignpostView';

export * from './Form/PoForm';
export * from './Tile/Tile';

export * from './Wizard/WizardStepWeb';
export * from './Wizard/LastStepTitle';

export * from './Page/podpora/SupportView';
export * from './Page/sprava-souhlasu/seznam/ConsentManagementView';
export * from './Page/sprava-souhlasu/seznam/ConsentManagementSkeleton';
export * from './Page/sprava-souhlasu/detail/ConsentManagementDetailView';
export * from './Page/sprava-souhlasu/detail/ConsentManagementDetailSkeleton';

export * from './Page/prirucka/UserManualSkeleton';
export * from './Page/prirucka/UserManualView';
export * from './Page/drozd/DrozdInfoView';
export * from './Page/drozd/DrozdInfoSkeleton';

export * from './Page/reza/seznam/RezaList';
export * from './Page/reza/seznam/RezaListSkeleton';
export * from './Page/reza/detail/RezaDetail';
export * from './Page/reza/detail/RezaDetailSkeleton';
export * from './Page/reza/sablony/seznam/RezaTemplateList';
export * from './Page/reza/sablony/seznam/RezaTemplateListSkeleton';
export * from './Page/reza/sablony/detail/RezaTemplateDetail';
export * from './Page/reza/sablony/detail/RezaTemplateDetailSkeleton';

export * from './SideBox/SideBoxHeader';

export const loadServiceByCode = (code: ServiceCode) => {
	return lazy<FunctionComponent<ServiceContainerProps>>(() =>
		import('./Service/' + code + '/Service').then(({ ServiceContainer }) => ({
			default: ServiceContainer,
		}))
	);
};
