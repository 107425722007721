import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import {
	NotificationsOrder,
	isResponseStatus,
	notificationsQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useConnection } from '@gov-nx/core/hooks';
import { isUnreadNotification } from '@gov-nx/module/page';

export const useNotificationMessageLoader = () => {
	const { isOnline } = useConnection();
	const queryClient = useQueryClient();

	const allNotificationsQuery = usePoQuery(
		{
			queryKey: ['notifications'],
			queryFn: () =>
				notificationsQuery({
					razeni: NotificationsOrder['-datumACasVytvoreni'],
					start: 0,
					pocet: 50,
				}),
			retry: 1,
			enabled: isOnline,
		},
		{
			errorIgnoreFilter: isResponseStatus(500),
		}
	);

	const reloadNotifications = async () => {
		await queryClient.invalidateQueries({ queryKey: ['notifications'] });
	};

	const unreadNotifications =
		allNotificationsQuery.data?.seznam?.filter(isUnreadNotification) ?? [];

	useEffect(() => {
		reloadNotifications();
	}, [isOnline]);

	return {
		unreadNotificationsCount: unreadNotifications.length,
		unreadNotifications,
		reloadNotifications,
	};
};
