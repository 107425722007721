import { useEffect, useState } from 'react';
import {
	notificationsDetailQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { isUnreadNotification, NotificationDto } from '@gov-nx/module/page';
import { extractLink, extractPoLinkAttributes } from '@gov-nx/utils/common';
import { useAppLanguage } from '../Localization/useAppLanguage';
import { useNotificationMessageLoader } from './useNotificationMessageLoader';

export const useNotificationDetail = () => {
	const [selected, setSelected] = useState<NotificationDto>();
	const { language } = useAppLanguage();
	const { reloadNotifications } = useNotificationMessageLoader();

	const query = usePoQuery({
		queryKey: ['notification', 'detail', selected?.id?.toString()],
		queryFn: async () =>
			selected ? notificationsDetailQuery(selected.id) : undefined,
		enabled: !!selected,
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const detail = query.data;

	const modalLabel = detail?.popisKratky ?? detail?.popisDlouhy ?? '';
	const poLinks = extractPoLinkAttributes(detail?.popisDlouhy, language);
	const { links } = extractLink(detail?.popisDlouhy);

	useEffect(() => {
		if (selected?.id && query.isFetched && isUnreadNotification(selected)) {
			reloadNotifications();
		}
	}, [selected?.id, query.isFetched]);

	return {
		isFetching: query.isFetching,
		detail,
		select: setSelected,
		unselect: () => setSelected(undefined),
		id: selected?.id,
		modalLabel,
		links,
		poLinks,
		link: {
			url: detail?.rozsirujiciInformace?.odkazUrl ?? undefined,
			urlText: detail?.rozsirujiciInformace?.odkazText ?? undefined,
		},
	};
};
