import { axiosInstance } from '../axios';
import {
	AgendyAisAgendaDto,
	AgendyAisAgendaSeznamDto,
	CiselnikyCiselnikPolozkaDto,
} from '../generated';

export const searchAgendasQuery = async (
	text: string | null,
	razeni: 'nazev',
	start = 0
): Promise<AgendyAisAgendaSeznamDto> => {
	const { data } = await axiosInstance.get('/api/v1/agendy', {
		params: {
			text,
			razeni,
			start,
		},
	});
	return data;
};

export const agendasQuery = async (): Promise<{
	seznam?: AgendyAisAgendaDto[];
}> => {
	const { data } = await axiosInstance.get(`/api/v1/agendy`);
	return data;
};

export const ovmAgendasQuery = async (
	kodOvm: string,
	maUdaje?: boolean
): Promise<AgendyAisAgendaSeznamDto> => {
	const { data } = await axiosInstance.get(`/api/v1/ovm/${kodOvm}/agendy`, {
		params: maUdaje ? { maUdaje } : {},
	});
	return data;
};

export interface CiselnikyCiselnikPolozkaDtoAgendaData
	extends CiselnikyCiselnikPolozkaDto {
	parametry: {
		adresaText?: string;
	};
}

export const agendasCiselnikyQuery = async (
	kod: string
): Promise<{
	seznam?: CiselnikyCiselnikPolozkaDtoAgendaData[];
}> => {
	const { data } = await axiosInstance.get(
		`/api/v1/ciselniky/UDAJE_AGENDY_${kod}/polozky`
	);
	return data;
};
