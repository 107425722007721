import { GovIcon } from '@gov-design-system-ce/react';
import React, { PropsWithChildren, ReactNode } from 'react';
import { Link } from 'react-router-dom';

export interface TileButtonProps {
	onClick: () => void;
}

export interface TileLinkProps {
	to: string;
}

export interface TileNotClickableProps {}

interface CommonProps {
	title: string;
	children?: ReactNode;
	icon?: ReactNode;
	favoriteToggle?: ReactNode;
}

type Props = CommonProps &
	(TileLinkProps | TileButtonProps | TileNotClickableProps);

const isLinkProps = (props: Props): props is TileLinkProps & CommonProps =>
	(props as TileLinkProps).to !== undefined;
const isButtonProps = (props: Props): props is TileButtonProps & CommonProps =>
	(props as TileButtonProps).onClick !== undefined;

const ContainerComponent = (props: PropsWithChildren<Props>) => {
	if (isLinkProps(props)) {
		return (
			<Link
				to={props.to}
				className={
					'flex gap-2 justify-between items-start w-full no-underline'
				}>
				{props.children}
			</Link>
		);
	}

	if (isButtonProps(props)) {
		return (
			<button
				onClick={props.onClick}
				className={
					'flex gap-2 justify-between items-start w-full no-underline'
				}>
				{props.children}
			</button>
		);
	}

	return <div>{props.children}</div>;
};

export const Tile = (props: Props) => {
	const { title, children, icon, favoriteToggle } = props;

	const isClickable = isLinkProps(props) || isButtonProps(props);

	return (
		<li>
			<div
				className={
					'p-4 transition-colors duration-200 ease-in relative [ hover:bg-primary-200 ][ md:p-6 ]'
				}>
				<ContainerComponent {...props}>
					{icon ? (
						<div
							className={
								'hidden [ md:block md:grow-0 md:shrink-0 md:basis-10 ]'
							}>
							{icon}
						</div>
					) : null}
					<div
						className={
							'grow gap-2 flex flex-col justify-start text-left pr-4 [ md:ml-4 ]'
						}>
						<h3 className={'text-l text-primary-600 [ md:text-xl ]'}>
							{title}
						</h3>
						{children}
					</div>
					<div className={'grow-0 shrink-0 basis-5 mt-0.5 text-center'}>
						{isClickable ? (
							<GovIcon
								name="chevron-right"
								type="basic"
								slot="icon"
								className={'w-3 text-primary-600'}></GovIcon>
						) : null}
					</div>
				</ContainerComponent>

				{favoriteToggle ? (
					<div
						className={
							'absolute top-14 right-1.5 z-30 [ md:top-16 md:right-3.5 ]'
						}>
						{favoriteToggle}
					</div>
				) : null}
			</div>
		</li>
	);
};

export const Tiles = ({ children }: PropsWithChildren) => {
	return <ul className={'gov-list--plain -ml-8 -mr-8 px-4'}>{children}</ul>;
};
