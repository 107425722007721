import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { FormDefinition2, useFormBuilder } from '@gov-nx/core/service';
import { usePpdfSouhlasyLocale } from '@gov-nx/module/page';
import { DataBoxFormData } from '@gov-nx/ui/types';
import { DataBoxFields, useDataboxDefinition } from '@gov-nx/utils/common';

export type PpdfSouhlasyUdeleniStep3 = DataBoxFormData;

type Fields = DataBoxFields;

export type PpdfSouhlasyUdeleniFormStep3 = FormDefinition2<
	PpdfSouhlasyUdeleniStep3,
	Fields
>;

export const useFormStep3 = ({
	isDisabled,
	fixedDataBoxId,
	databoxTypes,
}: {
	isDisabled: boolean;
	fixedDataBoxId: string;
	databoxTypes: DatovkaApiType['typSchranky'][];
}) => {
	const { t } = usePpdfSouhlasyLocale();

	const databox = useDataboxDefinition({
		name: 'datovaSchrankaId',
		label: t('formular.datova-schranka.label'),
		isDisabled,
		fixedDataBoxId: fixedDataBoxId,
		types: databoxTypes,
	});

	return useFormBuilder<PpdfSouhlasyUdeleniStep3, Fields>(databox);
};
