import React from 'react';
import { ApplicantFO } from '@gov-nx/component/web';
import { useApplicantLoader } from '@gov-nx/core/hooks';
import {
	FormDefinition,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { EPeticeFormDataStep1 } from '@gov-nx/module/page';
import { FormRadio } from '@gov-nx/ui/web';
import { ApplicantPO } from './ApplicantPO';

interface FounderSelectProps {
	code: PageCode;
	isDisabled: boolean;
	formDefinition: FormDefinition<EPeticeFormDataStep1>;
	applicant: ReturnType<typeof useApplicantLoader>;
}

export const FounderSelect = ({
	applicant,
	formDefinition,
	code,
	isDisabled,
}: FounderSelectProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	if (applicant.businessPerson?.seznam?.length && applicant.individualPerson) {
		return (
			<FormRadio
				field={{
					name: 'zakladatelTyp',
					disabled: isDisabled,
					options: [
						{
							value: 'FO',
							label: { children: tsn('formular.pole.zakladatel-typ-fo') },
							children:
								formDefinition.formMethods.getValues('zakladatelTyp') ===
								'FO' ? (
									<ApplicantFO
										strings={{
											nameLabel: tsn('formular.zakladatel.prijmeni'),
											surnameLabel: tsn('formular.zakladatel.jmeno'),
											dateOfBirthLabel: tsn(
												'formular.zakladatel.datum-narozeni'
											),
											addressLabel: tsn('formular.zakladatel.adresa'),
										}}
										person={applicant.individualPerson}
									/>
								) : undefined,
						},
						{
							value: 'PO',
							label: { children: tsn('formular.pole.zakladatel-typ-po') },
							children:
								formDefinition.formMethods.watch('zakladatelTyp') === 'PO' ? (
									<ApplicantPO code={code} />
								) : undefined,
						},
					],
				}}
				label={{ children: tsn('formular.pole.zakladatel-typ') }}
				control={{ size: 'm' }}
				group={{ gap: 's' }}
			/>
		);
	}

	if (applicant.individualPerson) {
		return (
			<ApplicantFO
				strings={{
					nameLabel: tsn('formular.zakladatel.prijmeni'),
					surnameLabel: tsn('formular.zakladatel.jmeno'),
					dateOfBirthLabel: tsn('formular.zakladatel.datum-narozeni'),
					addressLabel: tsn('formular.zakladatel.adresa'),
				}}
				person={applicant.individualPerson}
			/>
		);
	}
	if (applicant.businessPerson) {
		return <ApplicantPO code={code} />;
	}
	return null;
};
