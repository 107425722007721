import { GovIcon, GovModal } from '@gov-design-system-ce/react';
import { useSearchParamsOperations } from '@gov-nx/web';
import { parseIcon } from '@gov-nx/component/common';
import { useCmsExternalServiceModalLoader } from '@gov-nx/core/service';
import { SmartFormContextProvider } from '@gov-nx/module/service';
import { Button, DataTable } from '@gov-nx/ui/web';
import { FavoriteToggle } from '../Favorite/FavoriteToggle';
import { MarkdownRender } from '../Markdown/MarkdownRender';
import { SmartForm } from './SmartForm';

export const CmsExternalServiceListener = () => {
	const { hasParam, deleteParam } = useSearchParamsOperations();
	const { id, externalService, setId, urlCmsPath } =
		useCmsExternalServiceModalLoader({
			onExternalServiceSuccess: () => {
				if (hasParam('formId')) {
					deleteParam('formId');
				}
			},
		});

	if (id && externalService) {
		const parsedIcon = externalService.icon?.icon
			? parseIcon(externalService.icon.icon)
			: null;
		return (
			<GovModal
				open={!!id}
				label={externalService.name}
				onGov-close={() => setId(null)}
				wcagCloseLabel={'Zavřít okno s externím službou'}>
				<div className={'w-full relative -top-4 -left-3'}>
					<FavoriteToggle
						withLabel={true}
						code={externalService.code}
						name={externalService.name}
						size={'s'}
					/>
				</div>
				<MarkdownRender>{externalService.description}</MarkdownRender>
				<DataTable customClasses={'my-6 text-secondary-800'}>
					<tbody>
						{externalService.parameters.map((parameter) => {
							return (
								<tr key={parameter.id}>
									<td>{parameter.key}:</td>
									<td>
										<MarkdownRender
											activatePhoneNumbers
											applyClasses={{ paragraph: 'font-bold' }}
											disableWrapper>
											{parameter.value}
										</MarkdownRender>
									</td>
								</tr>
							);
						})}
					</tbody>
				</DataTable>
				{externalService.links.map((link) => {
					if (link.link.startsWith('?') && link.link.includes('formId=')) {
						return (
							<SmartFormContextProvider
								link={link}
								key={link.link}>
								<SmartForm />
							</SmartFormContextProvider>
						);
					}

					return (
						<Button
							enforceLink={true}
							target={'_blank'}
							href={link.link}
							key={link.id}
							type={link.properties.type}
							variant={link.properties.variant}
							inverse={link.properties.inverse}
							wcagLabel={link.label}>
							{link.label}
							<GovIcon
								type={'basic'}
								name={'box-arrow-up-right'}
								slot={'right-icon'}></GovIcon>
						</Button>
					);
				})}
				{externalService.icon?.file ? (
					<img
						slot={'icon'}
						src={urlCmsPath + externalService.icon?.file}
						alt=""
					/>
				) : (
					<GovIcon
						name={parsedIcon?.name}
						type={parsedIcon?.type}
						slot="icon"></GovIcon>
				)}
			</GovModal>
		);
	}

	return null;
};
